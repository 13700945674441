import { useMemo } from "react";

import { ANALYTICS_ATTRIBUTES, COMPONENT_PLACEMENT } from "@bwll/bw-types";
import { getISO } from "@bwll/bw-utils";

import {
  IMPRESSION_EVENT_NAMES,
  ON_LINK_CLICK_EVENT_NAMES,
  TFeatureAnalyticsAttributes,
} from "./analyticsContext.types";
import { AnalyticsEventProps } from "./featuresAttributes";

export const useSnackbarTrackEvents = ({ trackEvent, commonAttributes }: AnalyticsEventProps) => {
  //same uuid for both product viewed/clicked events

  const eventData = useMemo(() => {
    return {
      [ANALYTICS_ATTRIBUTES.IS_REDESIGNED_DASHBOARD]: true,
      [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]: getISO(),
    };
  }, []);

  return useMemo(
    () => ({
      snackbarViewed: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => {
        trackEvent(IMPRESSION_EVENT_NAMES.SNACKBAR_VIEWED, {
          ...commonAttributes,
          ...componentEventData,
          ...eventData,
          [ANALYTICS_ATTRIBUTES.PLACEMENT]: COMPONENT_PLACEMENT.DASHBOARD,
        });
      },
      snackbarClicked: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => {
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.SNACKBAR_CLICKED, {
          ...commonAttributes,
          ...componentEventData,
          ...eventData,
          [ANALYTICS_ATTRIBUTES.PLACEMENT]: COMPONENT_PLACEMENT.DASHBOARD,
        });
      },
      // Not supported by the Recommendation Snackbar
      snackbarExpanded: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => {
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.SNACKBAR_EXPANDED, {
          ...commonAttributes,
          ...componentEventData,
          ...eventData,
        });
      },
      // Not supported by the Recommendation Snackbar
      snackbarCollapsed: <T>(componentEventData: TFeatureAnalyticsAttributes<T>) => {
        trackEvent(ON_LINK_CLICK_EVENT_NAMES.SNACKBAR_COLLAPSED, {
          ...commonAttributes,
          ...componentEventData,
          ...eventData,
        });
      },
    }),
    [commonAttributes, trackEvent, eventData],
  );
};
