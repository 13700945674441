import { useCallback } from "react";

import { ANALYTICS_ATTRIBUTES, EVENT_LABEL } from "@bwll/bw-types";
import { getISO, uuid } from "@bwll/bw-utils";

import { AnalyticsEventProps, TFeatureAnalyticsAttributes } from "../..";
import {
  DropdownAnalyticsAttributes,
  FilterAnalyticsAttributes,
  ProductClickedAnalyticsAttributes,
  ProductCommonAnalyticsAttributes,
} from "./useMarketplaceEvents.types";

export const useMarketplaceEvents = ({ commonAttributes, trackEvent }: AnalyticsEventProps) => {
  const onMarketplaceProductViewed = useCallback(
    <T = ProductCommonAnalyticsAttributes>(featureAttributes: TFeatureAnalyticsAttributes<T>) => {
      trackEvent(EVENT_LABEL.PRODUCT_VIEWED, {
        ...commonAttributes,
        ...featureAttributes,
        [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]: getISO(),
      });
    },
    [commonAttributes, trackEvent],
  );

  const onMarketplaceProductClicked = useCallback(
    <T = ProductClickedAnalyticsAttributes>(featureAttributes: TFeatureAnalyticsAttributes<T>) => {
      trackEvent(EVENT_LABEL.PRODUCT_CLICKED, {
        ...commonAttributes,
        ...featureAttributes,

        [ANALYTICS_ATTRIBUTES.CLICK_ID]: uuid(),
      });
    },
    [commonAttributes, trackEvent],
  );

  const onMarketplaceProductExpanded = useCallback(
    <T = ProductClickedAnalyticsAttributes>(featureAttributes: TFeatureAnalyticsAttributes<T>) => {
      trackEvent(EVENT_LABEL.PRODUCT_EXPANDED, {
        ...commonAttributes,
        ...featureAttributes,

        [ANALYTICS_ATTRIBUTES.CLICK_ID]: uuid(),
      });
    },
    [commonAttributes, trackEvent],
  );

  const onMarketplaceDropdownClicked = useCallback(
    <T = DropdownAnalyticsAttributes>(featureAttributes: TFeatureAnalyticsAttributes<T>) => {
      trackEvent(EVENT_LABEL.DROPDOWN_CLICKED, {
        ...commonAttributes,
        ...featureAttributes,
      });
    },
    [commonAttributes, trackEvent],
  );
  const onMarketplaceDropdownOptionClicked = useCallback(
    <T = DropdownAnalyticsAttributes>(featureAttributes: TFeatureAnalyticsAttributes<T>) => {
      trackEvent(EVENT_LABEL.DROPDOWN_OPTION_CLICKED, {
        ...commonAttributes,
        ...featureAttributes,
      });
    },
    [commonAttributes, trackEvent],
  );

  const onMarketplaceFilterSelected = useCallback(
    <T = FilterAnalyticsAttributes>(featureAttributes: TFeatureAnalyticsAttributes<T>) => {
      trackEvent(EVENT_LABEL.FILTER_SELECTED, {
        ...commonAttributes,
        ...featureAttributes,
      });
    },
    [commonAttributes, trackEvent],
  );

  return {
    onMarketplaceProductViewed,
    onMarketplaceProductClicked,
    onMarketplaceProductExpanded,
    onMarketplaceDropdownOptionClicked,
    onMarketplaceDropdownClicked,
    onMarketplaceFilterSelected,
  };
};
