import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

type StyledLayoutProps = { isMobile?: boolean; isTablet?: boolean };

export const styles = {
  successImage: {
    width: 200,
    height: 200,
  },
};

export const PageContainer = styled.View`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.View`
  padding: 0 ${styledValue(spacing.xs)} 0 ${styledValue(spacing.xs)};
`;

export const SectionContainer = styled.View`
  margin: 0 auto;
`;

export const ImageContainer = styled.View`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const FooterContainer = styled.View`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Footer = styled.View<StyledLayoutProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ isMobile }) =>
    isMobile ? `${spacing.m}px ${spacing.m}px` : `${spacing.xl}px ${spacing.xl}px`};
  position: fixed;
  width: calc(100% - 48px);
  bottom: 0;
`;

export const ButtonContainer = styled.View<StyledLayoutProps>`
  width: ${({ isMobile }) => (isMobile ? "100%" : "360px")};
`;
