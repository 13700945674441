import React, { useCallback } from "react";

import { RecommendationsSnackbar } from "@bwll/bw-modules";
import { SessionStorageKeys } from "@bwll/bw-types";
import { useInternalNavigate } from "@bwll/dashboard/src/hooks";

import { MARKETPLACE_ROUTES } from "@app/router";

/**
 * A wrapper around the RecommendationsSnackbar component
 * that applies styling related to the screen implementation
 * and handles all platform-specific navigation callbacks
 * and analytics.
 */
export const RecommendationsSnackbarCard = () => {
  const { navigateToVertical } = useInternalNavigate();
  const shouldShowBadge = localStorage.getItem(SessionStorageKeys.DashboardShowBadge) !== "false";
  const recommendationSnackbarRoute = MARKETPLACE_ROUTES.CREDIT_CARDS;
  const analyticsAttributes = {
    destinationUrl: recommendationSnackbarRoute,
  };

  const navigateToCreditCardsVertical = useCallback(() => {
    localStorage.setItem(SessionStorageKeys.DashboardShowBadge, "false");
    navigateToVertical(recommendationSnackbarRoute);
  }, [navigateToVertical, recommendationSnackbarRoute]);

  return (
    <RecommendationsSnackbar
      analyticsAttributes={analyticsAttributes}
      redirectAction={navigateToCreditCardsVertical}
      shouldShowBadge={shouldShowBadge}
    />
  );
};
