import { PathParam, generatePath } from "react-router";

/**
 * Generates a React Router To object with an interpolated path.
 *
 * @see {@link https://reactrouter.com/en/main/utils/generate-path}
 * @param originalPath An uninterpolated path to navigate to.
 * @param pathParams An object of the dynamic path parts to interpolate with their values.
 * @param searchParams An optional object to parse into search params and attach to the end of the path.
 * @returns A React Router To object to use with navigate.
 */
export const generateTo = <TPath extends string>(
  originalPath: TPath,
  params: {
    pathParams?: { [key in PathParam<TPath>]: string | null };
    searchParams?: Record<string, string>;
  } = {},
) => {
  const { pathParams, searchParams } = params;
  const pathname = generatePath(originalPath, pathParams);
  const search = searchParams && new URLSearchParams(Object.entries(searchParams)).toString();
  return { pathname, search };
};
