import styled from "@emotion/native";

import { styledValue } from "@bwll/bw-utils";

export const BUTTON_MAX_WIDTH = 360;

export const Container = styled.View<{ isDesktop: boolean }>`
  width: 100%;
  max-width: ${({ isDesktop }) => (isDesktop ? styledValue(BUTTON_MAX_WIDTH) : "100%")};
`;
