import styled, { css } from "@emotion/native";

import { Body2, Heading2 } from "@bwll/bw-components/next";
import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.View`
  padding-vertical: 40px;
  flex: 1 1 100%;
`;

export const ItemsContainer = styled.View<{ isDesktop: boolean }>`
  flex-direction: ${({ isDesktop }) => (isDesktop ? "row" : "column")};
  flex: 1 1 100%;
  gap: ${styledValue(spacing.l)};
`;

export const ItemContainer = styled.View`
  flex-direction: column;
  align-items: center;
  flex: 1 1 100%;
  gap: ${styledValue(spacing.l)};
`;

export const imageStyles = css`
  width: 120px;
  height: 120px;
`;

export const ItemTextContainer = styled.View`
  flex: 1 1 100%;
`;

export const ItemTitleText = styled(Heading2)`
  color: ${COLORS.NEUTRAL.WARM["800"]};
  text-align: center;
`;

export const ItemDescriptionText = styled(Body2)`
  color: ${COLORS.NEUTRAL.COOL["600"]};
  text-align: center;
`;
