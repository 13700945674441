import React from "react";
import ReactDOMClient from "react-dom/client";
import { QueryClient } from "react-query";
import singleSpaReact from "single-spa-react";

import { SingleSpaCustomProps } from "@bwll/bw-types";

import App from "@app/App";

const defaultQueryClient = new QueryClient();

const getDefaultQueryClient = () => {
  // eslint-disable-next-line no-console
  console.warn("Shared Query Client not set, using independent instance.");
  return defaultQueryClient;
};

const lifecycles = singleSpaReact<SingleSpaCustomProps>({
  React,
  ReactDOMClient,
  // activatedPath is passed as custom prop from the web-root to determine the basename of the BrowserRouter.
  loadRootComponent: (props) => {
    const queryClient = props?.queryClient ?? getDefaultQueryClient();
    return new Promise((resolve) => {
      resolve(() => {
        return <App activatedPath={props?.activatedPath ?? "/"} queryClient={queryClient} />;
      });
    });
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
