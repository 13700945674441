import styled from "@emotion/styled";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${`${styledValue(spacing.m)} ${styledValue(spacing.m)}`};
  position: fixed;
  width: ${`calc(100% - ${spacing.m * 2}px)`};
  bottom: 0;
  border-top: 1px solid ${COLORS.NEUTRAL.COOL["100"]};
  background-color: ${COLORS.WHITE};
`;
