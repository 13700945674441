import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { ElementTracker, OfferCardTemplate } from "@bwll/bw-components/next";
import { AnalyticsContext, LTOCarouselCommonEventData } from "@bwll/bw-hooks";
import { COLORS } from "@bwll/bw-styles";
import { DATE_FORMATS } from "@bwll/bw-types";
import { formatDate } from "@bwll/bw-utils";

import { useLTOCarouselCommonAttributes } from "../useLTOCarouselCommonAttributes";
import { LTO_CARD_BANNER_TEST_ID } from "./LimitedTimeOfferCard.constants";
import { LimitedTimeOfferCardProps } from "./LimitedTimeOfferCard.types";

export const LimitedTimeOfferCard = ({
  limitedTimeOffer,
  primaryAction,
  secondaryAction,
  activeProductId,
  position,
}: LimitedTimeOfferCardProps) => {
  const i18next = useTranslation();
  const { productAnalytics } = useContext(AnalyticsContext);
  const analyticsAttributes = useLTOCarouselCommonAttributes(limitedTimeOffer, position);
  const buttonIconName = limitedTimeOffer.isQuickApply ? "new_tab" : undefined;

  const onTrackView = useCallback(() => {
    productAnalytics.productViewed<LTOCarouselCommonEventData>(analyticsAttributes);
  }, [analyticsAttributes, productAnalytics]);

  const primaryBtnClick = useCallback(() => {
    productAnalytics.productClicked<LTOCarouselCommonEventData>(analyticsAttributes);
    primaryAction(limitedTimeOffer);
  }, [analyticsAttributes, limitedTimeOffer, productAnalytics, primaryAction]);

  const secondaryBtnClick = useCallback(() => {
    productAnalytics.buttonClicked<LTOCarouselCommonEventData>(analyticsAttributes);
    secondaryAction(limitedTimeOffer);
  }, [analyticsAttributes, limitedTimeOffer, productAnalytics, secondaryAction]);

  return (
    <ElementTracker
      shouldStartTracking={activeProductId === limitedTimeOffer.id}
      trackingHandler={onTrackView}
    >
      <OfferCardTemplate
        testID={LTO_CARD_BANNER_TEST_ID}
        badgeIcon={"alarm"}
        badgeText={
          limitedTimeOffer.offerEndDate &&
          i18next.t("dashboard:cards:ltoCarousel:offerEndDatePrefix", {
            offerEndDate: formatDate({
              dateString: limitedTimeOffer.offerEndDate?.toString(),
              dateFormat: DATE_FORMATS.MMM_d,
            }).toLocaleUpperCase(),
          })
        }
        badgeBackgroundColor={COLORS.RED["050"]}
        badgeColor={COLORS.RED.DEFAULT}
        imageUrl={limitedTimeOffer.productImageUrl}
        buttonIconAlignment={"right"}
        buttonIconName={buttonIconName}
        header={limitedTimeOffer.productCategory}
        title={limitedTimeOffer.productName}
        body={limitedTimeOffer.bonus}
        primaryCtaTitle={
          limitedTimeOffer.isQuickApply
            ? i18next.t("dashboard:cards:ltoCarousel:primaryActionQuickApply")
            : i18next.t("dashboard:cards:ltoCarousel:primaryAction")
        }
        secondaryCtaTitle={i18next.t("dashboard:cards:ltoCarousel:secondaryAction")}
        primaryOnPress={primaryBtnClick}
        secondaryOnPress={secondaryBtnClick}
      />
    </ElementTracker>
  );
};
