import styled from "@emotion/native";

import { Display2 } from "@bwll/bw-components/next/atoms/Typography";
import { BoldText } from "@bwll/bw-components/next/atoms/Typography/Default";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const ScreenHeaderContainer = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: last baseline;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const LeftSection = styled.View`
  max-width: 100%;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: flex-start;
  gap: ${styledValue(spacing.xxs)};
`;

export const Title = styled(Display2)<{ isMobile: boolean }>`
  color: ${COLORS.NEUTRAL.WARM["800"]};
  font-size: ${({ isMobile }) => (isMobile ? styledValue(fontSize.m) : styledValue(fontSize.xxl))};
`;

export const RightSection = styled.View`
  max-width: 100%;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const AdDisclosureButton = styled.TouchableOpacity<{ isWeb: boolean }>`
  ${({ isWeb }) =>
    isWeb
      ? `
  align-items: flex-start;`
      : `
  align-items: center;
  flex: 1;`}
  justify-content: center;
  margin-left: ${styledValue(spacing.s)};
`;

export const AdDisclosureText = styled(BoldText)<{ isWeb: boolean }>`
  font-size: ${({ isWeb }) => styledValue(isWeb ? 11 : fontSize.xs)};
  color: ${COLORS.NEUTRAL.WARM["700"]};
  text-decoration: underline;
  text-decoration-color: ${COLORS.NEUTRAL.WARM["700"]};
`;
