import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";

import { ErrorBoundary } from "@bwll/bw-components/next";
import favicon from "@bwll/bw-components/src/assets/favicon-32x32.png";
import { ErrorReporting } from "@bwll/bw-utils";

import { ErrorBoundaryScreen } from "../ErrorBoundaryScreen";
import { AppOuterContainer, AppWidthContainer } from "./AppShell.styles";
import { AppShellProps } from "./AppShell.types";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";
import { ContextProviders } from "@app/contexts";
import "@app/styles/index.css";

/**
 * Initializes error reporting services.
 */
ErrorReporting.init({
  sentry: {
    dsn: ENVIRONMENT_VARIABLES.SENTRY_DSN,
    environment: ENVIRONMENT_VARIABLES.ENVIRONMENT,
    tracesSampleRate: parseFloat(ENVIRONMENT_VARIABLES.SENTRY_TRACES_SAMPLE_RATE) || 0.1,
    replaysOnErrorSampleRate: parseFloat(ENVIRONMENT_VARIABLES.SENTRY_REPLAYS_ERROR_SAMPLE_RATE) || 0,
    replaysSessionSampleRate: parseFloat(ENVIRONMENT_VARIABLES.SENTRY_REPLAYS_SESSION_SAMPLE_RATE) || 0,
  },
  appInsights: {
    connectionString: ENVIRONMENT_VARIABLES.APP_INSIGHTS_CONNECTION_STRING,
  },
});

export const AppShell = ({ queryClient }: AppShellProps) => {
  // TODO: Refactor helmet to be handled in a separate component
  const appMetaTags = (
    <Helmet>
      <title>Borrowell Dashboard</title>
      <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
    </Helmet>
  );

  return (
    <ErrorBoundary onError={ErrorReporting.logError} FallbackComponent={<ErrorBoundaryScreen />}>
      <ContextProviders queryClient={queryClient}>
        {appMetaTags}
        <AppOuterContainer>
          <AppWidthContainer>
            <Outlet />
          </AppWidthContainer>
        </AppOuterContainer>
      </ContextProviders>
    </ErrorBoundary>
  );
};
