export const roundWithPrecision = (num: number, precision = 0) => {
  const actualPrecision = precision >= 0 ? Math.min(precision, 292) : Math.max(precision, -292);

  if (actualPrecision) {
    // Shift with exponential notation to avoid floating-point issues.
    // See [MDN](https://mdn.io/round#Examples) for more details.
    let pair = `${num}e`.split("e");
    const value = Math.round(+`${pair[0]}e${+pair[1] + actualPrecision}`);
    pair = `${value}e`.split("e");
    return +`${pair[0]}e${+pair[1] - actualPrecision}`;
  }

  return Math.round(num);
};
