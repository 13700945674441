import { useCallback } from "react";

import { CtaPressHandlerParams, SponsoredCarousel } from "@bwll/bw-modules";
import { DASHBOARD_ANALYTICS_ORIGINS, Nullable, PRODUCT_VERTICAL_GUID } from "@bwll/bw-types";

import { useDashboardProductApplication } from "@app/hooks";

export const SponsoredCarouselCard = () => {
  const productApply = useDashboardProductApplication();

  const navigate = useCallback(
    ({ carouselItem }: CtaPressHandlerParams) => {
      /** @TODO handle Fig navigation later */
      productApply(
        {
          ...carouselItem,
          id: carouselItem.productReferenceId,
          productVerticalId: carouselItem.productVerticalId as unknown as Nullable<PRODUCT_VERTICAL_GUID>,
          impressionedProductLink: carouselItem.ctaLink,
        },
        DASHBOARD_ANALYTICS_ORIGINS.SPONSORED_PLACEMENT,
      );
    },
    [productApply],
  );

  return <SponsoredCarousel transformCtaLink={() => ""} onPress={navigate} />;
};
