import jwtDecode from "jwt-decode";
import { useCallback, useMemo } from "react";
import { event } from "react-ga";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";

import { useSessionContext, useTrackEvent } from "@bwll/bw-hooks";
import {
  ANALYTICS_ATTRIBUTES,
  APPLICATION_TYPE,
  COOKIE_NAME,
  DEVICE_PLATFORM,
  EVENT_ACTIONS,
  IAnalyticsAttributes,
  LAYOUT_TYPE,
} from "@bwll/bw-types";
import { getCookie } from "@bwll/bw-utils";

export const useAnalyticsEvent = (commonCustomAttributes?: IAnalyticsAttributes) => {
  const { mutate } = useTrackEvent();
  const { userData } = useSessionContext();
  const { search } = useLocation();
  const origin = new URLSearchParams(search).get("origin") ?? undefined;

  const commonAttributes = useMemo(() => {
    const {
      individualClientId,
      bwsid: authenticatedSessionId,
      auth_time: sessionStartTime,
    } = jwtDecode(userData?.accessToken);

    const applicationType = APPLICATION_TYPE.SINGLE_SPA;

    // Determine layout type by the user agent
    const layoutType = window?.navigator?.userAgent?.match(/(iPad)|(iPhone)|(android)|(webOS)/i)
      ? LAYOUT_TYPE.MOBILE_WEB
      : LAYOUT_TYPE.WEB;

    // Determine the referrer
    const visitUrl = getCookie(COOKIE_NAME.VISIT_URL);
    const referrer = visitUrl || document?.referrer;

    if (!commonCustomAttributes) {
      commonCustomAttributes = {};
    }

    return {
      [ANALYTICS_ATTRIBUTES.INDIVIDUAL_CLIENT_ID]: individualClientId,
      [ANALYTICS_ATTRIBUTES.AUTHENTICATED_SESSION_ID]: authenticatedSessionId,
      [ANALYTICS_ATTRIBUTES.SESSION_START_TIME]: sessionStartTime,
      [ANALYTICS_ATTRIBUTES.SESSION_ID]: userData?.sessionId,
      [ANALYTICS_ATTRIBUTES.PLATFORM]: DEVICE_PLATFORM.WEB,
      // Adding this based on this ticket: https://borrowell.atlassian.net/browse/GMS-13543
      // It should cover for the release of Redesigned Dashboard V1
      // TODO: Investigate why do we have the same property with different casing(layoutType) and if it is necessary
      [ANALYTICS_ATTRIBUTES.GEMSTONE_LAYOUT_TYPE]: layoutType,
      [ANALYTICS_ATTRIBUTES.REFERRER]: referrer,
      [ANALYTICS_ATTRIBUTES.APPLICATION_TYPE]: applicationType,
      [ANALYTICS_ATTRIBUTES.PAGE]: window.location.href,
      [ANALYTICS_ATTRIBUTES.ORIGIN]: origin,
      /** This property is always equal to `event` on Gemstone web */
      [ANALYTICS_ATTRIBUTES.HIT_TYPE]: "event",
    };
  }, [origin, userData?.accessToken, userData?.sessionId]);

  const trackEvent = useCallback(
    (eventLabel: string, customAttributes: IAnalyticsAttributes, onSettled?: (arg: unknown) => void) => {
      customAttributes[ANALYTICS_ATTRIBUTES.EVENT_LABEL] = eventLabel;
      mutate(
        {
          ...commonAttributes,
          ...customAttributes,
          ...commonCustomAttributes,
        },
        { onSettled: onSettled },
      );
      event({
        label: eventLabel,
        category: customAttributes[ANALYTICS_ATTRIBUTES.EVENT_CATEGORY] ?? EVENT_ACTIONS.EVENT,
        action: customAttributes[ANALYTICS_ATTRIBUTES.EVENT_ACTION] ?? EVENT_ACTIONS.EVENT,
      });
      TagManager.dataLayer({
        dataLayer: {
          event: eventLabel,
          ...commonAttributes,
          ...customAttributes,
        },
      });
    },
    [commonAttributes, commonCustomAttributes, mutate],
  );

  return {
    trackEvent,
  };
};
