import React from "react";

import { Skeleton } from "@bwll/bw-components/next";
import { useGetPrimePromoCards } from "@bwll/bw-hooks";
import { PrimePromoCardsParam } from "@bwll/bw-types";

import { LimitedTimeOfferCarousel } from "./LimitedTimeOfferCarousel.component";
import { LIMITED_TIME_OFFER_CAROUSEL_SKELETON_TEST_ID } from "./LimitedTimeOfferCarousel.constants";
import { LimitedTimeOfferCarouselProps } from "./LimitedTimeOfferCarousel.types";

export const LimitedTimeOfferCarouselContainer = (props: LimitedTimeOfferCarouselProps) => {
  const promoCardTypes = [
    PrimePromoCardsParam.TopPicks,
    PrimePromoCardsParam.CompareBankAccounts,
    PrimePromoCardsParam.DashboardLTOs,
    PrimePromoCardsParam.TrendingOffers,
  ];
  const { data, isLoading } = useGetPrimePromoCards(promoCardTypes, {
    enabled: true,
  });
  if (isLoading) {
    return (
      <Skeleton
        width="100%"
        height={300}
        variant="rounded"
        testID={LIMITED_TIME_OFFER_CAROUSEL_SKELETON_TEST_ID}
      />
    );
  }

  /** Empty response */
  if (!isLoading && !Boolean(data?.dashboardLTOsPromoCard?.limitedTimeOffers?.length !== 0)) {
    return null;
  }

  return <LimitedTimeOfferCarousel dashboardLTOsPromoCard={data?.dashboardLTOsPromoCard} {...props} />;
};
