import styled from "@emotion/native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { BUTTON_MAX_WIDTH } from "../../RALandingPage.constants";

export const Container = styled.View`
  padding-vertical: ${styledValue(spacing.xl)};
`;

export const AccordionContainer = styled.View`
  padding-vertical: ${styledValue(spacing.xs)};
  border: 1px solid transparent;
  border-bottom-color: ${COLORS.NEUTRAL.COOL["200"]};
`;

export const TitleContainer = styled.View`
  flex: 1;
`;

export const DescriptionContainer = styled.View`
  margin-vertical: ${styledValue(spacing.xs)};
`;

export const ButtonContainer = styled.View`
  margin-top: ${styledValue(spacing.m)};
  margin-horizontal: auto;
  max-width: ${styledValue(BUTTON_MAX_WIDTH)};
  width: 100%;
`;
