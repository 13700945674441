import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks/src/contexts";
import { EidCreditReportStatusResponse, IApiHookOptions, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

const getCreditReportStatus = async (accessToken: string, baseUrl: string, individualClientId: string) => {
  const response = await axios.get<EidCreditReportStatusResponse>(
    `${baseUrl}/api/identity-verification/v1/identity-verification-status/${individualClientId}/credit-report-request-status`,
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return response.data;
};

/**
 * The hook should be polling the latest status of the credit report
 * to determine whether the user should be redirected to dashboard or error screen
 */
export const useCreditReportStatus = (
  individualClientId: string,
  options: IApiHookOptions<EidCreditReportStatusResponse> = {},
) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_CREDIT_REPORT_STATUS, accessToken),
    () => getCreditReportStatus(accessToken, envConfigs.API_GATEWAY_URL, individualClientId),
    {
      ...queryBehavior({
        expiresIn,
        ...options,
        enabled: !!accessToken && !!options.enabled,
      }),
    },
  );
};
