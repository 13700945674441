import styled from "@emotion/native";

import { Body1 } from "@bwll/bw-components/next";
import {
  COLORS,
  borderRadius,
  borderWidth,
  fontSize,
  fontWeights,
  letterSpacing,
  lineHeight,
  spacing,
} from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const MajorValuePropositionBox = styled.View<{
  majorValuePropositionTypeExists: boolean;
  isDesktop: boolean;
}>`
  justify-content: center;
  height: ${({ isDesktop }) => styledValue(isDesktop ? 64 : 56)};
  width: ${({ isDesktop }) => (isDesktop ? styledValue(272) : styledValue(100, "%"))};
  margin: auto;
  padding: ${styledValue(spacing.xxxs)};
  border: ${styledValue(borderWidth.small)};
  border-color: ${({ majorValuePropositionTypeExists }) =>
    majorValuePropositionTypeExists ? COLORS.PRIMARY["100"] : COLORS.NEUTRAL.COOL["200"]};
  border-radius: ${({ majorValuePropositionTypeExists }) =>
    majorValuePropositionTypeExists ? styledValue(borderRadius.s) : styledValue(borderRadius.xs)};
  ${({ majorValuePropositionTypeExists }) =>
    majorValuePropositionTypeExists
      ? `background-image: linear-gradient(${COLORS.WHITE}, ${COLORS.BRIGHT_GREEK["100"]})`
      : `background-color: ${COLORS.NEUTRAL.COOL["050"]}`};
`;

export const MajorValuePropositionContent = styled(Body1)<{ majorValuePropositionTypeExists: boolean }>`
  text-align: center;
  line-height: ${({ majorValuePropositionTypeExists }) =>
    majorValuePropositionTypeExists ? styledValue(lineHeight.xxl) : styledValue(lineHeight.m)};
  font-weight: ${({ majorValuePropositionTypeExists }) =>
    majorValuePropositionTypeExists ? fontWeights.bold : fontWeights.regular};
  color: ${COLORS.NEUTRAL.WARM["800"]};
  font-size: ${({ majorValuePropositionTypeExists }) =>
    majorValuePropositionTypeExists ? styledValue(fontSize.xl) : styledValue(fontSize.xs)};
`;

export const MajorValuePropositionType = styled(Body1)`
  text-align: center;
  line-height: ${styledValue(lineHeight.xs)};
  font-size: ${styledValue(fontSize.xxs)};
  letter-spacing: ${styledValue(letterSpacing.l)};
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;
