import { v4 as uuidv4 } from "uuid";

import {
  ApplyableProduct,
  ImpressionedProduct,
  ImpressionedProductVerticalBankAccount,
  ImpressionedProductVerticalCreditCard,
  ImpressionedProductVerticalLoan,
  ProductVerticalCreditCard,
  ProductVerticalLoan,
  QuickApplyableProduct,
} from "@bwll/bw-types";
import { ProductVerticalBankAccount } from "@bwll/bw-types/src/types/bankAccount";

import { getImpressionedUrl } from "./getCID";

type ProductVertical = ProductVerticalCreditCard | ProductVerticalLoan | ProductVerticalBankAccount;

const isQuickApplyable = <T extends ApplyableProduct | QuickApplyableProduct>(
  product: T,
): product is T & QuickApplyableProduct => {
  return (
    "isPreSelection" in product &&
    "preSelectedLink" in product &&
    "isQuickApply" in product &&
    "productReferenceNumber" in product
  );
};

/**
 * Gets an impressioned URL for an applyable product that does not have an impressioned URL yet.
 * @param product The product to get an impressioned URL for.
 * @param impressionId The member's impression ID.
 * @param individualClientIdReferenceNumber The member's individual client ID reference number.
 * @param options Overrides for the impressioned URL.
 * @returns The impressioned URL.
 */
export const getImpressionedUrlFromProduct = (
  product: ApplyableProduct | QuickApplyableProduct,
  impressionId: string,
  individualClientIdReferenceNumber: number,
  options: { overrideWithDashboardLink: boolean } = { overrideWithDashboardLink: false },
) => {
  const unimpressionedProductLink =
    isQuickApplyable(product) && product.isPreSelection && product.preSelectedLink
      ? product.preSelectedLink
      : options.overrideWithDashboardLink && product.dashboardLink
      ? product.dashboardLink
      : product.websiteLink;

  return getImpressionedUrl(unimpressionedProductLink, individualClientIdReferenceNumber, impressionId);
};

const getImpressionedProduct = <T extends ProductVertical>(
  product: T,
  individualClientIdReferenceNumber: number,
): T & ImpressionedProduct => {
  const productImpressionId = uuidv4();

  const {
    impressionedUrl: impressionedProductLink,
    originalCID,
    modifiedCID,
  } = getImpressionedUrlFromProduct(product, productImpressionId, individualClientIdReferenceNumber, {
    overrideWithDashboardLink: false,
  });

  return {
    ...product,
    productImpressionId,
    impressionedProductLink,
    originalCID,
    modifiedCID,
  };
};

export const getImpressionedBankAccount = (
  bankAccount: ProductVerticalBankAccount,
  individualClientIdReferenceNumber: number,
): ImpressionedProductVerticalBankAccount =>
  getImpressionedProduct(bankAccount, individualClientIdReferenceNumber);

export const getImpressionedCreditCard = (
  creditCard: ProductVerticalCreditCard,
  individualClientIdReferenceNumber: number,
): ImpressionedProductVerticalCreditCard =>
  getImpressionedProduct(creditCard, individualClientIdReferenceNumber);

export const getImpressionedLoan = (
  loan: ProductVerticalLoan,
  individualClientIdReferenceNumber: number,
): ImpressionedProductVerticalLoan => getImpressionedProduct(loan, individualClientIdReferenceNumber);
