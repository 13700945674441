import React, { useCallback } from "react";

import { TrendingOffersPromoCardContainer } from "@bwll/bw-modules";
import { GemstoneAppRoute } from "@bwll/bw-types";

import { useInternalNavigate } from "@app/hooks";

/**
 * A wrapper around the TrendingOffersCard component
 * that applies styling related to the screen implementation
 * and handles all platform-specific navigation callbacks
 * and analytics.
 */
export const TrendingOffersCard = () => {
  const { navigateToVerticalUsingGemstoneAppRoute } = useInternalNavigate();

  const navigate = useCallback(
    (route: string) => {
      navigateToVerticalUsingGemstoneAppRoute(route as GemstoneAppRoute);
    },
    [navigateToVerticalUsingGemstoneAppRoute],
  );

  return <TrendingOffersPromoCardContainer onPress={navigate} enabled />;
};
