export enum HISTORICAL_REPORTING_ANALYTICS_EVENT_NAME {
  HISTORICAL_REPORTING = "Historical Reporting",
}

export enum HISTORICAL_REPORTING_EVENT_VALUES {
  // SCREEN VIEWED EVENTS
  EDUCATION_STEP_VIEWED = "education_step_viewed",
  CHECKOUT_CONFIRMATION_STEP_VIEWED = "checkout_confirmation_step_viewed",

  // BUTTON CLICKED EVENTS
  GO_TO_DASHBOARD_BUTTON_CLICKED = "go_to_dashboard_button_clicked",
  START_BUILDING_CREDIT_BUTTON_CLICKED = "start_building_credit_button_clicked",
  VIEW_ALL_FAQS_BUTTON_CLICKED = "view_all_faqs_button_clicked",
  APPLICATION_EXIT_BUTTON_CLICKED = "application_exit_button_clicked",

  V1 = "v1.0",
  COMPLETED_ONBOARDING_STATE = "completed",
  NOT_STARTED_ONBOARDING_STATE = "not_started",
}
