import { Body2 } from "../Body2";
import { ItemRow, ListIcon, ListWrapper } from "./List.styles";

export interface ListProps {
  isOrdered?: boolean;
  children: string[];
  color?: string;
}

export const List = ({ children, isOrdered, color }: ListProps) => {
  if (isOrdered)
    return (
      <ListWrapper>
        {Array.isArray(children) &&
          children.map((item, index: number) => (
            <ItemRow key={`ordered-${index}-${item}`}>
              <Body2 color={color}>{`${index + 1}. `}</Body2>
              <Body2 color={color}>{item}</Body2>
            </ItemRow>
          ))}
      </ListWrapper>
    );

  return (
    <ListWrapper>
      {Array.isArray(children) &&
        children.map((item, index: number) => (
          <ItemRow key={`unordered-${index}-${item}`}>
            <ListIcon color={color}>{"\u2022"}</ListIcon>
            <Body2 color={color}>{item}</Body2>
          </ItemRow>
        ))}
    </ListWrapper>
  );
};
