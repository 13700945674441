import React, { useCallback } from "react";

import { MarketplaceSnackbar, MarketplaceSnackbarCardProps } from "@bwll/bw-modules";
import { DASHBOARD_ANALYTICS_ORIGINS, SessionStorageKeys, SnackBarViewStatus } from "@bwll/bw-types";
import { useInternalNavigate } from "@bwll/dashboard/src/hooks";

/**
 * A wrapper around the MarketplaceSnackbar component
 * that applies styling related to the screen implementation
 * and handles all platform-specific navigation callbacks
 * and analytics.
 */
export const MarketplaceSnackbarCard = ({ marketplaceSnackbarData }: MarketplaceSnackbarCardProps) => {
  const { navigateToProduct, generateDestinationUrl } = useInternalNavigate();
  const originQueryParam = `origin=${DASHBOARD_ANALYTICS_ORIGINS.SNACKBAR_PRODUCT_OFFER}`;
  const navigateToVerticalProduct = useCallback(() => {
    if (!marketplaceSnackbarData) return;
    localStorage.setItem(SessionStorageKeys.SnackbarShowExpanded, SnackBarViewStatus.Collapsed);
    navigateToProduct(
      marketplaceSnackbarData.vertical,
      marketplaceSnackbarData.productReferenceId,
      originQueryParam,
    );
  }, [navigateToProduct, originQueryParam, marketplaceSnackbarData]);

  const destinationUrl = !!marketplaceSnackbarData
    ? generateDestinationUrl(
        marketplaceSnackbarData.vertical,
        marketplaceSnackbarData.productReferenceId,
        originQueryParam,
      )
    : "";

  return (
    <MarketplaceSnackbar
      marketplaceSnackbar={marketplaceSnackbarData}
      redirectAction={navigateToVerticalProduct}
      analyticsAttributes={{
        destinationUrl,
      }}
    />
  );
};
