import React, { useCallback } from "react";
import { Pressable } from "react-native";

import { CardContainer, ElementTracker, Icon, IconNames } from "@bwll/bw-components/next";
import { VerticalPromoCardsEventData, useAnalyticsContext } from "@bwll/bw-hooks";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { ANALYTICS_ATTRIBUTES } from "@bwll/bw-types";
import { uuid } from "@bwll/bw-utils";

import * as Styled from "../VerticalPromoCards.style";
import { useVerticalPromoCardsCommonAttributes } from "../useVerticalPromoCardsCommonAttributes";
import { VerticalCardProps } from "./VerticalCard.types";

export const VerticalCard = ({ verticalCard, redirectAction }: VerticalCardProps) => {
  const { promoCardViewed, promoCardClicked } = useAnalyticsContext();
  const analyticsAttributes = useVerticalPromoCardsCommonAttributes(verticalCard);

  const onTrackView = useCallback(() => {
    promoCardViewed<VerticalPromoCardsEventData>(analyticsAttributes);
  }, [analyticsAttributes, promoCardViewed]);

  const clickAction = useCallback(() => {
    redirectAction(verticalCard.verticalId, promoCardClicked, {
      ...analyticsAttributes,
      [ANALYTICS_ATTRIBUTES._CLICK_ID]: uuid(),
    });
  }, [analyticsAttributes, promoCardClicked, redirectAction, verticalCard.verticalId]);

  return (
    <Styled.CardWrapper>
      <ElementTracker shouldStartTracking={true} trackingHandler={onTrackView}>
        <Pressable onPress={clickAction}>
          <CardContainer
            testID={verticalCard.testID}
            paddingHorizontal={spacing.xxs}
            paddingVertical={spacing.xxs}
          >
            <Styled.CardContentWrapper>
              <Styled.IconContainer backgroundColor={verticalCard.iconBackgroundColor}>
                <Icon
                  icon={verticalCard.icon as IconNames}
                  color={COLORS.NEUTRAL.WARM["800"]}
                  size={fontSize.m}
                />
              </Styled.IconContainer>
              <Styled.VerticalText>{verticalCard.title}</Styled.VerticalText>
            </Styled.CardContentWrapper>
          </CardContainer>
        </Pressable>
      </ElementTracker>
    </Styled.CardWrapper>
  );
};
