export const CREDIT_CARD_COMPARE_TABLE_TEST_IDS = {
  PREQUALIFIED_BANNER: "ccct-prequalified-banner",
  APPROVAL_CHANCE: "ccct-approval-chance",
  DETAILS_BUTTON: "ccct-details-button",
  APPLY_BUTTON: "ccct-apply-button",
  REMOVE_CARD_BUTTON: "ccct-remove-card-button",
  ADD_CARD_BUTTON: "ccct-add-card-button",
  ADD_CARD_IMAGE: "ccct-add-card-image",
} as const;

export const IMAGE_DIMENSIONS = {
  HEIGHT: 80,
  WIDTH: 125,
} as const;
