import styled, { css } from "@emotion/native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const titleTextStyles = css`
  margin-bottom: ${styledValue(spacing.xs)};
`;

export const Container = styled.View<{ shouldDisplayBorder: boolean }>`
  margin-top: ${styledValue(spacing.xs)};
  padding-bottom: ${styledValue(spacing.xxs)};
  border: 1px solid transparent;
  border-bottom-color: ${({ shouldDisplayBorder }) =>
    shouldDisplayBorder ? COLORS.NEUTRAL.COOL["100"] : "transparent"};
`;

export const CardContainer = styled.View`
  margin-bottom: ${styledValue(spacing.xs)};
`;
