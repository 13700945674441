import i18next from "i18next";

import { CreditFactor, TotalAccountsStatus, TotalAccountsTypes } from "@bwll/bw-types";

/**
 *
 * A function that returns a message based on the status of the total accounts update.
 * Most of the logic was copied from (bw-react-frontend/apps/mobile/src/effects/network/api/creditFactors/getMissedPaymentsMessage.ts)
 * and aligned with the web implementation.
 * TODO: Unify these files.
 */
export const getTotalAccountsMessage = (item: CreditFactor) => {
  if (item.update?.status === TotalAccountsStatus.INCREASE) {
    const { type, amount } = item.update;

    if (type === null) {
      return i18next.t("creditFactors:totalAccounts:newAccounts", {
        count: amount,
      });
    }

    const copy = {
      [TotalAccountsTypes.REVOLVING_TRADE]: i18next.t("creditFactors:totalAccounts:revolvingTrade", {
        count: amount,
      }),
      [TotalAccountsTypes.INSTALLMENT_LOAN]: i18next.t("creditFactors:totalAccounts:installmentLoan", {
        count: amount,
      }),
      [TotalAccountsTypes.MORTGAGE]: i18next.t("creditFactors:totalAccounts:mortgage", { count: amount }),
      [TotalAccountsTypes.ACCOUNTS]: i18next.t("creditFactors:totalAccounts:accounts", { count: amount }),
    };

    return (type && copy[type as TotalAccountsTypes]) ?? null;
  }

  return null;
};
