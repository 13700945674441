import React, { useCallback } from "react";
import { navigateToUrl } from "single-spa";

import { useDashboardExperiment, usePromotions, useRecordPromotionAction } from "@bwll/bw-hooks";
import { Popup } from "@bwll/bw-modules";
import {
  CREDIT_BUILDING_PV_KNACK_ID,
  DASHBOARD_ANALYTICS_ORIGINS,
  PROMOTION_TYPE,
  PromotionPopup,
  WEB_EXPERIMENT_IDS,
  WEB_TREATMENT_IDS,
} from "@bwll/bw-types";
import { useInternalNavigate } from "@bwll/dashboard/src/hooks";

import { creditBuilderUrl, gemstoneNavigate } from "@app/helpers";
import { RENT_ADVANTAGE_ROUTES } from "@app/router";

/**
 * A wrapper around the Popup component
 * that applies styling related to the screen implementation
 * and handles all platform-specific navigation callbacks
 * and analytics.
 */
export const PopupCard = () => {
  const { navigateToProduct, generateDestinationUrl } = useInternalNavigate();
  const shouldShowPhase2Features = useDashboardExperiment({
    experimentId: WEB_EXPERIMENT_IDS.SSPA_ENABLE_DASHBOARD_V2_PHASE2_WEB,
    treatments: [WEB_TREATMENT_IDS.sspaDashboardWebPhase2.ON],
  });
  const { isLoading, data } = usePromotions({
    promotionType: PROMOTION_TYPE.POP_UP,
  });

  const { mutate: recordPromotionAction } = useRecordPromotionAction();

  const originQueryParam = `origin=${DASHBOARD_ANALYTICS_ORIGINS.POPUP}`;

  const popupData = data?.data?.[0] as PromotionPopup;
  const navigateToPopup = useCallback(
    (popup: PromotionPopup) => {
      if (Number(popup.knackId) === CREDIT_BUILDING_PV_KNACK_ID.CREDIT_BUILDER) {
        gemstoneNavigate(creditBuilderUrl);
      } else if (Number(popup.knackId) === CREDIT_BUILDING_PV_KNACK_ID.RENT_ADVANTAGE) {
        navigateToUrl(RENT_ADVANTAGE_ROUTES.ROOT);
      } else if (Number(popup.knackId) === CREDIT_BUILDING_PV_KNACK_ID.RENT_ADVANTAGE_PAST_PAYMENTS) {
        navigateToUrl(RENT_ADVANTAGE_ROUTES.PAST_PAYMENTS);
      } else {
        navigateToProduct(popup.vertical, popup.productReferenceId, originQueryParam);
      }
    },
    [navigateToProduct, originQueryParam],
  );

  const getDestinationUrl = useCallback(
    (popup: PromotionPopup) => {
      if (Number(popup?.knackId) === CREDIT_BUILDING_PV_KNACK_ID.CREDIT_BUILDER) {
        return creditBuilderUrl;
      }

      if (Number(popup?.knackId) === CREDIT_BUILDING_PV_KNACK_ID.RENT_ADVANTAGE) {
        return `${location.origin}${RENT_ADVANTAGE_ROUTES.ROOT}`;
      }

      if (Number(popup?.knackId) === CREDIT_BUILDING_PV_KNACK_ID.RENT_ADVANTAGE_PAST_PAYMENTS) {
        return `${location.origin}${RENT_ADVANTAGE_ROUTES.PAST_PAYMENTS}`;
      }

      return generateDestinationUrl(popupData?.vertical, popupData?.productReferenceId, originQueryParam);
    },
    [generateDestinationUrl, originQueryParam, popupData?.productReferenceId, popupData?.vertical],
  );

  if (!shouldShowPhase2Features || isLoading) return null;

  return (
    <Popup
      popupData={popupData}
      recordPromotionAction={recordPromotionAction}
      redirectAction={navigateToPopup}
      analyticsAttributes={{
        destinationUrl: getDestinationUrl(popupData),
      }}
    />
  );
};
