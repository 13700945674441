import { useCallback } from "react";

import { ANALYTICS_ATTRIBUTES, EVENT_LABEL } from "@bwll/bw-types";
import { getISO } from "@bwll/bw-utils";

import { AnalyticsEventProps, TFeatureAnalyticsAttributes } from "../../../..";
import { ComparisonCommonAnalyticsAttributes } from "./useCreditCardsComparisonEvents.types";

export const useCreditCardsComparisonEvents = ({ commonAttributes, trackEvent }: AnalyticsEventProps) => {
  // Credit Cards Comparison Screen
  const onComparisonProductViewed = useCallback(
    <T = ComparisonCommonAnalyticsAttributes>(featureAttributes: TFeatureAnalyticsAttributes<T>) => {
      trackEvent(EVENT_LABEL.PRODUCT_VIEWED, {
        ...commonAttributes,
        ...featureAttributes,
        [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]: getISO(),
      });
    },
    [commonAttributes, trackEvent],
  );

  const onComparisonProductApplyPressed = useCallback(
    <T = ComparisonCommonAnalyticsAttributes>(featureAttributes: TFeatureAnalyticsAttributes<T>) => {
      trackEvent(EVENT_LABEL.PRODUCT_CLICKED, {
        ...commonAttributes,
        ...featureAttributes,
        [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]: getISO(),
      });
    },
    [commonAttributes, trackEvent],
  );

  const onComparisonProductDetailsPressed = useCallback(
    <T = ComparisonCommonAnalyticsAttributes>(featureAttributes: TFeatureAnalyticsAttributes<T>) => {
      trackEvent(EVENT_LABEL.BUTTON_CLICKED, {
        ...commonAttributes,
        ...featureAttributes,
        [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]: getISO(),
      });
    },
    [commonAttributes, trackEvent],
  );

  // Credit Cards List Screen
  const onCompareProductClicked = useCallback(
    <T = ComparisonCommonAnalyticsAttributes>(featureAttributes: TFeatureAnalyticsAttributes<T>) => {
      trackEvent(EVENT_LABEL.COMPARE_CARD_ADDED, {
        ...commonAttributes,
        ...featureAttributes,
        [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]: getISO(),
      });
    },
    [commonAttributes, trackEvent],
  );

  const onCompareButtonClicked = useCallback(
    <T = ComparisonCommonAnalyticsAttributes>(featureAttributes: TFeatureAnalyticsAttributes<T>) => {
      trackEvent(EVENT_LABEL.COMPARE_BUTTON_CLICKED, {
        ...commonAttributes,
        ...featureAttributes,
        [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]: getISO(),
      });
    },
    [commonAttributes, trackEvent],
  );

  return {
    onComparisonProductViewed,
    onComparisonProductApplyPressed,
    onComparisonProductDetailsPressed,
    onCompareProductClicked,
    onCompareButtonClicked,
  };
};
