import { useMemo } from "react";

import { useBreakpoints } from "@bwll/bw-hooks";
import { COLORS } from "@bwll/bw-styles";

import { FILTER_INPUT_CONTAINER_TEST_IDS as TEST_IDS } from "./FilterInputContainer.constants";
import * as Styled from "./FilterInputContainer.styles";
import { FilterInputContainerProps } from "./FilterInputContainer.types";

const headingColor = COLORS.NEUTRAL.WARM["800"];

/**
 * Component for rendering a container for filter input components.
 *
 * @component
 * @param {boolean} forceMobile - If true, forces the container to render in mobile mode.
 * @param {string | undefined} heading - Optional heading to print inside the container.
 * @example
 * return (
 *  <FilterInputContainer heading="Offers">
 *    <Pill label="Best offers" onPress={noop} />
 *    <Pill label="Borrowell bonus" onPress={noop} />
 *    <Pill label="Limited time offers" onPress={noop} />
 *  </FilterInputContainer>
 * );
 */
export const FilterInputContainer = ({
  children,
  forceMobile = false,
  heading,
}: FilterInputContainerProps) => {
  const { isMobile: mobileBreakpoint, isTablet } = useBreakpoints();
  const isSmallScreen = mobileBreakpoint || isTablet || forceMobile;

  const headingComponent = useMemo(
    () =>
      isSmallScreen ? (
        <Styled.MobileHeading color={headingColor} testID={TEST_IDS.MOBILE_HEADING}>
          {heading}
        </Styled.MobileHeading>
      ) : (
        <Styled.DesktopHeading color={headingColor} testID={TEST_IDS.DESKTOP_HEADING}>
          {heading}
        </Styled.DesktopHeading>
      ),
    [heading, isSmallScreen],
  );

  return (
    <Styled.Container isMobile={isSmallScreen}>
      {heading && headingComponent}
      {children}
    </Styled.Container>
  );
};
