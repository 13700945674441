import styled from "@emotion/native";

import { Button } from "@bwll/bw-components/next";
import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const ButtonContainer = styled.View`
  width: 100%;
  margin-top: ${styledValue(spacing.m)};
`;
