export const QUICK_APPLY_PRODUCT_HEADER_TEST_IDS = {
  IMAGE: "qaph-image",
};

export const IMAGE_DIMENSIONS = {
  desktop: {
    width: 168,
    height: 96,
  },
  mobile: {
    width: 84,
    height: 48,
  },
};
