import styled from "@emotion/native";

import { Body1, Heading2 } from "@bwll/bw-components/next";
import {
  COLORS,
  borderRadius,
  borderWidth,
  fontSize,
  fontWeights,
  letterSpacing,
  lineHeight,
  spacing,
} from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

type LayoutProps = { isMobileView: boolean };

export const Container = styled.View`
  border-color: ${COLORS.BRIGHT_GREEK["200"]};
  border-width: ${styledValue(borderWidth.small)};
  border-radius: ${styledValue(borderRadius.s)};
  width: 100%;
`;

export const Summary = styled.View<LayoutProps>`
  background-color: ${COLORS.BRIGHT_GREEK["100"]};
  border-top-left-radius: ${styledValue(borderRadius.s)};
  border-top-right-radius: ${styledValue(borderRadius.s)};
  width: 100%;
  padding-top: ${styledValue(spacing.xxs)};
  padding-bottom: ${styledValue(spacing.xxs)};
  padding-left: ${({ isMobileView }) => (isMobileView ? styledValue(spacing.xs) : styledValue(spacing.m))};
  padding-right: ${({ isMobileView }) => (isMobileView ? styledValue(spacing.xs) : styledValue(spacing.m))};
`;

export const SummaryText = styled(Heading2)<LayoutProps>`
  font-size: ${({ isMobileView }) => (isMobileView ? styledValue(fontSize.xs) : styledValue(fontSize.l))};
  line-height: ${({ isMobileView }) => (isMobileView ? styledValue(lineHeight.m) : styledValue(28))};
  letter-spacing: ${({ isMobileView }) => (isMobileView ? styledValue(0.32) : styledValue(0.16))};
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;

export const ChecklistHeader = styled(Body1)<LayoutProps>`
  background-color: ${COLORS.WHITE};
  width: 100%;
  padding-left: ${({ isMobileView }) => (isMobileView ? styledValue(spacing.xs) : styledValue(spacing.m))};
  padding-right: ${({ isMobileView }) => (isMobileView ? styledValue(spacing.xs) : styledValue(spacing.m))};
  padding-top: ${({ isMobileView }) => (isMobileView ? styledValue(spacing.xxs) : styledValue(spacing.xs))};
  color: ${COLORS.NEUTRAL.WARM["800"]};
  font-weight: ${fontWeights.semiBold};
  letter-spacing: ${styledValue(letterSpacing.m)};
  font-size: ${({ isMobileView }) => (isMobileView ? styledValue(fontSize.xs) : styledValue(fontSize.s))};
  line-height: ${({ isMobileView }) =>
    isMobileView ? styledValue(lineHeight.m) : styledValue(lineHeight.xl)};
`;

export const ChecklistItem = styled.View<LayoutProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${styledValue(spacing.xxxs)};
  font-weight: ${fontWeights.regular};
  letter-spacing: ${styledValue(letterSpacing.xs)};
  line-height: ${styledValue(lineHeight.xl)};
`;

export const ItemText = styled(Body1)<LayoutProps>`
  flex: 1;
  flex-wrap: wrap;
  font-size: ${({ isMobileView }) => (isMobileView ? styledValue(fontSize.xs) : styledValue(fontSize.s))};
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;

export const Checklist = styled.View<LayoutProps>`
  gap: ${({ isMobileView }) => (isMobileView ? styledValue(spacing.xxxs) : styledValue(spacing.xxs))};
  background-color: ${COLORS.WHITE};
  border-bottom-left-radius: ${styledValue(borderRadius.s)};
  border-bottom-right-radius: ${styledValue(borderRadius.s)};
  padding-top: ${({ isMobileView }) => (isMobileView ? styledValue(spacing.xxxs) : styledValue(spacing.xxs))};
  padding-right: ${({ isMobileView }) => (isMobileView ? styledValue(spacing.xs) : styledValue(spacing.m))};
  padding-bottom: ${({ isMobileView }) =>
    isMobileView ? styledValue(spacing.xxs) : styledValue(spacing.xs)};
  padding-left: ${({ isMobileView }) => (isMobileView ? styledValue(spacing.xs) : styledValue(spacing.m))};
`;
