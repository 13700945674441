import React from "react";
import { Modal as NativeModal } from "react-native";

import { ModalProps } from "./Modal.types";

export const Modal = ({
  children,
  onShow,
  onDismiss,
  onRequestClose,
  animationType = "none",
  transparent = true,
  visible = false,
}: ModalProps) => {
  return (
    <NativeModal {...{ onShow, onDismiss, onRequestClose, animationType, transparent, visible }}>
      {children}
    </NativeModal>
  );
};
