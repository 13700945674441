import { useCreditCoachUpdates } from "@bwll/bw-hooks";

import { CreditCoachUpdatesCard } from "./CreditCoachUpdatesCard.component";
import { CreditCoachUpdatesCardContainerProps } from "./CreditCoachUpdatesCard.types";

export const CreditCoachUpdatesCardContainer = ({ onPress }: CreditCoachUpdatesCardContainerProps) => {
  const { data } = useCreditCoachUpdates();

  if (!data || data.numberOfTips === 0) {
    return null;
  }

  return (
    <CreditCoachUpdatesCard text={data.updates[0]?.text || ""} total={data.numberOfTips} onPress={onPress} />
  );
};
