import { QueryClient } from "react-query";
import { Navigate, RouteObject } from "react-router-dom";

import { ROUTES } from "@app/router";
import { AppShell } from "@app/screens/AppShell";
import { DashboardHome } from "@app/screens/DashboardHome";

export const getAppRoutes = ({ queryClient }: { queryClient: QueryClient }): RouteObject[] => {
  return [
    {
      element: <AppShell queryClient={queryClient} />,
      children: [
        { path: ROUTES.ROOT, element: <DashboardHome /> },
        { path: "*", element: <Navigate to={ROUTES.ROOT} /> },
      ],
    },
  ];
};
