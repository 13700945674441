import styled from "@emotion/native";

import { Button } from "@bwll/bw-components/next";
import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const FiltersContainer = styled.View`
  gap: ${styledValue(spacing.xs)};
`;

export const HeadingContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -${styledValue(spacing.xxs)};
`;

export const FiltersHeading = styled.View`
  flex-direction: row;
  align-items: center;
  gap: ${styledValue(spacing.xxxs)};
`;

export const ResetButton = styled(Button)`
  width: unset;
  padding: 0;
`;

export const PillContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${styledValue(spacing.xxs)};
`;
