import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { CreditFactorsSummaryResponse, IApiHookOptions, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

const getCreditFactorsSummary = async (accessToken: string, apiGatewayUrl: string) => {
  const response: AxiosResponse<CreditFactorsSummaryResponse> = await axios.get(
    `${apiGatewayUrl}/api/credit-coach/v1/credit-factors/summary`,
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return response.data;
};

export const useGetCreditFactorsSummary = <TReturn = CreditFactorsSummaryResponse>(
  options: IApiHookOptions<CreditFactorsSummaryResponse, TReturn> = {},
) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_CREDIT_FACTORS_SUMMARY, accessToken),
    () => getCreditFactorsSummary(accessToken, envConfigs.API_GATEWAY_URL),
    {
      ...queryBehavior({
        expiresIn,
        ...options,
        enabled: !!accessToken && (options?.enabled ?? true),
      }),
    },
  );
};
