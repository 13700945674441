import { Duration, add, isValid } from "date-fns";

interface adjustDateStringArgs {
  dateString?: string;
  duration?: Duration;
}

export const adjustDateString = ({ dateString, duration }: adjustDateStringArgs) => {
  if (dateString && isValid(new Date(dateString)) && duration) {
    return add(new Date(dateString), duration).toString();
  }

  return dateString;
};
