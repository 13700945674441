import React, { useCallback } from "react";
import { navigateToUrl } from "single-spa";

import { useDashboardExperiment } from "@bwll/bw-hooks";
import { RAWidget } from "@bwll/bw-modules";
import { WEB_EXPERIMENT_IDS, WEB_TREATMENT_IDS } from "@bwll/bw-types";

import { RENT_ADVANTAGE_ROUTES } from "@app/router";

export const RAWidgetCard = () => {
  const isPhase2Enabled = useDashboardExperiment({
    experimentId: WEB_EXPERIMENT_IDS.SSPA_ENABLE_DASHBOARD_V2_PHASE2_WEB,
    treatments: [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_ENABLE_DASHBOARD_V2_PHASE2_WEB].ON],
  });

  const navigate = useCallback(() => {
    navigateToUrl(RENT_ADVANTAGE_ROUTES.ROOT);
  }, []);

  if (!isPhase2Enabled) {
    return null;
  }

  return <RAWidget onPress={navigate} />;
};
