import React from "react";

import { Skeleton } from "@bwll/bw-components/next";
import { useBreakpoints, useTransitionGetMember } from "@bwll/bw-hooks";

import { RecommendationsSnackbar as RecommendationsSnackbarUI } from "./RecommendationsSnackbar.component";
import {
  CARD_HEIGHT,
  MOBILE_CARD_HEIGHT,
  RECOMMENDATIONS_SNACKBAR_SKELETON_TEST_ID,
} from "./RecommendationsSnackbar.constants";
import type { RecommendationsSnackbarProps } from "./RecommendationsSnackbar.types";

/**
 * Component represents a data container for the Recommendations Snackbar UI.
 * @param {RecommendationsSnackbarProps} props - container props interface
 */
export const RecommendationsSnackbarContainer = (props: RecommendationsSnackbarProps) => {
  const { data, isLoading } = useTransitionGetMember();
  const { isMobile } = useBreakpoints();

  if (isLoading) {
    return (
      <Skeleton
        testID={RECOMMENDATIONS_SNACKBAR_SKELETON_TEST_ID}
        variant="rounded"
        width="100%"
        height={isMobile ? MOBILE_CARD_HEIGHT : CARD_HEIGHT}
      />
    );
  }

  return (
    <RecommendationsSnackbarUI
      analyticsAttributes={props.analyticsAttributes}
      creditScoreRequest={data?.creditScoreRequest}
      creditScores={data?.creditScores}
      {...props}
    />
  );
};
