import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  Nullable,
  PRODUCT_VERTICAL,
  PRODUCT_VERTICAL_ID_MAP,
  PreselectedProductV4,
  ProductPick,
} from "@bwll/bw-types";
import { getImpressionedUrlFromProduct, roundWithPrecision, uuid } from "@bwll/bw-utils";

import { TransformCtaLinkHandler } from "./SponsoredCarousel.types";
import { ProductApproval, SponsoredCarouselItem } from "./SponsoredCarouselCard/SponsoredCarouselCard.types";
import { useConvertLikelihoodOfApproval } from "./useConvertLikelihoodOfApproval";

export const getProductType = (
  verticalId?: Nullable<keyof typeof PRODUCT_VERTICAL_ID_MAP>,
): PRODUCT_VERTICAL | undefined => {
  if (!verticalId || !PRODUCT_VERTICAL_ID_MAP[verticalId]) return undefined;

  return PRODUCT_VERTICAL_ID_MAP[verticalId];
};

export const useTransformSponsoredPlacementProducts = (
  transformCtaLink: TransformCtaLinkHandler,
  individualClientIdReferenceNumber: number,
) => {
  /** @TODO Remove transformCtaLink argument */
  const i18next = useTranslation();

  const convertLikelihoodOfApproval = useConvertLikelihoodOfApproval();

  /**
   * Copied from the mobile app almost as is
   */
  const transformSponsoredPlacementProducts = useCallback(
    (products: ProductPick[], productsV4?: PreselectedProductV4[]): SponsoredCarouselItem[] => {
      const commonRequiredKeys = ["id", "sponsoredPlacement", "isSponsored", "productVerticalId"];

      const requiredCreditCardsAndLoanCommonKeys = ["isPreSelection", "productReferenceNumber"];

      const requiredCreditCardKeys = [
        ...commonRequiredKeys,
        ...requiredCreditCardsAndLoanCommonKeys,
        "companyName",
        "cardType",
        "annualFee",
        "purchaseInterestRate",
        "cashAdvanceRate",
        "balanceTransferRate",
        "productImageUrl",
        "productName",
      ];

      const requiredLoanKeys = [
        ...commonRequiredKeys,
        ...requiredCreditCardsAndLoanCommonKeys,
        "interestRate",
        "interestRateText",
        "companyName",
        "companyLogo",
        "loanTypes",
        "term",
        "loanAmount",
      ];

      const requiredInsuranceKeys = [...commonRequiredKeys];
      const requiredBankAccountsKeys = [...commonRequiredKeys];
      const requiredMortgageKeys = [...commonRequiredKeys];

      const sponsoredProducts = products.filter((product) => product.sponsoredPlacement) as Array<
        ProductPick & { sponsoredPlacement: number }
      >;

      const filteredProducts = sponsoredProducts
        .filter((product) => {
          // filter products by checking every required keys in the object
          const checkProperty = (keys: string[]) => keys.every((prop) => prop in product);

          const productType = getProductType(product.productVerticalId);

          switch (productType) {
            case PRODUCT_VERTICAL.CREDIT_CARD:
              return checkProperty(requiredCreditCardKeys);
            case PRODUCT_VERTICAL.LOAN:
              return checkProperty(requiredLoanKeys);
            case PRODUCT_VERTICAL.INSURANCE:
              return checkProperty(requiredInsuranceKeys);
            case PRODUCT_VERTICAL.BANK_ACCOUNT:
              return checkProperty(requiredBankAccountsKeys);
            case PRODUCT_VERTICAL.MORTGAGE:
              return checkProperty(requiredMortgageKeys);
            default:
              return null;
          }
        })
        .filter(Boolean);

      filteredProducts.sort((a, b) => a.sponsoredPlacement - b.sponsoredPlacement);

      if (filteredProducts.length === 0) {
        return [];
      }

      return filteredProducts.map((product) => {
        const productVertical = getProductType(product.productVerticalId);
        const productImpressionId = uuid();
        const productV4 = productsV4?.find(
          (p4) => p4.productReferenceNumber === product.productReferenceNumber,
        );

        const isPreSelected = !!productV4 && product.isPreSelection;
        const ctaLink = getImpressionedUrlFromProduct(
          product,
          productImpressionId,
          individualClientIdReferenceNumber,
          { overrideWithDashboardLink: true },
        );

        return {
          // common
          productImpressionId,
          productReferenceId: product.id,
          productReferenceNumber: product.productReferenceNumber,
          isPreselected: product.isPreSelection,
          isSponsored: !product?.isBackupProduct,
          productName: product.productName || product.companyName || "",
          productLogo: product.productImageUrl || product.companyLogo || "",
          bannerTitle: productV4?.bannerTitle || null,
          memberRewardsInfo: product.bonusDescription || "",
          productDescription: product.productContext || "",
          sponsoredPlacement: product.sponsoredPlacement,
          shortDescriptionText: product.shortDescriptionText || "",
          cardType: productVertical,
          preApproval: product.preApproval,
          approvalChance: {
            ...convertLikelihoodOfApproval(product.likelihoodOfApprovalType),
            likelihoodOfApproval: product.likelihoodOfApproval,
            likelihoodOfApprovalType: product.likelihoodOfApprovalType,
          } as ProductApproval,
          // product web link redirect
          /** @TODO Don't coalesce null to empty string, as this will fail when we try to navigate **/
          ctaLink: ctaLink.impressionedUrl || "",
          isQuickApply: product?.isQuickApply || false,

          // loan
          loanAmount: product.loanAmount || "",
          interestRate:
            product.interestRate &&
            i18next.t("dashboard:cards:sponsoredCarousel:percentageTransform", {
              value: roundWithPrecision(product.interestRate * 100, 2),
            }),
          apr: product.interestRateText || "",
          term: product.term || "",
          promotionalDetails: product.promotionalDetailsMarkdown || "",

          // credit card
          annualFee:
            product.annualFee &&
            i18next.t("dashboard:cards:sponsoredCarousel:dollarSignTransform", {
              value: product.annualFee,
            }),
          purchaseInterestRate:
            product.purchaseInterestRate &&
            i18next.t("dashboard:cards:sponsoredCarousel:percentageTransform", {
              value: roundWithPrecision(product.purchaseInterestRate * 100, 2),
            }),
          cashAdvanceRate:
            product.cashAdvanceRate &&
            i18next.t("dashboard:cards:sponsoredCarousel:percentageTransform", {
              value: roundWithPrecision(product.cashAdvanceRate * 100, 2),
            }),
          balanceTransferRate:
            product.balanceTransferRate &&
            i18next.t("dashboard:cards:sponsoredCarousel:percentageTransform", {
              value: roundWithPrecision(product.balanceTransferRate * 100, 2),
            }),

          // other properties (only for analytics)
          trackingTag: product.trackingTag || "",
          belowShowAll: false,
          cashBackOffer: null,
          displayOrder: 1,
          isCashBack: !!product.cashbackBannerText,
          prepopulationFlow: product.isQuickApply,
          preferredOrder: product.preferredOrder,
          rank: product.rank,

          isPreSelected: isPreSelected,
          preSelectedLink: product.preSelectedLink,
          productVerticalId: product.productVerticalId,
        };
      });
    },
    [convertLikelihoodOfApproval, individualClientIdReferenceNumber, transformCtaLink],
  );

  return transformSponsoredPlacementProducts;
};
