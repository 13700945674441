import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  BUTTON_TYPE,
  Body2,
  Button,
  Caption,
  ElementTracker,
  Heading2,
  Icon,
  Spacer,
  Tooltip,
} from "@bwll/bw-components/next";
import { isWeb } from "@bwll/bw-components/next/constants";
import {
  IMPRESSION_EVENT_NAMES,
  ON_LINK_CLICK_EVENT_NAMES,
  SponsoredCarouselItemCommonEventData,
  useAnalyticsContext,
  useBreakpoints,
} from "@bwll/bw-hooks";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import {
  ANALYTICS_ATTRIBUTES,
  LOAN_KNACK_ID,
  LikelihoodOfApprovalType,
  PreapprovalStatus,
} from "@bwll/bw-types";
import { uuid } from "@bwll/bw-utils";

import { Row } from "../SponsoredCarousel.styles";
import { APPROVAL_CHANCE_TYPE, useConvertLikelihoodOfApproval } from "../useConvertLikelihoodOfApproval";
import {
  IMAGE_MAX_HEIGHT_DESKTOP,
  IMAGE_MAX_HEIGHT_MOBILE,
  IMAGE_MAX_WIDTH_DESKTOP,
  IMAGE_MAX_WIDTH_MOBILE,
  SPONSORED_CAROUSEL_CARD_BANNER_TEST_ID,
  SPONSORED_CAROUSEL_CARD_BUTTON_TEST_ID,
  SPONSORED_CAROUSEL_CARD_DESCRIPTION_TEST_ID,
  SPONSORED_CAROUSEL_CARD_LOA_TEST_ID,
  SPONSORED_CAROUSEL_CARD_SPONSORED_LABEL_TEST_ID,
} from "./SponsoredCarouselCard.constants";
import * as Styled from "./SponsoredCarouselCard.styles";
import { SponsoredCarouselCardProps, SponsoredCarouselItemConfig } from "./SponsoredCarouselCard.types";
import { SponsoredProductDetails } from "./components/SponsoredProductDetails";
import { useCommonAnalyticsAttributes } from "./useCommonAnalyticsAttributes";

export const SponsoredCarouselCard = ({
  onPress,
  currentItemId,
  ...carouselItem
}: SponsoredCarouselCardProps) => {
  const i18next = useTranslation();
  const { isMobile } = useBreakpoints();

  const {
    isPreselected,
    isSponsored,
    productLogo,
    productName,
    approvalChance,
    productDescription,
    annualFee,
    purchaseInterestRate,
    cashAdvanceRate,
    balanceTransferRate,
    interestRate,
    loanAmount,
    apr,
    term,
    cardType,
    shortDescriptionText,
    productReferenceNumber,
    preApproval,
    productReferenceId,
    bannerTitle,
    isQuickApply,
  } = carouselItem;

  const convertLikelihoodOfApproval = useConvertLikelihoodOfApproval();

  const { sponsoredCarouselAnalytics } = useAnalyticsContext();

  const isFigFlow = productReferenceNumber === LOAN_KNACK_ID.FIG_LOANS_OFFER;
  const isFigPreApproved =
    (isFigFlow && preApproval && preApproval.status !== PreapprovalStatus.NotStarted) || false;

  const config: SponsoredCarouselItemConfig = useMemo(() => {
    if (isFigPreApproved) {
      return {
        ctaText: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:figLoan:cta:preApproved"),
        isPreSelection: true,
        isLoaVisible: true,
        approvalChance: convertLikelihoodOfApproval(LikelihoodOfApprovalType.EXCELLENT),
        preQualHeaderText: i18next.t(
          "dashboard:cards:sponsoredCarousel:sponsoredCards:figLoan:preSelectedBanner",
        ),
        preQualTextKey: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:figLoan:titleTooltip"),
        productDescription: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:figLoan:daysLeft", {
          count: preApproval?.daysLeft,
        }),
        productTitle: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:figLoan:title"),
      };
    }

    if (isFigFlow) {
      return {
        ctaText: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:figLoan:cta:notStarted"),
        isPreSelection: false,
        isLoaVisible: false,
        approvalChance: convertLikelihoodOfApproval(LikelihoodOfApprovalType.NONE),
        preQualHeaderText: bannerTitle || "",
        preQualTextKey: i18next.t(
          "dashboard:cards:sponsoredCarousel:sponsoredCards:preSelectedCard:titleTooltip",
        ),
        productDescription,
        productTitle: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:figLoan:title"),
      };
    }

    return {
      ctaText: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:cta"),
      isPreSelection: isPreselected,
      isLoaVisible: approvalChance.text !== APPROVAL_CHANCE_TYPE.NOTELIGIBLE,
      approvalChance,
      preQualHeaderText: bannerTitle || "",
      preQualTextKey: i18next.t(
        "dashboard:cards:sponsoredCarousel:sponsoredCards:preSelectedCard:titleTooltip",
      ),
      productDescription,
      productTitle: productName,
    };
  }, [
    isFigPreApproved,
    isFigFlow,
    isPreselected,
    approvalChance,
    bannerTitle,
    productDescription,
    productName,
    convertLikelihoodOfApproval,
    preApproval?.daysLeft,
  ]);

  const headerTooltipText = i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:headerToolTip");

  const shouldRenderBanner = config.isPreSelection && bannerTitle;

  const commonAttributes = useCommonAnalyticsAttributes(config, carouselItem);

  const viewEventLabel =
    isFigFlow && isFigPreApproved
      ? IMPRESSION_EVENT_NAMES.INSTANT_APPLY_VIEWED
      : IMPRESSION_EVENT_NAMES.PRODUCT_VIEWED;
  const clickEventLabel =
    isFigFlow && isFigPreApproved
      ? ON_LINK_CLICK_EVENT_NAMES.INSTANT_APPLY_CLICKED
      : ON_LINK_CLICK_EVENT_NAMES.PRODUCT_CLICKED;

  const handlePress = useCallback(() => {
    onPress({
      carouselItem,
      isFigPreApproved,
      isFigFlow,
    });

    sponsoredCarouselAnalytics.productClicked<SponsoredCarouselItemCommonEventData>(clickEventLabel, {
      ...commonAttributes,
      [ANALYTICS_ATTRIBUTES.PREPOPULATION_FLOW]: Boolean(isQuickApply || isFigFlow),
      [ANALYTICS_ATTRIBUTES._CLICK_ID]: uuid(),
    });
  }, [
    onPress,
    carouselItem,
    isFigPreApproved,
    isFigFlow,
    sponsoredCarouselAnalytics,
    clickEventLabel,
    commonAttributes,
    isQuickApply,
  ]);

  const onTrackView = useCallback(() => {
    sponsoredCarouselAnalytics.productViewed<SponsoredCarouselItemCommonEventData>(viewEventLabel, {
      ...commonAttributes,
      [ANALYTICS_ATTRIBUTES.PREPOPULATION_FLOW]: Boolean(isQuickApply || isFigFlow),
    });
  }, [sponsoredCarouselAnalytics, viewEventLabel, commonAttributes, isQuickApply, isFigFlow]);

  return (
    <ElementTracker shouldStartTracking={currentItemId === productReferenceId} trackingHandler={onTrackView}>
      <Styled.Container>
        {shouldRenderBanner && (
          <Styled.Banner testID={SPONSORED_CAROUSEL_CARD_BANNER_TEST_ID}>
            <Caption color={COLORS.WHITE}>{config.preQualHeaderText}</Caption>
          </Styled.Banner>
        )}
        {shouldRenderBanner && <Spacer height={spacing.xs} />}
        <Styled.Row>
          <Styled.ImageContainer
            height={isMobile ? IMAGE_MAX_HEIGHT_MOBILE : IMAGE_MAX_HEIGHT_DESKTOP}
            width={isMobile ? IMAGE_MAX_WIDTH_MOBILE : IMAGE_MAX_WIDTH_DESKTOP}
          >
            <Styled.ProductImage source={{ uri: productLogo }} resizeMode="contain" />
          </Styled.ImageContainer>
          <Styled.DataColumn minHeight={isMobile ? IMAGE_MAX_HEIGHT_MOBILE : IMAGE_MAX_HEIGHT_DESKTOP}>
            {!!isSponsored && (
              <Styled.SponsoredLabelContainer testID={SPONSORED_CAROUSEL_CARD_SPONSORED_LABEL_TEST_ID}>
                <Tooltip
                  {...(isWeb && { content: headerTooltipText })}
                  {...(!isWeb && { bodyText: headerTooltipText })}
                >
                  <Row>
                    <Caption color={COLORS.NEUTRAL.COOL["600"]}>
                      {i18next.t("dashboard:cards:sponsoredCarouselV2:sponsoredHeaderSuffix")}{" "}
                    </Caption>
                    <Icon icon="info_hollow" size={fontSize.s} color={COLORS.NEUTRAL.COOL["600"]} />
                  </Row>
                </Tooltip>
              </Styled.SponsoredLabelContainer>
            )}
            <Styled.TitleContainer>
              <Heading2 numberOfLines={3} color={COLORS.NEUTRAL.WARM["800"]}>
                {config.productTitle}
              </Heading2>
            </Styled.TitleContainer>
            <Styled.CtaContainer>
              {config.isLoaVisible && (
                <Styled.ApprovalChanceContainer testID={SPONSORED_CAROUSEL_CARD_LOA_TEST_ID}>
                  <Body2 color={COLORS.NEUTRAL.WARM["600"]}>
                    {i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:approvalChance")}
                  </Body2>
                  <Spacer width={spacing.xxxs} />
                  <Body2 color={config.approvalChance.color}>{config.approvalChance.text}</Body2>
                  {!!config.approvalChance.tooltipText && (
                    <>
                      <Spacer width={spacing.xxxs} />
                      <Tooltip
                        {...(isWeb && { content: config.approvalChance.tooltipText })}
                        {...(!isWeb && { bodyText: config.approvalChance.tooltipText })}
                      >
                        <Styled.TooltipIconContainer>
                          <Icon icon="info_hollow" color={config.approvalChance.color} size={fontSize.s} />
                        </Styled.TooltipIconContainer>
                      </Tooltip>
                    </>
                  )}
                </Styled.ApprovalChanceContainer>
              )}
              {!isMobile && (
                <Styled.ButtonContainer>
                  <Button
                    testID={SPONSORED_CAROUSEL_CARD_BUTTON_TEST_ID}
                    type={BUTTON_TYPE.PRIMARY}
                    title={config.ctaText}
                    onPress={handlePress}
                  />
                </Styled.ButtonContainer>
              )}
            </Styled.CtaContainer>
          </Styled.DataColumn>
        </Styled.Row>
        <Styled.Whitespace />
        {!!config.productDescription && (
          <Styled.DescriptionContainer testID={SPONSORED_CAROUSEL_CARD_DESCRIPTION_TEST_ID}>
            <Body2 color={COLORS.NEUTRAL.WARM["800"]}>{config.productDescription}</Body2>
          </Styled.DescriptionContainer>
        )}
        <Styled.DetailsContainer isMobile={isMobile}>
          <SponsoredProductDetails
            {...{
              cardType,
              cashAdvanceRate,
              purchaseInterestRate,
              balanceTransferRate,
              annualFee,
              shortDescriptionText,
              isFigPreApproved,
              figPreApprovalData: preApproval,
              isFigFlow,
              loanAmount,
              apr,
              term,
              interestRate,
            }}
          />
        </Styled.DetailsContainer>
        {isMobile && (
          <>
            <Spacer height={spacing.xs} />
            <Button
              testID={SPONSORED_CAROUSEL_CARD_BUTTON_TEST_ID}
              type={BUTTON_TYPE.PRIMARY}
              title={config.ctaText}
              onPress={handlePress}
            />
          </>
        )}
      </Styled.Container>
    </ElementTracker>
  );
};
