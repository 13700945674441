import React from "react";

import { StyledPadding } from "./Padding.styles";
import { PaddingProps } from "./Padding.types";

/**
 * Component to add padding to Cell or other components
 *
 * @component
 * @example
 * return (
 *   <Padding />
 * )
 *
 * To replace the default padding values we can use the properties 'all', 'vertical' (applies to 'top and 'bottom'),'horizontal' (applies to 'left' and 'right'), 'top', 'bottom', 'left', and 'right'.
 *
 * The priority of the values applied goes from less specific, being 'all' to the more specific values, as 'right', 'bottom', 'left', and 'right'
 *
 * For example:
 *  If you set horizontal = 20, and left = 10:
 *  'horizontal' will override the default value for 'all'
 *    AND 'left' will override 'horizontal' value.
 */
export const Padding = ({ children, ...props }: PaddingProps) => {
  return <StyledPadding {...props}>{children}</StyledPadding>;
};
