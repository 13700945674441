import React, { useCallback } from "react";

import { CreditCoachUpdatesCard } from "@bwll/bw-modules";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";
import { gemstoneNavigate } from "@app/helpers";

export const CreditCoachUpdates = () => {
  const navigate = useCallback(() => {
    gemstoneNavigate(`${ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL}/#/app/creditcoach`, false);
  }, []);

  return <CreditCoachUpdatesCard onPress={navigate} />;
};
