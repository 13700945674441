import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import {
  Body2,
  Caption,
  Icon,
  PromoCardTemplate,
  PromoCardTemplateVariant,
  Spacer,
} from "@bwll/bw-components/next";
import { ElementTracker, IconNames } from "@bwll/bw-components/next";
import { CreditFactorUpdatesEventData, useAnalyticsContext, useBreakpoints } from "@bwll/bw-hooks";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import {
  ANALYTICS_ATTRIBUTES,
  CreditFactor,
  CreditInquiriesStatus,
  DATE_FORMATS,
  EnhancedCreditFactor,
  Factor,
  NOTICE_TYPE_TO_COLOR,
  NOTICE_TYPE_TO_EVENT_COLOR,
  NOTICE_TYPE_TO_ICON,
  NOTICE_TYPE_TO_ICON_COLOR,
  NoticeType,
} from "@bwll/bw-types";
import { formatDate, getDateTimeDiff } from "@bwll/bw-utils";
import { uuid } from "@bwll/bw-utils";

import {
  GRAPH_SCORE_DESCRIPTION_TEXT_TEST_ID,
  GRAPH_SCORE_GREETINGS_TEXT_TEST_ID,
  GRAPH_SCORE_INDICATOR_TEST_ID,
  GRAPH_SCORE_INDICATOR_TEXT_TEST_ID,
  GRAPH_SCORE_NEXT_DATE_TEST_ID,
  GRAPH_SCORE_PREV_DATE_TEST_ID,
  GRAPH_SCORE_TEST_ID,
  GRAPH_SCORE_TEXT_TEST_ID,
  creditScoreChangeIcons,
} from "./CreditScoreGraph.constants";
import * as Styled from "./CreditScoreGraph.styles";
import { transformSummary } from "./CreditScoreGraph.transformers";
import { CreditScoreGraphProps } from "./CreditScoreGraph.types";
import { identifyScoreRange } from "./CreditScoreGraph.utils";
import { CreditScoreTooltip } from "./CreditScoreTooltip.component";
import { CreditScoreTracker } from "./CreditScoreTracker.component";

/**
 * Component represents CS Graph UI
 * @param {CreditScoreGraphProps} props - external props interface
 * @property {Function} onPress - the main CTA press handler
 * @property {Function} [onTooltipPress] - the tooltip `onOpen` callback
 * @property {Function} onTooltipLinkPress - the tooltip link press handler
 * @property {Date} scorePulledDate - the previous pull date
 * @property {Date} nextRefreshDate - the next refresh date
 * @property {CreditScoreChangeDirectionType} scoreChangeDirection - CS change (up, down, neutral)
 * @property {number} scoreChangePoints - the difference between the previous and the current scores
 * @property {number} scoreCurrentPoints - the current scores value
 * @property {string} userFirstName - the current member first name value
 */
export const CreditScoreGraph = ({
  onPress,
  onTooltipPress,
  onTooltipLinkPress,
  scorePulledDate,
  nextRefreshDate,
  scoreChangeDirection,
  scoreChangePoints,
  scoreCurrentPoints,
  userFirstName,
  creditFactorsSummary,
}: CreditScoreGraphProps) => {
  const { isDesktop } = useBreakpoints();
  const i18next = useTranslation();
  const scoreRange = useMemo(() => identifyScoreRange(scoreCurrentPoints), [scoreCurrentPoints]);
  const { creditFactorUpdateViewed } = useAnalyticsContext();

  const trackCreditFactorView = useCallback(
    (creditFactor: EnhancedCreditFactor) => () => {
      const eventData = {
        [ANALYTICS_ATTRIBUTES.CREDIT_FACTOR]: creditFactor.title,
        [ANALYTICS_ATTRIBUTES.CREDIT_FACTOR_UPDATE_COLOUR]:
          NOTICE_TYPE_TO_EVENT_COLOR[creditFactor.updateCard.noticeType],
        [ANALYTICS_ATTRIBUTES.CREDIT_FACTOR_UPDATE_REMARK]:
          creditFactor.updateCard.dashboardSummaryMessage ?? "",
        [ANALYTICS_ATTRIBUTES.CREDIT_FACTOR_IMPRESSION_ID]: uuid(),
      };
      creditFactorUpdateViewed<CreditFactorUpdatesEventData>?.(eventData);
    },
    [creditFactorUpdateViewed],
  );

  const updateDays = getDateTimeDiff(nextRefreshDate, new Date(), "day");
  let updateDaysLabel = i18next.t("dashboard:cards:scoreTracker:nextUpdate", {
    days: updateDays,
    count: updateDays,
  });

  if (updateDays === 0) updateDaysLabel = i18next.t("dashboard:cards:scoreTracker:nextUpdate_today");

  const renderCreditFactorsAlerts = () => {
    if (creditFactorsSummary?.creditFactors.find((creditFactor) => creditFactor.update) === undefined) {
      return null;
    }
    const filteredCreditFactors = creditFactorsSummary?.creditFactors.filter(
      (factor) =>
        factor.update != null &&
        factor.update.status !== CreditInquiriesStatus.NO_CHANGE &&
        factor.update.status !== CreditInquiriesStatus.NONE &&
        // We only show the decrease update for average credit age
        !(
          factor.factor !== Factor.AVERAGE_CREDIT_AGE &&
          factor.update.status === CreditInquiriesStatus.DECREASE
        ) &&
        // We do not show the average credit age update for changes under 12 months
        !(factor.factor === Factor.AVERAGE_CREDIT_AGE && factor.update.amount < 12),
    );
    const enhancedCreditFactors = transformSummary(filteredCreditFactors as CreditFactor[]);
    return (
      <Styled.CreditAlertsContainer isDesktop={isDesktop}>
        {enhancedCreditFactors.map((creditFactor, index) => {
          if (!creditFactor.updateCard.dashboardSummaryMessage) {
            return null;
          }
          return (
            <Styled.CreditAlert
              backgroundColor={NOTICE_TYPE_TO_COLOR[creditFactor.updateCard.noticeType]}
              isDesktop={isDesktop}
              key={index}
              testID={`cs-graph-factor-${creditFactor.factor.toLowerCase()}`}
            >
              {creditFactor.updateCard.noticeType !== NoticeType.DEFAULT && (
                <Icon
                  icon={NOTICE_TYPE_TO_ICON[creditFactor.updateCard.noticeType] as IconNames}
                  size={fontSize.s}
                  color={NOTICE_TYPE_TO_ICON_COLOR[creditFactor.updateCard.noticeType]}
                />
              )}
              <Body2>{creditFactor.updateCard.dashboardSummaryMessage}</Body2>
              <ElementTracker
                shouldStartTracking={true}
                trackingHandler={trackCreditFactorView(creditFactor)}
              />
            </Styled.CreditAlert>
          );
        })}
      </Styled.CreditAlertsContainer>
    );
  };

  return (
    <PromoCardTemplate
      variant={PromoCardTemplateVariant.card}
      onPress={onPress}
      ctaTitle={i18next.t("dashboard:cards:creditFactors:viewMoreDetails")}
      testID={GRAPH_SCORE_TEST_ID}
    >
      <Styled.Row>
        <View>
          <Styled.GraphContainer>
            <Styled.TrackerContainer>
              <CreditScoreTracker scoreRange={scoreRange} scoreCurrentPoints={scoreCurrentPoints} />
            </Styled.TrackerContainer>
            <Styled.ScoreText testID={GRAPH_SCORE_TEXT_TEST_ID}>{scoreCurrentPoints}</Styled.ScoreText>
          </Styled.GraphContainer>
          <Spacer height={spacing.xxs} />
          <Styled.IndicatorContainer
            scoreChangeDirection={scoreChangeDirection}
            testID={GRAPH_SCORE_INDICATOR_TEST_ID}
          >
            <Icon
              icon={creditScoreChangeIcons[scoreChangeDirection].name}
              size={fontSize.xs}
              color={creditScoreChangeIcons[scoreChangeDirection].color}
            />
            <Spacer width={2} />
            <Caption
              color={creditScoreChangeIcons[scoreChangeDirection].color}
              testID={GRAPH_SCORE_INDICATOR_TEXT_TEST_ID}
            >
              {i18next.t("dashboard:cards:scoreTracker:pts", { points: Math.abs(scoreChangePoints || 0) })}
            </Caption>
          </Styled.IndicatorContainer>
        </View>
        <Styled.DataContainer>
          <Styled.GreetingsText numberOfLines={1} testID={GRAPH_SCORE_GREETINGS_TEXT_TEST_ID}>
            {i18next.t("dashboard:title", {
              firstName: userFirstName,
              interpolation: { escapeValue: false },
            })}
          </Styled.GreetingsText>
          <Styled.ScoreDescriptionContainer>
            <Styled.ScoreDescriptionText testID={GRAPH_SCORE_DESCRIPTION_TEXT_TEST_ID}>
              {scoreCurrentPoints === 0
                ? i18next.t("dashboard:cards:scoreTracker:noCreditScore")
                : i18next.t("dashboard:cards:scoreTracker:scoreState", {
                    state: i18next.t(`dashboard:cards:scoreTracker:state:${scoreRange}`),
                  })}
            </Styled.ScoreDescriptionText>
            <Spacer width={spacing.xxxs} />
            <CreditScoreTooltip
              scoreRange={scoreRange}
              scoreCurrentPoints={scoreCurrentPoints}
              onTooltipPress={onTooltipPress}
              onTooltipLinkPress={onTooltipLinkPress}
            />
          </Styled.ScoreDescriptionContainer>
          <Spacer height={12} />
          <Styled.Row>
            <Icon icon="activity" size={fontSize.s} color={COLORS.NEUTRAL.WARM["800"]} />
            <Styled.LabelText testID={GRAPH_SCORE_PREV_DATE_TEST_ID}>
              {i18next.t("dashboard:cards:scoreTracker:updated", {
                date: formatDate({
                  dateString: scorePulledDate.toISOString(),
                  dateFormat: DATE_FORMATS.MMM_d,
                }),
              })}
            </Styled.LabelText>
            <Styled.LabelText>•</Styled.LabelText>
            <Styled.LabelText testID={GRAPH_SCORE_NEXT_DATE_TEST_ID}>{updateDaysLabel}</Styled.LabelText>
          </Styled.Row>
        </Styled.DataContainer>
      </Styled.Row>
      {renderCreditFactorsAlerts()}
    </PromoCardTemplate>
  );
};
