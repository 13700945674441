import { Nullable, NullableString } from "../types";

export interface ProductRoute {
  internal: boolean;
  path: string;
}

interface ExperimentFilter {
  name: string;
  value: "prime" | "superPrime" | "on" | "off";
}

export enum PrimeCardType {
  CreditCards = 1,
  BankAccounts = 2,
  CreditLimitUtilization = 3,
}

export interface BasePrimePromoCard {
  title: string;
  body: string;
  image: string;
  buttonText: string;
  productRoute: ProductRoute;
  experimentFilter: ExperimentFilter;
  primeCardType: PrimeCardType;
}

export const PrimePromoCardsParam = {
  DashboardLTOs: "dashboard-ltos",
  TopPicks: "top-picks",
  CompareBankAccounts: "compare-bank-accounts",
  TrendingOffers: "trending-offers",
} as const;

type PrimePromoCardsParamKey = keyof typeof PrimePromoCardsParam;

export type PrimePromoCardsParamValue = (typeof PrimePromoCardsParam)[PrimePromoCardsParamKey];

interface ProductDetails {
  id: string;
  productIdReferenceNumber: number;
  firstYearValue: number;
  productImageUrl: string;
  productCategory: string;
  productName: string;
  bonus: string;
  ctaText: string;
  productUrl: string;
}

export interface LimitedTimeOffer extends ProductDetails {
  companyLogo: NullableString;
  isPreSelection: boolean;
  annualFee: number;
  purchaseInterestRate: number;
  cashAdvanceRate: number;
  balanceTransferRate: number;
  isFirstYearWaived: boolean;
  sponsoredPlacement: Nullable<number>;
  rewardsCategoryMarkdown: NullableString;
  isQuickApply: boolean;
  approvalChance: NullableString;
  likelihoodOfApproval: Nullable<number>;
  likelihoodOfApprovalType: number;
  offerEndDate: NullableString;
  trackingTag: NullableString;
  preferredOrder: Nullable<number>;
  rank: Nullable<number>;
  productImpressionId?: string;
}

export interface DashboardLTOsCard extends BasePrimePromoCard {
  limitedTimeOffers?: LimitedTimeOffer[];
}

export interface PrimePromoCardsResponse {
  dashboardLTOsPromoCard?: DashboardLTOsCard;
  topPicksPromoCard?: BasePrimePromoCard;
  compareBankAccountsPromoCard?: BasePrimePromoCard;
  trendingOffersPromoCard?: BasePrimePromoCard;
}
