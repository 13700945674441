import styled from "@emotion/native";

import { Cell } from "@bwll/bw-components/next";
import { COLORS, borderRadius, spacing, toRGBAString } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const StyledCell = styled(Cell)<{ isMobile: boolean }>`
  gap: ${styledValue(spacing.xxs)};
  padding-bottom: ${styledValue(spacing.m)};

  border: ${styledValue(1)} solid ${COLORS.PRIMARY[100]};
  border-radius: ${styledValue(borderRadius.s)};
  background-color: ${toRGBAString(COLORS.PRIMARY["050"], 0.2)};

  ${({ isMobile }) => isMobile && `padding-bottom: auto;`}
`;
