import {
  IApiHookOptions,
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_STATUS_QUERYABLE,
  Subscription,
} from "@bwll/bw-types";

import { useSubscriptions } from "./api";

export interface UseProductSubscriptionStatusProps {
  productId: string;
  customApiOptions?: IApiHookOptions<Subscription[]>;
}

export type ProductSubscriptionStatusResult = ReturnType<typeof useProductSubscriptionStatus>;

/**
 * Gets the member's subscription status for a product.
 *
 * @param {UseProductSubscriptionStatusProps} options
 * `options.productId` is the Id of the product to check the subscription status for
 *
 * `options.customApiOptions` are optional options to override the default react query behavior
 */
export const useProductSubscriptionStatus = ({
  productId,
  customApiOptions,
}: UseProductSubscriptionStatusProps) => {
  const {
    data: subscriptions,
    isLoading,
    isFetched,
  } = useSubscriptions({
    status: SUBSCRIPTION_STATUS_QUERYABLE.ALL,
    customApiOptions: customApiOptions,
  });

  // account for duplicate subscriptions for a product
  const productSubscriptions = subscriptions?.filter((x) =>
    x.plans.some((plan) => plan.productId === productId),
  );
  const activeSubscription = productSubscriptions?.find((x) => x.status === SUBSCRIPTION_STATUS.ACTIVE);
  const status = activeSubscription?.status ?? productSubscriptions?.[0]?.status;

  // we consider the subscription active if it is active or trialing
  const isSubscriptionActive =
    !!status && (status === SUBSCRIPTION_STATUS.ACTIVE || status === SUBSCRIPTION_STATUS.TRIALING);

  return {
    status,
    isSubscriptionActive,
    isLoading,
    isFetched,
  };
};
