/** Test IDs */
export const HIERARCHY_PROMO_CARD_TEST_ID = "hierarchy-promo-card";
export const HIERARCHY_PROMO_CARD_CTA_BUTTON_TEST_ID = "hierarchy-promo-card-button-cta";
export const HIERARCHY_PROMO_CARD_SKELETON_TEST_ID = "hierarchy-promo-card-skeleton";

/** Hierarchy Promo Card dimensions according to Figma */
export const CARD_HEIGHT = 312;
export const MOBILE_CARD_HEIGHT = 312;

export const RA_PROMO_CARD_BACKGROUND_COLOR = "#FEF1D680";
