import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import {
  IApiHookOptions,
  PrimePromoCardsParamValue,
  PrimePromoCardsResponse,
  QUERY_KEYS,
} from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

const getPrimePromoCards = async (accessToken: string, baseUrl: string, queryParams: string) => {
  const response = await axios.get<PrimePromoCardsResponse>(`${baseUrl}/prime/promo-cards?${queryParams}`, {
    headers: generateApiHeaders(accessToken),
  });

  return response.data;
};

export const useGetPrimePromoCards = <TReturn = PrimePromoCardsResponse>(
  promoCardTypes: PrimePromoCardsParamValue[],
  options: IApiHookOptions<PrimePromoCardsResponse, TReturn> = {},
) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  const queryParams = promoCardTypes.map((type, index) => `promoCardTypes[${index}]=${type}`).join("&");

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_PRIME_PROMO_CARDS, accessToken),
    () => getPrimePromoCards(accessToken, envConfigs.API_PROMOTION_URL, queryParams),
    {
      ...queryBehavior({
        expiresIn,
        enabled: !!accessToken && (!!options.enabled ?? true),
      }),
    },
  );
};
