import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { BUTTON_MAX_WIDTH } from "./LeaseTermsForm.constants";

export const Container = styled.View`
  flex: 1 1 100%;
`;

export const BottomCtaContainer = styled.View`
  width: 100%;
  max-width: ${styledValue(BUTTON_MAX_WIDTH)};
`;

export const InputContainer = styled.View`
  margin-top: ${styledValue(spacing.xxs)};
  margin-bottom: ${styledValue(spacing.xs)};
`;
