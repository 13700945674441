import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { IApiHookOptions, ICreditUtilizationDetailsV2, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

const getCreditAccountsDetails = async (accessToken: string, baseUrl: string) => {
  const response = await axios.get<Partial<ICreditUtilizationDetailsV2>>(
    `${baseUrl}/api/credit-coach/credit-factors/v1/details/accounts`,
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return response.data;
};

export const useCreditAccountsDetails = <TReturn = Partial<ICreditUtilizationDetailsV2>>(
  options: IApiHookOptions<Partial<ICreditUtilizationDetailsV2>, TReturn> = {},
) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_CREDIT_ACCOUNTS, accessToken),
    () => getCreditAccountsDetails(accessToken, envConfigs.API_GATEWAY_URL),
    {
      ...queryBehavior({
        expiresIn,
        ...options,
        enabled: !!accessToken,
      }),
    },
  );
};
