import i18next from "i18next";

import { CreditFactor, CreditInquiriesStatus } from "@bwll/bw-types";

/**
 *
 * A function that returns a message based on the status of the credit inquiries update.
 * Most of the logic was copied from (bw-react-frontend/apps/mobile/src/effects/network/api/creditFactors/getCreditInquiriesMessage.ts)
 * and aligned with the web implementation.
 * TODO: Unify these files.
 */
export const getCreditInquiriesMessage = (item: CreditFactor) => {
  if (!item.update) return null;
  const isPlural = item.update.amount > 1;
  if (item.update.status === CreditInquiriesStatus.INCREASE) {
    const { amount } = item.update;
    return isPlural
      ? i18next.t("creditFactors:creditInquiries:increase_plural", { count: amount })
      : i18next.t("creditFactors:creditInquiries:increase", { count: amount });
  }

  if (item.update.status === CreditInquiriesStatus.NONE) {
    return i18next.t("creditFactors:creditInquiries:noneStatus");
  }

  if (
    item.update.status === CreditInquiriesStatus.NO_CHANGE ||
    item.update.status === CreditInquiriesStatus.DECREASE
  ) {
    return i18next.t("creditFactors:creditInquiries:noChangeStatus");
  }

  return null;
};
