/**
 * The most convenient way to localize currency is to use either
 * .toLocaleString(lcoale, { style: "currency" ...}) or
 * new Intl.NumberFormat(locale, { style: "currency" ... }) approach.
 *
 * Since we don't use JSC Engine on mobile and don't have polyfills configured,
 * the Android OS handles neither approach mentioned above.
 * https://stackoverflow.com/a/63750685
 *
 * As the result, the most reliable cross-platform solution that works on web, iOS, and Android
 * is to manually format by using the regular expression.
 * https://stackoverflow.com/a/45084376
 */
import { AvailableLocale, ENGLISH_LANGUAGE, FRENCH_LANGUAGE } from "@bwll/bw-types";

interface FormatCurrencyDollarArgs {
  amount?: number;
  decimals?: number;
  defaultValue?: string;
  locale?: AvailableLocale;
  hideZeroDecimals?: boolean;
}

const CURRENCY_SYMBOL = "$";
const CURRENCY_OPTIONS = {
  [ENGLISH_LANGUAGE]: {
    thousandsSeparator: ",",
    decimalSeparator: ".",
  },
  [FRENCH_LANGUAGE]: {
    thousandsSeparator: " ",
    decimalSeparator: ",",
  },
} as const;

const prependNegative = (value: number): string => (value < 0 ? "-" : "");

const regex = /\B(?=(\d{3})+(?!\d))/g;

const localizeDollars = {
  [ENGLISH_LANGUAGE]: (value: number, decimals = 0) =>
    `${prependNegative(value)}${CURRENCY_SYMBOL}${Math.abs(value)
      .toFixed(decimals)
      .replace(regex, CURRENCY_OPTIONS[ENGLISH_LANGUAGE].thousandsSeparator)}`,

  [FRENCH_LANGUAGE]: (value: number, decimals = 0) => {
    const formattedAmount = `${prependNegative(value)}${Math.abs(value)
      .toFixed(decimals)
      .replace(regex, CURRENCY_OPTIONS[FRENCH_LANGUAGE].thousandsSeparator)
      .replace(/\./g, CURRENCY_OPTIONS[FRENCH_LANGUAGE].decimalSeparator)}`;

    return `${formattedAmount} ${CURRENCY_SYMBOL}`;
  },
};

export const formatCurrencyDollar = ({
  amount,
  decimals,
  defaultValue = "-",
  locale = ENGLISH_LANGUAGE,
  hideZeroDecimals = false,
}: FormatCurrencyDollarArgs): string => {
  if (Number.isNaN(amount) || typeof amount !== "number" || !localizeDollars[locale]) {
    return defaultValue;
  }

  if (hideZeroDecimals && amount % 1 == 0) {
    return localizeDollars[locale](amount, 0);
  } else {
    return localizeDollars[locale](amount, decimals);
  }
};
