import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import {
  Body2,
  ElementTracker,
  Icon,
  IconTag,
  Label,
  PromoCardTemplate,
  Spacer,
  Subheading2,
  Tooltip,
} from "@bwll/bw-components/next";
import { isWeb } from "@bwll/bw-components/next/constants";
import {
  IMPRESSION_EVENT_NAMES,
  ON_LINK_CLICK_EVENT_NAMES,
  RAWidgetEventData,
  useAnalyticsContext,
  useBreakpoints,
} from "@bwll/bw-hooks";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import {
  ANALYTICS_ATTRIBUTES,
  ENGLISH_LANGUAGE,
  MANUAL_RC_ANALYTICS_ATTRIBUTES,
  RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE,
} from "@bwll/bw-types";
import { getISO, uuid } from "@bwll/bw-utils";

import {
  RA_WIDGET_CONFIRMATION_BADGE_TEST_ID,
  RA_WIDGET_IN_REVIEW_BADGE_TEST_ID,
  RA_WIDGET_NOT_SUBSCRIBED_TEXT_BODY_TEST_ID,
  RA_WIDGET_STATUS_BADGE_TEST_ID,
  RA_WIDGET_SUBSCRIBED_CONTENT_TEST_ID,
  RA_WIDGET_TEST_ID,
} from "./RAWidget.constants";
import * as Styled from "./RAWidget.styles";
import { RAWidgetProps } from "./RAWidget.types";
import { useRentReportingStatusBadge } from "./useRentReportingStatusBadge";

export const RAWidget = ({
  isSubscribed,
  dateReportedFormatted,
  totalAmountFormatted,
  totalAmount,
  shouldShowAmount,
  shouldShowDateReported,
  locale = ENGLISH_LANGUAGE,
  rentPeriod,
  reportingStatus,
  reportedToAgencyDate,
  anyManualTransactionInReview,
  shouldConfirmRent,
  onPress,
}: RAWidgetProps) => {
  const i18next = useTranslation();
  const { isDesktop } = useBreakpoints();
  const statusBadge = useRentReportingStatusBadge({
    locale,
    rentPeriod,
    reportingStatus,
    reportedToAgencyDate,
  });

  const {
    rentAdvantageWidgetAnalytics: { track },
  } = useAnalyticsContext();

  const { status } = statusBadge;

  const impressionId = useMemo(() => uuid(), []);

  const analyticsAttributes = useMemo(
    () => ({
      [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP_]: getISO(),
      [RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE.DATE_REPORTED]: dateReportedFormatted || null,
      [RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE.AMOUNT]: isWeb
        ? totalAmount || 0
        : totalAmountFormatted || "-",

      ...(isWeb && {
        /** Web only */
        [RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE._REPORTING_STATUS]: status,
        [MANUAL_RC_ANALYTICS_ATTRIBUTES.IMPRESSION_ID]: impressionId,
        [ANALYTICS_ATTRIBUTES.ORIGIN]: "dashboard",
      }),

      ...(!isWeb && {
        /** Mobile only */
        [RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE.REPORTING_STATUS]: reportingStatus,
        [ANALYTICS_ATTRIBUTES._IMPRESSION_ID]: impressionId,
        [ANALYTICS_ATTRIBUTES.LANGUAGE]: locale,
      }),
    }),
    [locale, impressionId, dateReportedFormatted, reportingStatus, status, totalAmount, totalAmountFormatted],
  );

  const trackView = useCallback(() => {
    const platformSpecificEventName = isWeb
      ? IMPRESSION_EVENT_NAMES.RENT_REPORTING_CARD_VIEWED_WEB
      : IMPRESSION_EVENT_NAMES.RENT_REPORTING_CARD_VIEWED_MOBILE;

    track<RAWidgetEventData>(platformSpecificEventName, {
      ...analyticsAttributes,
      [isWeb ? ANALYTICS_ATTRIBUTES._EVENT_TYPE : ANALYTICS_ATTRIBUTES.EVENT_TYPE]: platformSpecificEventName,
    });
  }, [analyticsAttributes, track]);

  const trackClick = useCallback(() => {
    const platformSpecificEventName = isWeb
      ? ON_LINK_CLICK_EVENT_NAMES.RENT_REPORTING_CARD_CLICKED_WEB
      : ON_LINK_CLICK_EVENT_NAMES.RENT_REPORTING_CARD_CLICKED_MOBILE;

    track<RAWidgetEventData>(platformSpecificEventName, {
      ...analyticsAttributes,
      [isWeb ? ANALYTICS_ATTRIBUTES._EVENT_TYPE : ANALYTICS_ATTRIBUTES.EVENT_TYPE]: platformSpecificEventName,
    });
  }, [analyticsAttributes, track]);

  const onPressProxy = useCallback(() => {
    trackClick();
    onPress();
  }, [trackClick, onPress]);

  return (
    <ElementTracker shouldStartTracking trackingHandler={trackView}>
      <PromoCardTemplate
        variant="card"
        ctaTitle={i18next.t(
          isSubscribed ? "rentReporting:rentReportingCard:cta" : "rentReporting:rentReportingCard:learnMore",
        )}
        onPress={onPressProxy}
        title={i18next.t("rentReporting:rentReportingCard:titleTM")}
        icon="key"
        testID={RA_WIDGET_TEST_ID}
      >
        {!isSubscribed && (
          <Styled.TextContainer>
            <Body2 color={COLORS.NEUTRAL.WARM["700"]} testID={RA_WIDGET_NOT_SUBSCRIBED_TEXT_BODY_TEST_ID}>
              {i18next.t("rentReporting:rentReportingCard:bodyTextNotSubscribed")}
            </Body2>
          </Styled.TextContainer>
        )}
        {isSubscribed && (
          <Styled.DynamicRow testID={RA_WIDGET_SUBSCRIBED_CONTENT_TEST_ID}>
            <Styled.StaticRow marginBottom={isDesktop ? spacing.none : spacing.xs}>
              <Styled.Cell alignItems="flex-start">
                <Label color={COLORS.NEUTRAL.COOL["600"]}>
                  {i18next.t("rentReporting:rentReportingCard:statusTitle")}
                </Label>
                <Spacer height={spacing.xxxs} />
                {anyManualTransactionInReview && (
                  <IconTag
                    testID={RA_WIDGET_IN_REVIEW_BADGE_TEST_ID}
                    backgroundColor={COLORS.YELLOW["050"]}
                    icon="show"
                    iconColor={COLORS.YELLOW["900"]}
                  >
                    {i18next.t("rentReporting:servicingDashboard:badge:inReview")}
                  </IconTag>
                )}
                {!anyManualTransactionInReview && shouldConfirmRent && (
                  <IconTag
                    testID={RA_WIDGET_CONFIRMATION_BADGE_TEST_ID}
                    backgroundColor={COLORS.YELLOW["050"]}
                    icon="caution"
                    iconColor={COLORS.YELLOW["900"]}
                  >
                    {i18next.t("rentReporting:confirmRentTransaction:title")}
                  </IconTag>
                )}
                {!anyManualTransactionInReview && !shouldConfirmRent && (
                  <Styled.Row>
                    <IconTag
                      testID={RA_WIDGET_STATUS_BADGE_TEST_ID}
                      backgroundColor={statusBadge.lightBadgeBackgroundColor}
                      icon={statusBadge.iconName}
                      iconColor={statusBadge.lightBadgeIconColor}
                    >
                      <Label color={statusBadge.lightBadgeTextColor}>{statusBadge.status}</Label>
                    </IconTag>
                    {!!statusBadge.tooltip && (
                      <>
                        <Spacer width={spacing.xxs} />
                        <Tooltip title={statusBadge.status} content={<Body2>{statusBadge.tooltip}</Body2>}>
                          <View>
                            <Icon icon="info_hollow" size={fontSize.l} color={COLORS.NEUTRAL.COOL["600"]} />
                          </View>
                        </Tooltip>
                      </>
                    )}
                  </Styled.Row>
                )}
              </Styled.Cell>
              <Spacer width={spacing.xs} />
            </Styled.StaticRow>
            <Styled.StaticRow>
              <Styled.Cell alignItems="flex-start">
                <Label color={COLORS.NEUTRAL.COOL["600"]}>
                  {i18next.t("rentReporting:rentReportingCard:dateReported")}
                </Label>
                <Spacer height={spacing.xxs} />
                <Subheading2 color={COLORS.NEUTRAL.WARM["800"]}>
                  {(shouldShowDateReported && dateReportedFormatted) || "-"}
                </Subheading2>
              </Styled.Cell>
              <Spacer width={spacing.xs} />
              <Styled.Cell alignItems="flex-end">
                <Label color={COLORS.NEUTRAL.COOL["600"]}>
                  {i18next.t("rentReporting:rentReportingCard:reportedAmountTitle")}
                </Label>
                <Spacer height={spacing.xxs} />
                <Subheading2 color={COLORS.NEUTRAL.WARM["800"]}>
                  {(shouldShowAmount && totalAmountFormatted) || "-"}
                </Subheading2>
              </Styled.Cell>
            </Styled.StaticRow>
          </Styled.DynamicRow>
        )}
      </PromoCardTemplate>
    </ElementTracker>
  );
};
