import React, { useCallback } from "react";

import { ElementTracker, Skeleton } from "@bwll/bw-components/next";
import { useCreditScoreRequest, useTransitionGetMember } from "@bwll/bw-hooks";
import { CreditScoreGraph, NoHitCard } from "@bwll/bw-modules";

import { useCreditScoreCardAnalytics } from "./CreditScoreCard.hooks";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";
import { gemstoneNavigate } from "@app/helpers";
import { CREDIT_FACTORS_ROUTES } from "@app/router";

export const CreditScoreCard = () => {
  const { data: userData, isLoading } = useTransitionGetMember();
  const { isNoHitMember } = useCreditScoreRequest();

  const userEmail = userData?.individual.email;

  const Analytics = useCreditScoreCardAnalytics(userEmail);

  const navigateToCreditFactors = useCallback(() => {
    Analytics.trackLinkClick();
    gemstoneNavigate(`${ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL}/#${CREDIT_FACTORS_ROUTES.ROOT}`);
  }, [Analytics]);

  const navigateToExternalArticle = useCallback(() => {
    Analytics.trackTooltipLinkClick();
    /** @TODO add query params like _ga={token} etc. */
    gemstoneNavigate(`${ENVIRONMENT_VARIABLES.BWLL_ZENDESK_FAQ_URL}/articles/360057082772`, true);
  }, [Analytics]);

  const onMarkdownPress = useCallback((url: string) => {
    const isEmail: boolean = url.includes("mailto:");
    gemstoneNavigate(url, !isEmail);
  }, []);

  if (isLoading) {
    return <Skeleton width="100%" height={157} variant="rounded" />;
  }

  if (isNoHitMember && userData) {
    return <NoHitCard clientData={userData.individual} onMarkdownPress={onMarkdownPress} />;
  }

  return (
    <ElementTracker shouldStartTracking trackingHandler={Analytics.trackViewed}>
      <CreditScoreGraph
        onPress={navigateToCreditFactors}
        onTooltipPress={Analytics.trackTooltipOpen}
        onTooltipLinkPress={navigateToExternalArticle}
      />
    </ElementTracker>
  );
};
