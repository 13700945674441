import styled from "@emotion/native";

import { isWeb } from "@bwll/bw-components/next/constants";
import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

type LayoutProps = { isMobile: boolean };

export const Container = styled.View<LayoutProps>`
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
`;

export const DetailContainer = styled.View<LayoutProps & { withBorder: boolean }>`
  flex: 1;
  flex-direction: ${({ isMobile }) => (isMobile ? "row" : "column")};
  justify-content: ${({ isMobile }) => (isMobile ? "space-between" : "center")};
  align-items: center;
  padding-top: ${styledValue(isWeb ? spacing.xs : spacing.none)};
  padding-bottom: ${styledValue(spacing.xs)};
  border: 1px solid transparent;
  border-right-color: ${({ withBorder }) => (withBorder ? COLORS.NEUTRAL.COOL["100"] : "transparent")};
`;
