import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  Body2,
  Chip,
  Icon,
  PromoCardTemplate,
  PromoCardTemplateCtaContainer,
  PromoCardTemplateCtaText,
} from "@bwll/bw-components/next";
import {
  CreditCoachUpdatesCardClickedEventData,
  useAnalyticsContext,
  usePlatformContext,
} from "@bwll/bw-hooks";
import { COLORS, fontSize } from "@bwll/bw-styles";
import { ANALYTICS_ATTRIBUTES } from "@bwll/bw-types";
import { capitalize } from "@bwll/bw-utils";

import {
  CREDIT_CARD_UPDATES_CARD_BADGE_TEST_ID,
  CREDIT_CARD_UPDATES_CARD_CTA_TEST_ID,
  CREDIT_CARD_UPDATES_CARD_TEST_ID,
  CREDIT_CARD_UPDATES_CARD_TEXT_TEST_ID,
} from "./CreditCoachUpdatesCard.constants";
import * as Styled from "./CreditCoachUpdatesCard.styles";
import { CreditCoachUpdatesCardProps } from "./CreditCoachUpdatesCard.types";

export const CreditCoachUpdatesCard = ({ onPress, text, total }: CreditCoachUpdatesCardProps) => {
  const { platform } = usePlatformContext();
  const i18next = useTranslation();

  const { creditCoachUpdatesAnalytics } = useAnalyticsContext();

  const ctaText = i18next.t("dashboard:cards:creditCoachTips:viewUpdates");

  const trackClick = useCallback(() => {
    creditCoachUpdatesAnalytics.buttonClicked<CreditCoachUpdatesCardClickedEventData>({
      [ANALYTICS_ATTRIBUTES.BUTTON_TEXT]: capitalize(ctaText),
      [ANALYTICS_ATTRIBUTES.NUMBER_OF_UPDATES_ON_CARD]: 1,
      [ANALYTICS_ATTRIBUTES.RED_BADGE_NUMBER]: total,
      [ANALYTICS_ATTRIBUTES.RED_BADGE_PRESENT]: total > 1,
    });
  }, [creditCoachUpdatesAnalytics, total, ctaText]);

  const onPressProxy = useCallback(() => {
    trackClick();
    onPress();
  }, [onPress, trackClick]);

  return (
    <PromoCardTemplate
      variant="card"
      testID={CREDIT_CARD_UPDATES_CARD_TEST_ID}
      cta={
        <PromoCardTemplateCtaContainer isSnackbar={false} onPress={onPressProxy}>
          <PromoCardTemplateCtaText platform={platform} testID={CREDIT_CARD_UPDATES_CARD_CTA_TEST_ID}>
            {ctaText}
          </PromoCardTemplateCtaText>
          {total > 1 && (
            <Chip
              text={total.toString()}
              backgroundColor={COLORS.RED[500]}
              textColor={COLORS.WHITE}
              testID={CREDIT_CARD_UPDATES_CARD_BADGE_TEST_ID}
            />
          )}
        </PromoCardTemplateCtaContainer>
      }
    >
      <Styled.Row>
        <Styled.TitleContainer>
          <Styled.IconContainer>
            <Icon icon="warning" color="#ED2A7B" size={fontSize.xs} />
          </Styled.IconContainer>
        </Styled.TitleContainer>
        <Styled.TextContainer>
          {/* Some entries include <p> tag around the text */}
          <Body2 color={COLORS.NEUTRAL.WARM["700"]} testID={CREDIT_CARD_UPDATES_CARD_TEXT_TEST_ID}>
            {text.replace("<p>", "").replace("</p>", "")}
          </Body2>
        </Styled.TextContainer>
      </Styled.Row>
    </PromoCardTemplate>
  );
};
