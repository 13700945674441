import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const TextContainer = styled.View`
  margin-top: ${styledValue(spacing.xxs)};
`;

export const DynamicRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: ${styledValue(spacing.xxs)};
`;

export const StaticRow = styled.View<{ marginBottom?: number }>`
  min-width: 50%;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${({ marginBottom }) => styledValue(marginBottom || 0)};
`;

export const Row = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Cell = styled.View<{ alignItems: "flex-start" | "flex-end" }>`
  align-items: ${({ alignItems }) => alignItems};
`;
