import styled from "@emotion/native";

import { Body2, Caption } from "@bwll/bw-components/next";
import { COLORS, borderRadius, fontSize, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const FlexLayout = styled.View<{ minHeight: number; dataSet?: HTMLElement["dataset"] }>`
  width: 100%;
  min-height: ${({ minHeight }) => styledValue(minHeight)};
  border-radius: ${styledValue(borderRadius.s)};
  overflow: hidden;
  background-color: ${COLORS.WHITE};
`;

const SIDE_PADDING = spacing.xs;
const MOBILE_SIDE_PADDING = spacing.m;

export const InnerContent = styled.View`
  max-width: ${styledValue(554 + SIDE_PADDING * 2)};
  width: 100%;
  margin: 0 auto;
  align-items: center;
  flex: 1;
`;

// Prettier breaks long lines, so css was having an error when adding the logic inside.
const mobilePadding = `${styledValue(spacing.xxs)} ${styledValue(MOBILE_SIDE_PADDING)}`;
const webPadding = `${styledValue(spacing.xxs)} ${styledValue(SIDE_PADDING)}`;

export const Container = styled.View<{ mt: number; isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? mobilePadding : webPadding)};
  margin-top: ${({ mt }) => styledValue(mt)};
  align-items: center;
  width: ${({ isMobile }) => (isMobile ? styledValue(370) : styledValue(500))};
  max-width: 100%;
  flex: 1;
`;

export const TitleContainer = styled.View<{ dataSet?: HTMLElement["dataset"] }>`
  height: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TitleText = styled(Body2)<{ isMobile: boolean; testId?: string }>`
  font-size: ${({ isMobile }) => styledValue(isMobile ? fontSize.xs : fontSize.s)};

  ${({ isMobile }) => isMobile && "flex-shrink: 1;"}
  ${({ isMobile }) => isMobile && "flex-basis: 100%;"}
`;

export const OfferContainer = styled.View`
  max-width: 100%;
  width: 100%;
  padding-top: ${styledValue(spacing.xs)};
`;

export const OfferEndDateContainer = styled.View`
  height: ${styledValue(spacing.xs)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OfferRow = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  line-height: ${styledValue(lineHeight.xs)};
`;

export const OfferText = styled(Body2)<{ $textAlign: "left" | "center" }>`
  line-height: ${styledValue(lineHeight.xs)};
  width: 100%;
  margin: auto;
  text-align: ${({ $textAlign }) => $textAlign};
`;

const THUMBNAIL_SIZE = 32;
export const CompanyIcon = styled.Image<{ size?: number }>`
  width: ${({ size }) => styledValue(size ?? THUMBNAIL_SIZE)};
  height: ${({ size }) => styledValue(size ?? THUMBNAIL_SIZE)};
  margin-right: ${styledValue(spacing.xxxs)};
`;

export const ButtonGroup = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${styledValue(spacing.none)} ${styledValue(spacing.none)};
  height: 48px;
`;

export const ProductOfferContainer = styled.View`
  margin-top: ${styledValue(spacing.xxs)};
  height: ${styledValue(spacing.xl)};
`;

export const ButtonContainer = styled.View`
  flex: 1;
`;

export const DisclaimerContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${styledValue(spacing.xs)};
`;

export const DisclaimerText = styled(Caption)`
  text-align: center;
`;

export const Footer = styled.View`
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
  align-self: stretch;
`;
