import i18next from "i18next";

import {
  AverageCreditAgeStatus,
  CreditFactor,
  CreditFactorUpdateCard,
  CreditUtilizationStatus,
  EnhancedCreditFactor,
  Factor,
  MissedPaymentTypes,
  NoticeType,
} from "@bwll/bw-types";

import {
  calculateMark,
  getAverageCreditAgeMessage,
  getCreditInquiriesMessage,
  getCreditUtilizationMessage,
  getDerogatoryMarksMessage,
  getMissedPaymentsMessage,
  getTotalAccountsMessage,
} from "./utils";

type CreditFactorLookup = {
  [key in Factor]: {
    impact: string;
    order: number;
    title: string;
    tooltip: string;
    tooltipImpact: string;
    updateCard: (item: CreditFactor) => CreditFactorUpdateCard;
    formatTotal?: (item: number) => string;
  };
};

/**
 *
 * A function that returns a object with the credit factors data ready for display.
 * Most of the logic was copied from (bw-react-frontend/apps/mobile/src/effects/network/api/creditFactors/transformers.ts)
 * and aligned with the web implementation.
 * TODO: Unify these files.
 */
export const transformSummary = (summary: CreditFactor[] = []): EnhancedCreditFactor[] => {
  const data: CreditFactorLookup = {
    [Factor.MISSED_PAYMENTS]: {
      impact: i18next.t("dashboard:cards:creditFactors:impact:high"),
      order: 0,
      title: i18next.t("dashboard:cards:creditFactors:missedPaymentsTitle"),
      tooltip: i18next.t("dashboard:cards:creditFactors:tooltips:missedPayments"),
      tooltipImpact: i18next.t("dashboard:cards:creditFactors:tooltips:missedPaymentsImpact"),
      updateCard: (item: CreditFactor) => {
        const isDeferred = item?.update?.type === MissedPaymentTypes.DEFERRED;
        const updateMessage = getMissedPaymentsMessage(item);

        return {
          message: updateMessage,
          dashboardSummaryMessage: updateMessage,
          noticeType: isDeferred ? NoticeType.WARNING : NoticeType.ALERT,
        };
      },
    },
    [Factor.CREDIT_INQUIRIES]: {
      impact: i18next.t("dashboard:cards:creditFactors:impact:low"),
      order: 1,
      title: i18next.t("dashboard:cards:creditFactors:creditInquiriesTitle"),
      tooltip: i18next.t("dashboard:cards:creditFactors:tooltips:creditInquiries"),
      tooltipImpact: i18next.t("dashboard:cards:creditFactors:tooltips:creditInquiriesImpact"),
      updateCard: (item: CreditFactor) => {
        const updateMessage = getCreditInquiriesMessage(item);

        return {
          message: updateMessage,
          dashboardSummaryMessage: updateMessage,
          noticeType: NoticeType.WARNING,
        };
      },
    },
    [Factor.CREDIT_UTILIZATION]: {
      impact: i18next.t("dashboard:cards:creditFactors:impact:high"),
      order: 2,
      title: i18next.t("dashboard:cards:creditFactors:creditUtilizationTitle"),
      tooltip: i18next.t("dashboard:cards:creditFactors:tooltips:creditUtilization"),
      tooltipImpact: i18next.t("dashboard:cards:creditFactors:tooltips:creditUtilizationImpact"),
      updateCard: (item: CreditFactor) => {
        const isLessOrEqualThan30 = item.total <= 30;
        let noticeType = NoticeType.DEFAULT;
        if (isLessOrEqualThan30 && item.update && item.update.status === CreditUtilizationStatus.INCREASE)
          noticeType = NoticeType.WARNING;
        if (!isLessOrEqualThan30 && item.update && item.update.status === CreditUtilizationStatus.INCREASE)
          noticeType = NoticeType.ALERT;

        return {
          message: getCreditUtilizationMessage(item),
          dashboardSummaryMessage: getCreditUtilizationMessage(item, true),
          noticeType: noticeType,
        };
      },
      formatTotal: (item) => `${item}%`,
    },
    [Factor.DEROGATORY_MARKS]: {
      impact: i18next.t("dashboard:cards:creditFactors:impact:high"),
      order: 3,
      title: i18next.t("dashboard:cards:creditFactors:derogatoryMarksTitle"),
      tooltip: i18next.t("dashboard:cards:creditFactors:tooltips:derogatoryMarks"),
      tooltipImpact: i18next.t("dashboard:cards:creditFactors:tooltips:derogatoryMarksImpact"),
      updateCard: (item: CreditFactor) => ({
        message: getDerogatoryMarksMessage(item),
        dashboardSummaryMessage: getDerogatoryMarksMessage(item),
        noticeType: NoticeType.ALERT,
      }),
    },
    [Factor.AVERAGE_CREDIT_AGE]: {
      impact: i18next.t("dashboard:cards:creditFactors:impact:medium"),
      order: 4,
      title: i18next.t("dashboard:cards:creditFactors:avgCreditAgeTitle"),
      tooltip: i18next.t("dashboard:cards:creditFactors:tooltips:avgCreditAge"),
      tooltipImpact: i18next.t("dashboard:cards:creditFactors:tooltips:avgCreditAgeImpact"),
      updateCard: (item: CreditFactor) => {
        const isIncrease = item?.update?.status === AverageCreditAgeStatus.INCREASE;

        return {
          message: getAverageCreditAgeMessage({
            item,
          }),
          dashboardSummaryMessage: getAverageCreditAgeMessage({
            item,
            isSummary: true,
          }),
          noticeType: isIncrease ? NoticeType.SUCCESS : NoticeType.WARNING,
        };
      },
      // TODO: Extract these functions and add tests!
      formatTotal: (item) => {
        const years = Math.floor(item / 12);
        const remainingMonths = item % 12;

        if (years <= 0) {
          return i18next.t("dashboard:cards:creditFactors:month", { count: item });
        }

        return remainingMonths > 0
          ? `${i18next.t("dashboard:cards:creditFactors:yr", { count: years })} ${i18next.t(
              "dashboard:cards:creditFactors:mo",
              { count: remainingMonths },
            )}`
          : i18next.t("dashboard:cards:creditFactors:year", { count: years });
      },
    },
    [Factor.TOTAL_ACCOUNTS]: {
      impact: i18next.t("dashboard:cards:creditFactors:impact:low"),
      order: 5,
      title: i18next.t("dashboard:cards:creditFactors:totalAccountsTitle"),
      tooltip: i18next.t("dashboard:cards:creditFactors:tooltips:totalAccounts"),
      tooltipImpact: i18next.t("dashboard:cards:creditFactors:tooltips:totalAccountsImpact"),
      updateCard: (item: CreditFactor) => {
        const updateMessage = getTotalAccountsMessage(item);

        return {
          message: updateMessage,
          dashboardSummaryMessage: updateMessage,
          noticeType: NoticeType.SUCCESS,
        };
      },
    },
  };

  return summary
    .map((item) => {
      const uuid = item.uuid;
      const { title, formatTotal, order, tooltip, updateCard, impact, tooltipImpact } = data[item.factor];

      return {
        uuid,
        factor: item.factor,
        order,
        title,
        total: item.total,
        formattedTotal: formatTotal ? formatTotal(item.total) : item.total,
        ...(item.update ? { update: item.update } : { update: null }),
        tooltip,
        tooltipImpact,
        updateCard: updateCard(item),
        impact,
        mark: calculateMark(item.factor, item.total),
      } as EnhancedCreditFactor;
    })
    .sort((a, b) => a.order - b.order);
};
