import styled from "@emotion/native";
import { TextStyle } from "react-native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

type StyledLayoutProps = { isDesktop: boolean };

export const styles = {
  subHeading: {
    textAlign: "center",
  } as TextStyle,
};

export const Container = styled.View<
  StyledLayoutProps & { flexDirection: "row" | "column"; isLastChild: boolean }
>`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ flexDirection }) => (flexDirection === "column" ? "center" : "flex-start")};
  width: 100%;
  max-width: 100%;
  margin-right: ${({ isDesktop, isLastChild }) => isDesktop && !isLastChild && styledValue(40)};
  margin-bottom: ${({ isDesktop, isLastChild }) => !isDesktop && !isLastChild && styledValue(spacing.xl)};
`;

export const ImageContainer = styled.View<{ flexDirection: "row" | "column" }>`
  margin-bottom: ${({ flexDirection }) => (flexDirection === "column" ? styledValue(spacing.xs) : 0)};
  margin-right: ${({ flexDirection }) => (flexDirection === "column" ? 0 : styledValue(spacing.xs))};
`;

export const TextContainer = styled.View`
  flex-grow: 1;
  flex-basis: 0%;

  display: flex;
  flex-direction: column;
  gap: ${styledValue(12)};
`;
