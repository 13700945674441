import { useMemo } from "react";

import {
  DashboardComponentAnalyticsAttributes,
  PopupEventData,
  TFeatureAnalyticsAttributes,
} from "@bwll/bw-hooks";
import { useGetImpressionToken } from "@bwll/bw-hooks/src/hooks/analytics/useGetImpressionToken";
import {
  ANALYTICS_ATTRIBUTES,
  CREDIT_BUILDING_PV_KNACK_ID,
  POPUP_NAMES,
  PromotionPopup,
} from "@bwll/bw-types";

/**
 * Hook returns common attributes for the popup component.
 * @param {PromotionPopup} popupData - popup data
 * @returns {Record<string, string>} - common attributes
 * TODO: Finish implementing analytics attributes (https://borrowell.atlassian.net/browse/GMS-13471)
 */
const getFlowName = (knackId: number) => {
  if (knackId === CREDIT_BUILDING_PV_KNACK_ID.CREDIT_BUILDER) {
    return POPUP_NAMES.CBL_POPUP;
  }

  if (knackId === CREDIT_BUILDING_PV_KNACK_ID.RENT_ADVANTAGE) {
    return POPUP_NAMES.RENT_REPORTING_POPUP;
  }

  if (knackId === CREDIT_BUILDING_PV_KNACK_ID.RENT_ADVANTAGE_PAST_PAYMENTS) {
    return POPUP_NAMES.RENT_REPORTING_PAST_PAYMENTS_POPUP;
  }

  return POPUP_NAMES.MARKETPLACE_POPUP;
};
export const usePopupCommonAttributes = (
  popupData?: PromotionPopup,
  analyticsAttributes?: TFeatureAnalyticsAttributes<DashboardComponentAnalyticsAttributes>,
): PopupEventData => {
  const impressionId = useGetImpressionToken();
  return useMemo(() => {
    const flowName = popupData && popupData.knackId ? getFlowName(Number(popupData?.knackId)) : "";
    return {
      [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: analyticsAttributes?.destinationUrl || "",
      [ANALYTICS_ATTRIBUTES._FLOW_NAME]: flowName,
      [ANALYTICS_ATTRIBUTES.POPUP_IMPRESSION_ID]: impressionId,
      [ANALYTICS_ATTRIBUTES.TRACKING_TAG]: popupData?.name || "",
      [ANALYTICS_ATTRIBUTES.PRODUCT_ID]: popupData?.productReferenceId || "",
    };
  }, [analyticsAttributes?.destinationUrl, impressionId, popupData]);
};
