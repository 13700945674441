import i18next from "i18next";

import { AverageCreditAgeStatus, CreditFactor } from "@bwll/bw-types";

export interface IAverageCreditAgeMessage {
  item: CreditFactor;
  isSummary?: boolean;
}

/**
 *
 * A function that returns a message based on the status of the average credit age update.
 * Most of the logic was copied from (bw-react-frontend/apps/mobile/src/effects/network/api/creditFactors/getAverageCreditAgeMessage.ts)
 * and aligned with the web implementation.
 * TODO: Unify these files.
 */
export const getAverageCreditAgeMessage = ({ item, isSummary }: IAverageCreditAgeMessage): string | null => {
  if (item.update) {
    const { status, amount: months } = item.update;
    const years = Math.floor(months / 12);
    const isYears = years > 0;
    const timeUnit = isYears ? "year" : "month";
    const interpolation = { count: isYears ? years : months };

    if (status === AverageCreditAgeStatus.INCREASE) {
      return isSummary
        ? i18next.t("creditFactors:avgCreditAge:summaryUpdate:increasedBy") +
            i18next.t(`creditFactors:avgCreditAge:summaryUpdate:${timeUnit}`, interpolation)
        : i18next.t(`creditFactors:avgCreditAge:increase:${timeUnit}`, interpolation);
    }

    if (status === AverageCreditAgeStatus.DECREASE) {
      if (!isYears) {
        return null;
      }

      return isSummary
        ? i18next.t("creditFactors:avgCreditAge:summaryUpdate:decreasedBy") +
            i18next.t(`creditFactors:avgCreditAge:summaryUpdate:${timeUnit}`, interpolation)
        : i18next.t("creditFactors:avgCreditAge:decrease:year", interpolation);
    }
    if (!isYears) {
      return i18next.t("creditFactors:avgCreditAge:noneStatus");
    }
  }

  return null;
};
