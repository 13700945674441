import React from "react";

import { Skeleton } from "@bwll/bw-components/next";
import { useCreditScoreRequest, useGetProductPicks, usePreselectionsV4 } from "@bwll/bw-hooks";

import { SponsoredCarousel } from "./SponsoredCarousel.component";
import { SPONSORED_CAROUSEL_SKELETON_TEST_ID } from "./SponsoredCarousel.constants";
import { SponsoredCarouselContainerProps } from "./SponsoredCarousel.types";

export const SponsoredCarouselContainer = ({
  transformCtaLink,
  onPress,
}: SponsoredCarouselContainerProps) => {
  const { data, isLoading } = useGetProductPicks({ enabled: true });
  const { data: preselectionV4Data, isLoading: isV4Loading } = usePreselectionsV4({ enabled: true });
  const { data: creditScoreRequest } = useCreditScoreRequest();

  if (isLoading) {
    return (
      <Skeleton width="100%" height={536} variant="rounded" testID={SPONSORED_CAROUSEL_SKELETON_TEST_ID} />
    );
  }

  /** Empty response */
  if (
    (!isLoading && (!data || !data.productVerticals || data.productVerticals.length === 0)) ||
    (!isV4Loading && (!preselectionV4Data || preselectionV4Data.length === 0))
  ) {
    return null;
  }

  return (
    <SponsoredCarousel
      onPress={onPress}
      transformCtaLink={transformCtaLink}
      products={data.productVerticals}
      productsv4={preselectionV4Data}
      individualClientIdReferenceNumber={creditScoreRequest?.individualClientIdReferenceNumber || 0}
    />
  );
};
