import React from "react";
import { useTranslation } from "react-i18next";

import { SponsoredProductDetailsTemplate } from "../SponsoredProductDetailsTemplate";
import { SponsoredCreditCardDetailsProps } from "./SponsoredCreditCardDetails.types";

export const SponsoredCreditCardDetails = ({
  annualFee,
  purchaseInterestRate,
  cashAdvanceRate,
  balanceTransferRate,
}: SponsoredCreditCardDetailsProps) => {
  const i18next = useTranslation();

  return (
    <SponsoredProductDetailsTemplate
      details={[
        {
          label: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:creditCards:annualFee"),
          value: annualFee,
        },
        {
          label: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:creditCards:interest"),
          value: purchaseInterestRate,
        },
        {
          label: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:creditCards:cashAdvance"),
          value: cashAdvanceRate,
        },
        {
          label: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:creditCards:balanceTransfer"),
          value: balanceTransferRate,
        },
      ]}
    />
  );
};
