import { ANALYTICS_ATTRIBUTES, COMPONENT_PLACEMENT } from "@bwll/bw-types";

import { IMPRESSION_EVENT_NAMES, TFeatureAnalyticsAttributes } from "./analyticsContext.types";
import { AnalyticsEventProps } from "./featuresAttributes";

export const useCreditFactoUpdateTrackEvents = ({ trackEvent, commonAttributes }: AnalyticsEventProps) => {
  return {
    creditFactorUpdateViewed: <T>(eventAttributes: TFeatureAnalyticsAttributes<T>) => {
      trackEvent(IMPRESSION_EVENT_NAMES.CREDIT_FACTOR_UPDATE_VIEWED, {
        ...commonAttributes,
        ...eventAttributes,
        [ANALYTICS_ATTRIBUTES.PLACEMENT]: COMPONENT_PLACEMENT.DASHBOARD,
      });
    },
  };
};
