import i18next from "i18next";

import { CreditFactor, DerogatoryMarksStatus, DerogatoryMarksTypes } from "@bwll/bw-types";

/**
 *
 * A function that returns a message based on the status of the derogatory marks update.
 * Most of the logic was copied from (bw-react-frontend/apps/mobile/src/effects/network/api/creditFactors/getDerogatoryMarksMessage.ts)
 * and aligned with the web implementation.
 * TODO: Unify these files.
 */
export const getDerogatoryMarksMessage = (item: CreditFactor) => {
  if (!item.update) return null;
  const isPlural = item.update.amount > 1;
  if (item.update.status === DerogatoryMarksStatus.NONE) {
    return i18next.t("creditFactors:derogatoryMarks:none");
  }

  if (item.update.status === DerogatoryMarksStatus.INCREASE) {
    const { status, type, amount } = item.update;

    if (type === null) {
      return i18next.t("creditFactors:derogatoryMarks:increase:marks", {
        count: amount,
      });
    }

    const copy = {
      [DerogatoryMarksStatus.NONE]: null,
      [DerogatoryMarksStatus.NO_CHANGE]: null,
      [DerogatoryMarksStatus.DECREASE]: null,
      [DerogatoryMarksStatus.INCREASE]: {
        [DerogatoryMarksTypes.COLLECTION]: isPlural
          ? i18next.t("creditFactors:derogatoryMarks:increase:collection_plural", {
              count: amount,
            })
          : i18next.t("creditFactors:derogatoryMarks:increase:collection", {
              count: amount,
            }),
        [DerogatoryMarksTypes.BANKRUPTCY]: isPlural
          ? i18next.t("creditFactors:derogatoryMarks:increase:bankruptcy_plural", {
              count: amount,
            })
          : i18next.t("creditFactors:derogatoryMarks:increase:bankruptcy", {
              count: amount,
            }),
        [DerogatoryMarksTypes.LEGAL_ITEM]: isPlural
          ? i18next.t("creditFactors:derogatoryMarks:increase:legalItem_plural", {
              count: amount,
            })
          : i18next.t("creditFactors:derogatoryMarks:increase:legalItem", {
              count: amount,
            }),
        [DerogatoryMarksTypes.MARKS]: isPlural
          ? i18next.t("creditFactors:derogatoryMarks:increase:marks_plural", {
              count: amount,
            })
          : i18next.t("creditFactors:derogatoryMarks:increase:marks", {
              count: amount,
            }),
      },
    };

    return copy?.[status]?.[type as DerogatoryMarksTypes] ?? copy?.[status];
  }

  return i18next.t("creditFactors:derogatoryMarks:noChange");
};
