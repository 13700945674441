import { LeaseTermsFormFields } from "./LeaseTermsForm.types";

export const defaultLeaseTerms: LeaseTermsFormFields = {
  amount: "",
  leaseStartDate: "",
  rentPaymentDate: "",
};

export const BUTTON_MAX_WIDTH = 360;
export const WEB_CONTENT_MAX_WIDTH = 560;
export const MAX_RENT_AMOUNT = 10000;

export const AMOUNT_MAX_LENGTH = 14;

export const LEASE_TERMS_FORM_TEST_IDS = {
  MONTHLY_RENT_AMOUNT: "monthly-rent-amount-text-input",
  LEASE_START_DATE: "lease-start-month-text-input",
  RENT_PAYMENT_DUE_DATE: "rent-payment-due-date-text-input",
  SUBMIT: {
    MOBILE: "continue-to-submit-button",
    WEB: "continue-button",
  },
} as const;

export const LEASE_TERMS_FORM_WEB_IDS = {
  MONTHLY_RENT_AMOUNT: "monthlyRentAmount",
  LEASE_START_DATE: "leaseStartDate",
  RENT_PAYMENT_DUE_DATE: "rentPaymentDueDate",
} as const;
