import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { BUTTON_TYPE, Button, ElementTracker, PromoCardTemplate, Spacer } from "@bwll/bw-components/next";
import { AnalyticsContext, PopupEventData } from "@bwll/bw-hooks";
import { spacing } from "@bwll/bw-styles";
import {
  CREDIT_BUILDING_PV_KNACK_ID,
  DASHBOARD_PROMOTION_POPUP,
  DEEPLINK_PRODUCT_TYPE_ID,
  PRODUCT_VERTICAL_ID_TO_NAMES_MAPPING,
  PROMOTION_ACTION_ID,
  PROMOTION_TYPE,
  PromotionPopup,
} from "@bwll/bw-types";

import { POPUP_BUTTON_CTA_TEST_ID, POPUP_BUTTON_DISMISS_TEST_ID, POPUP_TEST_ID } from "./Popup.constants";
import { BackdropContainer, ButtonContainer, PromoCardTemplateContainer } from "./Popup.style";
import { PopupProps } from "./Popup.types";
import { usePopupCommonAttributes } from "./usePopupCommonAttributes";

const getPromotionActionKey = (knackId: number, vertical?: number) => {
  if (knackId === CREDIT_BUILDING_PV_KNACK_ID.CREDIT_BUILDER) {
    return DASHBOARD_PROMOTION_POPUP.CBL2;
  }

  if (knackId === CREDIT_BUILDING_PV_KNACK_ID.RENT_ADVANTAGE) {
    return DASHBOARD_PROMOTION_POPUP.RENT_REPORTING;
  }

  if (knackId === CREDIT_BUILDING_PV_KNACK_ID.RENT_ADVANTAGE_PAST_PAYMENTS) {
    return DASHBOARD_PROMOTION_POPUP.RENT_REPORTING_PAST_PAYMENTS;
  }

  return `${knackId}-${PRODUCT_VERTICAL_ID_TO_NAMES_MAPPING[vertical as DEEPLINK_PRODUCT_TYPE_ID]}`;
};

export const Popup = ({
  analyticsAttributes,
  popupData,
  redirectAction,
  recordPromotionAction,
  MobileModal,
  onShowPopup,
  onHidePopup,
}: PopupProps) => {
  const i18next = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const { popupAnalytics } = useContext(AnalyticsContext);
  const popupAnalyticsAttributes = usePopupCommonAttributes(popupData, analyticsAttributes);

  const onPress = useCallback(
    (popup: PromotionPopup) => () => {
      popupAnalytics.popupClicked<PopupEventData>(popupAnalyticsAttributes);
      redirectAction(popup);
      setIsVisible(false);
      onHidePopup?.();
      if (recordPromotionAction) {
        recordPromotionAction({
          key: getPromotionActionKey(Number(popup.knackId), popup.vertical),
          type: PROMOTION_TYPE.POP_UP,
          popupActionId: PROMOTION_ACTION_ID.COMPLETED,
        });
      }
    },
    [popupAnalytics, popupAnalyticsAttributes, redirectAction, onHidePopup, recordPromotionAction],
  );

  const dismissPopup = useCallback(
    (popup: PromotionPopup) => () => {
      popupAnalytics.popupDismissed<PopupEventData>(popupAnalyticsAttributes);
      setIsVisible(false);
      onHidePopup?.();
      if (recordPromotionAction) {
        recordPromotionAction({
          key: getPromotionActionKey(Number(popup.knackId), popup.vertical),
          type: PROMOTION_TYPE.POP_UP,
          popupActionId: PROMOTION_ACTION_ID.DISMISSED,
        });
      }
    },
    [popupAnalytics, popupAnalyticsAttributes, onHidePopup, recordPromotionAction],
  );

  const onViewed = useCallback(() => {
    popupAnalytics.popupViewed<PopupEventData>(popupAnalyticsAttributes);
  }, [popupAnalyticsAttributes, popupAnalytics]);

  useEffect(() => {
    if (isVisible) {
      onShowPopup?.();
    }
  }, [isVisible, onShowPopup]);

  if (!popupData) return null;

  const badgeText =
    popupData?.knackId && popupData?.knackId in CREDIT_BUILDING_PV_KNACK_ID
      ? i18next.t(`dashboard:popup:newBadgeText`)
      : undefined;

  const ctaButton = (
    <ButtonContainer>
      <Button
        testID={POPUP_BUTTON_CTA_TEST_ID}
        onPress={onPress(popupData)}
        title={popupData.buttonText}
        type={BUTTON_TYPE.PRIMARY}
      />
      <Spacer height={spacing.xxs} />
      <Button
        testID={POPUP_BUTTON_DISMISS_TEST_ID}
        onPress={dismissPopup(popupData)}
        title={i18next.t(`dashboard:popup:dismissButton`)}
        type={BUTTON_TYPE.TERTIARY}
      />
    </ButtonContainer>
  );

  const getPopupCard = () => (
    <PromoCardTemplateContainer>
      <ElementTracker shouldStartTracking={true} trackingHandler={onViewed}>
        <PromoCardTemplate
          cardImageProps={{ width: "100%", height: "157px" }}
          badgeText={badgeText}
          variant={"card"}
          withLine={false}
          cardImageSource={{
            uri: popupData.image,
          }}
          cardImageBackgroundImage={popupData.image}
          title={popupData.title}
          body={popupData.body}
          cta={ctaButton}
          testID={POPUP_TEST_ID}
        />
      </ElementTracker>
    </PromoCardTemplateContainer>
  );

  if (MobileModal) {
    return (
      <MobileModal isModalVisible={isVisible} onBackdropPress={dismissPopup(popupData)}>
        {getPopupCard()}
      </MobileModal>
    );
  }

  if (isVisible) {
    return <BackdropContainer onPress={dismissPopup(popupData)}>{getPopupCard()}</BackdropContainer>;
  }

  return null;
};
