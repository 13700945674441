import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Rate, RatesTable } from "../../RatesTable";
import { CreditCardRatesTableProps, RatesTableOrder } from "./CreditCardRatesTable.types";

const defaultOrder: RatesTableOrder = [
  "annualFee",
  "purchaseInterestRate",
  "cashAdvanceRate",
  "balanceTransferRate",
];

const propertyLocaleKeyMap = {
  annualFee: "annualFeesTerm",
  balanceTransferRate: "balanceTransferTerm",
  cashAdvanceRate: "cashAdvanceTerm",
  purchaseInterestRate: "purchaseInterestTerm",
};

// TODO remove temp values, hardcoding is required for  GMS-14240
const tempCapitalOneValueMap = {
  annualFee: "annualFeesCapOne",
  balanceTransferRate: "balanceTransferCapOne",
  cashAdvanceRate: "cashAdvanceCapOne",
  purchaseInterestRate: "purchaseInterestCapOne",
};

export const CreditCardRatesTable = ({
  data,
  order = defaultOrder,
  testID,
  isCapitalOne,
}: CreditCardRatesTableProps) => {
  const i18next = useTranslation();
  const fullOrder: RatesTableOrder = useMemo(() => [...new Set([...order, ...defaultOrder])], [order]);
  const rates = useMemo<Rate[]>(
    () =>
      fullOrder.map((key) => ({
        key,
        labelLocaleKey: `productCatalog:creditCards:metrics:${propertyLocaleKeyMap[key]}`,
        value: data[key],
        ...(key === "annualFee"
          ? {
              type: "currency",
              isFirstYearWaived: data.isFirstYearWaived,
            }
          : { type: "percentage" }),
      })),
    [data, fullOrder],
  );

  const capitalOneRates = useMemo<Rate[]>(
    () =>
      fullOrder.map((key) => ({
        key,
        labelLocaleKey: `productCatalog:creditCards:metrics:${propertyLocaleKeyMap[key]}`,
        value: i18next.t(`productCatalog:creditCards:metrics:${tempCapitalOneValueMap[key]}`),
        type: "text",
      })),
    [],
  );

  return <RatesTable rates={isCapitalOne ? capitalOneRates : rates} testID={testID} />;
};
