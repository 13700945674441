export const MARKETPLACE_ROUTES = {
  ROOT: "/",
  CREDIT_CARDS: "/credit-cards",
  CREDIT_CARDS_CATEGORY: "/credit-cards/category/:category",
  CREDIT_CARDS_DETAILS: "/credit-cards/product/:productId",
  CREDIT_CARDS_COMPARE: "/credit-cards/compare",
  CREDIT_CARDS_QUICK_APPLY: "/credit-cards/product/:productId/quick-apply",
  LOANS: "/loans",
  CAR_LOANS: "/car-loans",
  MORTGAGES: "/mortgages",
  BANK_ACCOUNTS: "/bank-accounts",
  BANK_ACCOUNTS_DETAILS: "/bank-accounts/product/:productId",
  INSURANCES: "/insurances",
  BUILD_CREDIT: "/build-credit",
  INVESTMENTS: "/investments",
  PROFILE_EDIT: "/profile/edit",
} as const satisfies Record<string, string>;
