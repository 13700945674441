import { useMemo } from "react";

import { useSubscriptions } from "@bwll/bw-hooks";
import { SUBSCRIPTION_STATUS, SUBSCRIPTION_STATUS_QUERYABLE } from "@bwll/bw-types";

export const useIsMemberSubscribedToProduct = (productId: string) => {
  const {
    isLoading: isSubscriptionStatusLoading,
    isSuccess: isSubscriptionStatusSuccess,
    data: subscriptions,
  } = useSubscriptions({ status: SUBSCRIPTION_STATUS_QUERYABLE.ALL });

  const isSubscriptionActive = subscriptions?.some(
    (sub) =>
      sub.status === SUBSCRIPTION_STATUS.ACTIVE && sub.plans.some((plan) => plan.productId === productId),
  );

  const doesSubscriptionExist = subscriptions?.some((sub) =>
    sub.plans.some((plan) => plan.productId === productId),
  );

  return useMemo(
    () => ({
      isLoading: isSubscriptionStatusLoading,
      isSuccess: isSubscriptionStatusSuccess,
      isMemberSubscribedToProduct: !!isSubscriptionActive,
      memberHasBeenSubscribed: !!doesSubscriptionExist,
    }),
    [isSubscriptionStatusLoading, isSubscriptionStatusSuccess, isSubscriptionActive, doesSubscriptionExist],
  );
};
