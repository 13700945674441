import { navigateToUrl } from "single-spa";

import { PRODUCT_APPLICATION_DESTINATION_TYPES, useProductApplication } from "@bwll/bw-hooks";
import { ImpressionedApplyableProduct, ImpressionedQuickApplyableProduct } from "@bwll/bw-types";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";
import { gemstoneNavigate } from "@app/helpers";

/**
 * Returns a function which, when called, will apply for the product on behalf of the user.
 *
 * - If product is not Quick Applyable, a window will be opened to the partner application page.
 * - If the product is Quick Applyable and the user is in the `sspaMarketplaceQuickApply` treatment, they will be navigated to the Quick Apply screen.
 * - If not in the `sspaMarketplaceQuickApply` treatment, they will be navigated to Gemstone Web's Quick Apply.
 */
export const useDashboardProductApplication = (): ((
  product: ImpressionedApplyableProduct | ImpressionedQuickApplyableProduct,
  origin?: string,
) => void) => {
  const getApplyDestination = useProductApplication(ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL);

  return (product, origin) => {
    const destination = getApplyDestination(product);

    switch (destination.type) {
      case PRODUCT_APPLICATION_DESTINATION_TYPES.MARKETPLACE_QUICK_APPLY:
        /** @TODO Add impression ID to search parameters */
        navigateToUrl(destination.sspaPath);
        break;
      case PRODUCT_APPLICATION_DESTINATION_TYPES.GEMSTONE_WEB_QUICK_APPLY:
        gemstoneNavigate(origin ? `${destination.url}&origin=${origin}` : destination.url);
        break;
      case PRODUCT_APPLICATION_DESTINATION_TYPES.EXTERNAL_WEBSITE:
        if (destination.url) {
          window.open(destination.url);
        }
    }
  };
};
