import styled, { css } from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.View`
  padding-vertical: ${styledValue(spacing.xl)};
  flex: 1 1 100%;
`;

export const ItemsContainer = styled.View<{ isDesktop: boolean }>`
  flex-direction: ${({ isDesktop }) => (isDesktop ? "row" : "column")};
  flex: 1 1 100%;
  gap: ${styledValue(spacing.l)};
`;

export const ItemContainer = styled.View`
  flex-direction: row;
  flex: 1 1 100%;
`;

const IMAGE_SIZE = styledValue(120);

export const imageStyles = css`
  width: ${IMAGE_SIZE};
  height: ${IMAGE_SIZE};
  margin-right: ${styledValue(spacing.l)};
`;

export const ItemTextContainer = styled.View`
  flex: 0.9;
  margin-top: ${styledValue(spacing.s)};
`;
