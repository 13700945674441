import React, { useCallback, useMemo } from "react";
import { navigateToUrl } from "single-spa";

import { Skeleton } from "@bwll/bw-components/next";
import {
  useBreakpoints,
  useDashboardExperiment,
  useGetPromoCardHierarchyV3,
  useTransitionGetMember,
} from "@bwll/bw-hooks";
import {
  CARD_HEIGHT,
  HIERARCHY_PROMO_CARD_SKELETON_TEST_ID,
  HierarchyPromoCard as HierarchyPromoCardUI,
  MOBILE_CARD_HEIGHT,
} from "@bwll/bw-modules";
import {
  CardHierarchyResponse,
  DASHBOARD_ANALYTICS_ORIGINS,
  PRODUCT_VERTICAL_NAMES,
  PRODUCT_VERTICAL_STRING_TO_ID,
  WEB_EXPERIMENT_IDS,
  WEB_TREATMENT_IDS,
} from "@bwll/bw-types";

import { gemstoneNavigateToCreditBuilder } from "@app/helpers";
import { useInternalNavigate } from "@app/hooks";
import { RENT_ADVANTAGE_ROUTES } from "@app/router";

type HierarchyPromoCardProps = {
  shouldStartTracking?: boolean;
  setShouldStartTracking?: (value: boolean) => void;
};

/**
 * Component represents a data container for the Hierarchy Promo Card UI.
 */
export const HierarchyPromoCard = ({
  shouldStartTracking,
  setShouldStartTracking,
}: HierarchyPromoCardProps) => {
  const { isLoading } = useTransitionGetMember();
  const { isMobile } = useBreakpoints();
  const { navigateToProduct, generateDestinationUrl } = useInternalNavigate();
  const shouldShowPhase2Features = useDashboardExperiment({
    experimentId: WEB_EXPERIMENT_IDS.SSPA_ENABLE_DASHBOARD_V2_PHASE2_WEB,
    treatments: [WEB_TREATMENT_IDS.sspaDashboardWebPhase2.ON],
  });
  const { data } = useGetPromoCardHierarchyV3({
    phase2Enabled: shouldShowPhase2Features,
  });
  const preSelectedProduct = data?.preSelectedProduct;

  const destinationUrlParams = useMemo(() => {
    return [
      !!preSelectedProduct
        ? PRODUCT_VERTICAL_STRING_TO_ID[preSelectedProduct.vertical]
        : PRODUCT_VERTICAL_STRING_TO_ID[PRODUCT_VERTICAL_NAMES.DEFAULT],
      preSelectedProduct?.productReferenceId ?? "",
      `origin=${DASHBOARD_ANALYTICS_ORIGINS.PRESELECTED}`,
    ] as const;
  }, [preSelectedProduct]);

  const redirectAction = useCallback(() => {
    if (!data) return;

    if (data.promoCardType === CardHierarchyResponse.PRESELECTION && preSelectedProduct) {
      navigateToProduct(...destinationUrlParams);
      return;
    }

    if (data.promoCardType === CardHierarchyResponse.CBL2) {
      gemstoneNavigateToCreditBuilder();
      return;
    }

    if (data.promoCardType === CardHierarchyResponse.RENT_REPORTING) {
      navigateToUrl(RENT_ADVANTAGE_ROUTES.ROOT);
      return;
    }
  }, [navigateToProduct, preSelectedProduct, destinationUrlParams, data]);

  const destinationUrl = !!preSelectedProduct ? generateDestinationUrl(...destinationUrlParams) : "";

  if (isLoading) {
    return (
      <Skeleton
        testID={HIERARCHY_PROMO_CARD_SKELETON_TEST_ID}
        variant="rounded"
        width="100%"
        height={isMobile ? MOBILE_CARD_HEIGHT : CARD_HEIGHT}
      />
    );
  }

  if (!data || (!shouldShowPhase2Features && data.promoCardType !== CardHierarchyResponse.PRESELECTION)) {
    //When nothing is returned from the BE or card belongs to a Phase2 feature, but Phase2 is off.
    return null;
  }

  return (
    <HierarchyPromoCardUI
      hierarchyPromoCard={data}
      analyticsAttributes={{ destinationUrl }}
      redirectAction={redirectAction}
      enableAnalyticsEvent={shouldStartTracking}
      setEnableAnalyticsEvent={setShouldStartTracking}
    />
  );
};
