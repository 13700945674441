import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";

import { OfferEndDateProps } from "./OfferEndDate.types";

export const Container = styled.View<Pick<OfferEndDateProps, "justifyContent" | "testID">>(
  ({ justifyContent }) => ({
    height: spacing.m,
    flexDirection: "row",
    alignItems: "center",
    justifyContent,
  }),
);
