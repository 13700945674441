// ID Map to check the user status from the "Member API"
export const CREDIT_SCORE_REQUEST_STATUS_IDS = {
  // Started Identity Verification
  REQUEST_SUBMITTED: "00000000-0000-0000-0000-000000000048",
  // eID No Hit
  REQUEST_NO_HIT: "00000000-0000-0000-0000-000000000053",
  // Failed Identity Verification
  REQUEST_FAILED: "00000000-0000-0000-0000-000000000051",
  // Completed Identity Verification
  REQUEST_COMPLETED: "00000000-0000-0000-0000-000000000066",
  ARCHIVED: "00000000-0000-0000-0000-000000000068",
  // System issue
  REQUEST_SYSTEM_ISSUE: "00000000-0000-0000-0000-000000000052",
  // Unused/Deprecated
  OPEN: "00000000-0000-0000-0000-000000000065",
  REFRESH_NO_HIT: "00000000-0000-0000-0000-000000000055",
  REQUEST_INVALID_DATA: "00000000-0000-0000-0000-000000000072",
} as const;

export type CreditScoreRequestStatusId =
  (typeof CREDIT_SCORE_REQUEST_STATUS_IDS)[keyof typeof CREDIT_SCORE_REQUEST_STATUS_IDS];

export const NO_HIT_STATUS_IDS = [
  CREDIT_SCORE_REQUEST_STATUS_IDS.REQUEST_FAILED,
  CREDIT_SCORE_REQUEST_STATUS_IDS.REQUEST_NO_HIT,
  CREDIT_SCORE_REQUEST_STATUS_IDS.REQUEST_SYSTEM_ISSUE,
  CREDIT_SCORE_REQUEST_STATUS_IDS.REQUEST_INVALID_DATA,
  CREDIT_SCORE_REQUEST_STATUS_IDS.REFRESH_NO_HIT,
] as const;

export const FULL_MEMBER_STATUS_IDS = [
  CREDIT_SCORE_REQUEST_STATUS_IDS.REQUEST_COMPLETED,
  CREDIT_SCORE_REQUEST_STATUS_IDS.ARCHIVED,
] as const;

export interface CreditScore {
  id: string;
  date: string;
  score: number;
}

export interface CreditScoreRequest {
  reportViewed: boolean;
  realStatusId: string;
  id: string;
  idReferenceNumber: number;
  individualClientId: string;
  individualClientIdReferenceNumber: number;
  creditReportId: string;
  statusId: CreditScoreRequestStatusId;
  isIdentityConfirmed: boolean;
  firstName: string;
  lastName: string;
  email: string;
  canContinue: boolean;
  canApply: boolean;
  incomeRange: number;
  refreshDate: string;
  mostRecentCreditScore: CreditScore;
  dateCreated: string;
  financialGoalCategories: string[];
  isArchived: boolean;
}
