import { useCallback, useMemo } from "react";

import { IMPRESSION_EVENT_NAMES, ON_LINK_CLICK_EVENT_NAMES } from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES } from "@bwll/bw-types";
import { uuid } from "@bwll/bw-utils";

import { useAnalyticsEvent } from "@app/hooks/useAnalyticsEvent";

export const useCreditScoreCardAnalytics = (userEmail?: string) => {
  const creditScoreCardImpressionId = useMemo(() => uuid(), []);

  const { trackEvent } = useAnalyticsEvent({
    [ANALYTICS_ATTRIBUTES.SHOULD_EMIT_TO_SEGMENT]: true,
    [ANALYTICS_ATTRIBUTES.IS_REDESIGNED_DASHBOARD]: true,
    [ANALYTICS_ATTRIBUTES.PLACEMENT]: "Dashboard",
  });

  const trackViewed = useCallback(() => {
    trackEvent(IMPRESSION_EVENT_NAMES.CREDIT_SCORE_VIEWED, {
      [ANALYTICS_ATTRIBUTES.USER_EMAIL]: userEmail,
    });
  }, [trackEvent, userEmail]);

  const trackLinkClick = useCallback(() => {
    trackEvent(ON_LINK_CLICK_EVENT_NAMES.LINK_CLICK, {
      [ANALYTICS_ATTRIBUTES.LINK_NAME]: "View More Details",
      [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: "Credit Factors Page",
      [ANALYTICS_ATTRIBUTES.FLOW_NAME]: "Credit Factors",
      [ANALYTICS_ATTRIBUTES.CREDIT_FACTOR_IMPRESSION_ID]: creditScoreCardImpressionId,
      [ANALYTICS_ATTRIBUTES.USER_EMAIL]: userEmail,
    });
  }, [trackEvent, userEmail, creditScoreCardImpressionId]);

  const trackTooltipOpen = useCallback(() => {
    trackEvent(ON_LINK_CLICK_EVENT_NAMES.TOOLTIP_OPENED, {
      [ANALYTICS_ATTRIBUTES.TOOLTIP_NAME]: "Dashboard ERS 2.0",
      [ANALYTICS_ATTRIBUTES.USER_EMAIL]: userEmail,
    });
  }, [trackEvent, userEmail]);

  const trackTooltipLinkClick = useCallback(() => {
    trackEvent(ON_LINK_CLICK_EVENT_NAMES.LINK_CLICK, {
      [ANALYTICS_ATTRIBUTES.LINK_NAME]: "ERS Learn More",
      [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: "ERS Learn More",
      [ANALYTICS_ATTRIBUTES.USER_EMAIL]: userEmail,
    });
  }, [trackEvent, userEmail]);

  return useMemo(
    () => ({
      trackViewed,
      trackLinkClick,
      trackTooltipOpen,
      trackTooltipLinkClick,
    }),
    [trackViewed, trackLinkClick, trackTooltipOpen, trackTooltipLinkClick],
  );
};
