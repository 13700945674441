import { useCallback, useMemo } from "react";
import { generatePath } from "react-router";
import { navigateToUrl } from "single-spa";

import {
  DashboardAnalyticsOrigin,
  GemstoneAppRoute,
  ImpressionedProductVerticalCreditCard,
  PRODUCT_VERTICAL,
  PRODUCT_VERTICAL_GUID,
  PRODUCT_VERTICAL_ID,
  PRODUCT_VERTICAL_TO_ID_MAPPING,
} from "@bwll/bw-types";
import { PRODUCT_VERTICAL_BANK_ACCOUNTS, PRODUCT_VERTICAL_CREDIT_CARDS, generateTo } from "@bwll/bw-utils";

import {
  gemstoneGeneratePathToProduct,
  gemstoneGeneratePathToProductPartnership,
  gemstoneMapUrlToReactMarketplaceUrl,
  gemstoneNavigateToProduct,
  gemstoneNavigateToVertical,
} from "@app/helpers";
import {
  MARKETPLACE_QUICK_APPLY_ROUTES,
  MARKETPLACE_ROUTES,
  MarketplaceRoutes,
  VERTICAL_GUID_TO_MARKETPLACE_VERTICAL_NAME,
} from "@app/router";

const generateMarketplacePath = (
  vertical: number,
  productReferenceId: string,
  queryParams?: string,
): string => {
  let productPath = generatePath(
    `${MARKETPLACE_ROUTES.ROOT}/${
      VERTICAL_GUID_TO_MARKETPLACE_VERTICAL_NAME[vertical as PRODUCT_VERTICAL_ID]
    }/product/:productId`,
    { productId: productReferenceId },
  );
  if (queryParams) productPath = `${productPath}?${queryParams}`;

  return productPath;
};

export const useInternalNavigate = () => {
  // This set of variables will dictate what verticals
  // are currently available(implemented) in the redesigned marketplace
  const availableMarketplaceVerticals = useMemo(() => {
    return {
      productVerticalIds: [PRODUCT_VERTICAL_ID.CREDIT_CARD, PRODUCT_VERTICAL_ID.BANK_ACCOUNT],
      gemstoneAppRoutes: [PRODUCT_VERTICAL_CREDIT_CARDS, PRODUCT_VERTICAL_BANK_ACCOUNTS],
    };
  }, []);

  // Checks if a vertical id is available in the redesigned marketplace
  const isMarketplaceVerticalAvailable = useCallback(
    (vertical: number) => {
      return availableMarketplaceVerticals.productVerticalIds.find((verticalId) => verticalId === vertical);
    },
    [availableMarketplaceVerticals.productVerticalIds],
  );

  // Checks if a gemstone app route is available in the redesigned marketplace
  const isMarketplaceRouteAvailable = useCallback(
    (appRoute: string) => {
      return availableMarketplaceVerticals.gemstoneAppRoutes.find((route) => appRoute.includes(route));
    },
    [availableMarketplaceVerticals.gemstoneAppRoutes],
  );

  const navigateToCreditCard = useCallback(
    (item: ImpressionedProductVerticalCreditCard, origin: DashboardAnalyticsOrigin) => {
      const to = generateTo(MARKETPLACE_ROUTES.CREDIT_CARDS_DETAILS, {
        pathParams: { productId: item.id },
        searchParams: { origin: origin },
      });
      navigateToUrl(`${to.pathname}?${to.search}`);
    },
    [],
  );

  const generateDestinationUrl = useCallback(
    (vertical: number, productReferenceId: string, queryParams?: string) => {
      if (isMarketplaceVerticalAvailable(vertical)) {
        return generateMarketplacePath(vertical, productReferenceId, queryParams);
      } else {
        return gemstoneGeneratePathToProduct(vertical, productReferenceId, queryParams);
      }
    },
    [isMarketplaceVerticalAvailable],
  );

  const navigateToProduct = useCallback(
    (vertical: number, productReferenceId: string, queryParams?: string) => {
      const productPath = generateDestinationUrl(vertical, productReferenceId, queryParams);
      if (isMarketplaceVerticalAvailable(vertical)) {
        navigateToUrl(productPath);
      } else {
        gemstoneNavigateToProduct(productPath);
      }
    },
    [generateDestinationUrl, isMarketplaceVerticalAvailable],
  );

  const navigateToVertical = useCallback((vertical: MarketplaceRoutes) => {
    navigateToUrl(vertical);
  }, []);

  const navigateToVerticalUsingGemstoneAppRoute = useCallback(
    (gemstoneRoute: GemstoneAppRoute) => {
      if (isMarketplaceRouteAvailable(gemstoneRoute)) {
        const mappedUrl = gemstoneMapUrlToReactMarketplaceUrl(gemstoneRoute);

        if (!mappedUrl) return;

        navigateToUrl(mappedUrl);
      } else {
        gemstoneNavigateToVertical(gemstoneRoute);
      }
    },
    [isMarketplaceRouteAvailable],
  );

  const generateQuickApplyUrl = useCallback(
    (
      vertical: PRODUCT_VERTICAL | undefined,
      verticalId: string,
      productReferenceNumber: number,
      productReferenceId: string,
      origin: DashboardAnalyticsOrigin,
      impressionId?: string,
    ): string => {
      const searchParams = new URLSearchParams(
        Object.entries({ origin, impressionId: impressionId ?? "" }),
      ).toString();
      if (vertical && isMarketplaceVerticalAvailable(PRODUCT_VERTICAL_TO_ID_MAPPING[vertical])) {
        return `${generatePath(
          `${MARKETPLACE_QUICK_APPLY_ROUTES[verticalId as PRODUCT_VERTICAL_GUID]}/:productId`,
          {
            productId: productReferenceId,
          },
        )}?${searchParams}`;
      }

      return gemstoneGeneratePathToProductPartnership(
        vertical,
        productReferenceNumber,
        productReferenceId,
        `${searchParams}`,
      );
    },
    [isMarketplaceVerticalAvailable],
  );

  return {
    navigateToCreditCard,
    navigateToVertical,
    navigateToVerticalUsingGemstoneAppRoute,
    navigateToProduct,
    generateDestinationUrl,
    generateQuickApplyUrl,
  };
};
