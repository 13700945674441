import { useTranslation } from "react-i18next";

import { Body1, Heading2 } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";
import { PRODUCT_VERTICAL_GUID } from "@bwll/bw-types";

import { StyledCell } from "./QuickApplyBanner.styles";
import { QuickApplyBannerProps } from "./QuickApplyBanner.types";

const verticalNameKeys: Record<string, string> = {
  [PRODUCT_VERTICAL_GUID.CREDIT_CARD]: "productCatalog:quickApply:view:verticalNames:creditCard",
  [PRODUCT_VERTICAL_GUID.LOAN]: "productCatalog:quickApply:view:verticalNames:loan",
};

export const QuickApplyBanner = ({ verticalId }: QuickApplyBannerProps) => {
  const i18next = useTranslation();
  const { isMobile } = useBreakpoints();

  return (
    <StyledCell isMobile={isMobile}>
      <Heading2>
        {i18next.t("productCatalog:quickApply:view:infoBanner:title", {
          vertical: i18next.t(
            verticalNameKeys[verticalId] ?? "productCatalog:quickApply:view:verticalNames:product",
          ),
        })}
      </Heading2>
      <Body1>{i18next.t("productCatalog:quickApply:view:infoBanner:description")}</Body1>
    </StyledCell>
  );
};
