import { IconNames } from "@bwll/bw-components/next";
import { COLORS } from "@bwll/bw-styles";
import { CreditScoreChangeDirection, CreditScoreRange } from "@bwll/bw-types";

export const creditScoreChangeIcons: Record<
  keyof typeof CreditScoreChangeDirection,
  { name: IconNames; color: string }
> = {
  [CreditScoreChangeDirection.Up]: {
    name: "arrow_up_circle",
    color: COLORS.GREEN["800"],
  },
  [CreditScoreChangeDirection.Down]: {
    name: "arrow_down_circle",
    color: COLORS.RED["800"],
  },
  [CreditScoreChangeDirection.Neutral]: {
    name: "minus_circle",
    color: COLORS.NEUTRAL.COOL["600"],
  },
} as const;

/** Test IDs */
export const GRAPH_SCORE_TEST_ID = "credit-score-graph";
export const GRAPH_SCORE_SKELETON_TEST_ID = "credit-score-graph-skeleton";
export const GRAPH_SCORE_TEXT_TEST_ID = "credit-score-graph-score-text";
export const GRAPH_SCORE_INDICATOR_TEST_ID = "credit-score-graph-indicator";
export const GRAPH_SCORE_INDICATOR_TEXT_TEST_ID = "credit-score-graph-indicator-text";
export const GRAPH_SCORE_GREETINGS_TEXT_TEST_ID = "credit-score-graph-greetings-text";
export const GRAPH_SCORE_DESCRIPTION_TEXT_TEST_ID = "credit-score-graph-description-text";
export const GRAPH_SCORE_TOOLTIP_TITLE_TEST_ID = "credit-score-graph-tooltip-title";
export const GRAPH_SCORE_TOOLTIP_LINK_TEST_ID = "credit-score-graph-tooltip-link";
export const GRAPH_SCORE_TOOLTIP_TRIGGER_TEST_ID = "credit-score-graph-tooltip-trigger";
export const GRAPH_SCORE_PREV_DATE_TEST_ID = "credit-score-graph-prev-date";
export const GRAPH_SCORE_NEXT_DATE_TEST_ID = "credit-score-graph-next-date";

/** Strict value by design */
export const TRACKER_WIDTH = 80;
/** Strict value by design */
export const TRACKER_HEIGHT = 44;

export const TOOLTIP_WIDTH_MOBILE = 300;
export const TOOLTIP_WIDTH_DESKTOP = 400;

/**
 * Official Borrowell credit score ranges
 * https://borrowell.atlassian.net/wiki/spaces/GROW/pages/8387985410/Credit+Score+Graph+Dashboard+Redesign+V1
 */
export const SCORE_LEVEL_RANGES = [
  { min: 300, max: 599, level: CreditScoreRange.POOR },
  { min: 600, max: 659, level: CreditScoreRange.FAIR },
  { min: 660, max: 724, level: CreditScoreRange.GOOD },
  { min: 725, max: 759, level: CreditScoreRange.VERY_GOOD },
  { min: 760, max: 900, level: CreditScoreRange.EXCELLENT },
] as const;

/** The colors map contains unique colors for the credit score tracker */
export const COLOR_MAP = {
  [CreditScoreRange.POOR]: "#D55E00",
  [CreditScoreRange.FAIR]: "#E69F00",
  [CreditScoreRange.GOOD]: "#56B4E9",
  [CreditScoreRange.VERY_GOOD]: "#0072B2",
  [CreditScoreRange.EXCELLENT]: "#089F72",
} as const;

export const COLOR_EMPTY = COLORS.NEUTRAL.COOL["200"];
/** CS Graph card height according to Figma */
export const CARD_HEIGHT = 157;
