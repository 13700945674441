import { ReportingBadgeStatus } from "@bwll/bw-types";

export const reportingStatusBadgeStyle: Record<string, ReportingBadgeStatus> = {
  reportedAsPaid: {
    cardBackgroundColor: "rgba(228, 255, 253, 0.6)",
    cardBorderColor: "rgba(0, 124, 128, 1)",
    darkBadgeBackgroundColor: "rgba(0, 124, 128, 1)",
    darkBadgeTextColor: "rgba(255, 255, 255, 1)",
    darkBadgeIconColor: "rgba(255, 255, 255, 1)",
    lightBadgeBackgroundColor: "rgba(228, 255, 253, 0.6)",
    lightBadgeTextColor: "rgba(38, 38, 37, 1)",
    lightBadgeIconColor: "rgba(0, 124, 128, 1)",
    transactionBackgroundColor: "rgba(208, 246, 244, 1)",
  },
  reportedAsMissed: {
    cardBackgroundColor: "rgba(250, 232, 233, 0.6)",
    cardBorderColor: "rgba(194, 43, 50, 1)",
    darkBadgeBackgroundColor: "rgba(194, 43, 50, 1)",
    darkBadgeTextColor: "rgba(255, 255, 255, 1)",
    darkBadgeIconColor: "rgba(255, 255, 255, 1)",
    lightBadgeBackgroundColor: "rgba(250, 232, 233, 0.6)",
    lightBadgeTextColor: "rgba(38, 38, 37, 1)",
    lightBadgeIconColor: "rgba(194, 43, 50, 1)",
    transactionBackgroundColor: "rgba(250, 232, 233, 0.6)",
  },
  willBeReportedAsPaid: {
    cardBackgroundColor: "rgba(255, 255, 255, 1)",
    cardBorderColor: "rgba(223, 224, 230, 1)",
    darkBadgeBackgroundColor: "rgba(228, 255, 253, 1)",
    darkBadgeTextColor: "rgba(38, 38, 37, 1)",
    darkBadgeIconColor: "rgba(38, 38, 37, 1)",
    lightBadgeBackgroundColor: "rgba(228, 255, 253, 1)",
    lightBadgeTextColor: "rgba(38, 38, 37, 1)",
    lightBadgeIconColor: "rgba(38, 38, 37, 1)",
    transactionBackgroundColor: "rgba(245, 246, 250, 1)",
  },
  willBeReportedAsMissed: {
    cardBackgroundColor: "rgba(255, 255, 255, 1)",
    cardBorderColor: "rgba(223, 224, 230, 1)",
    darkBadgeBackgroundColor: "rgba(250, 232, 233, 1)",
    darkBadgeTextColor: "rgba(38, 38, 37, 1)",
    darkBadgeIconColor: "rgba(38, 38, 37, 1)",
    lightBadgeBackgroundColor: "rgba(250, 232, 233, 1)",
    lightBadgeTextColor: "rgba(38, 38, 37, 1)",
    lightBadgeIconColor: "rgba(38, 38, 37, 1)",
    transactionBackgroundColor: "rgba(245, 246, 250, 1)",
  },
  empty: {
    cardBackgroundColor: "rgba(255, 255, 255, 1)",
    cardBorderColor: "rgba(255, 255, 255, 1)",
    darkBadgeBackgroundColor: "rgba(255, 255, 255, 1)",
    darkBadgeTextColor: "rgba(255, 255, 255, 1)",
    darkBadgeIconColor: "rgba(255, 255, 255, 1)",
    lightBadgeBackgroundColor: "rgba(255, 255, 255, 1)",
    lightBadgeTextColor: "rgba(255, 255, 255, 1)",
    lightBadgeIconColor: "rgba(255, 255, 255, 1)",
    transactionBackgroundColor: "rgba(255, 255, 255, 1)",
  },
};
