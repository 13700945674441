import styled, { css } from "@emotion/native";
import { ImageStyle } from "react-native";

import { COLORS, borderRadius, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const InstitutionSection = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLORS.NEUTRAL.COOL["050"]};
  border: 1px solid ${COLORS.NEUTRAL.COOL["200"]};
  border-radius: ${styledValue(borderRadius.s)};
  padding: ${styledValue(spacing.xs)};
`;

const LOGO_SIZE = 40;

export const logoStyles = css<ImageStyle>`
  width: ${styledValue(LOGO_SIZE)};
  height: ${styledValue(LOGO_SIZE)};
`;

export const InstitutionDataContainer = styled.View`
  flex-direction: row;
  align-items: center;
  gap: ${styledValue(spacing.xs)};
`;
