import { useMemo } from "react";

import { CreditScoreRange } from "@bwll/bw-types";

import { SCORE_LEVEL_RANGES } from "./CreditScoreGraph.constants";
import type { CreditScoreFilledRanges } from "./CreditScoreGraph.types";

/** Determine what tracker elements should be filled with color and what shouldn't */
export const useFilledRanges = (scoreCurrentPoints: number): CreditScoreFilledRanges => {
  const filledRanges = useMemo(
    () => ({
      [CreditScoreRange.POOR]: scoreCurrentPoints >= SCORE_LEVEL_RANGES[0].min,
      [CreditScoreRange.FAIR]: scoreCurrentPoints >= SCORE_LEVEL_RANGES[1].min,
      [CreditScoreRange.GOOD]: scoreCurrentPoints >= SCORE_LEVEL_RANGES[2].min,
      [CreditScoreRange.VERY_GOOD]: scoreCurrentPoints >= SCORE_LEVEL_RANGES[3].min,
      [CreditScoreRange.EXCELLENT]: scoreCurrentPoints >= SCORE_LEVEL_RANGES[4].min,
    }),
    [scoreCurrentPoints],
  );

  return filledRanges;
};
