import React, { useRef } from "react";

import { useBreakpoints } from "@bwll/bw-hooks";
import { spacing } from "@bwll/bw-styles";
import { makeTestIdGenerator } from "@bwll/bw-utils";

import { BUTTON_TYPE } from "../../atoms/Button";
import { Icon, IconNames } from "../../atoms/Icon";
import { Image } from "../../atoms/Image";
import {
  BADGE_TEST_ID,
  BODY_TEST_ID,
  HEADER_TEST_ID,
  IMAGE_MAX_HEIGHT_DESKTOP,
  IMAGE_MAX_HEIGHT_MOBILE,
  IMAGE_MAX_WIDTH_DESKTOP,
  IMAGE_MAX_WIDTH_MOBILE,
  PRIMARY_CTA_TEST_ID,
  SECONDARY_CTA_TEST_ID,
  TITLE_TEST_ID,
} from "./OfferCardTemplate.constants";
import * as Styled from "./OfferCardTemplate.styles";
import type { OfferCardTemplateProps } from "./OfferCardTemplate.types";

export const OfferCardTemplate = ({
  testID,
  imageUrl,
  header,
  title,
  body,
  primaryCtaTitle,
  secondaryCtaTitle,
  buttonIconName,
  buttonIconAlignment,
  badgeIcon,
  badgeText,
  badgeBackgroundColor,
  badgeColor,
  primaryOnPress,
  secondaryOnPress,
}: OfferCardTemplateProps) => {
  const { isMobile } = useBreakpoints();
  const defaultTestId = useRef(`${Date.now()}`);

  const generateTestId = makeTestIdGenerator(testID || defaultTestId.current);

  return (
    <Styled.Container>
      {badgeText && badgeBackgroundColor && badgeColor && (
        <Styled.BadgeContainer backgroundColor={badgeBackgroundColor} testID={generateTestId(BADGE_TEST_ID)}>
          {badgeIcon && <Icon icon={badgeIcon as IconNames} color={badgeColor} size={spacing.xs} />}
          <Styled.BadgeText color={badgeColor}>{badgeText}</Styled.BadgeText>
        </Styled.BadgeContainer>
      )}
      <Styled.ContentContainer>
        <Image
          source={{
            uri: imageUrl,
            height: isMobile ? IMAGE_MAX_HEIGHT_MOBILE : IMAGE_MAX_HEIGHT_DESKTOP,
            width: isMobile ? IMAGE_MAX_WIDTH_MOBILE : IMAGE_MAX_WIDTH_DESKTOP,
          }}
          resizeMode={"contain"}
        />
        <Styled.TextContainer>
          <Styled.Body numberOfLines={1} testID={generateTestId(HEADER_TEST_ID)}>
            {header}
          </Styled.Body>
          <Styled.Title numberOfLines={2} testID={generateTestId(TITLE_TEST_ID)}>
            {title}
          </Styled.Title>
          <Styled.Body numberOfLines={2} testID={generateTestId(BODY_TEST_ID)}>
            {body}
          </Styled.Body>
        </Styled.TextContainer>
      </Styled.ContentContainer>
      <Styled.CtaContainer>
        <Styled.CtaButton
          type={BUTTON_TYPE.SECONDARY}
          testID={generateTestId(SECONDARY_CTA_TEST_ID)}
          title={secondaryCtaTitle}
          onPress={secondaryOnPress}
        />
        <Styled.CtaButton
          type={BUTTON_TYPE.PRIMARY}
          testID={generateTestId(PRIMARY_CTA_TEST_ID)}
          title={primaryCtaTitle}
          onPress={primaryOnPress}
          iconName={buttonIconName}
          iconAlignment={buttonIconAlignment}
        />
      </Styled.CtaContainer>
    </Styled.Container>
  );
};
