export const quickApply = {
  "buttons": {
    "backToProducts": "Back to products",
    "update": "Update",
  },
  "personalDetails": {
    "heading": "Review your personal details",
    "form": {
      "fullNameLabel": "Full name",
      "dateOfBirthLabel": "Date of birth",
      "phoneNumberLabel": "Phone number",
      "emailAddressLabel": "Email address",
      "addressLabel": "Address",
    },
  },
};