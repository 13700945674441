import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useEnvironmentContext, usePlatformContext } from "@bwll/bw-hooks";
import {
  BasePrimePromoCard,
  GEMSTONE_APP_ROUTES,
  GemstoneAppRoute,
  ICreditUtilizationDetailsV2,
  PLATFORM,
  PrimeCardType,
  WEB_EXPERIMENT_IDS,
} from "@bwll/bw-types";

import { TOP_PICKS_TARGET_CREDIT_UTILIZATION } from "./TopPicksPromoCardsV2.constants";
import { TopPicksPromoCardV2Type, TopPicksPromoCardsV2ContainerProps } from "./TopPicksPromoCardsV2.types";
import { generateTopPicksPromoCardsImageURL } from "./TopPicksPromoCardsV2.utils";

export const useFormatTopPicksV2PromoCards = (props: TopPicksPromoCardsV2ContainerProps) => {
  const i18next = useTranslation();
  const { envConfigs } = useEnvironmentContext();
  const { platform } = usePlatformContext();
  const parseBasePrimePromoCard = useCallback(
    (basePromo: BasePrimePromoCard): TopPicksPromoCardV2Type => {
      return {
        ...basePromo,
        image: generateTopPicksPromoCardsImageURL(envConfigs.CDN_URL, basePromo.primeCardType),
        onPress: () => {
          if (platform === PLATFORM.MOBILE_APP)
            props.mobileNavigationFn?.(basePromo.primeCardType, basePromo.productRoute.path);
          else props.webNavigationFn?.(basePromo.productRoute.path as GemstoneAppRoute);
        },
      };
    },
    [props.webNavigationFn, props.mobileNavigationFn, platform, envConfigs],
  );

  const getFormatedPromoCards = useCallback(
    (
      basePrimePromoCards: BasePrimePromoCard[],
      creditUtilizationDetails: Partial<ICreditUtilizationDetailsV2> | undefined,
    ): TopPicksPromoCardV2Type[] => {
      const promoCards = basePrimePromoCards.map(parseBasePrimePromoCard);
      if (!props.shouldShowPhase2Features) return promoCards;
      if (
        creditUtilizationDetails &&
        creditUtilizationDetails.totalCreditUtilization &&
        creditUtilizationDetails?.totalCreditUtilization?.utilization <= TOP_PICKS_TARGET_CREDIT_UTILIZATION
      )
        return promoCards;

      promoCards.push({
        title: i18next.t("dashboard:topPicksV2:cluPromoCard:title"),
        body: i18next.t("dashboard:topPicksV2:cluPromoCard:body", {
          utilization: creditUtilizationDetails?.totalCreditUtilization?.utilization,
        }),
        image: generateTopPicksPromoCardsImageURL(envConfigs.CDN_URL, PrimeCardType.CreditLimitUtilization),
        buttonText: i18next.t("dashboard:topPicksV2:cluPromoCard:cta"),
        productRoute: {
          internal: true,
          path: GEMSTONE_APP_ROUTES.CREDIT_CARDS,
        },
        experimentFilter: {
          name: WEB_EXPERIMENT_IDS.SSPA_ENABLE_DASHBOARD_V2_PHASE2_WEB,
          value: props.shouldShowPhase2Features ? "on" : "off",
        },
        primeCardType: PrimeCardType.CreditLimitUtilization,
        onPress: () => {
          if (platform === PLATFORM.MOBILE_APP)
            props.mobileNavigationFn?.(
              PrimeCardType.CreditLimitUtilization,
              GEMSTONE_APP_ROUTES.CREDIT_CARDS.substring(1),
            );
          else props.webNavigationFn?.(GEMSTONE_APP_ROUTES.CREDIT_CARDS);
        },
      });
      return promoCards;
    },
    [envConfigs.CDN_URL, parseBasePrimePromoCard, platform, props],
  );
  return getFormatedPromoCards;
};
