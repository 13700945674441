import React, { useCallback } from "react";

import { BUTTON_TYPE, Button, ElementTracker, PromoCardTemplate } from "@bwll/bw-components/next";
import { HierarchyPromoCardCommonEventData, useAnalyticsContext, useBreakpoints } from "@bwll/bw-hooks";
import { useGetImpressionToken } from "@bwll/bw-hooks/src/hooks/analytics/useGetImpressionToken";
import { CardHierarchyResponse } from "@bwll/bw-types";

import {
  HIERARCHY_PROMO_CARD_CTA_BUTTON_TEST_ID,
  HIERARCHY_PROMO_CARD_TEST_ID,
} from "./HierarchyPromoCard.constants";
import { ButtonContainer } from "./HierarchyPromoCard.style";
import { HierarchyPromoCardProps } from "./HierarchyPromoCard.types";
import { useHierarchyPromoCardEventData } from "./useHierarchyPromoCardEventData";
import { usePrepareHierarchyPromoCardData } from "./usePrepareHierarchyPromoCardData";

export const HierarchyPromoCard = ({
  hierarchyPromoCard,
  analyticsAttributes,
  redirectAction,
  enableAnalyticsEvent = true,
  setEnableAnalyticsEvent,
}: HierarchyPromoCardProps) => {
  const { promoCardViewed, promoCardClicked } = useAnalyticsContext();
  const { isMobile } = useBreakpoints();
  const impressionId = useGetImpressionToken();
  const hierarchyPromoCardData = usePrepareHierarchyPromoCardData(isMobile, hierarchyPromoCard);
  const preSelectedProduct = hierarchyPromoCard?.preSelectedProduct;
  const promoCardType = hierarchyPromoCard?.promoCardType;
  const { clickedEventData, viewedEventData } = useHierarchyPromoCardEventData({
    isMobile,
    impressionId,
    preSelectedProduct,
    hierarchyPromoCardData,
    analyticsAttributes,
    promoCardType,
  });

  const onPress = useCallback(() => {
    // To be able to click, the promo card must have data, and if it's a preselection card must have a product,
    // or it's CB/RA.
    const canClick =
      hierarchyPromoCard &&
      ((hierarchyPromoCard.promoCardType === CardHierarchyResponse.PRESELECTION && preSelectedProduct) ||
        hierarchyPromoCard.promoCardType !== CardHierarchyResponse.PRESELECTION);

    if (!canClick) {
      return;
    }

    promoCardClicked?.<HierarchyPromoCardCommonEventData>(clickedEventData);
    redirectAction?.();
  }, [hierarchyPromoCard, preSelectedProduct, promoCardClicked, clickedEventData, redirectAction]);

  const onViewed = useCallback(() => {
    promoCardViewed?.<HierarchyPromoCardCommonEventData>(viewedEventData);
    setEnableAnalyticsEvent?.(false);
  }, [promoCardViewed, viewedEventData, setEnableAnalyticsEvent]);

  if (!hierarchyPromoCard || !hierarchyPromoCardData) {
    return null;
  }

  const ctaButton = (
    <ButtonContainer>
      <Button
        testID={HIERARCHY_PROMO_CARD_CTA_BUTTON_TEST_ID}
        onPress={onPress}
        title={hierarchyPromoCardData.cardButtonText}
        type={BUTTON_TYPE.PRIMARY}
      />
    </ButtonContainer>
  );

  return (
    <ElementTracker shouldStartTracking={enableAnalyticsEvent} trackingHandler={onViewed}>
      <PromoCardTemplate
        variant={"card"}
        withLine={false}
        cardImageSource={{
          uri: hierarchyPromoCardData.cardImageUri,
        }}
        cardImageBackgroundImage={hierarchyPromoCardData.cardImageBackgroundUri}
        cardImageBackgroundColor={hierarchyPromoCardData.cardImageBackgroundColor}
        title={hierarchyPromoCardData.cardTitle}
        body={hierarchyPromoCardData.cardBody}
        cta={ctaButton}
        onPress={onPress}
        testID={HIERARCHY_PROMO_CARD_TEST_ID}
      />
    </ElementTracker>
  );
};
