import styled, { css } from "@emotion/native";

import { Body2, Heading2 } from "@bwll/bw-components/next";
import { COLORS, fontWeights, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

type ItemValueProps = { color?: string };

const flexColumn = css`
  gap: ${styledValue(spacing.xs)};
  flex-direction: column;
`;

export const Content = styled.View`
  ${flexColumn}
`;

export const Table = styled.View`
  ${flexColumn}
  padding-vertical: ${styledValue(spacing.xxs)};
`;

export const Item = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ItemLabel = styled(Body2)`
  align-items: flex-start;
  color: ${COLORS.NEUTRAL.WARM["600"]};
  padding-horizontal: ${styledValue(spacing.m)};
`;

export const ItemValue = styled(Body2)`
  align-items: flex-end;
  padding-horizontal: ${styledValue(spacing.m)};
  font-weight: ${fontWeights.bold};
  color: ${({ color }: ItemValueProps) => color ?? COLORS.NEUTRAL.WARM["800"]};
`;

export const Image = styled.Image`
  resize-mode: contain;
  height: ${styledValue(spacing.xxxl * 2)};
`;

export const Heading = styled(Heading2)`
  text-align: center;
`;

export const Subtitle = styled(Body2)`
  text-align: center;
  font-weight: ${fontWeights.bold};
`;

export const Description = styled(Body2)`
  text-align: center;
`;
