import styled from "@emotion/native";

import { COLORS, borderRadius, borderWidth, boxShadow } from "@bwll/bw-styles";
import { styledValue as sv } from "@bwll/bw-utils";

export const Footer = styled.View<{ isDesktop: boolean }>`
  position: fixed;
  z-index: 201;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${sv(64)};
  bottom: ${sv(0)};
  background-color: ${COLORS.WHITE};
  border-top-width: ${sv(borderWidth.small)};
  border-top-color: ${COLORS.NEUTRAL.COOL["100"]};
  border-left-width: ${({ isDesktop }) => (isDesktop ? sv(borderWidth.small) : sv(0))};
  border-left-color: ${COLORS.NEUTRAL.COOL["100"]};
  border-radius: ${sv(borderRadius.s)} ${sv(borderRadius.s)} ${sv(0)} ${sv(0)};
  box-shadow: ${boxShadow};
  left: ${({ isDesktop }) => (isDesktop ? sv(351) : sv(0))};
  max-width: ${({ isDesktop }) => (isDesktop ? "calc(100vw - 351px)" : "none")};
`;

export const CompareButtonContainer = styled.View<{ isDesktop: boolean }>`
  width: 100%;
  max-width: ${({ isDesktop }) => (isDesktop ? sv(300) : sv(200))};
`;

export const ClearButtonContainer = styled.View<{ isDesktop: boolean }>`
  width: 100%;
  max-width: ${({ isDesktop }) => (isDesktop ? sv(300) : sv(127))};
`;
