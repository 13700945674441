import { useMemo } from "react";

import { FULL_MEMBER_STATUS_IDS, NO_HIT_STATUS_IDS } from "@bwll/bw-types";

import { useTransitionGetMember } from "./api";

export type { CreditScoreRequest } from "@bwll/bw-types/src/types/member/creditScoreRequest";

export const useCreditScoreRequest = () => {
  const { data, isLoading, isError, isSuccess, isFetched } = useTransitionGetMember();

  const isIdentityVerified = useMemo(() => {
    if (!data?.creditScoreRequest?.statusId) return false;

    return FULL_MEMBER_STATUS_IDS.some((id) => data?.creditScoreRequest?.statusId === id);
  }, [data?.creditScoreRequest?.statusId]);

  const isNoHitMember = useMemo(() => {
    return (
      !data?.creditScores ||
      !data?.creditScoreRequest?.statusId ||
      NO_HIT_STATUS_IDS.some((id) => data?.creditScoreRequest?.statusId === id) ||
      data.creditScores?.length === 0
    );
  }, [data?.creditScoreRequest?.statusId, data?.creditScores]);

  return {
    data: data?.creditScoreRequest,
    isLoading,
    isError: isError || (isSuccess && !data?.creditScoreRequest),
    isSuccess: isSuccess && !!data?.creditScoreRequest,
    isIdentityVerified,
    isFetched,
    isNoHitMember,
  };
};

export type Member = ReturnType<typeof useTransitionGetMember>;
