import { useTranslation } from "react-i18next";

import { GoogleAddressResult } from "@bwll/bw-components/next";
import { REGION_LABELS } from "@bwll/bw-types";

import {
  ADDRESS_MAX_LENGTH,
  defaultAddress,
  validPostalCode,
  validSpecialCharacters,
} from "./AddressForm.constants";
import { FormErrorSchema, FormTouchedFields } from "./AddressForm.types";

const provinces: string[] = Object.values(REGION_LABELS);

const invalidate = (schema: FormErrorSchema, fieldName: keyof GoogleAddressResult, message: string): void => {
  schema[fieldName] = message;
  schema.isValid = false;
};

export const useIsAddressValid = (
  address: GoogleAddressResult,
  touchedFields: FormTouchedFields,
): FormErrorSchema => {
  const i18next = useTranslation();

  const errorSchema: FormErrorSchema = { ...defaultAddress, isValid: true };

  const required = i18next.t("rentReporting:formPage:errorMessages:required");
  const invalidProvince = i18next.t("rentReporting:formPage:errorMessages:invalidProvince");
  const invalidPostalCode = i18next.t("rentReporting:formPage:errorMessages:invalidPostalCode");
  const noSpecialCharacters = i18next.t("rentReporting:formPage:errorMessages:noSpecialCharacters");
  const maxCharacters = i18next.t(
    "translations:rentReporting:formPage:errorMessages:maximumCharactersLimit",
    {
      maximum: ADDRESS_MAX_LENGTH,
    },
  );

  if (touchedFields.street) {
    if (!address.street) {
      invalidate(errorSchema, "street", required);
    } else if (address.street.length > ADDRESS_MAX_LENGTH) {
      invalidate(errorSchema, "street", maxCharacters);
    } else if (!validSpecialCharacters.test(address.street)) {
      invalidate(errorSchema, "street", noSpecialCharacters);
    }
  }

  if (touchedFields.city) {
    if (!address.city) {
      invalidate(errorSchema, "city", required);
    } else if (address.city.length > ADDRESS_MAX_LENGTH) {
      invalidate(errorSchema, "city", maxCharacters);
    } else if (!validSpecialCharacters.test(address.city)) {
      invalidate(errorSchema, "city", noSpecialCharacters);
    }
  }

  if (!provinces.includes(address.province) && touchedFields.province) {
    invalidate(errorSchema, "province", invalidProvince);
  }

  if (touchedFields.postalCode) {
    if (!address.postalCode) {
      invalidate(errorSchema, "postalCode", required);
    } else if (!validPostalCode.test(address.postalCode)) {
      invalidate(errorSchema, "postalCode", invalidPostalCode);
    }
  }

  return errorSchema;
};
