import React, { useRef } from "react";
import { Pressable, useWindowDimensions } from "react-native";

import { usePlatformContext } from "@bwll/bw-hooks";
import { COLORS, fontSize } from "@bwll/bw-styles";
import { makeTestIdGenerator } from "@bwll/bw-utils";

import { Icon } from "../../atoms/Icon";
import { CardContainer } from "../../molecules/CardContainer";
import {
  BODY_TEST_ID,
  CTA_TEST_ID,
  IMAGE_TEST_ID,
  TITLE_TEST_ID,
} from "./IllustrativePromoCardTemplate.constants";
import * as Styled from "./IllustrativePromoCardTemplate.styles";
import type { IllustrativePromoCardTemplateProps } from "./IllustrativePromoCardTemplate.types";

export const IllustrativePromoCardTemplate = ({
  title,
  imageUrl,
  body,
  ctaText,
  testID,
  onPress,
}: IllustrativePromoCardTemplateProps) => {
  const { platform } = usePlatformContext();
  const { width } = useWindowDimensions();
  const defaultTestId = useRef(`${Date.now()}`);

  const generateTestId = makeTestIdGenerator(testID || defaultTestId.current);
  return (
    <CardContainer testID={testID}>
      <Styled.CardContainer>
        <Styled.ImageContainer platform={platform} testID={generateTestId(IMAGE_TEST_ID)}>
          <Styled.PromoCardImage source={{ uri: imageUrl }} resizeMode="contain" />
        </Styled.ImageContainer>
        <Styled.ContentContainer>
          <Styled.TitleContainer testID={generateTestId(TITLE_TEST_ID)}>
            <Styled.TitleText>{title}</Styled.TitleText>
          </Styled.TitleContainer>
          <Styled.BodyContainer testID={generateTestId(BODY_TEST_ID)}>
            <Styled.BodyText>{body}</Styled.BodyText>
          </Styled.BodyContainer>
          <Pressable onPress={onPress} testID={generateTestId(CTA_TEST_ID)}>
            <Styled.CtaContainer>
              <Styled.CtaLink platform={platform} width={width}>
                {ctaText?.toUpperCase()}
              </Styled.CtaLink>
              <Icon icon="forward_arrow" size={fontSize.s} color={COLORS.PRIMARY["500"]} />
            </Styled.CtaContainer>
          </Pressable>
        </Styled.ContentContainer>
      </Styled.CardContainer>
    </CardContainer>
  );
};
