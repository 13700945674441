import type { To } from "react-router-dom";

import {
  ImpressionedApplyableProduct,
  ImpressionedQuickApplyableProduct,
  MARKETPLACE_ROUTES,
  NullableString,
  PRODUCT_VERTICAL_GUID,
} from "@bwll/bw-types";
import { generateTo } from "@bwll/bw-utils";

import { useMarketplaceQuickApplyExperiment } from "../marketplace/useMarketplaceQuickApplyExperiment";

export const PRODUCT_APPLICATION_DESTINATION_TYPES = {
  EXTERNAL_WEBSITE: "externalWebsite",
  GEMSTONE_WEB_QUICK_APPLY: "gemstoneWebQuickApply",
  MARKETPLACE_QUICK_APPLY: "marketplaceQuickApply",
} as const;

type ApplicationDestination =
  | {
      type: (typeof PRODUCT_APPLICATION_DESTINATION_TYPES)["MARKETPLACE_QUICK_APPLY"];
      to: To;
      sspaPath: string;
    }
  | {
      type: (typeof PRODUCT_APPLICATION_DESTINATION_TYPES)["GEMSTONE_WEB_QUICK_APPLY"];
      url: string;
    }
  | {
      type: (typeof PRODUCT_APPLICATION_DESTINATION_TYPES)["EXTERNAL_WEBSITE"];
      url: NullableString;
    };

const isQuickApplyable = (
  product: ImpressionedApplyableProduct | ImpressionedQuickApplyableProduct,
): product is ImpressionedQuickApplyableProduct => {
  return "isQuickApply" in product && "productReferenceNumber" in product;
};

const getGemstoneWebQuickApplyDestination = (
  gemstoneBaseUrl: string,
  { productVerticalId, productReferenceNumber, id, productImpressionId }: ImpressionedQuickApplyableProduct,
): ApplicationDestination => {
  const vertical = productVerticalId === PRODUCT_VERTICAL_GUID.CREDIT_CARD ? "creditcards" : "loans";

  return {
    type: PRODUCT_APPLICATION_DESTINATION_TYPES.GEMSTONE_WEB_QUICK_APPLY,
    url: `${gemstoneBaseUrl}/#/app/product-partnership/${vertical}/${productReferenceNumber}/${id}?impressionId=${productImpressionId}`,
  };
};

const getMarketplaceQuickApplyDestination = (
  gemstoneBaseUrl: string,
  product: ImpressionedQuickApplyableProduct,
): ApplicationDestination => {
  const route =
    product.productVerticalId === PRODUCT_VERTICAL_GUID.CREDIT_CARD
      ? MARKETPLACE_ROUTES.CREDIT_CARDS_QUICK_APPLY
      : undefined;

  if (!route) {
    // Have not set up this vertical for Quick Apply yet
    return getGemstoneWebQuickApplyDestination(gemstoneBaseUrl, product);
  }

  const to = generateTo(route, {
    pathParams: {
      productId: product.id,
    },
  });

  return {
    type: PRODUCT_APPLICATION_DESTINATION_TYPES.MARKETPLACE_QUICK_APPLY,
    to,
    sspaPath: `/marketplace/${to.pathname.slice(1)}`,
  };
};

/**
 * Gets a callback that, when called, determines the destination to apply for a product.
 * @param gemstoneBaseUrl The base URL for the Gemstone Web application, retrieved from app's `ENVIRONMENT_VARIABLES`.
 * @returns A callback that, when called, determines the destination to apply for a product.
 */
export const useProductApplication = (
  gemstoneBaseUrl: string,
): ((
  product: ImpressionedApplyableProduct | ImpressionedQuickApplyableProduct,
) => ApplicationDestination) => {
  const { showQuickApply } = useMarketplaceQuickApplyExperiment();

  return (product) => {
    return !isQuickApplyable(product) || !product.isQuickApply
      ? {
          type: PRODUCT_APPLICATION_DESTINATION_TYPES.EXTERNAL_WEBSITE,
          url: product.impressionedProductLink,
        }
      : showQuickApply
      ? getMarketplaceQuickApplyDestination(gemstoneBaseUrl, product)
      : getGemstoneWebQuickApplyDestination(gemstoneBaseUrl, product);
  };
};
