import styled, { css } from "@emotion/native";

import { Body2, Heading2 } from "@bwll/bw-components/next";
import {
  COLORS,
  borderRadius,
  fontSize,
  fontWeights,
  letterSpacing,
  lineHeight,
  spacing,
} from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

const rowCenter = css`
  flex-direction: row;
  align-items: center;
`;

export const ContentContainer = styled.View`
  width: 100%;
  margin-vertical: ${styledValue(spacing.xs)};
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${styledValue(spacing.xs)};
  justify-content: center;
`;

export const TitleContainer = styled.View`
  ${rowCenter};
  gap: ${styledValue(8)};
`;

export const Row = styled.View`
  ${rowCenter};
  width: 100%;
`;

export const TitleText = styled(Heading2)`
  color: ${COLORS.NEUTRAL.WARM["800"]};
  font-size: ${styledValue(fontSize.m)};
  font-weight: ${fontWeights.bold};
  line-height: ${styledValue(lineHeight.xl)};
  letter-spacing: ${styledValue(letterSpacing.xs)};
`;

export const VerticalText = styled(Body2)`
  color: ${COLORS.BLACK};
  font-size: ${styledValue(fontSize.xs)};
  font-weight: ${fontWeights.semiBold};
  line-height: ${styledValue(lineHeight.xs)};
  letter-spacing: ${styledValue(0.32)};
`;

export const IconContainer = styled.View<{ backgroundColor: string }>`
  padding: ${styledValue(spacing.xxs)};
  border-radius: ${styledValue(borderRadius.s)};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const CardContentWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  gap: ${styledValue(spacing.xxxs)};
`;
export const CardWrapper = styled.View`
  width: 47%;
`;
