import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { LTOCarouselCommonEventData } from "@bwll/bw-hooks";
import {
  ANALYTICS_ATTRIBUTES,
  DASHBOARD_ANALYTICS_ORIGINS,
  LIKELIHOOD_OF_APPROVAL_LABEL_MAPPING,
  LikelihoodOfApprovalType,
  LimitedTimeOffer,
} from "@bwll/bw-types";

export const useLTOCarouselCommonAttributes = (
  limitedTimeOffer: LimitedTimeOffer,
  index: number,
): LTOCarouselCommonEventData => {
  const i18next = useTranslation();
  return useMemo(
    () => ({
      [ANALYTICS_ATTRIBUTES.BELOW_SHOW_ALL]: false,
      [ANALYTICS_ATTRIBUTES.CASH_BACK_OFFER]: null,
      [ANALYTICS_ATTRIBUTES.IS_CASH_BACK]: false,
      [ANALYTICS_ATTRIBUTES.PREPOPULATION_FLOW]: !!limitedTimeOffer.isQuickApply,
      [ANALYTICS_ATTRIBUTES.SPONSORED]: "No",
      [ANALYTICS_ATTRIBUTES.PREFERRED_ORDER]: limitedTimeOffer.preferredOrder
        ? parseFloat(limitedTimeOffer.preferredOrder.toFixed(4))
        : limitedTimeOffer.preferredOrder,
      [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: index + 1,
      [ANALYTICS_ATTRIBUTES.RANK]: limitedTimeOffer.rank
        ? parseFloat(limitedTimeOffer.rank.toFixed(4))
        : limitedTimeOffer.rank,
      [ANALYTICS_ATTRIBUTES.IS_LIMITED_TIME_OFFER]: true,
      [ANALYTICS_ATTRIBUTES._CALL_TO_ACTION]: limitedTimeOffer.isQuickApply
        ? i18next.t("dashboard:cards:ltoCarousel:primaryActionQuickApply")
        : i18next.t("dashboard:cards:ltoCarousel:primaryAction"),
      [ANALYTICS_ATTRIBUTES.PRESELECTED]: limitedTimeOffer.isPreSelection ? "Yes" : "No",
      [ANALYTICS_ATTRIBUTES.LIKELIHOOD_OF_APPROVAL]: limitedTimeOffer.likelihoodOfApproval
        ? parseFloat(limitedTimeOffer.likelihoodOfApproval.toFixed(4))
        : limitedTimeOffer.likelihoodOfApproval,
      [ANALYTICS_ATTRIBUTES.LIKELIHOOD_OF_APPROVAL_BUCKET]: limitedTimeOffer.likelihoodOfApprovalType,
      [ANALYTICS_ATTRIBUTES.LIKELIHOOD_OF_APPROVAL_LABEL]:
        LIKELIHOOD_OF_APPROVAL_LABEL_MAPPING[
          limitedTimeOffer.likelihoodOfApprovalType as LikelihoodOfApprovalType
        ],
      [ANALYTICS_ATTRIBUTES.TRACKING_TAG]: limitedTimeOffer.trackingTag ?? "",
      [ANALYTICS_ATTRIBUTES.PRODUCT_ID]: limitedTimeOffer.id,
      [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: limitedTimeOffer.productUrl,
      [ANALYTICS_ATTRIBUTES.SPONSORED_PLACEMENT]: limitedTimeOffer.sponsoredPlacement,
      [ANALYTICS_ATTRIBUTES._IMPRESSION_ID]: limitedTimeOffer.productImpressionId ?? "",
      [ANALYTICS_ATTRIBUTES.PLACEMENT]: DASHBOARD_ANALYTICS_ORIGINS.LTO_PLACEMENT,
    }),
    [
      index,
      limitedTimeOffer.id,
      limitedTimeOffer.isPreSelection,
      limitedTimeOffer.isQuickApply,
      limitedTimeOffer.likelihoodOfApproval,
      limitedTimeOffer.likelihoodOfApprovalType,
      limitedTimeOffer.preferredOrder,
      limitedTimeOffer.productImpressionId,
      limitedTimeOffer.productUrl,
      limitedTimeOffer.rank,
      limitedTimeOffer.sponsoredPlacement,
      limitedTimeOffer.trackingTag,
    ],
  );
};
