import styled from "@emotion/native";

import { Button, Heading2 } from "@bwll/bw-components/next";
import { fontSize, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue as sv } from "@bwll/bw-utils";

export const InlineButton = styled(Button)`
  width: auto;
`;

export const Container = styled.View`
  gap: ${sv(spacing.xxl)};
`;

export const HeaderContainer = styled.View`
  gap: ${sv(spacing.m)};
`;

export const PersonalDetailsContainer = styled.View`
  gap: ${sv(spacing.xxs)};
`;

export const PersonalDetailsHeading = styled.View`
  flex-direction: row;
  gap: ${sv(spacing.xs)};
  align-items: center;
`;

export const H2Mobile = styled(Heading2)`
  font-size: ${sv(fontSize.m)};
  line-height: ${sv(lineHeight.xl)};
`;
