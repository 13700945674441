import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { IApiHookOptions, PromoCardsHierarchyV3Result, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

type ExtraRequestParameters = {
  phase2Enabled: boolean;
};

/**
 * Retrieves the hierarchy of promotional cards from the Promotion API.
 * The hierarchy can be found in CardHierarchyResponse type.
 * This is used on the new unified dashboard.
 *
 * @param accessToken - The access token for authentication.
 * @param baseUrl - The base URL of the API.
 * @param extraRequestParameters - Data that needs to be passed to the endpoint.
 * @returns A promise that resolves to an array of `PromoCardsHierarchyV2Result` or `null`.
 */
const getPromoCardHierarchyV3 = async (
  accessToken: string,
  baseUrl: string,
  extraRequestParameters: ExtraRequestParameters,
): Promise<PromoCardsHierarchyV3Result | undefined> => {
  const url = `${baseUrl}/promotion/v3/hierarchy?isPhase2=${extraRequestParameters.phase2Enabled}`;
  const response = await axios.get<PromoCardsHierarchyV3Result>(url, {
    headers: generateApiHeaders(accessToken),
  });

  if (response?.status === 204 || !response?.data) {
    return undefined;
  }

  return response.data;
};

export function useGetPromoCardHierarchyV3<TTransformReturn = PromoCardsHierarchyV3Result>(
  extraRequestParameters: ExtraRequestParameters = { phase2Enabled: false },
  options: IApiHookOptions<PromoCardsHierarchyV3Result, TTransformReturn> = {},
) {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;
  const enabled = options.enabled && !!accessToken;

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_PROMO_CARDS_HIERARCHY_V3 as QUERY_KEYS, accessToken),
    () => getPromoCardHierarchyV3(accessToken, envConfigs.API_PROMOTION_URL, extraRequestParameters),
    {
      ...queryBehavior({
        expiresIn,
        enabled,
      }),
    },
  );
}
