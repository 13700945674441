import styled from "@emotion/native";
import styledWeb from "@emotion/styled";

import { Caption, Heading1 } from "@bwll/bw-components/next";
import { isWeb } from "@bwll/bw-components/next/constants";
import { COLORS, fontSize, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { BUTTON_MAX_WIDTH } from "./RALandingPage.constants";

export const Container = styled.View`
  flex-grow: 1;
`;

export const BottomCtaContainer = styled.View`
  width: 100%;
  max-width: ${styledValue(BUTTON_MAX_WIDTH)};
`;

export const HeadingContainer = styled.View<{ isColumn: boolean }>`
  flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
  gap: ${styledValue(spacing.s)};
`;

export const HeadingText = styled(Heading1)`
  font-size: ${styledValue(isWeb ? fontSize.xl + 4 : fontSize.xl)};
  line-height: ${styledValue(lineHeight.xxxl)};
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;

export const TitleContainer = styled.View`
  flex-direction: column;
  ${isWeb && "flex: 1 1 100%"};
`;

export const TitleContainerRow = styled.View`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const Note = styled(Caption)`
  color: ${COLORS.NEUTRAL.WARM["800"]};
  text-align: center;
`;

export const ImageContainer = styled.View`
  width: 300px;
  height: 300px;
  margin: 0 auto;
`;

export const CtaContainerWeb = styledWeb.div<{ isVisible: boolean }>`
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  height: 40px;
  width: 100%;
  max-width: 295px;
  margin: ${styledValue(spacing.l)} auto;
`;
