export const GEMSTONE_APP_ROUTES = {
  AUTO_LOANS: "/app/product/loans/auto",
  BANK_ACCOUNTS: "/app/product/bankaccounts",
  BUILD_CREDIT: "/app/product/creditbuilding",
  CAR_LOANS: "/app/product/carloans",
  CREDIT_BUILDER: "/app/cb",
  CREDIT_CARDS: "/app/product/creditcards",
  CREDIT_CARDS_CATEGORY: "app/product/creditcards?filterBy=:category",
  CREDIT_CARDS_DETAILS: "app/product/creditcards/:productId",
  COMPARE: "/app/product/creditcards/compare",
  DASHBOARD: "/app/dashboard",
  INSURANCE: "/app/product/insurance",
  INVESTING: "/app/product/investments",
  LOANS: "/app/product/loans",
  MORTGAGES: "/app/product/mortgages",
  MORTGAGE_COACH: "/app/mortgagecoach",
} as const satisfies Record<string, string>;

export type GemstoneAppRoute = (typeof GEMSTONE_APP_ROUTES)[keyof typeof GEMSTONE_APP_ROUTES];
