import styled from "@emotion/native";
import { StyleSheet } from "react-native";

import { COLORS, WEB_SCREEN_PADDING, borderWidth, spacing, toRGBAString } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

const panelWidth = styledValue(350);

export const SortAndFilterContainer = styled.View`
  flex-direction: row;
  gap: ${styledValue(spacing.xs)};
  z-index: 1;
`;

export const SortContainer = styled.View`
  flex: 1 1 auto;
`;

export const Backdrop = styled.View`
  ${StyleSheet.absoluteFillObject};
  background-color: ${toRGBAString(COLORS.NEUTRAL.COOL["800"], 0.5)};
  z-index: 201;
  position: fixed;
`;

//  Sheet panel
export const SheetPanel = styled.View<{ isMobile: boolean; isExpanded: boolean }>`
  position: fixed;
  z-index: 202;
  width: ${panelWidth};
  height: calc(100% - 160px);
  left: 0;

  background: ${COLORS.WHITE};
  margin-top: ${styledValue(-WEB_SCREEN_PADDING)};

  ${({ isMobile }) =>
    isMobile &&
    `
    width: 100%;
    height: calc(100% - 60px);
    top: 60px;

    padding-top: ${styledValue(40)};
    margin-top: auto;

    border-top-left-radius: ${styledValue(20)};
    border-top-right-radius: ${styledValue(20)};
  `}

  ${({ isMobile, isExpanded }) => isMobile && !isExpanded && `display: none;`}
`;

export const SheetPanelContentContainer = styled.View`
  padding: ${styledValue(spacing.m)};
  gap: ${styledValue(spacing.m)};
`;

export const SheetPanelSortContainer = styled.View`
  gap: ${styledValue(spacing.xxs)};
  z-index: 1;
`;

export const SheetPanelSortHeadingContainer = styled.View`
  flex-direction: row;
  align-items: center;
  gap: ${styledValue(spacing.xxxs)};
`;

export const SheetPanelFooterContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-horizontal: ${styledValue(spacing.xs)};
  padding-vertical: ${styledValue(spacing.xxs)};
  border-top-width: ${styledValue(borderWidth.small)};
  border-top-color: ${COLORS.NEUTRAL.COOL[100]};
`;

export const SheetPanelButtonContainer = styled.View`
  flex: 1;
`;
