import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { COLORS } from "@bwll/bw-styles";
import { LikelihoodOfApprovalType } from "@bwll/bw-types";

import { ProductApproval } from "./SponsoredCarouselCard/SponsoredCarouselCard.types";

export enum APPROVAL_CHANCE_TYPE {
  "NONE" = "None",
  "LOW" = "Low",
  "FAIR" = "Fair",
  "GOOD" = "Good",
  "EXCELLENT" = "Excellent",
  "NOTELIGIBLE" = "Not Eligible",
}

export const useConvertLikelihoodOfApproval = () => {
  const i18next = useTranslation();

  const low = i18next.t("sponsoredCard:approvalChanceTooltip:low");
  const fair = i18next.t("sponsoredCard:approvalChanceTooltip:fair");
  const good = i18next.t("sponsoredCard:approvalChanceTooltip:good");
  const excellent = i18next.t("sponsoredCard:approvalChanceTooltip:excellent");
  const notEligible = i18next.t("sponsoredCard:approvalChanceTooltip:notEligible");

  const convertLikelihoodOfApproval = useCallback(
    (likelihood: string | LikelihoodOfApprovalType): Partial<ProductApproval> => {
      const likelihoodValue = typeof likelihood === "string" ? Number(likelihood) : likelihood;

      switch (likelihoodValue) {
        case LikelihoodOfApprovalType.LOW:
          return {
            text: APPROVAL_CHANCE_TYPE.LOW.toString(),
            tooltipText: low,
            color: COLORS.NEUTRAL.COOL["600"],
          };
        case LikelihoodOfApprovalType.FAIR:
          return {
            text: APPROVAL_CHANCE_TYPE.FAIR.toString(),
            tooltipText: fair,
            color: COLORS.NEUTRAL.COOL["600"],
          };
        case LikelihoodOfApprovalType.GOOD:
          return {
            text: APPROVAL_CHANCE_TYPE.GOOD.toString(),
            tooltipText: good,
            color: COLORS.TURQUOISE["900"],
          };
        case LikelihoodOfApprovalType.EXCELLENT:
          return {
            text: APPROVAL_CHANCE_TYPE.EXCELLENT.toString(),
            tooltipText: excellent,
            color: COLORS.TURQUOISE["900"],
          };
        default:
          return {
            text: APPROVAL_CHANCE_TYPE.NOTELIGIBLE.toString(),
            tooltipText: notEligible,
            color: COLORS.NEUTRAL.COOL["600"],
          };
      }
    },
    [excellent, fair, good, low, notEligible],
  );

  return convertLikelihoodOfApproval;
};
