import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  BUTTON_TYPE,
  Button,
  ElementTracker,
  Heading1,
  Heading2,
  Paragraph,
  Spacer,
  SvgImage,
} from "@bwll/bw-components/next";
import { useAnalyticsContext, useBreakpoints } from "@bwll/bw-hooks";
import { spacing } from "@bwll/bw-styles";
import { ANALYTICS_ATTRIBUTES, HISTORICAL_REPORTING_EVENT_VALUES } from "@bwll/bw-types";

import {
  HISTORICAL_REPORTING_CONFIRMATION_PAGE_CTA_TEST_ID,
  RA_CONFIRMATION_SUCCESS_IMAGE_KEY,
} from "./HistoricalReportingConfirmationPage.constants";
import {
  ButtonContainer,
  ContentContainer,
  Footer,
  FooterContainer,
  ImageContainer,
  PageContainer,
  SectionContainer,
  styles,
} from "./HistoricalReportingConfirmationPage.styles";
import { HistoricalReportingConfirmationPageProps } from "./HistoricalReportingConfirmationPage.types";

export const HistoricalReportingConfirmationPage = ({
  onCtaPress,
  experimentTreatment,
}: HistoricalReportingConfirmationPageProps) => {
  const { isMobile } = useBreakpoints();
  const i18next = useTranslation();

  const { historicalReportingEvents } = useAnalyticsContext();

  const commonAnalyticsAttributes = useMemo(() => {
    return {
      // TODO: Add onboarding state logic
      [ANALYTICS_ATTRIBUTES.ONBOARDING_STATE]: "",
      [ANALYTICS_ATTRIBUTES.TREATMENT]: experimentTreatment,
      [ANALYTICS_ATTRIBUTES.VERSION]: HISTORICAL_REPORTING_EVENT_VALUES.V1,
    };
  }, [experimentTreatment]);

  const trackViewEvent = useCallback(() => {
    historicalReportingEvents.onHistoricalReportingScreenViewed({
      [ANALYTICS_ATTRIBUTES.CURRENT_STEP_NAME]:
        HISTORICAL_REPORTING_EVENT_VALUES.CHECKOUT_CONFIRMATION_STEP_VIEWED,
      ...commonAnalyticsAttributes,
    });
  }, [commonAnalyticsAttributes, historicalReportingEvents]);

  const onButtonPressHandler = useCallback(() => {
    historicalReportingEvents.onHistoricalReportingButtonClicked(
      {
        [ANALYTICS_ATTRIBUTES.CURRENT_STEP_NAME]:
          HISTORICAL_REPORTING_EVENT_VALUES.GO_TO_DASHBOARD_BUTTON_CLICKED,
        ...commonAnalyticsAttributes,
      },
      onCtaPress,
    );
  }, [commonAnalyticsAttributes, historicalReportingEvents]);

  return (
    <PageContainer>
      <ElementTracker trackingHandler={trackViewEvent} shouldStartTracking={true} />
      <ContentContainer>
        <SectionContainer>
          <Spacer height={isMobile ? spacing.xs : spacing.xl} />
          <Heading1>{i18next.t("rentReporting:historicalConfirmationPage:title")}</Heading1>
          <Spacer height={isMobile ? spacing.xs : spacing.m} />
          <Heading2>{i18next.t("rentReporting:historicalConfirmationPage:subtitle")}</Heading2>
          <Spacer height={isMobile ? spacing.xs : spacing.m} />
          <Paragraph>{i18next.t("rentReporting:historicalConfirmationPage:paragraph1")}</Paragraph>
          <Spacer height={isMobile ? spacing.xs : spacing.xxs} />
          <Paragraph>{i18next.t("rentReporting:historicalConfirmationPage:paragraph2")}</Paragraph>
          <Spacer height={isMobile ? spacing.xs : spacing.m} />
        </SectionContainer>
        <ImageContainer>
          <SvgImage imageKey={RA_CONFIRMATION_SUCCESS_IMAGE_KEY} containerStyle={styles.successImage} />
        </ImageContainer>
        <Spacer height={isMobile ? spacing.xs : spacing.xl} />
      </ContentContainer>
      <FooterContainer>
        <Footer isMobile={isMobile}>
          <ButtonContainer isMobile={isMobile}>
            <Button
              testID={HISTORICAL_REPORTING_CONFIRMATION_PAGE_CTA_TEST_ID}
              title={i18next.t("rentReporting:historicalConfirmationPage:ctaButton")}
              onPress={onButtonPressHandler}
              type={BUTTON_TYPE.PRIMARY}
            />
          </ButtonContainer>
        </Footer>
      </FooterContainer>
    </PageContainer>
  );
};
