import axios from "axios";
import { useMutation } from "react-query";

import { useEnvironmentContext } from "@bwll/bw-hooks";
import { useSessionContext } from "@bwll/bw-hooks/src/contexts";
import { MutationOptions, QUERY_KEYS, RecordPromotionActionRequest } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey } from "@bwll/bw-utils";

const recordPromotionAction = async (
  accessToken: string,
  apiGatewayUrl: string,
  body: RecordPromotionActionRequest,
) => {
  const response = await axios.post<boolean>(`${apiGatewayUrl}/promotion/record-promotion-action`, body, {
    headers: generateApiHeaders(accessToken),
  });
  return response.data;
};

export const useRecordPromotionAction = ({
  onSuccess,
  onError,
  onSettled,
  onMutate,
}: MutationOptions = {}) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();
  const { accessToken } = userData;
  return useMutation(
    (data: RecordPromotionActionRequest) =>
      recordPromotionAction(accessToken, envConfigs.API_PROMOTION_URL, data),
    {
      mutationKey: generateQueryKey(QUERY_KEYS.RECORD_PROMOTION_ACTION, accessToken),
      onSuccess: async (response) => {
        onSuccess?.(response);
      },
      onError,
      onSettled,
      onMutate,
    },
  );
};
