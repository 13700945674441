import React from "react";

import { CreditScoreRange } from "@bwll/bw-types";

import { COLOR_EMPTY, COLOR_MAP, TRACKER_HEIGHT, TRACKER_WIDTH } from "./CreditScoreGraph.constants";
import { useFilledRanges } from "./CreditScoreGraph.hooks";
import type { CreditScoreTrackerProps } from "./CreditScoreGraph.types";

/**
 * Component represents the visual CS tracker (web only)
 * @param {CreditScoreTrackerProps} props - external props interface
 * @property {CreditScoreRangeType} scoreRange - current score range (poor, fair, good, etc.)
 * @property {number} scoreCurrentPoints - current score value
 */
export const CreditScoreTracker = ({ scoreRange, scoreCurrentPoints }: CreditScoreTrackerProps) => {
  const rangeColor = COLOR_MAP[scoreRange];

  const filledRanges = useFilledRanges(scoreCurrentPoints);

  return (
    <svg
      width={TRACKER_WIDTH}
      height={TRACKER_HEIGHT}
      viewBox={`0 0 ${TRACKER_WIDTH} ${TRACKER_HEIGHT}`}
      fill="none"
    >
      <path
        d="M2.87786 41.9999H2.75519C2.37752 41.9839 2.00667 41.8923 1.66385 41.7302C1.32103 41.5682 1.01299 41.339 0.757337 41.0558C0.501687 40.7725 0.303453 40.4407 0.173978 40.0794C0.0445035 39.7181 -0.0136698 39.3344 0.00278729 38.9502C0.240921 33.5092 1.54419 28.1712 3.83622 23.2491C3.99832 22.9009 4.22626 22.5885 4.50702 22.3299C4.78778 22.0712 5.11586 21.8714 5.47254 21.7417C5.82922 21.6121 6.20751 21.5552 6.5858 21.5743C6.9641 21.5934 7.335 21.6881 7.67732 21.853C8.01964 22.0179 8.32668 22.2498 8.58091 22.5354C8.83514 22.821 9.03158 23.1548 9.15902 23.5177C9.28646 23.8805 9.34239 24.2654 9.32363 24.6502C9.30487 25.0351 9.21179 25.4124 9.04969 25.7607C7.07498 29.9725 5.9538 34.5457 5.75294 39.2076C5.71937 39.9601 5.4017 40.6705 4.86612 41.1907C4.33053 41.7108 3.61832 42.0007 2.87786 41.9999Z"
        fill={filledRanges[CreditScoreRange.POOR] ? rangeColor : COLOR_EMPTY}
      />
      <path
        d="M11.7643 19.1151C11.215 19.1121 10.6779 18.9493 10.2164 18.6462C9.7549 18.343 9.38816 17.9119 9.15937 17.4038C8.93058 16.8957 8.84928 16.3316 8.92503 15.778C9.00079 15.2245 9.23044 14.7045 9.58696 14.2792C13.0797 10.1523 17.3431 6.77205 22.13 4.33446C22.4693 4.14709 22.8423 4.03119 23.2266 3.99366C23.611 3.95614 23.9988 3.99776 24.367 4.11604C24.7352 4.23432 25.0762 4.42684 25.3697 4.68211C25.6632 4.93739 25.9031 5.25018 26.0751 5.60184C26.2472 5.95349 26.3479 6.3368 26.3712 6.7289C26.3944 7.121 26.3398 7.51385 26.2106 7.88402C26.0814 8.25418 25.8802 8.59408 25.6191 8.88343C25.358 9.17277 25.0422 9.40564 24.6907 9.56814C20.5946 11.661 16.9465 14.5585 13.9571 18.0934C13.6861 18.4151 13.3497 18.673 12.9711 18.8495C12.5925 19.0259 12.1808 19.1165 11.7643 19.1151Z"
        fill={filledRanges[CreditScoreRange.FAIR] ? rangeColor : COLOR_EMPTY}
      />
      <path
        d="M32.6257 6.62017C31.8799 6.67247 31.1435 6.42719 30.5724 5.93634C30.0014 5.4455 29.6408 4.74769 29.567 3.99088C29.4932 3.23408 29.7121 2.47779 30.1772 1.88238C30.6423 1.28698 31.317 0.899278 32.0584 0.801496C37.3119 -0.267165 42.7213 -0.267165 47.9748 0.801496C48.7147 0.963111 49.3622 1.41502 49.7767 2.05918C50.1913 2.70335 50.3394 3.48781 50.189 4.2424C50.0387 4.99698 49.6019 5.66081 48.9735 6.08989C48.3452 6.51897 47.5758 6.67868 46.8324 6.53437C42.331 5.61392 37.6945 5.61392 33.1931 6.53437C33.0072 6.58184 32.8171 6.61058 32.6257 6.62017Z"
        fill={filledRanges[CreditScoreRange.GOOD] ? rangeColor : COLOR_EMPTY}
      />
      <path
        d="M68.237 19.1154C67.8231 19.1145 67.4143 19.0228 67.0386 18.8464C66.6628 18.6701 66.3288 18.4133 66.0596 18.0936C63.0692 14.5597 59.4213 11.6624 55.326 9.56836C54.9852 9.39862 54.6808 9.16193 54.4303 8.87194C54.1799 8.58195 53.9883 8.2444 53.8667 7.87874C53.7451 7.51307 53.6959 7.12654 53.7218 6.74142C53.7477 6.3563 53.8484 5.98022 54.0178 5.63484C54.1873 5.28947 54.4224 4.98165 54.7093 4.72912C54.9963 4.47659 55.3296 4.28435 55.69 4.16349C56.0503 4.04262 56.4306 3.99552 56.809 4.0249C57.1873 4.05428 57.5562 4.15956 57.8944 4.33467C62.6827 6.77686 66.9482 10.1594 70.445 14.2873C70.8042 14.7136 71.0351 15.2362 71.1102 15.7925C71.1853 16.3488 71.1015 16.9153 70.8686 17.4244C70.6358 17.9335 70.2638 18.3637 69.7972 18.6635C69.3305 18.9634 68.7889 19.1202 68.237 19.1154Z"
        fill={filledRanges[CreditScoreRange.VERY_GOOD] ? rangeColor : COLOR_EMPTY}
      />
      <path
        d="M77.1462 42C76.407 41.9988 75.6967 41.708 75.1628 41.188C74.6289 40.6681 74.3123 39.9589 74.2788 39.2077C74.0779 34.5457 72.9567 29.9726 70.982 25.7608C70.8048 25.4129 70.6981 25.0324 70.6682 24.6418C70.6383 24.2512 70.6859 23.8584 70.8081 23.4869C70.9303 23.1153 71.1246 22.7725 71.3795 22.4789C71.6344 22.1852 71.9447 21.9466 72.292 21.7772C72.6393 21.6079 73.0165 21.5113 73.4012 21.4931C73.7859 21.4749 74.1702 21.5355 74.5314 21.6714C74.8927 21.8072 75.2234 22.0155 75.504 22.2839C75.7846 22.5522 76.0093 22.8752 76.1648 23.2336C78.4631 28.1534 79.7667 33.4927 79.9982 38.9347C80.0315 39.7093 79.7611 40.4657 79.2464 41.0376C78.7317 41.6094 78.0149 41.95 77.2535 41.9844L77.1462 42Z"
        fill={filledRanges[CreditScoreRange.EXCELLENT] ? rangeColor : COLOR_EMPTY}
      />
    </svg>
  );
};
