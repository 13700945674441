import React from "react";
import { useTranslation } from "react-i18next";
import { ImageURISource } from "react-native/types";

import { Body1, Cell, Divider, Heading2, Markdown, Spacer } from "@bwll/bw-components/next";
import { COLORS, borderRadius, spacing } from "@bwll/bw-styles";
import { DATE_FORMATS } from "@bwll/bw-types";
import { formatDate, formatPhoneNumber } from "@bwll/bw-utils";

import { NO_HIT_CARD_TEST_ID } from "./NoHitCard.constants";
import { HeaderContent, HeaderImage, MultiColumnsBlock } from "./NoHitCard.styles";
import { NoHitCardProps } from "./NoHitCard.types";
import EIDFailDashboardIcon from "./assets/EIDFailDashboardIcon.png";

export const NoHitCard = ({ clientData, onMarkdownPress }: NoHitCardProps) => {
  const i18next = useTranslation();

  const { firstName, legalName, primaryAddress, birthDate, primaryPhoneNumber } = clientData;

  const informationList = [
    {
      label: i18next.t("signup:preMemberDashboard:noHit:informationCard:fullName"),
      value: `${firstName} ${legalName}`,
    },
    {
      label: i18next.t("signup:preMemberDashboard:noHit:informationCard:DOB"),
      value: formatDate({
        dateString: birthDate,
        dateFormat: DATE_FORMATS.yyyy_MM_dd,
      }),
    },
    {
      label: i18next.t("signup:preMemberDashboard:noHit:informationCard:phoneNumber"),
      value: formatPhoneNumber(`1${primaryPhoneNumber}`),
    },
    {
      label: i18next.t("signup:preMemberDashboard:noHit:informationCard:residentialAddress"),
      value: primaryAddress.formattedAddress,
    },
  ];

  return (
    <Cell hasShadow borderRadius={borderRadius.s} testID={NO_HIT_CARD_TEST_ID}>
      <MultiColumnsBlock>
        <HeaderContent>
          <Heading2>{i18next.t("signup:preMemberDashboard:noHit:title")}</Heading2>
          <Body1>{i18next.t("signup:preMemberDashboard:noHit:description")}</Body1>
        </HeaderContent>
        <HeaderImage source={EIDFailDashboardIcon as ImageURISource} resizeMode="contain" />
      </MultiColumnsBlock>

      <Divider marginVertical={spacing.xs} />

      <Body1 variant="bold">{i18next.t("signup:preMemberDashboard:noHit:whatYouCanDo")}</Body1>
      <Spacer height={spacing.xs} />
      <Body1 variant="bold" color={COLORS.NEUTRAL.WARM["800"]}>
        {i18next.t("signup:preMemberDashboard:noHit:contactEquifaxCanada")}
      </Body1>
      <Markdown color={COLORS.NEUTRAL.WARM["700"]} onLinkPress={onMarkdownPress}>
        {i18next.t("signup:preMemberDashboard:noHit:contactEquifaxCanadaDescription")}
      </Markdown>
      <Spacer height={spacing.xs} />
      <Body1>{i18next.t("signup:preMemberDashboard:noHit:informationCard:title")}</Body1>

      <Spacer height={spacing.xs} />
      {informationList.map(({ label, value }, index) => (
        <React.Fragment key={`NO_HIT_INFO_${index}`}>
          <Body1>{label}</Body1>
          <Body1 variant="bold">{value}</Body1>
          <Spacer height={spacing.xs} />
        </React.Fragment>
      ))}

      <Body1 variant="bold" color={COLORS.NEUTRAL.WARM["800"]}>
        {i18next.t("signup:preMemberDashboard:noHit:contactBorrowell")}
      </Body1>
      <Markdown color={COLORS.NEUTRAL.WARM["700"]} onLinkPress={onMarkdownPress}>
        {i18next.t("signup:preMemberDashboard:noHit:contactBorrowellDescription")}
      </Markdown>
    </Cell>
  );
};
