import { useTranslation } from "react-i18next";

import { reportingStatusBadgeStyle } from "@bwll/bw-styles";
import { AvailableLocale, DATE_FORMATS, RENT_REPORTING_STATUS } from "@bwll/bw-types";
import { adjustDateString, formatDate, isValidRentReportingStatus } from "@bwll/bw-utils";

import { RAWidgetStatusBadge, RAWidgetStatusBadgeParams } from "./RAWidget.types";

export const useRentReportingStatusBadge = ({
  locale,
  rentPeriod,
  reportingStatus,
  reportedToAgencyDate,
}: {
  locale: AvailableLocale;
  rentPeriod?: string;
  reportedToAgencyDate?: string;
  reportingStatus?: RENT_REPORTING_STATUS;
}): RAWidgetStatusBadge => {
  const i18next = useTranslation();

  const reportedPeriod = formatDate({
    dateString: reportedToAgencyDate,
    dateFormat: DATE_FORMATS.MMMM_yyyy,
    locale,
  });
  const currentPeriod = formatDate({
    dateString: rentPeriod,
    dateFormat: DATE_FORMATS.MMMM_yyyy,
    ignoreOffset: true,
    locale,
  });
  const estimatedPaymentVerifiedDate = formatDate({
    dateString: adjustDateString({
      dateString: reportedToAgencyDate,
      duration: { months: 1 },
    }),
    dateFormat: DATE_FORMATS.MMMM_dd_yyyy,
    locale,
  });

  const config: RAWidgetStatusBadgeParams = {
    [RENT_REPORTING_STATUS.REPORTED_AS_PAID]: {
      ...reportingStatusBadgeStyle.reportedAsPaid,
      iconName: "checkmark",
      title: i18next.t("rentReporting:reportingStatus:reportedAsPaid:title"),
      status: i18next.t("rentReporting:reportingStatus:reportedAsPaid:status"),
      tooltip: i18next.t("rentReporting:reportingStatus:reportedAsPaid:tooltip", {
        paymentPeriod: reportedPeriod,
        date: estimatedPaymentVerifiedDate,
      }),
      description: i18next.t("rentReporting:reportingStatus:reportedAsPaid:description", {
        paymentPeriod: reportedPeriod,
        date: estimatedPaymentVerifiedDate,
      }),
      hasTransactionFooter: true,
      hasContactSupportFooter: false,
    },
    [RENT_REPORTING_STATUS.REPORTED_AS_MISSED]: {
      ...reportingStatusBadgeStyle.reportedAsMissed,
      iconName: "close",
      title: i18next.t("rentReporting:reportingStatus:reportedAsMissed:title"),
      status: i18next.t("rentReporting:reportingStatus:reportedAsMissed:status"),
      tooltip: i18next.t("rentReporting:reportingStatus:reportedAsMissed:tooltip", {
        paymentPeriod: reportedPeriod,
        date: estimatedPaymentVerifiedDate,
      }),
      description: i18next.t("rentReporting:reportingStatus:reportedAsMissed:description", {
        paymentPeriod: reportedPeriod,
        date: estimatedPaymentVerifiedDate,
      }),
      hasTransactionFooter: false,
      hasContactSupportFooter: true,
    },
    [RENT_REPORTING_STATUS.WILL_BE_REPORTED_AS_PAID]: {
      ...reportingStatusBadgeStyle.willBeReportedAsPaid,
      iconName: "checkmark",
      title: i18next.t("rentReporting:reportingStatus:willBeReportedAsPaid:title"),
      status: i18next.t("rentReporting:reportingStatus:willBeReportedAsPaid:status"),
      tooltip: i18next.t("rentReporting:reportingStatus:willBeReportedAsPaid:tooltip", {
        paymentPeriod: currentPeriod,
      }),
      description: i18next.t("rentReporting:reportingStatus:willBeReportedAsPaid:description", {
        paymentPeriod: currentPeriod,
      }),
      hasTransactionFooter: true,
      hasContactSupportFooter: false,
    },
    [RENT_REPORTING_STATUS.WILL_BE_REPORTED_AS_MISSED]: {
      ...reportingStatusBadgeStyle.willBeReportedAsMissed,
      iconName: "close",
      title: i18next.t("rentReporting:reportingStatus:willBeReportedAsMissed:title"),
      status: i18next.t("rentReporting:reportingStatus:willBeReportedAsMissed:status"),
      tooltip: i18next.t("rentReporting:reportingStatus:willBeReportedAsMissed:tooltip", {
        paymentPeriod: currentPeriod,
      }),
      description: i18next.t("rentReporting:reportingStatus:willBeReportedAsMissed:description", {
        paymentPeriod: currentPeriod,
      }),
      hasTransactionFooter: false,
      hasContactSupportFooter: true,
    },
  };

  if (reportingStatus !== undefined && isValidRentReportingStatus(reportingStatus)) {
    return config[`${reportingStatus}`];
  }

  return {
    ...reportingStatusBadgeStyle.empty,
    iconName: "close",
    title: "",
    status: "",
    description: "",
    tooltip: "",
    hasTransactionFooter: false,
    hasContactSupportFooter: false,
  };
};
