import React from "react";

import { useDashboardExperiment } from "@bwll/bw-hooks";
import { TopPicksPromoCardsV2Container } from "@bwll/bw-modules";
import { WEB_EXPERIMENT_IDS, WEB_TREATMENT_IDS } from "@bwll/bw-types";

import { useInternalNavigate } from "@app/hooks";

/**
 * A wrapper around the TopPicksPromoCards component
 * that applies styling related to the screen implementation
 * and handles all platform-specific navigation callbacks
 * and analytics.
 */
export const TopPicksPromoCards = () => {
  const shouldShowPhase2Features = useDashboardExperiment({
    experimentId: WEB_EXPERIMENT_IDS.SSPA_ENABLE_DASHBOARD_V2_PHASE2_WEB,
    treatments: [WEB_TREATMENT_IDS.sspaDashboardWebPhase2.ON],
  });
  const { navigateToVerticalUsingGemstoneAppRoute } = useInternalNavigate();

  return (
    <TopPicksPromoCardsV2Container
      shouldShowPhase2Features={shouldShowPhase2Features}
      webNavigationFn={navigateToVerticalUsingGemstoneAppRoute}
      enabled
    />
  );
};
