import React, { PropsWithChildren, useEffect, useRef, useState } from "react";

interface ElementTrackerProps extends PropsWithChildren {
  trackingHandler: () => void;
  shouldStartTracking: boolean;
  onVisibilityChange?: (isVisible: boolean) => void;
  /**
   * A unique key for the element being tracked.
   * It will reset the one-time-only tracking if changed, even if the component isn't remounted.
   */
  trackingKey?: unknown;
}

export const ElementTracker = ({
  trackingHandler,
  shouldStartTracking,
  onVisibilityChange,
  children,
  trackingKey,
}: ElementTrackerProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const element = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
      onVisibilityChange?.(entry.isIntersecting);
    });
    if (element.current) {
      observer.observe(element.current);
    }

    return () => observer.disconnect();
  }, [element, onVisibilityChange]);

  const hasSentImpression = useRef(false);

  useEffect(() => {
    if (shouldStartTracking) {
      hasSentImpression.current = false;
    }
  }, [shouldStartTracking]);

  useEffect(() => {
    if (isVisible && !hasSentImpression.current && shouldStartTracking) {
      trackingHandler();
      hasSentImpression.current = true;
    }
  }, [isVisible, trackingHandler, shouldStartTracking]);

  useEffect(() => {
    hasSentImpression.current = false;
  }, [trackingKey]);

  return (
    /**
     * Tracks the children components by adding the tracker immediatly after they get rendered.
     * The element must be scrolled entirely in order to trigger the @trackingHandler
     *  */
    <div>
      {children}
      <div ref={element} />
    </div>
  );
};
