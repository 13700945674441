import React from "react";
import { useTranslation } from "react-i18next";

import { ProductVerticalLoan } from "@bwll/bw-types";
import { formatCurrencyDollar } from "@bwll/bw-utils";

import { SponsoredProductDetailsTemplate } from "../SponsoredProductDetailsTemplate";
import { SponsoredLoanFigPreApprovedDetailsProps } from "./SponsoredLoanFigPreApprovedDetails.types";

const findPercentIfNeeded = (value: number): string => {
  // The case when the value format is the exact rate in percents (Ex: 29.95 is already 29.95%)
  // we don't need to multiply by 100 because the value is already formatted.
  // Otherwise, calculate the display value (Ex: 0.051 is 5.10%)
  if (value < 1) {
    value *= 100;
  }

  // To avoid things like 0.9999999999991 ¯\_(ツ)_/¯
  return `${value.toFixed(2)}%`;
};

export const getRate = (product: ProductVerticalLoan) => {
  const { interestRate } = product;

  return findPercentIfNeeded(interestRate ?? 0);
};

export const SponsoredLoanFigPreApprovedDetails = ({
  figPreApprovalData,
}: SponsoredLoanFigPreApprovedDetailsProps) => {
  const i18next = useTranslation();
  const offers = figPreApprovalData?.offers;
  if (!offers || offers.length === 0) return null;

  //Lowest MonthlyPaymentOffer
  const offerData = offers.reduce((lowest, offer) => {
    return offer.monthlyPayment < lowest.monthlyPayment ? offer : lowest;
  });

  if (!offerData) return null;

  return (
    <SponsoredProductDetailsTemplate
      details={[
        {
          label: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:figLoan:loanAmount"),
          value: formatCurrencyDollar({
            amount: offerData.loanAmount,
            decimals: 2,
          }),
        },
        {
          label: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:figLoan:monthlyPayment"),
          value: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:figLoan:monthlyPaymentAmount", {
            amount: formatCurrencyDollar({
              amount: offerData.monthlyPayment,
              decimals: 2,
            }),
          }),
        },
        {
          label: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:figLoan:apr"),
          value: getRate({ interestRate: offerData.apr } as ProductVerticalLoan),
        },
        {
          label: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:figLoan:term"),
          value: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:figLoan:termDuration", {
            term: offerData.termInMonths,
          }),
        },
      ]}
    />
  );
};
