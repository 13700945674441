import { useMemo } from "react";

import { TopPicksPromoCardsEventData } from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES, ANALYTICS_VALUES, BasePrimePromoCard } from "@bwll/bw-types";
import { uuid } from "@bwll/bw-utils";

import {
  TRENDING_OFFERS_PROMO_CARD_CATEGORY,
  TRENDING_OFFERS_PROMO_CARD_NAME,
} from "./TrendingOffersPromoCard.constants";

export const useTrendingOffersCommonAttributes = (
  promoCard: BasePrimePromoCard,
): TopPicksPromoCardsEventData => {
  return useMemo(
    () => ({
      [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: promoCard.productRoute.path,
      [ANALYTICS_ATTRIBUTES.BUTTON_TEXT]: promoCard.buttonText,
      [ANALYTICS_ATTRIBUTES.POSITION]: ANALYTICS_VALUES.DASHBOARD_LOCATION,
      [ANALYTICS_ATTRIBUTES.CARD_LOCATION]: ANALYTICS_VALUES.DASHBOARD_LOCATION,
      [ANALYTICS_ATTRIBUTES.CARD_POSITION]: ANALYTICS_VALUES.FEED_POSITION,
      [ANALYTICS_ATTRIBUTES.PROMO_CARD_NAME]: TRENDING_OFFERS_PROMO_CARD_NAME,
      [ANALYTICS_ATTRIBUTES.PROMO_CATEGORY]: TRENDING_OFFERS_PROMO_CARD_CATEGORY,
      [ANALYTICS_ATTRIBUTES.PROMO_TEXT_BODY]: promoCard.body,
      [ANALYTICS_ATTRIBUTES.PROMO_TEXT_HEADER]: promoCard.title,
      [ANALYTICS_ATTRIBUTES._IMPRESSION_ID]: uuid(),
    }),
    [
      promoCard.body,
      promoCard.buttonText,
      promoCard.primeCardType,
      promoCard.productRoute.path,
      promoCard.title,
    ],
  );
};
