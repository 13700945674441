import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { IApiHookOptions, ProductPicksResponse, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

const getProductPicks = async (accessToken: string, baseUrl: string) => {
  const response = await axios.get<ProductPicksResponse>(
    `${baseUrl}/api/product-verticals/products/v6/product-picks`,
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return response.data;
};

export const useGetProductPicks = <TReturn = ProductPicksResponse>(
  options: IApiHookOptions<ProductPicksResponse, TReturn> = {},
) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_PRODUCT_PICKS, accessToken),
    () => getProductPicks(accessToken, envConfigs.API_GATEWAY_URL),
    {
      ...queryBehavior({
        expiresIn,
        ...options,
        enabled: !!accessToken && !!options.enabled,
      }),
    },
  );
};
