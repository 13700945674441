import styled from "@emotion/native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const ButtonContainer = styled.View`
  width: 100%;
  margin-top: ${styledValue(spacing.m)};
`;

export const PromoCardTemplateContainer = styled.Pressable`
  cursor: default;
  width: 327px;
`;

export const BackdropContainer = styled.Pressable`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.BLACK}80;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
