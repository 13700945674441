export const checkoutSummaryPage = {
  "edit": "Modifier",
  "month": "mois",
  "title": "Résumé",
  "button": "PAYER AVEC STRIPE",
  "change": "Changer",
  "changePeriod": "Changer",
  "changeTransactions": "Sélectionner différentes transactions",
  "startingOn": "Début ",
  "rentalAddressTitle": "Adresse de location",
  "termsAndConditions": "Conditions générales de Le Bienfait du Loyer",
  "checkAgreementPart1": "Je comprends et j'accepte les",
  "checkAgreementPart2": "et j'autorise Borrowell à communiquer mes paiements de loyer et les paiements de loyer non effectués à des agences d'évaluation du crédit.",
  "plusApplicableTaxes": "Taxes applicables en sus",
  "selectedTransactionTitle": "Transaction sélectionnée",
  "selectedTransactionsTitle": "Montant à déclarer",
  "subscriptionSummaryTitle": "Abonnement",
  "termsAndConditionsCheckBoxMd": "Je comprends et j'accepte les [__Conditions générales de Rent Advantage__]() et je consens à ce que Borrowell rapporte mes paiements de loyer et les paiements de loyer manqués aux agences d'évaluation du crédit.",
  "paymentPeriodTitle": "Période de paiement",
  "paymentPeriodDescriptionCurrentPeriod": "Les transactions que vous avez sélectionnées servent à payer le loyer de **{{paymentPeriod}}**.",
  "paymentPeriodDescriptionNonCurrentPeriod": "Les transactions que vous avez sélectionnées ont servi à payer le loyer de **{{paymentPeriod}}**.",
  "selectedPaymentPeriodDescriptionCurrentPeriod": "Les transactions que vous avez sélectionnées servent à payer le loyer de **{{selectedPaymentPeriod}}**.",
  "selectedPaymentPeriodDescriptionNonCurrentPeriod": "Les transactions que vous avez sélectionnées ont servi à payer le loyer de **{{selectedPaymentPeriod}}**.",
  "updateRentPeriod": "Mettre à jour la période de location",
  "updateRentPeriodDescription": "Sélectionnez le mois pour lequel vous avez payé votre loyer avec les transactions que vous avez sélectionnées.",
  "paymentPeriodDescriptionFuture": "Les transactions que vous avez sélectionnées servent à payer le loyer de {{paymentPeriod}}.",
  "paymentPeriodDescriptionPresent": "Les transactions que vous avez sélectionnées ont servi à payer le loyer de {{paymentPeriod}}.",
  "changePaymentPeriod": {
    "title": "Modifier la période de paiement",
    "description": "Sélectionnez le mois pour lequel vous avez payé votre loyer avec les transactions que vous avez sélectionnées. Vous ne pouvez déclarer votre loyer que pour les mois suivants."
  },
  "saveChangesButton": "SAUVEGARDER LES MODIFICATIONS",
  "totalAmountLabel": "Montant total",
  "amountTooltipTitle": "Qu'est-ce que cela signifie?",
  "amountTooltipContent": "Il s'agit du montant que nous déclarerons à Equifax Canada en fonction des transactions que vous avez sélectionnées."
}
