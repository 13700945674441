import styled from "@emotion/native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue as sv } from "@bwll/bw-utils";

export const Input = styled.View`
  padding: ${sv(spacing.xs)};
  border-bottom-width: 1px;
  border-bottom-color: ${COLORS.NEUTRAL.COOL[100]};
`;
