import React from "react";

import { Body1, Spacer } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";
import { spacing } from "@bwll/bw-styles";
import { PRODUCT_VERTICAL } from "@bwll/bw-types";

import { SponsoredCreditCardDetails } from "../SponsoredCreditCardDetails";
import { SponsoredLoanDetails } from "../SponsoredLoanDetails";
import { SponsoredLoanFigPreApprovedDetails } from "../SponsoredLoanFigPreApprovedDetails";
import { SponsoredProductDetailsProps } from "./SponsoredProductDetails.types";

export const SponsoredProductDetails = ({
  cardType,
  cashAdvanceRate,
  purchaseInterestRate,
  balanceTransferRate,
  annualFee,
  shortDescriptionText,
  isFigPreApproved,
  figPreApprovalData,
  loanAmount,
  apr,
  term,
}: Partial<SponsoredProductDetailsProps>) => {
  const { isMobile } = useBreakpoints();

  if (cardType) {
    switch (cardType) {
      case PRODUCT_VERTICAL.CREDIT_CARD:
        return (
          <SponsoredCreditCardDetails
            cashAdvanceRate={cashAdvanceRate}
            purchaseInterestRate={purchaseInterestRate}
            balanceTransferRate={balanceTransferRate}
            annualFee={annualFee}
          />
        );

      case PRODUCT_VERTICAL.LOAN: {
        if (isFigPreApproved) {
          return <SponsoredLoanFigPreApprovedDetails figPreApprovalData={figPreApprovalData} />;
        }

        return <SponsoredLoanDetails loanAmount={loanAmount} apr={apr} term={term} />;
      }

      default:
        return (
          <>
            {!isMobile && <Spacer height={spacing.xs} />}
            <Body1>{shortDescriptionText}</Body1>
          </>
        );
    }
  }

  return null;
};
