import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.View`
  display: flex;
  row-gap: ${styledValue(spacing.m)};
`;

export const SectionContainer = styled.View`
  display: flex;
  flex-direction: column;
`;

export const InputSection = styled.View<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  flex-wrap: wrap;
  column-gap: ${styledValue(spacing.m)};
`;

export const InputSectionItem = styled.View<{ isMobile: boolean }>`
  flex: 0 0 ${({ isMobile }) => (isMobile ? "100%" : "40%")};
`;
