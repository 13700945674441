import React from "react";

import { Body2, Caption } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";
import { COLORS } from "@bwll/bw-styles";

import * as Styled from "./SponsoredProductDetailsTemplate.styles";
import { SponsoredProductDetailsProps } from "./SponsoredProductDetailsTemplate.types";

export const SponsoredProductDetailsTemplate = ({ details }: SponsoredProductDetailsProps) => {
  const { isMobile } = useBreakpoints();

  return (
    <Styled.Container isMobile={isMobile}>
      {details.map(
        ({ label, value }, index) =>
          !!value && (
            <Styled.DetailContainer
              key={label}
              isMobile={isMobile}
              withBorder={!isMobile && index !== details.length - 1}
            >
              <Caption color={COLORS.NEUTRAL.WARM["700"]}>{label}</Caption>
              <Body2 color={COLORS.NEUTRAL.WARM["800"]}>{value}</Body2>
            </Styled.DetailContainer>
          ),
      )}
    </Styled.Container>
  );
};
