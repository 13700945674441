import styled from "@emotion/native";

import { Image } from "@bwll/bw-components/next";
import { spacing } from "@bwll/bw-styles";

export const MultiColumnsBlock = styled.View({
  flexDirection: "row",
  gap: spacing.xxs,
});

export const HeaderContent = styled.View({ width: "65%", flexDirection: "column", gap: spacing.xs });

export const HeaderImage = styled(Image)({
  width: "35%",
});
