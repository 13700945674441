import styled from "@emotion/native";
import { StyleProp, TextStyle } from "react-native";

import { Heading2, View as ResponsiveView } from "@bwll/bw-components/next";
import {
  COLORS,
  borderWidth,
  fontSize,
  fontWeights,
  letterSpacing,
  lineHeight,
  mq,
  spacing,
} from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { HERO_BACKGROUND_COLOR } from "./HistoricalReportingLandingPage.constants";

type StyledLayoutProps = { isMobile?: boolean; isTablet?: boolean };

export const styles = {
  heroHeading: {
    fontSize: fontSize.xxl,
    fontWeight: fontWeights.semiBold,
    lineHeight: lineHeight.xxxl,
    letterSpacing: letterSpacing.xs,
  },
  heroImage: {
    width: 327,
    height: 276,
  },
  ctaButton: {
    maxWidth: 343,
  },
  faqButton: {
    maxWidth: 360,
  },
  footerCtaButton: {
    maxWidth: 360,
  },
  sectionHeading: {
    lineHeight: lineHeight.xxl,
  },
  leftAligned: {
    alignSelf: "flex-start",
  } as StyleProp<TextStyle>,
};

export const PageContainer = styled.View`
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const HeroContainer = styled.View`
  background-color: ${HERO_BACKGROUND_COLOR};
  width: 100%;
  max-width: 100%;
`;

export const MarkdownContentWrapper = styled.View<StyledLayoutProps & { leftPadding?: boolean }>`
  margin-top: ${styledValue(spacing.xs)};
  margin-left: ${({ isMobile, leftPadding }) =>
    isMobile || !leftPadding ? `${styledValue(spacing.none)}` : `${styledValue(spacing.m)}`};
`;

const Section = styled.View<StyledLayoutProps & { maxWidth?: number }>`
  width: 100%;
  max-width: ${({ isMobile, maxWidth }) =>
    isMobile ? "100%" : styledValue(maxWidth ?? 1040 + 2 * spacing.xl)};
  margin: 0 auto;
`;

export const HeroSection = styled(Section)`
  flex-direction: ${({ isMobile, isTablet }) => (isMobile || isTablet ? "column" : "row")};
  padding: ${({ isMobile }) =>
    isMobile
      ? `${styledValue(spacing.xs)} ${styledValue(spacing.m)}`
      : `${styledValue(48)} ${styledValue(48)}`};
`;

export const HeroInfo = ResponsiveView(
  mq({
    flexGrow: 1,
    flexBasis: 0,
    gap: spacing.xs,
    display: "flex",
    flexDirection: "column",
    marginBottom: [spacing.xs, spacing.xl, 0],
  }),
);

export const HeroImageContainer = styled.View<StyledLayoutProps>`
  flex-grow: 1;
  flex-basis: 0%;
  display: flex;
  align-items: ${({ isMobile, isTablet }) => (isMobile || isTablet ? "center" : "end")};
`;

export const ContentContainer = styled.View`
  width: 100%;
  max-width: 100%;
`;

export const MainSection = styled(Section)`
  flex-direction: column;
  align-items: center;
  padding: ${({ isMobile, isTablet }) =>
    isMobile
      ? `${styledValue(spacing.xl)} ${styledValue(spacing.m)}`
      : isTablet
      ? `${styledValue(spacing.xl)} ${styledValue(48)}`
      : `${styledValue(64)} ${styledValue(48)}`};
`;

export const MultiblockContainer = styled(Section)`
  flex-direction: ${({ isMobile, isTablet }) => (isMobile || isTablet ? "column" : "row")};
  margin: ${({ isMobile, isTablet }) =>
    isMobile || isTablet
      ? `${styledValue(spacing.m)} 0`
      : `${styledValue(spacing.l)} 0 ${styledValue(spacing.xl)}`};
`;

export const FaqContainer = styled(Section)`
  flex-direction: column;
  align-items: center;
`;

export const FaqItem = styled.View`
  width: 100%;
  flex-grow: 1;
  flex-basis: 0%;
  padding: ${styledValue(spacing.xs)} 0;
`;

export const FaqTitle = styled(Heading2)`
  flex-grow: 1;
  flex-basis: 0%;
`;

export const FooterContainer = styled.View`
  height: ${styledValue(96)};
  width: 100%;
`;

export const Footer = styled.View`
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-top-color: ${COLORS.NEUTRAL.COOL[100]};
  border-top-width: ${styledValue(borderWidth.small)};
  border-top-style: solid;
  padding: ${styledValue(spacing.m)};
  position: fixed;
  bottom: 0;

  flex-direction: column;
  align-items: center;
`;
