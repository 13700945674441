import styled, { css } from "@emotion/native";

import { Body2, View as ResponsiveView, Subheading2 } from "@bwll/bw-components/next";
import { isWeb } from "@bwll/bw-components/next/constants";
import { Breakpoints } from "@bwll/bw-hooks";
import { COLORS, borderRadius, mq, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

type LayoutProps = Pick<Breakpoints, "isDesktop">;

const sidePadding = isWeb ? 0 : spacing.m;
const responsivePadding = [sidePadding, sidePadding, 0];

export const Content = ResponsiveView(
  mq({
    paddingLeft: responsivePadding,
    paddingRight: responsivePadding,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  }),
);

export const Breadcrumbs = styled.View`
  margin-right: ${styledValue(spacing.l)};
`;

export const AdDisclosure = styled.View`
  margin-left: auto;
`;

export const ProductData = styled.View<LayoutProps & { showSimilarOffers: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ isDesktop }) => (isDesktop ? "row" : "column")};
  ${({ isDesktop }) =>
    isDesktop &&
    `
    border-top-left-radius: ${styledValue(borderRadius.s)};
    border-top-right-radius: ${styledValue(borderRadius.s)};
  `}
  ${({ isDesktop, showSimilarOffers }) =>
    isDesktop &&
    !showSimilarOffers &&
    `
    border-bottom-left-radius: ${styledValue(borderRadius.s)};
    border-bottom-right-radius: ${styledValue(borderRadius.s)};
  `}
  background-color: ${COLORS.WHITE};
`;

export const TitleContainer = styled.View<LayoutProps>`
  margin-top: ${({ isDesktop }) => styledValue(isDesktop ? spacing.m : spacing.xxs)};
  height: 40px;
`;

export const TitleText = styled(Body2)`
  text-align: center;
`;

const responsiveSpacing = (props: LayoutProps) => css`
  padding-top: ${props.isDesktop ? "2%" : 0};
  padding-bottom: 2%;
  padding-horizontal: ${props.isDesktop ? "2%" : 0};
`;

export const LeftContainer = styled.View<LayoutProps>`
  min-width: ${({ isDesktop }) => (isDesktop ? "361px" : "100%")};
  max-width: ${({ isDesktop }) => (isDesktop ? "361px" : "100%")};
  ${(props) => responsiveSpacing(props)};
`;

export const RightContainer = styled.View<LayoutProps>`
  flex-shrink: 1;
  ${(props) => responsiveSpacing(props)};
`;

export const ImageContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-horizontal: auto;
  margin-bottom: ${styledValue(spacing.xs)};
`;

export const BannerContainer = styled.View<{ centered: boolean }>`
  margin-top: ${styledValue(spacing.xs)};
  margin-bottom: ${styledValue(spacing.xxs)};
  display: flex;
  flex-direction: ${({ centered }) => (centered ? "row" : "column")};
  justify-content: center;
  height: ${styledValue(spacing.l)};
`;

export const HighlightsContainer = styled.View`
  margin-top: ${styledValue(spacing.m)};
`;

export const RatesContainer = styled.View`
  margin-top: ${styledValue(spacing.m)};
  border-radius: ${styledValue(borderRadius.s)};
  border: 1px solid ${COLORS.NEUTRAL.COOL["100"]};
  overflow: hidden;
`;

export const RewardsContainer = styled.View`
  margin-top: ${styledValue(spacing.m)};
  padding: ${styledValue(spacing.xs)};
  border-radius: ${styledValue(borderRadius.s)};
  border: 1px solid ${COLORS.NEUTRAL.COOL["100"]};
`;

export const KeyBenefitsContainer = styled.View`
  margin-top: ${styledValue(spacing.m)};
`;

export const KeyBenefitRow = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BoldSubheading = styled(Subheading2)`
  font-weight: 700;
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;

export const OfferContainer = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.View<{ hasActiveOffer: boolean }>`
  padding: ${styledValue(spacing.xs)};
  ${({ hasActiveOffer }) => hasActiveOffer && `padding-top: ${styledValue(spacing.xxxs)};`}
  width: 100%;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${COLORS.WHITE};
  border-top-color: ${COLORS.NEUTRAL.COOL["100"]};
  border-top-width: ${styledValue(1)};
  gap: ${styledValue(spacing.xxxs)};
`;

export const ButtonContainer = styled.View`
  max-width: 400px;
  width: 100%;
`;

export const MarkdownContainer = styled.View`
  padding-bottom: ${styledValue(spacing.m)};
`;
