import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { PROMOTION_TYPE, PromotionPopup, PromotionSnackbar, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

interface UsePromotions {
  promotionType: PROMOTION_TYPE;
}

interface PromotionsData {
  data: PromotionPopup[] | PromotionSnackbar[];
}

const fetchPromotions = async (accessToken: string, baseUrl: string, promotionType: PROMOTION_TYPE) => {
  const cacheID = new Date().getTime();
  const response = await axios.get<PromotionsData>(
    `${baseUrl}/promotion?promotionTypes=${promotionType}&cache=${cacheID}`,
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return response.data;
};

export const usePromotions = ({ promotionType }: UsePromotions) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  return useQuery(
    generateQueryKey(`${promotionType}${QUERY_KEYS.GET_PROMOTIONS}` as QUERY_KEYS, accessToken),
    () => fetchPromotions(accessToken, envConfigs.API_PROMOTION_URL, promotionType),
    {
      ...queryBehavior({
        expiresIn,
        enabled: !!accessToken,
      }),
      cacheTime: 0,
    },
  );
};
