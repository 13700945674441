import styled from "@emotion/native";

import { Body2 } from "@bwll/bw-components/next";
import { COLORS, fontSize, fontWeights, fonts, spacing } from "@bwll/bw-styles";
import { PLATFORM } from "@bwll/bw-types";
import { styledValue } from "@bwll/bw-utils";

type CtaTextProps = { platform: PLATFORM };

export const Row = styled.View`
  flex-direction: row;
  align-items: flex-start;
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin-right: ${styledValue(spacing.xxs)};
`;

export const IconContainer = styled.View`
  height: ${styledValue(spacing.m)};
  width: ${styledValue(spacing.m)};
  border-radius: ${styledValue(Math.floor(spacing.m / 2))};
  justify-content: center;
  align-items: center;
  background-color: #fde9f1;
`;

export const TextContainer = styled.View`
  flex: 1;
`;
