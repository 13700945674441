import { useMemo } from "react";

import { SponsoredCarouselItemCommonEventData } from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES, LOAN_KNACK_ID, PreapprovalStatus } from "@bwll/bw-types";

import { APPROVAL_CHANCE_TYPE } from "../useConvertLikelihoodOfApproval";
import { SponsoredCarouselItem, SponsoredCarouselItemConfig } from "./SponsoredCarouselCard.types";

export const useCommonAnalyticsAttributes = (
  config: SponsoredCarouselItemConfig,
  carouselItem: SponsoredCarouselItem,
) => {
  const isFigFlow = carouselItem.productReferenceNumber === LOAN_KNACK_ID.FIG_LOANS_OFFER;
  const isFigPreApproved =
    (isFigFlow &&
      carouselItem.preApproval &&
      carouselItem.preApproval.status !== PreapprovalStatus.NotStarted) ||
    false;

  const commonAttributes: SponsoredCarouselItemCommonEventData = useMemo(
    () => ({
      [ANALYTICS_ATTRIBUTES.IS_REDESIGNED_DASHBOARD]: true,
      [ANALYTICS_ATTRIBUTES._CALL_TO_ACTION]: config.ctaText,
      [ANALYTICS_ATTRIBUTES.PRESELECTED]: carouselItem.isPreselected ? "Yes" : "No",
      [ANALYTICS_ATTRIBUTES.LIKELIHOOD_OF_APPROVAL]:
        isFigFlow && isFigPreApproved ? 1 : carouselItem.approvalChance.likelihoodOfApproval || null,
      [ANALYTICS_ATTRIBUTES.LIKELIHOOD_OF_APPROVAL_BUCKET]:
        isFigFlow && isFigPreApproved ? 4 : carouselItem.approvalChance.likelihoodOfApprovalType || null,
      [ANALYTICS_ATTRIBUTES.LIKELIHOOD_OF_APPROVAL_LABEL]:
        isFigFlow && isFigPreApproved
          ? APPROVAL_CHANCE_TYPE.EXCELLENT
          : carouselItem.approvalChance.text || "",
      [ANALYTICS_ATTRIBUTES.TRACKING_TAG]: carouselItem.trackingTag || "",
      [ANALYTICS_ATTRIBUTES.PRODUCT_ID]: carouselItem.productReferenceId,
      [ANALYTICS_ATTRIBUTES.BELOW_SHOW_ALL]: carouselItem.belowShowAll,
      [ANALYTICS_ATTRIBUTES.CASH_BACK_OFFER]: carouselItem.cashBackOffer,
      [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: carouselItem.displayOrder,
      [ANALYTICS_ATTRIBUTES.IS_CASH_BACK]: carouselItem.isCashBack,
      [ANALYTICS_ATTRIBUTES.PREPOPULATION_FLOW]: carouselItem.prepopulationFlow,
      [ANALYTICS_ATTRIBUTES.SPONSORED]: carouselItem.isSponsored ? "Yes" : "No",
      [ANALYTICS_ATTRIBUTES.PREFERRED_ORDER]: carouselItem.preferredOrder,
      [ANALYTICS_ATTRIBUTES.RANK]: carouselItem.rank,
      [ANALYTICS_ATTRIBUTES._IMPRESSION_ID]: carouselItem.productImpressionId,
      [ANALYTICS_ATTRIBUTES.SPONSORED_PLACEMENT]: carouselItem.sponsoredPlacement,
      ...(isFigFlow ? { [ANALYTICS_ATTRIBUTES.ACTIVE_OFFER]: isFigPreApproved } : {}),
    }),
    [config.ctaText, carouselItem, isFigFlow, isFigPreApproved],
  );

  return commonAttributes;
};
