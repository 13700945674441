import {
  sortCreditCardsByAnnualFee,
  sortCreditCardsByFirstYearValue,
  sortCreditCardsByLikelihoodOfApproval,
  sortCreditCardsByPreSelectedOrder,
  sortCreditCardsByPurchaseInterestRate,
  sortCreditCardsByTrendingOrder,
} from "@bwll/bw-utils";

import { CreditCardSortOptionKey, CreditCardSorter, SORT_OPTIONS } from "./ProductSortDropdown.types";

export const creditCardSorters: Record<CreditCardSortOptionKey, CreditCardSorter> = {
  [SORT_OPTIONS.Recommended]: {
    key: SORT_OPTIONS.Recommended,
    sortFn: sortCreditCardsByPreSelectedOrder,
    localeKey: "marketplace:productList:sortAndFilters:sort:recommended",
    shortLocaleKey: "marketplace:productList:sortAndFilters:sort:recommended",
  },
  [SORT_OPTIONS.LOA]: {
    key: SORT_OPTIONS.LOA,
    sortFn: sortCreditCardsByLikelihoodOfApproval,
    localeKey: "marketplace:productList:sortAndFilters:sort:approvalChance",
    shortLocaleKey: "marketplace:productList:sortAndFilters:sort:shortApprovalChance",
  },
  [SORT_OPTIONS.FirstYearValue]: {
    key: SORT_OPTIONS.FirstYearValue,
    sortFn: sortCreditCardsByFirstYearValue,
    localeKey: "marketplace:productList:sortAndFilters:sort:firstYearValue",
    shortLocaleKey: "marketplace:productList:sortAndFilters:sort:shortFirstYearValue",
  },
  [SORT_OPTIONS.Fee]: {
    key: SORT_OPTIONS.Fee,
    sortFn: sortCreditCardsByAnnualFee,
    localeKey: "marketplace:productList:sortAndFilters:sort:fees",
    shortLocaleKey: "marketplace:productList:sortAndFilters:sort:shortFees",
  },
  [SORT_OPTIONS.InterestRate]: {
    key: SORT_OPTIONS.InterestRate,
    sortFn: sortCreditCardsByPurchaseInterestRate,
    localeKey: "marketplace:productList:sortAndFilters:sort:interestRate",
    shortLocaleKey: "marketplace:productList:sortAndFilters:sort:shortInterestRate",
  },
  [SORT_OPTIONS.Trending]: {
    key: SORT_OPTIONS.Trending,
    sortFn: sortCreditCardsByTrendingOrder,
    localeKey: "marketplace:productList:sortAndFilters:sort:trending",
    shortLocaleKey: "marketplace:productList:sortAndFilters:sort:shortTrending",
  },
};
