import styled from "@emotion/native";

import { COLORS } from "@bwll/bw-styles";

export const Container = styled.View`
  position: relative;
  background-color: ${COLORS.WHITE};
  height: auto;
  flex: 1 0 auto;
`;

export const Row = styled.View`
  flex-direction: row;
`;
