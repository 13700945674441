import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Select } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";

import { DropdownContainer } from "./ProductSortDropdown.styles";
import { SortDropdownProps } from "./ProductSortDropdown.types";

/**
 * Product agnostic sorting dropdown
 * Pass in a list of options and an onValueChange to handle the sorting from the parent component
 * onValueChange passes through a sorting key
 *
 * @component
 * @example
 * const [sortedCards, setSortedCards] = useState(creditCards);
 * 
 * const options = [
 *  {
    value: "Recommended",
    label: "Recommended"
    },
    {
      value: "LOA",
      label: "Likelihood of Approval",
    },
 * ]
 *
 * return (
 *  <ProductSortDropdown onValueChange={({ sortKey }) => setSortedCards(sortersMap[key].sortFn(creditCards))} options={options} />
 * );
 */
export const ProductSortDropdown = (props: SortDropdownProps) => {
  const i18next = useTranslation();
  const { isMobile } = useBreakpoints();

  const initialOption = useMemo(
    () => props.options.find(({ value }) => value === props.initialSort),
    [props.options, props.initialSort],
  );

  return (
    <DropdownContainer isMobile={isMobile}>
      <Select
        {...props}
        prefixLabel={i18next.t("marketplace:productList:sortAndFilters:sort:prefix")}
        onSelectOption={(key) => {
          props.onValueChange && props.onValueChange(key);
        }}
        initialOption={initialOption ?? props.options[0]}
        onSelectToggle={props.onDropdownToggle}
      />
    </DropdownContainer>
  );
};
