import i18next from "i18next";

import { CreditFactor, CreditUtilizationStatus } from "@bwll/bw-types";

/**
 *
 * A function that returns a message based on the status of the credit utilization update.
 * Most of the logic was copied from (bw-react-frontend/apps/mobile/src/effects/network/api/creditFactors/getCreditUtilizationMessage.ts)
 * and aligned with the web implementation.
 * TODO: Unify these files.
 */
export const getCreditUtilizationMessage = (item: CreditFactor, isSummary = false) => {
  if (item.total == null) {
    return i18next.t("creditFactors:creditUtilization:utilizationNotAvailable");
  }
  if (item.update && item.update.status === CreditUtilizationStatus.INCREASE) {
    const { amount } = item.update;

    if (item.total <= 30) {
      return isSummary
        ? i18next.t("creditFactors:creditUtilization:increase:summaryLessThan30", { count: amount })
        : i18next.t("creditFactors:creditUtilization:increase:lessThan30", { count: amount });
    }

    if (item.total > 30) {
      return isSummary
        ? i18next.t("creditFactors:creditUtilization:increase:summaryMoreThan30", { count: amount })
        : i18next.t("creditFactors:creditUtilization:increase:moreThan30", { count: amount });
    }
  }
  if (item.total >= 30) return i18next.t("creditFactors:creditUtilization:utilizationHigh");

  return i18next.t("creditFactors:creditUtilization:noneStatus");
};
