import React from "react";

import { ElementTracker, IllustrativePromoCardTemplate } from "@bwll/bw-components/next";

import { OffersPromoCardProps } from "./OffersPromoCard.types";

export const OffersPromoCard = ({ promoCard, testID, onTrackView, clickAction }: OffersPromoCardProps) => {
  return (
    <ElementTracker shouldStartTracking={true} trackingHandler={onTrackView}>
      <IllustrativePromoCardTemplate
        testID={testID}
        body={promoCard.body}
        title={promoCard.title}
        ctaText={promoCard.buttonText}
        imageUrl={promoCard.image}
        onPress={clickAction}
      />
    </ElementTracker>
  );
};
