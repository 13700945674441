import { initializeLocalization } from "@bwll/bw-locale";

import { ErrorBoundaryScreenContainer } from "./ErrorBoundaryScreen.styles";

const { localizationReady } = initializeLocalization({ interpolation: {} });

/**
 * Component for rendering standalone error screen on the dashboard app,
 * used for root error boundary.
 *
 * @component
 * @example
 * return (
 *   <ErrorBoundaryScreen />
 * )
 */
export const ErrorBoundaryScreen = () => {
  localizationReady();
  return (
    <ErrorBoundaryScreenContainer>
      <div>Error Boundary Component</div>
    </ErrorBoundaryScreenContainer>
  );
};
