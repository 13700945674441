import { Skeleton } from "@bwll/bw-components/next";
import { useBreakpoints, useGetPrimePromoCards } from "@bwll/bw-hooks";
import { PrimePromoCardsParam } from "@bwll/bw-types";

import { TrendingOffersPromoCard } from "./TrendingOffersPromoCard.component";
import {
  TRENDING_OFFERS_PROMO_CARD_HEIGHT,
  TRENDING_OFFERS_PROMO_CARD_HEIGHT_MOBILE,
  TRENDING_OFFERS_PROMO_CARD_SKELETON_TEST_ID,
} from "./TrendingOffersPromoCard.constants";
import { TrendingOffersPromoCardContainerProps } from "./TrendingOffersPromoCard.types";

export const TrendingOffersPromoCardContainer = (props: TrendingOffersPromoCardContainerProps) => {
  const { isMobile } = useBreakpoints();
  const promoCardTypes = [
    PrimePromoCardsParam.TopPicks,
    PrimePromoCardsParam.CompareBankAccounts,
    PrimePromoCardsParam.DashboardLTOs,
    PrimePromoCardsParam.TrendingOffers,
  ];
  const { data, isLoading } = useGetPrimePromoCards(promoCardTypes, {
    enabled: props.enabled,
  });

  if (isLoading) {
    return (
      <Skeleton
        testID={TRENDING_OFFERS_PROMO_CARD_SKELETON_TEST_ID}
        variant="rounded"
        width="100%"
        height={isMobile ? TRENDING_OFFERS_PROMO_CARD_HEIGHT_MOBILE : TRENDING_OFFERS_PROMO_CARD_HEIGHT}
      />
    );
  }

  if (!isLoading && data && data.trendingOffersPromoCard) {
    return <TrendingOffersPromoCard promoCard={data.trendingOffersPromoCard} {...props} />;
  }

  return null;
};
