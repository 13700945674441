import i18next from "i18next";

import { CreditFactor, MissedPaymentTypes, MissedPaymentsStatus } from "@bwll/bw-types";

/**
 *
 * A function that returns a message based on the status of the missed payments update.
 * Most of the logic was copied from (bw-react-frontend/apps/mobile/src/effects/network/api/creditFactors/getMissedPaymentsMessage.ts)
 * and aligned with the web implementation.
 * TODO: Unify these files.
 */
export const getMissedPaymentsMessage = (item: CreditFactor) => {
  if (!item.update) return null;
  const isPlural = item.update.amount > 1;
  if (item.update.status === MissedPaymentsStatus.INCREASE) {
    if (item.update.type === MissedPaymentTypes.DEFERRED) {
      return isPlural
        ? i18next.t("creditFactors:missedPayments:increase:deferred_plural", { count: item.update.amount })
        : i18next.t("creditFactors:missedPayments:increase:deferred", { count: item.update.amount });
    }
    return isPlural
      ? i18next.t("creditFactors:missedPayments:increase:missed_plural", { count: item.update.amount })
      : i18next.t("creditFactors:missedPayments:increase:missed", { count: item.update.amount });
  }

  if (item.update.status === MissedPaymentsStatus.NONE) {
    return i18next.t("creditFactors:missedPayments:noneStatus");
  }

  if (item.update.status === MissedPaymentsStatus.NO_CHANGE) {
    return i18next.t("creditFactors:missedPayments:noCangeStatus");
  }

  return null;
};
