import styled from "@emotion/native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const ListContainer = styled.View<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  border-radius: ${styledValue(spacing.xs)};
  padding: ${({ isMobile }) =>
    isMobile ? ` ${styledValue(spacing.none)}` : ` ${styledValue(spacing.xxs)} ${styledValue(spacing.s)}`};
`;
