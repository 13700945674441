import styled from "@emotion/native";
import { Platform } from "react-native";

import { Body1, Heading2 } from "@bwll/bw-components/next";
import { Breakpoints } from "@bwll/bw-hooks";
import { COLORS, borderRadius, borderWidth, fontSize, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

type LayoutProps = Pick<Breakpoints, "isDesktop">;

const companyIconSize = 40;

export const ProductData = styled.View<LayoutProps>`
  width: 100%;
  flex-direction: ${({ isDesktop }) => (isDesktop ? "row" : "column")};
  gap: ${({ isDesktop }) => (isDesktop ? styledValue(spacing.xxxl) : styledValue(spacing.xs))};
  border-radius: ${({ isDesktop }) => styledValue(isDesktop ? borderRadius.s : spacing.none)};
  background-color: ${COLORS.WHITE};
  ${({ isDesktop }) => isDesktop && `padding: ${styledValue(40)} ${styledValue(spacing.xxxl)};`}
`;

export const TitleContainer = styled.View<LayoutProps>`
  flex-direction: row;
  gap: ${styledValue(spacing.xxs)};
  align-items: center;
`;

export const TitleCompanyIcon = styled.Image<{ size?: number }>`
  width: ${({ size }) => styledValue(size ?? companyIconSize)};
  height: ${({ size }) => styledValue(size ?? companyIconSize)};
`;

export const Heading = styled(Heading2)`
  letter-spacing: ${styledValue(0.16)};
  line-height: ${styledValue(28)};
  flex: 1;
`;

export const RatesAndFeesContainer = styled.View`
  border: ${styledValue(borderWidth.small)} solid ${COLORS.NEUTRAL.COOL["100"]};
  border-radius: ${styledValue(borderRadius.s)};
  overflow: hidden;
`;

export const NoFeeContainer = styled.View`
  flex-direction: row;
  gap: ${styledValue(spacing.xxxs)};
  justify-content: flex-start;
`;

export const NoFeeText = styled(Body1)<LayoutProps>`
  flex: 1;
  flex-wrap: wrap;
  letter-spacing: ${({ isDesktop }) => (isDesktop ? styledValue(0.16) : styledValue(0.24))};
  ${({ isDesktop }) =>
    !isDesktop &&
    `
    font-size: ${styledValue(fontSize.xs)};
    line-height: ${styledValue(lineHeight.m)};
  `}
`;

export const ApplyContainer = styled.View<LayoutProps>`
  align-items: center;
  gap: ${({ isDesktop }) => (isDesktop ? styledValue(spacing.xxs) : styledValue(spacing.xxxs))};
`;

export const LeftContainer = styled.View<LayoutProps>`
  gap: ${({ isDesktop }) => (isDesktop ? styledValue(spacing.m) : styledValue(spacing.xs))};
  min-width: ${({ isDesktop }) => (isDesktop ? "361px" : "100%")};
  max-width: ${({ isDesktop }) => (isDesktop ? "361px" : "100%")};
`;

export const RightContainer = styled.View<LayoutProps>`
  flex-grow: 1;
  flex-shrink: 1;
  gap: ${({ isDesktop }) => (isDesktop ? styledValue(spacing.m) : styledValue(spacing.xs))};
`;

export const LearnMoreContainer = styled.View`
  gap: ${styledValue(spacing.xxs)};
`;

export const Footer = styled.View`
  padding: ${styledValue(spacing.xxxs)} ${styledValue(spacing.m)} ${styledValue(spacing.xxs)};
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${COLORS.WHITE};
  border-top-color: ${COLORS.NEUTRAL.COOL["100"]};
  border-top-width: ${styledValue(borderWidth.small)};
`;

export const ButtonContainer = styled.View`
  max-width: 400px;
  width: 100%;
`;

/**
 * When adding flex on web, it comes with a weird behaviour on the components distribution, overlaping each other.
 * On mobile, flex prevents the 'Details' title to be cut off.
 */
export const customLearnMoreContainerStyle = Platform.OS === "web" ? {} : { flex: 1 };
