import { AvailableLocale } from "@bwll/bw-types";

import { useReportingStatusConfig } from "./useReportingStatusConfig";
import { useServicingData } from "./useServicingData";

export const useIsRentProofRejected = (locale: AvailableLocale) => {
  const { anyManualTransactionInReview, anyManualTransactionApproved } = useServicingData(locale);

  const { status } = useReportingStatusConfig();

  return !anyManualTransactionApproved && !anyManualTransactionInReview && !status;
};
