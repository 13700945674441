import React from "react";

import { borderRadius, spacing } from "@bwll/bw-styles";

import { StyledCell } from "./CardContainer.styles";
import type { CardContainerProps } from "./CardContainer.types";

export const CardContainer = ({
  children,
  testID,
  paddingHorizontal,
  paddingVertical,
}: CardContainerProps) => (
  <StyledCell
    borderRadius={borderRadius.m}
    hasShadow={false}
    paddingHorizontal={paddingHorizontal ?? spacing.xs}
    paddingVertical={paddingVertical ?? spacing.xs}
    testID={testID}
  >
    {children}
  </StyledCell>
);
