import { useCallback } from "react";
import { Platform } from "react-native";

import {
  ANALYTICS_ATTRIBUTES,
  ANALYTICS_VALUES,
  RA_EXPERIMENT_FLOW,
  RENT_REPORTING_ANALYTICS_EVENT_NAME,
} from "@bwll/bw-types";
import { getISO, uuid } from "@bwll/bw-utils";

import { AnalyticsEventProps, FLOW_NAMES, TFeatureAnalyticsAttributes } from "../..";

export const enum RA_FLOW_NAME {
  RENT_ADVANTAGE_ONBOARDING = "Rent Advantage Onboarding",
  RENT_CONFIRMATION = "Rent Confirmation",
}

export const enum ONBOARDING_TYPE {
  BANKING = "Banking",
  MANUAL = "Manual",
}

export type RentAdvatageStepAttributes = {
  onboardingType?: ONBOARDING_TYPE;
};

export const useRentAdvantageEvents = ({ commonAttributes, trackEvent }: AnalyticsEventProps) => {
  const onRentAdvantageScreenViewed = useCallback(
    (featureAttributes: TFeatureAnalyticsAttributes<RentAdvatageStepAttributes>) => {
      trackEvent(RENT_REPORTING_ANALYTICS_EVENT_NAME.RENT_REPORTING_APPLICATION, {
        ...(Platform.OS !== "web" && {
          [ANALYTICS_ATTRIBUTES.APPLICATION_TYPE]: ANALYTICS_VALUES.REACT_NATIVE,
        }),
        ...commonAttributes,
        ...featureAttributes,
        [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]: getISO(),
        [ANALYTICS_ATTRIBUTES.FLOW_NAME]: featureAttributes.flowName ?? FLOW_NAMES.RENT_ADVANTAGE_ONBOARDING,
        [ANALYTICS_ATTRIBUTES.VERSION]: RA_EXPERIMENT_FLOW.V2,
      });
    },
    [commonAttributes, trackEvent],
  );

  const onRentAdvantageClicked = useCallback(
    (featureAttributes: TFeatureAnalyticsAttributes<RentAdvatageStepAttributes>) => {
      trackEvent(RENT_REPORTING_ANALYTICS_EVENT_NAME.RENT_REPORTING_APPLICATION, {
        ...(Platform.OS !== "web" && {
          [ANALYTICS_ATTRIBUTES.APPLICATION_TYPE]: ANALYTICS_VALUES.REACT_NATIVE,
        }),
        ...commonAttributes,
        ...featureAttributes,
        [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]: getISO(),
        [ANALYTICS_ATTRIBUTES.CLICK_ID]: uuid(),
        [ANALYTICS_ATTRIBUTES.FLOW_NAME]: featureAttributes.flowName ?? FLOW_NAMES.RENT_ADVANTAGE_ONBOARDING,
        [ANALYTICS_ATTRIBUTES.VERSION]: RA_EXPERIMENT_FLOW.V2,
      });
    },
    [commonAttributes, trackEvent],
  );

  return { onRentAdvantageScreenViewed, onRentAdvantageClicked };
};
