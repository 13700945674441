import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, usePostQualify, useSessionContext } from "@bwll/bw-hooks";
import { IApiHookOptions, PreselectedProductV4, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

const fetchPreselectionsV4 = async (
  accessToken: string,
  baseUrl: string,
): Promise<PreselectedProductV4[]> => {
  const response = await axios.get<PreselectedProductV4[]>(
    `${baseUrl}/api/product-verticals/pre-selection/v4`,
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return response.data;
};

export const usePreselectionsV4 = (
  options: IApiHookOptions<PreselectedProductV4[], PreselectedProductV4[]> = {},
) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  const { isFetched: wasQualifyQueryFetched } = usePostQualify({ enabled: true });

  return useQuery(
    generateQueryKey(`${QUERY_KEYS.GET_PRESELECTIONS}` as QUERY_KEYS, accessToken),
    () => fetchPreselectionsV4(accessToken, envConfigs.API_GATEWAY_URL),
    {
      ...queryBehavior({
        expiresIn,
        enabled: !!accessToken && wasQualifyQueryFetched && !!options.enabled,
        ...options,
      }),
    },
  );
};
