import { useCallback } from "react";

import { CtaPressHandlerParams, SponsoredCarousel } from "@bwll/bw-modules";
import { DASHBOARD_ANALYTICS_ORIGINS, Nullable, PRODUCT_VERTICAL_GUID } from "@bwll/bw-types";

import { useDashboardProductApplication } from "@app/hooks";

export const SponsoredCarouselCard = () => {
  const productApply = useDashboardProductApplication(DASHBOARD_ANALYTICS_ORIGINS.SPONSORED_PLACEMENT);

  const navigate = useCallback(
    ({ carouselItem }: CtaPressHandlerParams) => {
      productApply({
        ...carouselItem,
        id: carouselItem.productReferenceId,
        productVerticalId: carouselItem.productVerticalId as unknown as Nullable<PRODUCT_VERTICAL_GUID>,
        impressionedProductLink: carouselItem.ctaLink,
      });
    },
    [productApply],
  );

  return <SponsoredCarousel transformCtaLink={() => ""} onPress={navigate} />;
};
