export interface Plan {
  id?: string;
  active?: boolean;
  billingScheme?: unknown;
  nickName?: string;
  amount?: number;
  currency?: string;
  billingType?: string;
  recurring: {
    aggregateUsage?: number;
    interval?: number;
    intervalCount?: number;
    usageType?: number;
  };
  taxBehavior?: number;
  tierMode?: number;
  productId?: string;
  dateCreated?: string;
  dateModified?: string;
}

// subscription back end does not return the full plan sometimes
export interface SimplifiedPlan {
  id: string;
  amount: number;
  productId: string;
}

export interface SubscriptionProduct {
  id?: string;
  isActive?: boolean;
  name?: string;
  description?: string;
  images?: string[];
  statementDescription?: string;
  url?: string;
  taxCode?: string;
  unitLabel?: string;
  defaultPrice?: string;
  dateCreated?: string;
  dateModified?: string;
  plans: Plan[];
}

export enum SUBSCRIPTION_STATUS {
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  TRIALING = "trialing",
  ACTIVE = "active",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
}

export enum SUBSCRIPTION_STATUS_QUERYABLE {
  ALL = "all",
  ENDED = "ended",
}

export type LIST_SUBSCRIPTION_STATUS = SUBSCRIPTION_STATUS | SUBSCRIPTION_STATUS_QUERYABLE;

export interface Subscription {
  id?: string;
  status?: SUBSCRIPTION_STATUS;
  dateEffective?: string;
  dateCancelled?: string;
  dateNextCharge?: string;
  cancelAtPeriodEnd?: boolean;
  currentPeriodEnd?: string;
  plans: SimplifiedPlan[];
}

export interface CreateSubscriptionResponse {
  sessionId: string;
  url: string;
}
