import { GEMSTONE_APP_ROUTES, PRODUCT_VERTICAL_GUID } from "@bwll/bw-types";

export const ROUTES = {
  ROOT: "/",
} as const satisfies Record<string, string>;

export const RENT_ADVANTAGE_ROUTES = {
  ROOT: "/rent-advantage",
  PAST_PAYMENTS: "/rent-advantage/historical-reporting",
} as const satisfies Record<string, string>;

export const MARKETPLACE_ROUTES = {
  ROOT: "/marketplace",
  CREDIT_CARDS: "/marketplace/credit-cards",
  CREDIT_CARDS_CATEGORY: "/marketplace/credit-cards/category/:category",
  CREDIT_CARDS_DETAILS: "/marketplace/credit-cards/product/:productId",
  LOANS: "/marketplace/loans",
  CAR_LOANS: "/marketplace/car-loans",
  MORTGAGES: "/marketplace/mortgages",
  BANK_ACCOUNTS: "/marketplace/bank-accounts",
  INSURANCES: "/marketplace/insurances",
  BUILD_CREDIT: "/marketplace/build-credit",
  INVESTMENTS: "/marketplace/investments",
} as const satisfies Record<string, string>;

export const MARKETPLACE_QUICK_APPLY_ROUTES = {
  [PRODUCT_VERTICAL_GUID.BANK_ACCOUNT]: MARKETPLACE_ROUTES.BANK_ACCOUNTS,
  [PRODUCT_VERTICAL_GUID.CAR_LOAN]: MARKETPLACE_ROUTES.CAR_LOANS,
  [PRODUCT_VERTICAL_GUID.CREDIT_CARD]: MARKETPLACE_ROUTES.CREDIT_CARDS,
  [PRODUCT_VERTICAL_GUID.LOAN]: MARKETPLACE_ROUTES.LOANS,
  [PRODUCT_VERTICAL_GUID.INSURANCE]: MARKETPLACE_ROUTES.INSURANCES,
  [PRODUCT_VERTICAL_GUID.INVESTMENT]: MARKETPLACE_ROUTES.INVESTMENTS,
  [PRODUCT_VERTICAL_GUID.MORTGAGE]: MARKETPLACE_ROUTES.MORTGAGES,
};

export type MarketplaceRoutes = (typeof MARKETPLACE_ROUTES)[keyof typeof MARKETPLACE_ROUTES];

export const MARKETPLACE_ROUTES_TO_GEMSTONE_APP_ROUTES_MAPPING = {
  [MARKETPLACE_ROUTES.ROOT]: GEMSTONE_APP_ROUTES.CREDIT_CARDS,
  [MARKETPLACE_ROUTES.CREDIT_CARDS_CATEGORY]: GEMSTONE_APP_ROUTES.CREDIT_CARDS_CATEGORY,
  [MARKETPLACE_ROUTES.CREDIT_CARDS_DETAILS]: GEMSTONE_APP_ROUTES.CREDIT_CARDS_DETAILS,
  [MARKETPLACE_ROUTES.CREDIT_CARDS]: GEMSTONE_APP_ROUTES.CREDIT_CARDS,
  [MARKETPLACE_ROUTES.LOANS]: GEMSTONE_APP_ROUTES.LOANS,
  [MARKETPLACE_ROUTES.CAR_LOANS]: GEMSTONE_APP_ROUTES.CAR_LOANS,
  [MARKETPLACE_ROUTES.MORTGAGES]: GEMSTONE_APP_ROUTES.MORTGAGES,
  [MARKETPLACE_ROUTES.BANK_ACCOUNTS]: GEMSTONE_APP_ROUTES.BANK_ACCOUNTS,
  [MARKETPLACE_ROUTES.INSURANCES]: GEMSTONE_APP_ROUTES.INSURANCE,
  [MARKETPLACE_ROUTES.BUILD_CREDIT]: GEMSTONE_APP_ROUTES.BUILD_CREDIT,
  [MARKETPLACE_ROUTES.INVESTMENTS]: GEMSTONE_APP_ROUTES.INVESTING,
} as const;

export const GEMSTONE_APP_ROUTES_TO_MARKETPLACE_ROUTES_MAPPING = {
  [GEMSTONE_APP_ROUTES.CREDIT_CARDS_CATEGORY]: MARKETPLACE_ROUTES.CREDIT_CARDS_CATEGORY,
  [GEMSTONE_APP_ROUTES.CREDIT_CARDS_DETAILS]: MARKETPLACE_ROUTES.CREDIT_CARDS_DETAILS,
  [GEMSTONE_APP_ROUTES.CREDIT_CARDS]: MARKETPLACE_ROUTES.CREDIT_CARDS,
  [GEMSTONE_APP_ROUTES.LOANS]: MARKETPLACE_ROUTES.LOANS,
  [GEMSTONE_APP_ROUTES.AUTO_LOANS]: MARKETPLACE_ROUTES.CAR_LOANS,
  [GEMSTONE_APP_ROUTES.CAR_LOANS]: MARKETPLACE_ROUTES.CAR_LOANS,
  [GEMSTONE_APP_ROUTES.COMPARE]: MARKETPLACE_ROUTES.ROOT,
  [GEMSTONE_APP_ROUTES.DASHBOARD]: MARKETPLACE_ROUTES.ROOT,
  [GEMSTONE_APP_ROUTES.MORTGAGE_COACH]: MARKETPLACE_ROUTES.ROOT,
  [GEMSTONE_APP_ROUTES.MORTGAGES]: MARKETPLACE_ROUTES.MORTGAGES,
  [GEMSTONE_APP_ROUTES.BANK_ACCOUNTS]: MARKETPLACE_ROUTES.BANK_ACCOUNTS,
  [GEMSTONE_APP_ROUTES.INSURANCE]: MARKETPLACE_ROUTES.INSURANCES,
  [GEMSTONE_APP_ROUTES.BUILD_CREDIT]: MARKETPLACE_ROUTES.BUILD_CREDIT,
  [GEMSTONE_APP_ROUTES.INVESTING]: MARKETPLACE_ROUTES.INVESTMENTS,
  [GEMSTONE_APP_ROUTES.CREDIT_BUILDER]: MARKETPLACE_ROUTES.ROOT,
} as const;

export const VERTICAL_GUID_TO_MARKETPLACE_VERTICAL_NAME = {
  0: "default",
  1: "credit-cards",
  2: "loans",
  3: "insurances",
  4: "mortgages",
  5: "investments",
  6: "bank-accounts",
  7: "build-credit",
} as const;

export const CREDIT_FACTORS_ROUTES = {
  ROOT: "/credit-factors",
} as const satisfies Record<string, string>;
