import { PvPreSelectedProduct } from "../../promotion";
import { PROMO_CARD_TYPE } from "../promotion";

export interface PromoCardsHierarchyResult {
  isEmpty: boolean;

  slot1PromoCardType: number;
  slot2PromoCardType: number;

  preSelectionProduct: PromoCardsHierarchyPreSelectionProduct;
  preSelectionAvailable: boolean;

  genericPromoCard: PromoCardsHierarchyGenericPromoCard;
}

export interface PromoCardsHierarchyPreSelectionProduct {
  cardType?: PROMO_CARD_TYPE;

  partner: string;
  vertical: string;
  amount: number;
  productReferenceId: string;
  productReferenceNumber: number;
  productName: string;
  productImageUrl: string;
  isQuickApply: boolean;
  preSelectedLink: string;
  websiteLink: string;
  likelihoodOfApprovalType: number;
  likelihoodOfApproval: number;
  bannerTitle: string;
  toolTipTitle: string;
  toolTipText: string;
  modalTitle: string;
  modalDescription: string;
  modalClarificationHeader: string;
  modalClarification: string;
  modalStepsHeader: string;
  modalSteps: string;
  modalFooterText: string;
  modalCTAButtonText: string;
  preselectionCardTitle: string;
  preselectionCardDescription: string;
  preselectionCardImageUrl: string;
  preselectionCardBackgroundImageUrl: string;
}

export interface PromoCardsHierarchyGenericPromoCard {
  cardType?: PROMO_CARD_TYPE;
}

export interface PromoCardsHierarchyV2Result {
  promoCardType: CardHierarchyResponse;
  status: PromoCardStatus;
  promosHistory?: string[];
  details?: PromoCardCopy;
}

export interface PromoCardsHierarchyV3Result extends PromoCardsHierarchyV2Result {
  preSelectedProduct?: PvPreSelectedProduct;
}

export interface PromoCardCopy {
  title: string;
  body: string;
  image: string;
  buttonText: string;
  imageName: string;
  background: string;
  productRoute: PromoCardRouteDetails;
  promoCardVersion: string;
}

export interface PromoCardRouteDetails {
  internal: boolean;
  path: string;
}

export enum CardHierarchyResponse {
  CBL2 = 1,
  PRESELECTION = 2,
  PRODUCT_PLACEMENT = 4,
  CONTEXTUAL = 5,
  DEFAULT = 6,
  RENT_REPORTING = 7,
}

export enum PromoCardStatus {
  Eligible = 1,
  NotEligible = 2,
  Subscribed = 3,
  NotApplicable = 4,
}
