export const IMAGE_MAX_WIDTH_MOBILE = 80;
export const IMAGE_MAX_HEIGHT_MOBILE = 126;
export const IMAGE_MAX_WIDTH_DESKTOP = 100;
export const IMAGE_MAX_HEIGHT_DESKTOP = 158;

export const SPONSORED_CAROUSEL_CARD_BANNER_TEST_ID = "sponsored-carousel-card-banner";
export const SPONSORED_CAROUSEL_CARD_SPONSORED_LABEL_TEST_ID = "sponsored-carousel-card-sponsored-label";
export const SPONSORED_CAROUSEL_CARD_BUTTON_TEST_ID = "sponsored-carousel-card-button";
export const SPONSORED_CAROUSEL_CARD_LOA_TEST_ID = "sponsored-carousel-card-loa";
export const SPONSORED_CAROUSEL_CARD_DESCRIPTION_TEST_ID = "sponsored-carousel-card-description";
