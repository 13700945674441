import {
  IUseExperimentsProps,
  STANDARD_TREATMENTS,
  WEB_EXPERIMENT_IDS,
  WEB_TREATMENT_IDS,
} from "@bwll/bw-types";

import { useExperiments } from "../helpers";

export const useDashboardExperiment = ({
  experimentId,
  treatment = STANDARD_TREATMENTS.ON,
  treatments = [],
}: IUseExperimentsProps) => {
  const isIncludedInTrafficAllocation = useExperiments({
    experimentId: WEB_EXPERIMENT_IDS.SSPA_DASHBOARD_TRAFFIC_ALLOCATION,
    treatments: [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_DASHBOARD_TRAFFIC_ALLOCATION].ON],
  });

  const isExperimentEnabled = useExperiments({
    experimentId: experimentId,
    treatment: treatment,
    treatments: treatments,
  });

  return isIncludedInTrafficAllocation && isExperimentEnabled;
};
