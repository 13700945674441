import React from "react";

import { COLORS, spacing } from "@bwll/bw-styles";

import { Icon } from "../../atoms/Icon";
import { Label } from "../../atoms/Typography";
import { ICON_TEST_ID, WRAPPER_TEST_ID } from "./Chip.constants";
import { ChipIconWrapper, ChipWrapper } from "./Chip.styles";
import { ChipProps } from "./Chip.types";

/**
 * Component for showing the Chip. It accepts an optional icon prop that renders a left icon
 *
 * @component
 * @example
 * return (
 *   <Chip
 *   backgroundColor="#623636"
 *   onPress={() => {}}
 *   text="This is a chip text"
 *   textColor="#ffffff"
 * />
 * )
 */
export const Chip = ({ icon, text, backgroundColor, onPress, textColor, testID }: ChipProps) => {
  return (
    <ChipWrapper
      testID={testID ?? WRAPPER_TEST_ID}
      backgroundColor={backgroundColor}
      onPress={onPress}
      disabled={!!onPress}
    >
      {icon && (
        <ChipIconWrapper testID={ICON_TEST_ID}>
          <Icon icon={icon} width={spacing.xs} height={spacing.xs} color={COLORS.NEUTRAL.WARM["800"]} />
        </ChipIconWrapper>
      )}
      <Label color={textColor} testID={testID}>
        {text}
      </Label>
    </ChipWrapper>
  );
};
