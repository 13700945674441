export enum ONBOARDING_STEP_COMPLETED {
  RENTAL_ADDRESS_V2 = "RentalAddressV2",
  LEASE_TERMS_V2 = "LeaseTermsV2",
  RENT_METHOD_SELECT_MANUAL_V2 = "RentMethodSelectManualV2",
  RENT_METHOD_SELECT_CONNECT_BANK_V2 = "RentMethodSelectConnectBankV2",
  BANK_CONNECT_V2 = "BankConnectV2",
  SELECT_ACCOUNT_V2 = "SelectAccountV2",
  RENT_TRANSACTION_SELECT_V2 = "RentTransactionSelectV2",
  MANUAL_RENT_UPLOAD_V2 = "ManualRentUploadV2",
  MANUAL_RENT_DETAILS_V2 = "ManualRentDetailsV2",
  CHECKOUT_SUMMARY_V2 = "CheckoutSummaryV2",
  CHECKOUT_CONFIRMATION_V2 = "CheckoutConfirmationV2",
}

export const enum RA_EXPERIMENT_FLOW {
  V2 = "v2.0",
}
