export const IMAGE_MAX_WIDTH_MOBILE = 80;
export const IMAGE_MAX_HEIGHT_MOBILE = 50;
export const IMAGE_MAX_WIDTH_DESKTOP = 100;
export const IMAGE_MAX_HEIGHT_DESKTOP = 63;

export const BADGE_TEST_ID = "offer-card-template-badge";
export const HEADER_TEST_ID = "offer-card-template-header";
export const TITLE_TEST_ID = "offer-card-template-title";
export const BODY_TEST_ID = "offer-card-template-body";
export const PRIMARY_CTA_TEST_ID = "offer-card-template-primary-cta";
export const SECONDARY_CTA_TEST_ID = "offer-card-template-secondary-cta";
