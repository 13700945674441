import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, ScrollView } from "react-native";

import { Body2, Icon, Link1, Modal, Spacer, Subheading1 } from "@bwll/bw-components/next";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";

import {
  GRAPH_SCORE_TOOLTIP_LINK_TEST_ID,
  GRAPH_SCORE_TOOLTIP_TITLE_TEST_ID,
  GRAPH_SCORE_TOOLTIP_TRIGGER_TEST_ID,
} from "./CreditScoreGraph.constants";
import { CreditScoreTooltipProps } from "./CreditScoreGraph.types";
import * as Styled from "./CreditScoreTooltip.styles";

export const CreditScoreTooltip = ({
  scoreCurrentPoints,
  scoreRange,
  onTooltipPress,
  onTooltipLinkPress,
}: CreditScoreTooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const i18next = useTranslation();

  const open = useCallback(() => {
    setIsVisible(true);
    onTooltipPress?.();
  }, [onTooltipPress]);

  const close = useCallback(() => {
    setIsVisible(false);
  }, []);

  const handleLinkPress = useCallback(() => {
    close();
    onTooltipLinkPress?.();
  }, [close, onTooltipLinkPress]);

  return (
    <>
      <Styled.TooltipTriggerContainer testID={GRAPH_SCORE_TOOLTIP_TRIGGER_TEST_ID} onPress={open}>
        <Styled.TooltipTriggerText>!</Styled.TooltipTriggerText>
      </Styled.TooltipTriggerContainer>
      <Modal onDismiss={close} onRequestClose={close} transparent animationType="none" visible={isVisible}>
        <ScrollView bounces={false} contentContainerStyle={Styled.contentContainerStyle}>
          <Styled.ModalContainer>
            <Styled.ModalContent>
              <Styled.ModalHeader>
                <Subheading1>{i18next.t("dashboard:cards:scoreTracker:tooltip:title")}</Subheading1>
                <Pressable onPress={close}>
                  <Icon size={fontSize.xl} icon="close" color={COLORS.PRIMARY.DEFAULT} />
                </Pressable>
              </Styled.ModalHeader>
              <Body2 testID={GRAPH_SCORE_TOOLTIP_TITLE_TEST_ID}>
                {i18next.t(
                  `dashboard:cards:scoreTracker:tooltip:body:${
                    scoreCurrentPoints === 0 ? "zero" : scoreRange
                  }`,
                )}
              </Body2>
              <Spacer height={spacing.xs} />
              <Subheading1>{i18next.t("dashboard:cards:scoreTracker:tooltip:footNote:title")}</Subheading1>
              <Spacer height={spacing.xs} />
              <Body2>{i18next.t("dashboard:cards:scoreTracker:tooltip:footNote:content")}</Body2>
              <Link1 onPress={handleLinkPress} testID={GRAPH_SCORE_TOOLTIP_LINK_TEST_ID}>
                {i18next.t("dashboard:cards:scoreTracker:tooltip:footNote:link")}
              </Link1>
            </Styled.ModalContent>
          </Styled.ModalContainer>
        </ScrollView>
      </Modal>
    </>
  );
};
