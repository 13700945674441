import { CreditScoreRangeType } from "@bwll/bw-types";

import { SCORE_LEVEL_RANGES } from "./CreditScoreGraph.constants";

export const identifyScoreRange = (score: number): CreditScoreRangeType => {
  /** Cover cases when the score is less than 300 */
  if (score < SCORE_LEVEL_RANGES[0].min) {
    return SCORE_LEVEL_RANGES[0].level;
  }

  /** Cover cases when the score is greater than 850 */
  if (score > SCORE_LEVEL_RANGES[4].max) {
    return SCORE_LEVEL_RANGES[4].level;
  }

  /**
   * Cast type since the `.find` method may return `undefined` (according to the ECMAScript spec)
   * but we cover all edge cases in 2 previous conditions
   */
  return SCORE_LEVEL_RANGES.find((range) => score >= range.min && score <= range.max)
    ?.level as CreditScoreRangeType;
};
