import { useLayoutEffect } from "react";

import { isWeb } from "../../constants";

export const useWebStyles = (id: string) => {
  useLayoutEffect(() => {
    /**
     * This fixes the react-native-web issue on the web when the scrollview (flatlist)
     * lives insode another scrollview: https://github.com/necolas/react-native-web/issues/1042
     *
     * Since the react-native-web overrides the `touchAction` prop on styles level,
     * we need to mutate the DOM element style attribute intentionally.
     */
    if (isWeb) {
      const carousel = document.getElementById(id);

      if (carousel) {
        carousel.style.touchAction = "auto";
      }
    }
  }, [id]);
};
