import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";

import { BUTTON_TYPE, Button, Expandable, Icon, Markdown } from "@bwll/bw-components/next";
import { isWeb } from "@bwll/bw-components/next/constants";
import { useBankAccountDetailsExperiment, useBreakpoints } from "@bwll/bw-hooks";
import { COLORS, fontSize } from "@bwll/bw-styles";
import { getPromoBannerText, isOfferExpired, makeTestIdGenerator } from "@bwll/bw-utils";

import { OfferChecklist } from "../OfferChecklist";
import { OfferEndDate } from "../OfferEndDate";
import { ProductBanner } from "../ProductBanner";
import { Rate, RatesTable } from "../RatesTable";
import { BANK_ACCOUNT_PRODUCT_DETAILS_TEST_IDS as TEST_IDS } from "./BankAccountProductDetails.constants";
import * as Styled from "./BankAccountProductDetails.styles";
import type { BankAccountProductDetailsProps } from "./BankAccountProductDetails.types";

export const BankAccountProductDetails = ({
  product,
  onApplyPress,
  onLegalDetailsToggle,
  onExternalLinkPress,
  HeaderComponent,
  contentContainerStyle,
}: BankAccountProductDetailsProps) => {
  const i18next = useTranslation();

  const {
    id,
    productName,
    companyIcon,
    eTransfer,
    interestRateText,
    learnMoreMarkdown,
    legalMarkdown,
    offerEndDate,
    offerChecklist,
    offerSummary,
    minimumBalanceText,
    monthlyFeeMarkdown,
    transactionMarkdown,
  } = product;

  const generateTestId = useMemo(() => makeTestIdGenerator(id), [id]);
  const rates = useMemo<Rate[]>(
    () => [
      {
        key: "interestRateText",
        labelLocaleKey: "marketplace:bankAccounts:rates:interestRate",
        type: "text",
        value: interestRateText ?? " ",
      },
      {
        key: "monthlyFeeHtml",
        labelLocaleKey: "marketplace:bankAccounts:rates:monthlyFee",
        type: "text",
        value: monthlyFeeMarkdown ?? " ",
        ...(minimumBalanceText && { asteriskIcon: "no_fee" }),
      },
      {
        key: "transactionMarkdown",
        labelLocaleKey: "marketplace:bankAccounts:rates:transactions",
        type: "text",
        value: transactionMarkdown ?? " ",
      },
      {
        key: "eTransfer",
        labelLocaleKey: "marketplace:bankAccounts:rates:eTransfer",
        type: "text",
        value: eTransfer ?? " ",
      },
    ],
    [eTransfer, interestRateText, minimumBalanceText, monthlyFeeMarkdown, transactionMarkdown],
  );

  const { isDesktop } = useBreakpoints();

  const offerExpired = isOfferExpired(offerEndDate);
  const promoBannerText = getPromoBannerText(product);

  // Dynamic ordering based on experiment (GMS-13679)
  const { hideOfferChecklistAndMinBalance, viewProductCta } = useBankAccountDetailsExperiment();

  const applyButtonTitle = viewProductCta
    ? i18next.t("productCatalog:all:buttons:viewProduct")
    : i18next.t("productCatalog:all:buttons:apply");
  const learnMoreHeading = i18next.t("productCatalog:creditCards:details:title");

  return (
    <>
      <ScrollView bounces={false} scrollEnabled={!isDesktop} contentContainerStyle={contentContainerStyle}>
        <Styled.ProductData isDesktop={isDesktop}>
          {isWeb && HeaderComponent}
          <Styled.LeftContainer isDesktop={isDesktop}>
            {isDesktop && promoBannerText && !offerExpired && (
              <ProductBanner tall={true} testID={generateTestId(TEST_IDS.PRODUCT_BANNER_DESKTOP)}>
                {promoBannerText}
              </ProductBanner>
            )}
            <Styled.TitleContainer isDesktop={isDesktop}>
              {companyIcon && (
                <Styled.TitleCompanyIcon
                  testID={generateTestId(TEST_IDS.COMPANY_ICON)}
                  source={{ uri: companyIcon }}
                />
              )}
              <Styled.Heading testID={generateTestId(TEST_IDS.PRODUCT_NAME)}>{productName}</Styled.Heading>
            </Styled.TitleContainer>
            <Styled.RatesAndFeesContainer>
              <RatesTable rates={rates} />
            </Styled.RatesAndFeesContainer>
            {minimumBalanceText && !hideOfferChecklistAndMinBalance && (
              <Styled.NoFeeContainer>
                <Icon
                  icon="no_fee"
                  size={isDesktop ? fontSize.xxl : fontSize.xl}
                  color={COLORS.PRIMARY["400"]}
                />
                <Styled.NoFeeText isDesktop={isDesktop}>{minimumBalanceText}</Styled.NoFeeText>
              </Styled.NoFeeContainer>
            )}
            {isDesktop && (
              <Styled.ApplyContainer isDesktop={isDesktop}>
                {offerEndDate && (
                  <OfferEndDate testID={generateTestId(TEST_IDS.OFFER_END_DATE)} date={offerEndDate} />
                )}
                <Button
                  title={applyButtonTitle}
                  iconName="new_tab"
                  onPress={onApplyPress}
                  type={BUTTON_TYPE.PRIMARY}
                  testID={generateTestId(TEST_IDS.APPLY_BUTTON_DESKTOP)}
                />
              </Styled.ApplyContainer>
            )}
          </Styled.LeftContainer>
          <Styled.RightContainer isDesktop={isDesktop}>
            {offerSummary && !hideOfferChecklistAndMinBalance && (
              <OfferChecklist
                summary={offerSummary}
                checklist={offerChecklist}
                testID={TEST_IDS.OFFER_CHECKLIST}
              />
            )}
            {
              <Styled.LearnMoreContainer style={Styled.customLearnMoreContainerStyle}>
                <Styled.Heading>{learnMoreHeading}</Styled.Heading>
                <Markdown
                  withSuperscript
                  color={COLORS.NEUTRAL.WARM["800"]}
                  onLinkPress={onExternalLinkPress}
                >
                  {learnMoreMarkdown ?? ""}
                </Markdown>
              </Styled.LearnMoreContainer>
            }
            {legalMarkdown && (
              <Expandable
                testID={generateTestId(TEST_IDS.LEGAL)}
                title={i18next.t("productCatalog:creditCards:details:legalDetails")}
                onExpandableToggled={onLegalDetailsToggle}
              >
                <Markdown
                  withSuperscript
                  color={COLORS.NEUTRAL.WARM["800"]}
                  onLinkPress={onExternalLinkPress}
                >
                  {legalMarkdown}
                </Markdown>
              </Expandable>
            )}
          </Styled.RightContainer>
        </Styled.ProductData>
      </ScrollView>
      {!isDesktop && (
        <Styled.Footer>
          <Styled.ApplyContainer isDesktop={isDesktop}>
            {offerEndDate && <OfferEndDate date={offerEndDate} />}
            <Styled.ButtonContainer>
              <Button
                title={applyButtonTitle}
                iconName="new_tab"
                onPress={onApplyPress}
                type={BUTTON_TYPE.PRIMARY}
                testID={generateTestId(TEST_IDS.APPLY_BUTTON_MOBILE)}
              />
            </Styled.ButtonContainer>
          </Styled.ApplyContainer>
        </Styled.Footer>
      )}
    </>
  );
};
