import { useMemo } from "react";

import {
  AnalyticsEventProps,
  IMPRESSION_EVENT_NAMES,
  ON_LINK_CLICK_EVENT_NAMES,
  TFeatureAnalyticsAttributes,
} from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES } from "@bwll/bw-types";

export const usePromoCardTrackEvents = ({ trackEvent, commonAttributes }: AnalyticsEventProps) => {
  return useMemo(
    () => ({
      promoCardViewed: <T>(eventAttributes: TFeatureAnalyticsAttributes<T>) => {
        const customAttributes = {
          ...commonAttributes,
          ...eventAttributes,
          [ANALYTICS_ATTRIBUTES.IS_REDESIGNED_DASHBOARD]: true,
        };

        trackEvent(IMPRESSION_EVENT_NAMES.PROMO_CARD_VIEWED, customAttributes);
      },
      promoCardClicked: <T>(eventAttributes: TFeatureAnalyticsAttributes<T>) => {
        const customAttributes = {
          ...commonAttributes,
          ...eventAttributes,
          [ANALYTICS_ATTRIBUTES.IS_REDESIGNED_DASHBOARD]: true,
        };

        trackEvent(ON_LINK_CLICK_EVENT_NAMES.PROMO_CARD_CLICKED, customAttributes);
      },
    }),
    [trackEvent, commonAttributes],
  );
};
