import { useTranslation } from "react-i18next";

import { COLORS, toRGBAString } from "@bwll/bw-styles";
import { CardHierarchyResponse, PromoCardsHierarchyV3Result } from "@bwll/bw-types";

import { RA_PROMO_CARD_BACKGROUND_COLOR } from "./HierarchyPromoCard.constants";
import { HierarchyPromoCardData } from "./HierarchyPromoCard.types";

export const usePrepareHierarchyPromoCardData = (
  isMobile: boolean,
  data: PromoCardsHierarchyV3Result | undefined | null,
): HierarchyPromoCardData | undefined => {
  const i18next = useTranslation();

  if (!data) {
    return undefined;
  }

  if (data.promoCardType === CardHierarchyResponse.PRESELECTION && data?.preSelectedProduct) {
    const { preSelectedProduct } = data;

    // In the FE is hard-coded "View Offer", and mobile uses modalCTAButtonText property.
    const cardButtonText = isMobile
      ? preSelectedProduct.modalCTAButtonText
      : i18next.t("dashboard:hierarchyPromoCard:buttonText");

    return {
      cardButtonText,
      cardTitle: preSelectedProduct.preselectionCardTitle,
      cardBody: preSelectedProduct.preselectionCardDescription,
      cardImageUri: preSelectedProduct.preselectionCardImageUrl,
      cardImageBackgroundUri: preSelectedProduct.preselectionCardBackgroundImageUrl,
      cardImageAlt: preSelectedProduct?.partner,
    };
  }

  if (!data?.details) {
    //If there's no details for the promo card, there's nothing to show to the user.
    return undefined;
  }

  const { details } = data;

  const cardDetails = {
    cardButtonText: details?.buttonText,
    cardTitle: details?.title,
    cardBody: details?.body,
    cardImageUri: details?.image,
    imageName: details?.imageName,
  };

  if (data.promoCardType === CardHierarchyResponse.CBL2) {
    return {
      ...cardDetails,
      cardImageBackgroundColor: toRGBAString(COLORS.PRIMARY["050"], 0.5),
    };
  }

  if (data.promoCardType === CardHierarchyResponse.RENT_REPORTING) {
    return {
      ...cardDetails,
      cardImageBackgroundColor: RA_PROMO_CARD_BACKGROUND_COLOR,
    };
  }

  return undefined;
};
