import { AnalyticsProvider } from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES } from "@bwll/bw-types";

import { AnalyticsProviderWrapperProps } from "./AnalyticsProviderWrapper.types";

import { useAnalyticsEvent } from "@app/hooks/useAnalyticsEvent";

const commonAttributes = {
  [ANALYTICS_ATTRIBUTES.IS_REDESIGNED_DASHBOARD]: true,
};

export const AnalyticsProviderWrapper = ({ children }: AnalyticsProviderWrapperProps) => {
  const { trackEvent } = useAnalyticsEvent();

  return (
    <AnalyticsProvider trackEvent={trackEvent} commonAttributes={commonAttributes}>
      {children}
    </AnalyticsProvider>
  );
};
