import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ElementTracker,
  PromoCardTemplate,
  PromoCardTemplateCtaContainer,
  PromoCardTemplateCtaText,
} from "@bwll/bw-components/next";
import { useAnalyticsContext, usePlatformContext } from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES, ANALYTICS_VALUES, PLATFORM } from "@bwll/bw-types";
import { uuid } from "@bwll/bw-utils";

import {
  PRODUCT_RECOMMENDATIONS_PROMO_CARD_CTA_BUTTON_TEST_ID,
  PRODUCT_RECOMMENDATIONS_PROMO_CARD_TEST_ID,
} from "./ProductRecommendationsPromoCard.constants";
import { ProductRecommendationsPromoCardProps } from "./ProductRecommendationsPromoCard.types";

const getPlatformSpecificAttributes = (isMobile: boolean, impressionId: string) => {
  if (isMobile) {
    return {
      [ANALYTICS_ATTRIBUTES.PROMO_CARD_NAME]: ANALYTICS_VALUES.IMPRESSION_ILLUSTRATIVE_CARD,
      [ANALYTICS_ATTRIBUTES.PROMO_CATEGORY]: ANALYTICS_VALUES.CATEGORY_PRODUCT_CATALOG_MOBILE,
    };
  }

  return {
    [ANALYTICS_ATTRIBUTES.PROMO_CATEGORY]: ANALYTICS_VALUES.CATEGORY_PRODUCT_CATALOG,
    [ANALYTICS_ATTRIBUTES.RANKING]: ANALYTICS_VALUES.PROMO_CARD_RANKING,
    [ANALYTICS_ATTRIBUTES.PROMO_IMPRESSION_ID]: impressionId,
  };
};

const calculateAnalyticsAttributes = (
  isMobile: boolean,
  firstName: string | undefined,
  buttonText: string,
  body: string,
  title: string,
  destinationUrl: string,
  image: string,
) => {
  const impressionId = uuid();
  const commonAttributes = {
    ...getPlatformSpecificAttributes(isMobile, impressionId),
    [ANALYTICS_ATTRIBUTES.BUTTON_TEXT]: buttonText,
    [ANALYTICS_ATTRIBUTES.PROMO_TEXT_BODY]: body.replace(`${firstName}, `, ""),
    [ANALYTICS_ATTRIBUTES.PROMO_TEXT_HEADER]: title,
    [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: destinationUrl,
    [ANALYTICS_ATTRIBUTES.IMAGE]: image,
    [ANALYTICS_ATTRIBUTES.CARD_LOCATION]: ANALYTICS_VALUES.DASHBOARD_LOCATION,
    [ANALYTICS_ATTRIBUTES.CARD_POSITION]: ANALYTICS_VALUES.FEED_POSITION,
    [ANALYTICS_ATTRIBUTES._IMPRESSION_ID]: impressionId,
  };
  return isMobile
    ? {
        clicked: {
          ...commonAttributes,
          [ANALYTICS_ATTRIBUTES.CLICK_ID]: ANALYTICS_VALUES.IMPRESSION_ILLUSTRATIVE_CARD,
        },
        viewed: {
          ...commonAttributes,
          [ANALYTICS_ATTRIBUTES.IMPRESSION_ID]: ANALYTICS_VALUES.IMPRESSION_ILLUSTRATIVE_CARD,
        },
      }
    : {
        clicked: {
          ...commonAttributes,
        },
        viewed: {
          ...commonAttributes,
        },
      };
};

export const ProductRecommendationsPromoCard = ({
  firstName,
  enableAnalyticsEvent = true,
  redirectAction,
  analyticsAttributes,
}: ProductRecommendationsPromoCardProps) => {
  const { platform } = usePlatformContext();
  const isMobile = platform === PLATFORM.MOBILE_APP;
  const i18next = useTranslation();

  const body = i18next.t(`dashboard:productRecommendationsPromoCard:body`, { username: firstName });
  const title = i18next.t(`dashboard:productRecommendationsPromoCard:title`);
  const buttonText = i18next.t("dashboard:productRecommendationsPromoCard.buttonText");
  const destinationUrl = analyticsAttributes?.destinationUrl ?? "";
  const image = analyticsAttributes?.image ?? "";

  const componentAnalyticsAttributes = useMemo(
    () => calculateAnalyticsAttributes(isMobile, firstName, buttonText, body, title, destinationUrl, image),
    [isMobile, buttonText, body, firstName, title, destinationUrl, image],
  );

  const { promoCardViewed, promoCardClicked } = useAnalyticsContext();

  const onPress = useCallback(() => {
    promoCardClicked?.(componentAnalyticsAttributes.clicked);
    redirectAction();
  }, [componentAnalyticsAttributes.clicked, promoCardClicked, redirectAction]);

  return (
    <ElementTracker
      shouldStartTracking={enableAnalyticsEvent}
      trackingHandler={() => promoCardViewed?.(componentAnalyticsAttributes.viewed)}
    >
      <PromoCardTemplate
        variant={"card"}
        icon={"sparkle"}
        body={body}
        title={title}
        cta={
          <PromoCardTemplateCtaContainer
            onPress={onPress}
            isSnackbar={false}
            testID={PRODUCT_RECOMMENDATIONS_PROMO_CARD_CTA_BUTTON_TEST_ID}
          >
            <PromoCardTemplateCtaText platform={platform}>{buttonText}</PromoCardTemplateCtaText>
          </PromoCardTemplateCtaContainer>
        }
        testID={PRODUCT_RECOMMENDATIONS_PROMO_CARD_TEST_ID}
      />
    </ElementTracker>
  );
};
