import { Skeleton, Spacer } from "@bwll/bw-components/next";
import { useBreakpoints, useCreditAccountsDetails, useGetPrimePromoCards } from "@bwll/bw-hooks";
import { PrimePromoCardsParam } from "@bwll/bw-types";

import { TopPicksPromoCardsV2 as TopPicksPromoCardsV2UI } from "./TopPicksPromoCardsV2.component";
import {
  TOP_PICKS_CARD_HEIGHT,
  TOP_PICKS_CLU_SKELETON_TEST_ID,
  TOP_PICKS_CREDIT_CARDS_BANK_ACCOUNTS_V2_SKELETON_TEST_ID,
  TOP_PICKS_CREDIT_CARDS_PROMO_CARD_V2_SKELETON_TEST_ID,
  TOP_PICKS_MOBILE_CARD_HEIGHT,
  TOP_PICKS_PROMO_CARDS_V2_TEST_ID,
} from "./TopPicksPromoCardsV2.constants";
import { TopPicksPromoCardsV2ContainerProps } from "./TopPicksPromoCardsV2.types";
import { useFormatTopPicksV2PromoCards } from "./useFormatTopPicksV2PromoCards";

export const TopPicksPromoCardsV2Container = (props: TopPicksPromoCardsV2ContainerProps) => {
  const { isMobile } = useBreakpoints();
  const getFormatedPromoCards = useFormatTopPicksV2PromoCards(props);
  const promoCardTypes = [
    PrimePromoCardsParam.TopPicks,
    PrimePromoCardsParam.CompareBankAccounts,
    PrimePromoCardsParam.DashboardLTOs,
    PrimePromoCardsParam.TrendingOffers,
  ];
  const { data, isLoading } = useGetPrimePromoCards(promoCardTypes, {
    enabled: props.enabled,
  });
  const { data: accountsData, isLoading: isAccountsLoading } = useCreditAccountsDetails();

  if (isLoading || isAccountsLoading) {
    return (
      <>
        <Spacer height={50} />
        <Skeleton
          testID={TOP_PICKS_CREDIT_CARDS_PROMO_CARD_V2_SKELETON_TEST_ID}
          variant="rounded"
          width="100%"
          height={isMobile ? TOP_PICKS_MOBILE_CARD_HEIGHT : TOP_PICKS_CARD_HEIGHT}
        />
        <Spacer height={16} />
        <Skeleton
          testID={TOP_PICKS_CREDIT_CARDS_BANK_ACCOUNTS_V2_SKELETON_TEST_ID}
          variant="rounded"
          width="100%"
          height={isMobile ? TOP_PICKS_MOBILE_CARD_HEIGHT : TOP_PICKS_CARD_HEIGHT}
        />
        {isAccountsLoading && (
          <>
            <Spacer height={16} />
            <Skeleton
              testID={TOP_PICKS_CLU_SKELETON_TEST_ID}
              variant="rounded"
              width="100%"
              height={isMobile ? TOP_PICKS_MOBILE_CARD_HEIGHT : TOP_PICKS_CARD_HEIGHT}
            />
          </>
        )}
      </>
    );
  }
  /** Empty response */
  if (!isLoading && !data) {
    return null;
  }

  const validPromos = [
    ...(data?.topPicksPromoCard ? [data.topPicksPromoCard] : []),
    ...(data?.compareBankAccountsPromoCard ? [data.compareBankAccountsPromoCard] : []),
  ];

  const promoCards = {
    promoCards: getFormatedPromoCards(validPromos, accountsData),
    testID: TOP_PICKS_PROMO_CARDS_V2_TEST_ID,
  };

  return <TopPicksPromoCardsV2UI {...promoCards} />;
};
