import styled from "@emotion/native";

import { Body1 } from "@bwll/bw-components/next";
import { COLORS, borderRadius, fontSize, fontWeights, letterSpacing, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.View`
  background: ${COLORS.NEUTRAL.COOL["050"]};
  padding: ${styledValue(spacing.m)};

  border-bottom-left-radius: ${styledValue(borderRadius.s)};
  border-bottom-right-radius: ${styledValue(borderRadius.s)};
`;

export const Heading = styled(Body1)`
  font-weight: ${fontWeights.bold};
  font-size: ${styledValue(fontSize.m)};
  letter-spacing: ${letterSpacing.xs};
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;
