import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { HighlightTile, Spacer } from "@bwll/bw-components/next";
import { HighlightTileProps } from "@bwll/bw-components/next/molecules/HighlightTile/HighlightTile.types";
import { spacing } from "@bwll/bw-styles";

import { rewardsIcons } from "./Rewards.contstants";
import { RewardsProps } from "./Rewards.types";

export const Rewards = ({
  perksDescriptionMarkdown,
  rewardsCategoryDescriptionMarkdown,
  rewardsCategoryMarkdown,
  rewardsCategoryType,
  signupBonusDescriptionMarkdown,
  signupBonusMarkdown,
}: RewardsProps) => {
  const i18next = useTranslation();
  const perksTitle = i18next.t("productCatalog:creditCards:tiles:perksTitle");

  const highlightTiles: HighlightTileProps[] = useMemo(() => {
    const tiles: HighlightTileProps[] = [];

    if (rewardsCategoryMarkdown && rewardsCategoryDescriptionMarkdown && rewardsCategoryType) {
      const icons = rewardsIcons[rewardsCategoryType] ?? rewardsIcons.Cashback;
      tiles.push({
        icon: icons.icon,
        title: rewardsCategoryMarkdown,
        content: rewardsCategoryDescriptionMarkdown,
      });
    }

    if (signupBonusMarkdown && signupBonusDescriptionMarkdown) {
      tiles.push({
        icon: "member_plus",
        title: signupBonusMarkdown,
        content: signupBonusDescriptionMarkdown,
      });
    }

    if (perksDescriptionMarkdown) {
      tiles.push({
        icon: "2hearts",
        title: perksTitle,
        content: perksDescriptionMarkdown,
      });
    }

    return tiles;
  }, [
    perksTitle,
    rewardsCategoryMarkdown,
    rewardsCategoryDescriptionMarkdown,
    rewardsCategoryType,
    signupBonusMarkdown,
    signupBonusDescriptionMarkdown,
    perksDescriptionMarkdown,
  ]);

  return highlightTiles.length > 0 ? (
    <>
      {highlightTiles.map((tile, index) => (
        <React.Fragment key={tile.title}>
          <HighlightTile key={tile.title} {...tile} />
          {index !== highlightTiles.length - 1 && <Spacer height={spacing.xxs} />}
        </React.Fragment>
      ))}
    </>
  ) : null;
};
