import styled from "@emotion/native";

import { Body1 } from "@bwll/bw-components/next";
import { COLORS, borderRadius, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const CenteredView = styled.SafeAreaView`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
`;

export const ModalContent = styled.View`
  border-radius: ${styledValue(borderRadius.s)};
  background-color: ${COLORS.WHITE};
  width: 330px;
  padding: ${styledValue(spacing.m)};
  padding-bottom: ${styledValue(spacing.xs)};
`;

export const ModalText = styled(Body1)`
  text-align: center;
`;

export const ButtonGroup = styled.View`
  padding-top: ${styledValue(spacing.xs)};
  display: flex;
  flex-direction: row;
  width: 50%;
`;
