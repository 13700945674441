import React, { useState } from "react";

import { useBreakpoints } from "@bwll/bw-hooks";

import {
  FullWidthContainer,
  MainContentContainer,
  MainContentFullWidthContainer,
  SideContentContainer,
} from "./DashboardHome.style";
import {
  CreditCoachUpdates,
  CreditScoreCard,
  HierarchyPromoCard,
  LimitedTimeOfferCarouselCard,
  PopupCard,
  ProductRecommendationsPromoCard,
  RAWidgetCard,
  SnackbarCard,
  SponsoredCarouselCard,
  TopPicksPromoCards,
  TrendingOffersCard,
} from "./components";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";
import { gemstoneNavigate } from "@app/helpers";
import { useMemberRole } from "@app/hooks";

export const DashboardHome = () => {
  const { isPreMember } = useMemberRole();

  const { isMobile } = useBreakpoints();
  const [shouldEmitHierarchyViewEvent, setShouldEmitHierarchyViewEvent] = useState(true);

  if (isPreMember) {
    // If the user is a Pre-Member and they try to access the new dashboard, they will be
    // redirected to the Pre-Member dashboard (angular): https://app.dev.bwll.net/#/signup/dashboard
    gemstoneNavigate(`${ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL}/#/signup/dashboard`);

    return null;
  }

  return (
    <FullWidthContainer>
      <PopupCard />
      <FullWidthContainer>
        <SnackbarCard />
      </FullWidthContainer>
      <MainContentFullWidthContainer>
        <MainContentContainer>
          <CreditScoreCard />
          <RAWidgetCard />
          <CreditCoachUpdates />
          <TrendingOffersCard />
          <TopPicksPromoCards />
          {isMobile && (
            <HierarchyPromoCard
              shouldStartTracking={shouldEmitHierarchyViewEvent}
              setShouldStartTracking={setShouldEmitHierarchyViewEvent}
            />
          )}
          <LimitedTimeOfferCarouselCard />
          <ProductRecommendationsPromoCard />
          <SponsoredCarouselCard />
        </MainContentContainer>
        <SideContentContainer>
          {!isMobile && (
            <HierarchyPromoCard
              shouldStartTracking={shouldEmitHierarchyViewEvent}
              setShouldStartTracking={setShouldEmitHierarchyViewEvent}
            />
          )}
        </SideContentContainer>
      </MainContentFullWidthContainer>
    </FullWidthContainer>
  );
};
