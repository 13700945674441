import styled, { css } from "@emotion/native";

import { Body2, Heading2, Icon, IconButton } from "@bwll/bw-components/next";
import { IconProps } from "@bwll/bw-components/next/atoms/Icon/Icon.types";
import { IconButtonProps } from "@bwll/bw-components/next/atoms/IconButton/IconButton.types";
import { Body2Props } from "@bwll/bw-components/next/atoms/Typography/Body2/Body2.types";
import { COLORS, borderRadius, borderWidth, boxShadow, fontSize, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue as sv } from "@bwll/bw-utils";

import { IMAGE_DIMENSIONS } from "./CreditCardCompareTable.constants";

const MIN_COLUMN_WIDTH = sv(195);

// CSS
const mobileHeadingCss = css`
  line-height: ${sv(lineHeight.m)};
  font-size: ${sv(fontSize.s)};
  letter-spacing: ${sv(0.24)};
`;

export const Table = styled.View`
  // Border radius fix
  overflow-x: auto;
  align-items: start;
  border-radius: ${sv(borderRadius.s)};
`;

// Thead
export const Thead = styled.View`
  flex-direction: row;
  min-width: 100%;

  background: ${COLORS.WHITE};
`;

export const TheadTd = styled.View`
  flex: 1;
  padding: ${sv(spacing.xs)};
  gap: ${sv(spacing.xxs)};
  align-items: center;

  min-width: ${MIN_COLUMN_WIDTH};
`;

export const TheadHeading = styled(Heading2)<{ isMobile: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;

  color: ${COLORS.NEUTRAL.WARM["800"]};
  text-align: center;

  ${({ isMobile }) => isMobile && mobileHeadingCss};
`;

export const TheadImageContainer = styled.View`
  width: ${sv(IMAGE_DIMENSIONS.WIDTH)};
  height: ${sv(IMAGE_DIMENSIONS.HEIGHT)};
`;

export const EmptyCardContainer = styled.View`
  flex: 1;
`;

export const EmptyCard = styled.Pressable`
  width: ${sv(IMAGE_DIMENSIONS.WIDTH)};
  height: ${sv(IMAGE_DIMENSIONS.HEIGHT)};

  align-items: center;
  justify-content: center;

  border: ${sv(borderWidth.small)} solid ${COLORS.PRIMARY.DEFAULT};
  border-radius: ${sv(borderRadius.s)};
  background: ${COLORS.NEUTRAL.COOL["100"]};
`;

export const EmptyCardIconContainer = styled.View`
  width: ${sv(spacing.m)};
  height: ${sv(spacing.m)};

  align-items: center;
  justify-content: center;

  border: ${sv(borderWidth.small)} solid ${COLORS.PRIMARY.DEFAULT};
  border-radius: ${sv(borderRadius.circle)};
`;

export const EmptyCardIcon = (props: Omit<IconProps, "size" | "color" | "icon">) =>
  Icon({
    ...props,
    size: spacing.m,
    color: COLORS.PRIMARY.DEFAULT,
    icon: "plus",
  });

export const RemoveButtonContainer = styled.View`
  position: absolute;
  top: ${sv(-5)};
  right: ${sv(-5)};

  border-radius: ${sv(borderRadius.circle)};
  background-color: ${COLORS.WHITE};
  box-shadow: ${boxShadow};
`;

export const RemoveButton = (props: Omit<IconButtonProps, "icon" | "size" | "color">) =>
  IconButton({
    ...props,
    icon: "close",
    size: spacing.m,
    color: COLORS.PRIMARY.DEFAULT,
  });

// Tr

// Extends Th to size of subsequent Tr row (for horizontal scroll background colour)
export const RowWrapper = styled.View`
  min-width: 100%;
`;

export const Tr = styled.View`
  flex-direction: row;
`;

export const Td = styled.View<{ alignCenter?: boolean; lastItem?: boolean }>`
  flex: 1;
  align-items: start;
  justify-content: center;
  padding-vertical: ${sv(spacing.xxs)};
  padding-horizontal: ${sv(spacing.xs)};
  min-width: ${MIN_COLUMN_WIDTH};

  ${({ alignCenter }) => alignCenter && `align-items: center;`}

  background: ${COLORS.WHITE};

  ${({ lastItem }) =>
    !lastItem &&
    `border-right-width: ${sv(1)};
  border-right-style: solid;
  border-right-color: ${COLORS.PURPLE["900"]};`}
`;

export const Th = styled.View`
  flex-direction: row;
  gap: ${sv(spacing.xxxs)};
  align-items: center;

  background: ${COLORS.PINK["050"]};
  color: ${COLORS.PURPLE["900"]};
  padding-vertical: ${sv(spacing.xxs)};
  padding-horizontal: ${sv(spacing.xs)};

  border-bottom-width: ${sv(2)};
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.PURPLE["900"]};
`;

export const ThIcon = (isMobile: boolean) => (props: Omit<IconProps, "size" | "color">) =>
  Icon({
    ...props,
    size: isMobile ? 20 : 24,
    color: COLORS.PURPLE["900"],
  });

export const ThHeading = styled(Heading2)<{ isMobile: boolean }>`
  color: inherit;

  ${({ isMobile }) => isMobile && mobileHeadingCss};
`;

// TODO remove this when a backend solution is implemented to support string formatted rates/fee table
// Currently hardcoding for GMS-14240
export const RateText = (props: Omit<Body2Props, "variant" | "color">) =>
  Body2({ ...props, variant: "semi-bold", color: COLORS.NEUTRAL.WARM["800"] });
