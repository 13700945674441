import React from "react";
import { useTranslation } from "react-i18next";

import { Icon, Markdown, Spacer, Tooltip, TooltipText } from "@bwll/bw-components/next";
import { Subheading2Props } from "@bwll/bw-components/next/atoms/Typography/Subheading2/Subheading2.types";
import { isWeb } from "@bwll/bw-components/next/constants";
import { useBreakpoints } from "@bwll/bw-hooks";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";

import * as Styled from "./PreQualifiedBanner.styles";
import { PreQualifiedBannerProps } from "./PreQualifiedBanner.types";

const ResponsiveText = (props: Subheading2Props) => {
  const { isMobile } = useBreakpoints();

  return <Styled.ResponsiveText isMobile={isMobile} {...props} />;
};

export const PreQualifiedBanner = ({
  testID,
  toolTipTitle,
  toolTipTextMarkdown,
}: PreQualifiedBannerProps) => {
  const i18next = useTranslation();

  const text = toolTipTitle ?? i18next.t("productCatalog:creditCards:preQualified:text");
  const tooltipCopy = toolTipTextMarkdown ?? i18next.t("productCatalog:creditCards:preQualified:tooltipCopy");
  const tooltipKey = toolTipTitle ?? i18next.t("productCatalog:creditCards:preQualified:tooltipKey");

  const tooltipProps = isWeb
    ? {
        content: (
          <ResponsiveText>
            <Markdown>{tooltipCopy}</Markdown>
          </ResponsiveText>
        ),
      }
    : {
        bodyOverride: <Markdown>{tooltipCopy}</Markdown>,
        tooltipWidth: 300,
      };

  return (
    <Styled.Container testID={testID}>
      <Tooltip {...tooltipProps}>
        <Styled.RowWrapper>
          <Icon icon="check_circle_filled" size={fontSize.s} color={COLORS.TURQUOISE["500"]} />
          <Spacer width={spacing.xxs} />
          <TooltipText
            tooltipKey={tooltipKey}
            TypographyComponent={ResponsiveText}
            color={COLORS.TURQUOISE["900"]}
            variant="semi-bold"
          >
            {text}
          </TooltipText>
        </Styled.RowWrapper>
      </Tooltip>
    </Styled.Container>
  );
};
