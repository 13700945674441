import React from "react";

import { Skeleton } from "@bwll/bw-components/next";
import {
  useEnvironmentContext,
  useIsMemberSubscribedToProduct,
  useIsOnboardingCompleted,
  useIsRentProofRejected,
  useServicingData,
} from "@bwll/bw-hooks";
import { ENGLISH_LANGUAGE } from "@bwll/bw-types";
import { formatCurrencyDollar } from "@bwll/bw-utils";

import { RAWidget as RAWidgetUI } from "./RAWidget.component";
import { CARD_HEIGHT } from "./RAWidget.constants";
import { RAWidgetContainerProps } from "./RAWidget.types";

export const RAWidgetContainer = ({ locale = ENGLISH_LANGUAGE, onPress }: RAWidgetContainerProps) => {
  const { envConfigs } = useEnvironmentContext();
  const { isMemberSubscribedToProduct } = useIsMemberSubscribedToProduct(
    envConfigs.RENT_REPORTING_PRODUCT_ID,
  );

  const isOnboardingCompleted = useIsOnboardingCompleted();

  const {
    totalAmount,
    totalAmountRaw,
    dateReported,
    reportingStatus,
    shouldConfirmRent,
    anyManualTransactionInReview,
    rentProofSubmissionTransactions,

    reportedToAgencyDateRaw,
    rentPeriod,

    isProfileLoading,
    isLatestRentPeriodTrxsLoading,
    isPaymentProfileLoading,
    isSubscriptionsLoading,
    isRentProofSubmissionLoading,
  } = useServicingData(locale);

  const isRentProofRejected = useIsRentProofRejected(locale);

  const rentProofTotalAmount = rentProofSubmissionTransactions?.reduce((sum, trx) => trx.amount + sum, 0);

  const amount =
    anyManualTransactionInReview && rentProofSubmissionTransactions?.length
      ? formatCurrencyDollar({
          amount: rentProofTotalAmount,
          decimals: 2,
          locale,
        })
      : totalAmount;

  const isLoading =
    isProfileLoading ||
    isLatestRentPeriodTrxsLoading ||
    isPaymentProfileLoading ||
    isSubscriptionsLoading ||
    isRentProofSubmissionLoading;

  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height={CARD_HEIGHT} />;
  }

  return (
    <RAWidgetUI
      onPress={onPress}
      isSubscribed={!isRentProofRejected && isMemberSubscribedToProduct && isOnboardingCompleted}
      shouldShowAmount={!(shouldConfirmRent && !anyManualTransactionInReview)}
      shouldShowDateReported={!(shouldConfirmRent || anyManualTransactionInReview)}
      totalAmountFormatted={amount}
      totalAmount={totalAmountRaw}
      dateReportedFormatted={dateReported}
      locale={locale}
      rentPeriod={rentPeriod}
      reportingStatus={reportingStatus}
      reportedToAgencyDate={reportedToAgencyDateRaw}
      anyManualTransactionInReview={anyManualTransactionInReview}
      shouldConfirmRent={shouldConfirmRent}
    />
  );
};
