import { Factor, VisualScaleMark } from "@bwll/bw-types";

/**
 *
 * A function that calculates the mark based on the factor and the total values of the Credit Factor.
 * Most of the logic was copied from (bw-react-frontend/apps/mobile/src/screens/creditFactor/components/utils.tsx)
 * and aligned with the web implementation.
 * TODO: Unify these files.
 */
export const calculateMark = (factor: Factor, total: number): VisualScaleMark => {
  switch (factor) {
    case Factor.AVERAGE_CREDIT_AGE:
      if (total <= 60) return VisualScaleMark.NEEDS_WORK;
      if (total >= 61 && total <= 179) return VisualScaleMark.FAIR;
      return VisualScaleMark.GOOD;
    case Factor.CREDIT_INQUIRIES:
      if (total >= 5) return VisualScaleMark.NEEDS_WORK;
      if (total >= 2 && total <= 4) return VisualScaleMark.FAIR;
      return VisualScaleMark.GOOD;
    case Factor.CREDIT_UTILIZATION:
      if (total >= 51) return VisualScaleMark.NEEDS_WORK;
      if (total <= 50 && total >= 31) return VisualScaleMark.FAIR;
      return VisualScaleMark.GOOD;
    case Factor.DEROGATORY_MARKS:
      if (total >= 3) return VisualScaleMark.NEEDS_WORK;
      if (total == 1 || total == 2) return VisualScaleMark.FAIR;
      return VisualScaleMark.GOOD;
    case Factor.MISSED_PAYMENTS:
      if (total >= 2) return VisualScaleMark.NEEDS_WORK;
      if (total == 1) return VisualScaleMark.FAIR;
      return VisualScaleMark.GOOD;
    case Factor.TOTAL_ACCOUNTS:
      if (total <= 5) return VisualScaleMark.NEEDS_WORK;
      if (total >= 6 && total <= 10) return VisualScaleMark.FAIR;
      return VisualScaleMark.GOOD;
  }
};
