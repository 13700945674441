import styled from "@emotion/native";

import { ExpressiveLabel, Subheading2 } from "@bwll/bw-components/next";
import { COLORS, borderRadius, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

type MobileProps = { isMobile: boolean };

export const Container = styled.View<MobileProps>`
  border-radius: ${styledValue(borderRadius.s)};
  gap: ${styledValue(spacing.xxs)};

  ${({ isMobile }) =>
    !isMobile &&
    `
    background: ${COLORS.NEUTRAL.COOL["050"]};
    padding: ${styledValue(spacing.xs)}
  `};
`;

export const DesktopHeading = styled(Subheading2)`
  letter-spacing: ${styledValue(0.32)};
`;

export const MobileHeading = styled(ExpressiveLabel)`
  letter-spacing: ${styledValue(1.12)};
`;
