import styled from "@emotion/native";

import { Body2, Heading2, Label } from "@bwll/bw-components/next";
import { COLORS, borderRadius, fontSize, spacing } from "@bwll/bw-styles";
import { CreditScoreChangeDirection, CreditScoreChangeDirectionType } from "@bwll/bw-types";
import { styledValue } from "@bwll/bw-utils";

import { TRACKER_HEIGHT, TRACKER_WIDTH } from "./CreditScoreGraph.constants";

const getIndicatorBackgroundColor = (scoreChangeDirection?: CreditScoreChangeDirectionType): string => {
  if (scoreChangeDirection === CreditScoreChangeDirection.Up) return COLORS.GREEN["050"];
  if (scoreChangeDirection === CreditScoreChangeDirection.Down) return COLORS.RED["050"];
  return COLORS.NEUTRAL.COOL["100"];
};

type CreditAlertProps = { backgroundColor: string; isDesktop: boolean };
type CreditAlertsContainerProps = { isDesktop: boolean };

export const Row = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

export const GraphContainer = styled.View`
  position: relative;
  justify-content: flex-end;
  width: ${styledValue(TRACKER_WIDTH)};
  height: ${styledValue(TRACKER_HEIGHT)};
`;

export const TrackerContainer = styled.View`
  position: absolute;
  left: 0;
  top: 0;
`;

export const IndicatorContainer = styled.View<{ scoreChangeDirection?: CreditScoreChangeDirectionType }>`
  flex-direction: row;
  flex-shrink: 1;
  align-items: center;
  margin: 0 auto;
  background-color: ${({ scoreChangeDirection }) => getIndicatorBackgroundColor(scoreChangeDirection)};
  border-radius: ${styledValue(borderRadius.m)};
  padding-vertical: ${styledValue(spacing.xxxs)};
  padding-horizontal: ${styledValue(spacing.xxs)};
`;

export const ScoreText = styled(Heading2)`
  font-weight: 500;
  line-height: ${styledValue(fontSize.l)};
  letter-spacing: -0.8px;
  text-align: center;
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;

export const GreetingsText = styled(Heading2)`
  font-size: ${styledValue(fontSize.m)};
  line-height: ${styledValue(fontSize.xl)};
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;

export const ScoreDescriptionContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ScoreDescriptionText = styled(Body2)`
  font-weight: 600;
  color: ${COLORS.NEUTRAL.WARM["700"]};
`;

export const DataContainer = styled.View`
  max-width: 70%;
  margin-left: ${styledValue(spacing.xxs)};
`;

export const Line = styled.View`
  height: ${styledValue(1)};
  width: 100%;
  background-color: ${COLORS.NEUTRAL.COOL["100"]};
  margin-vertical: ${styledValue(spacing.xs)};
`;

export const LinkContainer = styled.View`
  align-items: center;
`;

export const LabelText = styled(Label)`
  margin-left: ${styledValue(spacing.xxxs)};
`;

export const CreditAlertsContainer = styled.View<CreditAlertsContainerProps>`
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? "row" : "column")};
  flex-wrap: wrap;
  gap: ${styledValue(spacing.xxs)};
  margin-top: ${styledValue(spacing.xs)};
`;

export const CreditAlert = styled.View<CreditAlertProps>`
  width: ${({ isDesktop }) => (isDesktop ? "48%" : "100%")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: ${styledValue(spacing.xxxs)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${styledValue(borderRadius.s)};
  padding: ${styledValue(spacing.xxxs)} ${styledValue(spacing.xxs)};
`;
