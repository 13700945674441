import { isDateAfter, isDateBefore, isDateSame, isDateValid, subtractDateTime } from "@bwll/bw-utils";

import { LeaseTermsFormErrorSchema, LeaseTermsFormFieldsName } from "./LeaseTermsForm.types";

const MIN_LEASE_START_DATE = new Date(1989, 11, 31);

/**
 * Transforms given value into the MM/YYYY format if possible
 */
export const maskDate = (value: string) => {
  if (value.length <= 2) {
    return value;
  }

  const maskedValue = value.replace(/\D/g, "");

  return `${maskedValue.substring(0, 2)}/${maskedValue.substring(2, 6)}`;
};

export const extractLeaseStartDate = (value = ""): string => {
  if (!value) {
    return value;
  }

  const parts = value.split("-");

  if (parts.length === 1) {
    return value;
  }

  return `${parts[1]}/${parts[0]}`;
};

/**
 * Potential value should be in the MM/YYYY format. However, it may be anything else
 */
export const isLeaseStartDateValid = (value: string): boolean => {
  const [mm, yyyy] = value.toString().split("/").map(Number);

  // Cover the case when the user inserts month greater than 12
  // (date-fns functions calculate additional months by incrementing a year value)
  if (!isNaN(mm) && mm > 12) {
    return false;
  }

  const unsafeDate = new Date(yyyy, mm - 1);

  return isDateValid(unsafeDate);
};

export const isLeaseStartDateAfter = (value: string): boolean => {
  const [mm, yyyy] = value.toString().split("/").map(Number);
  const date = new Date(yyyy, mm - 1);

  return isDateAfter(date, MIN_LEASE_START_DATE, "day");
};

export const isLeaseStartDateSameOrBefore = (value: string, oneDayAgo: Date): boolean => {
  const [mm, yyyy] = value.toString().split("/").map(Number);
  const date = new Date(yyyy, mm - 1);

  return isDateBefore(date, oneDayAgo, "day") || isDateSame(date, oneDayAgo, "month");
};

export const invalidate = (
  schema: LeaseTermsFormErrorSchema,
  fieldName: LeaseTermsFormFieldsName,
  message: string,
): void => {
  schema[fieldName] = message;
  schema.isValid = false;
};
