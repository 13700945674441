import React, { useCallback } from "react";

import { ProductRecommendationsPromoCard as ProductRecommendationsPromoCardUI } from "@bwll/bw-modules";
import { useInternalNavigate } from "@bwll/dashboard/src/hooks";
import { MARKETPLACE_ROUTES } from "@bwll/dashboard/src/router";

/**
 * A wrapper around the ProductRecommendationsPromoCard component
 * that applies styling related to the screen implementation
 * and handles all platform-specific navigation callbacks
 * and analytics.
 */
export const ProductRecommendationsPromoCard = () => {
  const { navigateToVertical } = useInternalNavigate();
  const destinationRoute = MARKETPLACE_ROUTES.CREDIT_CARDS;

  const navigateToCreditCardsVertical = useCallback(() => {
    navigateToVertical(destinationRoute);
  }, [navigateToVertical]);

  return (
    <ProductRecommendationsPromoCardUI
      redirectAction={navigateToCreditCardsVertical}
      analyticsAttributes={{
        destinationUrl: destinationRoute,
      }}
    />
  );
};
