import styled from "@emotion/native";
import { StyleSheet } from "react-native";

import { Body2 } from "@bwll/bw-components/next";
import { COLORS, borderRadius, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

type StyledLayoutProps = { isMobile: boolean; isTablet?: boolean };

export const AccordionContentContainer = styled.View<StyledLayoutProps>`
  margin-top: ${styledValue(spacing.xs)};
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  gap: ${styledValue(spacing.xs)};
`;

export const ImageContainer = styled.View<StyledLayoutProps>`
  width: ${styledValue(164)};
  height: ${styledValue(109)};
  border-radius: ${styledValue(borderRadius.s)};
  margin: 0 auto;
  overflow: hidden;
`;

export const TextContainer = styled.View<StyledLayoutProps>`
  width: ${({ isMobile, isTablet }) => (isMobile ? "100%" : isTablet ? "70%" : "80%")};
  display: flex;
  flex-direction: column;
  gap: ${styledValue(spacing.xs)};
  flex-shrink: 1;
`;

export const ButtonContainer = styled.View<StyledLayoutProps>`
  max-width: ${({ isMobile }) => (isMobile ? "auto" : styledValue(288))};
`;

export const StyledBody = styled(Body2)`
  color: ${COLORS.BLACK};
`;
