import styled from "@emotion/native";

import { Cell, Image, Subheading2 } from "@bwll/bw-components/next";
import { borderRadius, boxShadow, spacing } from "@bwll/bw-styles";
import { styledValue as sv } from "@bwll/bw-utils";

import { IMAGE_DIMENSIONS } from "./QuickApplyProductHeader.constants";

type IsMobile = { isMobile: boolean };

export const Card = styled(Cell)<IsMobile>`
  flex-direction: row;
  align-items: center;
  gap: ${sv(spacing.xs)};

  border-radius: ${sv(borderRadius.s)};
  box-shadow: ${boxShadow};

  ${({ isMobile }) =>
    isMobile &&
    `
    align-items: start;
    gap: ${sv(spacing.xxs)};

    padding: 0;
    background-color: none;
    border-radius: 0;
    box-shadow: none;`}
`;

export const ProductImage = styled(Image)<IsMobile>`
  width: ${sv(IMAGE_DIMENSIONS.desktop.width)};
  height: ${sv(IMAGE_DIMENSIONS.desktop.height)};
  resize-mode: contain;

  ${({ isMobile }) =>
    isMobile &&
    `
    width: ${sv(IMAGE_DIMENSIONS.mobile.width)};
    height: ${sv(IMAGE_DIMENSIONS.mobile.height)};`}
`;

// TODO: GMS-14263 Change to h4-subheading
export const ProductHeading = styled(Subheading2)`
  letter-spacing: ${sv(0.32)};
  flex-wrap: wrap;
  flex: 1;
`;
