import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ListRenderItem } from "react-native";

import { HorizontalCarousel } from "@bwll/bw-components/next";

import { SPONSORED_CAROUSEL_TEST_ID } from "./SponsoredCarousel.constants";
import * as Styled from "./SponsoredCarousel.styles";
import { SponsoredCarouselProps } from "./SponsoredCarousel.types";
import { useTransformSponsoredPlacementProducts } from "./SponsoredCarousel.utils";
import { SponsoredCarouselCard } from "./SponsoredCarouselCard/SponsoredCarouselCard.component";
import { SponsoredCarouselItem } from "./SponsoredCarouselCard/SponsoredCarouselCard.types";

export const SponsoredCarousel = ({
  products,
  productsv4,
  individualClientIdReferenceNumber,
  transformCtaLink,
  onPress,
}: SponsoredCarouselProps) => {
  const i18next = useTranslation();

  const transformProducts = useTransformSponsoredPlacementProducts(
    transformCtaLink,
    individualClientIdReferenceNumber,
  );

  const cards: SponsoredCarouselItem[] = useMemo(() => {
    return transformProducts(products, productsv4);
  }, [products, productsv4, transformProducts]);

  const [activeProductId, setActiveProductId] = useState(cards[0]?.productReferenceId);

  const handleChange = useCallback((item: SponsoredCarouselItem) => {
    setActiveProductId(item.productReferenceId);
  }, []);

  const renderItem: ListRenderItem<SponsoredCarouselItem> = useCallback(
    ({ item }) => {
      return (
        <Styled.Container>
          <SponsoredCarouselCard
            {...item}
            onPress={onPress}
            /** For data tracking purposes */
            currentItemId={activeProductId}
          />
        </Styled.Container>
      );
    },
    [onPress, activeProductId],
  );

  return (
    <HorizontalCarousel
      testID={SPONSORED_CAROUSEL_TEST_ID}
      data={cards}
      onChange={handleChange}
      renderItem={renderItem}
      title={i18next.t("dashboard:cards:sponsoredCarouselV2:title")}
      iconName="percent_circle"
      renderLabelText={(params) => i18next.t("dashboard:cards:sponsoredCarouselV2:label", params)}
    />
  );
};
