import { useMemo } from "react";
import { useAuth } from "react-oidc-context";

export type UseMemberRoleResponse = {
  userRole: string | undefined;
  isPreMember: boolean | undefined;
};

const preMemberRole = "Pre Member";

/*
 * This hook returns the current user role and a helper variable that tells if the user
 * is a PreMember or not.
 *
 * This hook will only return any value if the user is authenticated.
 *
 * Remarks:
 * 1. Not returning the whole user object, because it's an auth object and has sensitive
 * information like token, and refresh_token.
 * 2. Also returning the actual user role so this hook can be used by other features.
 * */
export const useMemberRole = (): UseMemberRoleResponse => {
  const { isAuthenticated, user } = useAuth();

  return useMemo(() => {
    if (!isAuthenticated || !user) {
      return {
        userRole: undefined,
        isPreMember: undefined,
      };
    }

    const userProfile = user.profile.role?.toString();

    return {
      userRole: userProfile,
      isPreMember: userProfile === preMemberRole,
    };
  }, [isAuthenticated, user]);
};
