import styled from "@emotion/styled";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { WEB_CONTAINER_MAX_WIDTH } from "./ScreenContainer.constants";

export const Container = styled.div<{ maxWidth?: number }>`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  max-width: ${({ maxWidth }) => styledValue(maxWidth || WEB_CONTAINER_MAX_WIDTH)};
  width: calc(100% - 48px);
  margin: ${styledValue(spacing.m)} auto;
`;
