import styled from "@emotion/native";

export const styles = {
  ctaButton: {
    maxWidth: 343,
  },
};

export const PageContainer = styled.View`
  width: 100%;
  height: 100%;
  flex-direction: column;
`;
