import { CreditCardSortFunction } from "@bwll/bw-utils";

export const SORT_OPTIONS = {
  Recommended: "Recommended",
  LOA: "LOA",
  FirstYearValue: "FirstYearValue",
  Fee: "Fee",
  InterestRate: "InterestRate",
  Trending: "Trending",
} as const;

export type CreditCardSortOptionKey = keyof typeof SORT_OPTIONS;

export type CreditCardSorter = {
  key: CreditCardSortOptionKey;
  sortFn: CreditCardSortFunction;
  localeKey?: string;
  shortLocaleKey?: string;
};

export type SortDropdownProps = {
  initialSort?: string;
  options: {
    value: string;
    label: string;
    shortLabel?: string;
  }[];
  testID?: string;
  onValueChange?: (sorter: any) => void;
  onDropdownToggle?: () => void;
  state?: "active" | "inactive";
  showSelectedOptionShortLabel?: boolean;
};
