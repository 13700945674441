import { NullableString } from "@bwll/bw-types";

const originalCIDRegex = /{CID}/gi;
const modifiedCIDRegex = /{CID(\d+)}/i;

/**
 * Gets a concatenated CID for use with a partner's URL.
 * @param individualClientIdReferenceNumber The member's ICI.
 * @param impressionToken The unique product impression token.
 * @returns A concatenated CID.
 */
export const getCID = (individualClientIdReferenceNumber: number, impressionToken: string): string =>
  `${individualClientIdReferenceNumber}-${impressionToken}`;

/**
 * Gets a random string of supplied length for use with a partner's URL.
 * @param length The length of the modified CID.
 * @returns A random CID.
 */
export const getModifiedCID = (length: number): string =>
  [...Array(length)].map(() => Math.random().toString(36)[2]).join("");

const getModifiedCIDLength = (url: string): number => {
  const matches = url.match(modifiedCIDRegex);
  if (matches) {
    return Number(matches[1]);
  }
  return 0;
};

/**
 * Replaces a product URL's CID tokens with a generated CID.
 *
 * If a product URL has {CID} in the text, it will be replaced with a generated CID. That CID will be returned in originalCID.
 * If a product URL has {CID\d+} in the text, it will be replaced with a randomized modified CID. That CID will be returned in modifiedCID.
 * @param url The product URL.
 * @param individualClientIdReferenceNumber The member's Individual Client ID reference number (for use in the CID).
 * @param productImpressionId The product's impression token (for use in the CID).
 * @returns An object with the product URL with CID tokens replaced, the generated CID and a generated modified CID (if necessary).
 */
export const getImpressionedUrl = (
  url: NullableString,
  individualClientIdReferenceNumber: number,
  productImpressionId: string,
): { impressionedUrl: NullableString; originalCID: string; modifiedCID?: string } => {
  const originalCID = getCID(individualClientIdReferenceNumber, productImpressionId);

  if (!url)
    return {
      impressionedUrl: url,
      originalCID,
    };

  const modifiedCIDLength = getModifiedCIDLength(url);
  const modifiedCID = modifiedCIDLength > 0 ? getModifiedCID(modifiedCIDLength) : undefined;

  const impressionedUrl = url
    .replace(modifiedCIDRegex, modifiedCID ?? "")
    .replace(originalCIDRegex, originalCID);
  return {
    impressionedUrl,
    originalCID,
    modifiedCID,
  };
};
