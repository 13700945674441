/**
 * Formats markdown so it can be displayed properly by preserving multiple new-lines
 * @param markdown the markdown string to format
 * @returns Markdown with multiple new-lines preserved
 */
export const formatLineBreaks = (markdown: string) => {
  // Markdown was treating multiple new lines as just one
  // But a nbsp before a new line preserves it
  const multiLineBreakRegexRule = /\n/gi;
  return markdown.replace(multiLineBreakRegexRule, "&nbsp;\n");
};

/**
 * Formats markdown superscript tags to display properly on mobile
 * @param markdown the markdown string to format
 * @returns markdown with superscripts formatted
 */
export const formatSuperscript = (markdown: string) => {
  const superscriptRegexRule = /(<([sup>,sup/>]+)>)/gi;
  return markdown.replace(superscriptRegexRule, "`");
};
