import { useMemo } from "react";

import { WEB_EXPERIMENT_IDS } from "@bwll/bw-types";

import { useExperimentStoreContext } from "../../contexts";

/**
 * Returns the UI toggles for a member based on their treatment for the Split flag "sspaMarketplaceQuickApply"
 * https://app.split.io/org/99385f60-da53-11e9-b7b8-0e6b6ef4c55e/ws/b0e53290-2e55-11ea-9413-12f2f63694e5/splits/0c569460-5ffd-11ef-9e11-dea3ab90bb5a/env/b0e72e60-2e55-11ea-9413-12f2f63694e5/definition
 */
export const useMarketplaceQuickApplyExperiment = () => {
  const { experimentsMap } = useExperimentStoreContext();

  return useMemo(() => {
    const treatment =
      experimentsMap.userConfig?.[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_QUICK_APPLY]?.treatment ?? "off";

    return { showQuickApply: treatment === "on" };
  }, [experimentsMap.userConfig]);
};
