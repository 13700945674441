import i18next from "i18next";
import { View } from "react-native";

import { Icon } from "@bwll/bw-components/next";
import { COLORS, fontSize } from "@bwll/bw-styles";

import { PrimePicksCard } from "./PrimePicksCard/PrimePicksCard.component";
import { TOP_PICKS_PROMO_CARDS_TITLE_TEST_ID } from "./TopPicksPromoCardsV2.constants";
import * as Styled from "./TopPicksPromoCardsV2.style";
import { TopPicksPromoCardsV2Props } from "./TopPicksPromoCardsV2.types";

export const TopPicksPromoCardsV2 = (props: TopPicksPromoCardsV2Props) => {
  const title = i18next.t("dashboard:topPicksV2:title");

  if (!props.promoCards || !props.promoCards.length) return null;

  return (
    <View testID={props.testID}>
      <Styled.Row>
        <Styled.TitleContainer>
          <Icon icon={"flag"} color={COLORS.PRIMARY.DEFAULT} size={fontSize.l} />
          <Styled.TitleText testID={TOP_PICKS_PROMO_CARDS_TITLE_TEST_ID}>{title}</Styled.TitleText>
        </Styled.TitleContainer>
      </Styled.Row>
      <Styled.ContentContainer>
        {props.promoCards.map((promoCard, index) => {
          return <PrimePicksCard promoCard={promoCard} key={index} />;
        })}
      </Styled.ContentContainer>
    </View>
  );
};
