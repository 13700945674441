import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const FilterPillsContainer = styled.View`
  display: flex;
  flex-direction: row;
  gap: ${styledValue(spacing.xxs)};
`;
