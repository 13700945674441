import styled from "@emotion/native";

import { Button } from "@bwll/bw-components/next";
import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const FilterButton = styled(Button)`
  display: flex;
  gap: ${styledValue(spacing.xxs)};
  padding: ${styledValue(12)};
  width: unset;
  color: ${COLORS.NEUTRAL.WARM["700"]};
`;
