import type { GoogleAddressResult } from "@bwll/bw-components/next";

export const defaultAddress: GoogleAddressResult = {
  street: "",
  city: "",
  province: "",
  unitApt: "",
  postalCode: "",
};

export const BUTTON_MAX_WIDTH = 360;
export const WEB_CONTENT_MAX_WIDTH = 560;

export const ADDRESS_MAX_LENGTH = 50;
export const validSpecialCharacters = /^[0-9a-zàâçéèêëîïôûùüÿñæœ ./-]*$/i;
export const validPostalCode =
  /(^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][\s-]?\d[ABCEGHJ-NPRSTV-Z]\d$)|(^\d{5}(-\d{4})?$)/;

export const ADDRESS_FORM_BUTTON_TEST_ID = "continue-button";
export const ADDRESS_FORM_STREET_INPUT_TEST_ID = "street-input";
export const ADDRESS_FORM_UNIT_INPUT_TEST_ID = "unitApt-input";
export const ADDRESS_FORM_CITY_INPUT_TEST_ID = "city-input";
export const ADDRESS_FORM_PROVINCE_DROPDOWN_TEST_ID = "province-dropdown";
export const ADDRESS_FORM_POSTAL_CODE_INPUT_TEST_ID = "postalCode-input";

export const ADDRESS_FORM_SECTION_CONTAINER_1_TEST_ID = "address-form-section-container-1";
export const ADDRESS_FORM_SECTION_CONTAINER_2_TEST_ID = "address-form-section-container-2";
export const ADDRESS_FORM_SECTION_CONTAINER_3_TEST_ID = "address-form-section-container-3";
export const ADDRESS_FORM_SECTION_ITEM_1_TEST_ID = "address-form-item-container-1";
export const ADDRESS_FORM_SECTION_ITEM_2_TEST_ID = "address-form-item-container-2";
export const ADDRESS_FORM_SECTION_ITEM_3_TEST_ID = "address-form-item-container-3";
export const ADDRESS_FORM_SECTION_ITEM_4_TEST_ID = "address-form-item-container-4";
