import React from "react";

import { Skeleton } from "@bwll/bw-components/next";
import { useBreakpoints, useTransitionGetMember } from "@bwll/bw-hooks";

import { ProductRecommendationsPromoCard as ProductRecommendationsPromoCardUI } from "./ProductRecommendationsPromoCard.component";
import {
  CARD_HEIGHT,
  MOBILE_CARD_HEIGHT,
  PRODUCT_RECOMMENDATIONS_PROMO_CARD_SKELETON_TEST_ID,
} from "./ProductRecommendationsPromoCard.constants";
import { ProductRecommendationsPromoCardProps } from "./ProductRecommendationsPromoCard.types";

/**
 * Component represents a data container for the Product Recommendations PromoCard UI.
 * @param {ProductRecommendationsPromoCardProps} props - container props interface
 */
export const ProductRecommendationsPromoCardContainer = (props: ProductRecommendationsPromoCardProps) => {
  const { data, isLoading } = useTransitionGetMember();
  const { isMobile } = useBreakpoints();

  if (isLoading) {
    return (
      <Skeleton
        testID={PRODUCT_RECOMMENDATIONS_PROMO_CARD_SKELETON_TEST_ID}
        variant="rounded"
        width="100%"
        height={isMobile ? MOBILE_CARD_HEIGHT : CARD_HEIGHT}
      />
    );
  }

  return (
    <ProductRecommendationsPromoCardUI
      analyticsAttributes={props.analyticsAttributes}
      firstName={data?.individual.firstName}
      {...props}
    />
  );
};
