import React, { useCallback } from "react";

import { TopPicksPromoCardsEventData, useAnalyticsContext } from "@bwll/bw-hooks";

import { OffersPromoCard } from "../../OffersPromoCard";
import { TOP_PICKS_TEST_ID_MAPPING } from "../TopPicksPromoCardsV2.constants";
import { useTopPicksV2CommonAttributes } from "../useTopPicksV2CommonAttributes";
import { PrimePicksCardProps } from "./PrimePicksCard.types";

export const PrimePicksCard = ({ promoCard }: PrimePicksCardProps) => {
  const { promoCardViewed, promoCardClicked } = useAnalyticsContext();
  const analyticsAttributes = useTopPicksV2CommonAttributes(promoCard);

  const onTrackView = useCallback(() => {
    promoCardViewed<TopPicksPromoCardsEventData>(analyticsAttributes);
  }, [analyticsAttributes, promoCardViewed]);

  const clickAction = useCallback(() => {
    promoCardClicked<TopPicksPromoCardsEventData>(analyticsAttributes);
    promoCard.onPress();
  }, [analyticsAttributes, promoCard, promoCardClicked]);

  return (
    <OffersPromoCard
      testID={TOP_PICKS_TEST_ID_MAPPING[promoCard.primeCardType]}
      promoCard={promoCard}
      onTrackView={onTrackView}
      clickAction={clickAction}
    />
  );
};
