import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BUTTON_TYPE, Button, ElementTracker, Image, PromoCardTemplate } from "@bwll/bw-components/next";
import {
  MarketplaceSnackbarEventData,
  useAnalyticsContext,
  useBreakpoints,
  usePlatformContext,
  useRecordPromotionAction,
} from "@bwll/bw-hooks";
import { useGetImpressionToken } from "@bwll/bw-hooks/src/hooks/analytics/useGetImpressionToken";
import {
  ANALYTICS_ATTRIBUTES,
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PLATFORM,
  PRODUCT_VERTICAL_ID,
  PRODUCT_VERTICAL_ID_TO_NAMES_MAPPING,
  PROMOTION_TYPE,
  SessionStorageKeys,
  SnackBarViewStatus,
} from "@bwll/bw-types";

import {
  MARKETPLACE_SNACKBAR_CTA_BUTTON_TEST_ID,
  MARKETPLACE_SNACKBAR_TEST_ID,
} from "./MarketplaceSnackbar.constants";
import {
  AccordionContentContainer,
  ButtonContainer,
  ImageContainer,
  StyledBody,
  TextContainer,
} from "./MarketplaceSnackbar.style";
import { MarketplaceSnackbarProps } from "./MarketplaceSnackbar.types";

export const MarketplaceSnackbar = ({
  enableAnalyticsEvent = true,
  marketplaceSnackbar,
  redirectAction,
  analyticsAttributes,
}: MarketplaceSnackbarProps) => {
  const { isMobile, isTablet } = useBreakpoints();
  const { platform } = usePlatformContext();
  const i18next = useTranslation();
  const { mutate: recordPromotionAction } = useRecordPromotionAction();
  const isExpanded =
    platform === PLATFORM.WEB_APP &&
    localStorage.getItem(SessionStorageKeys.SnackbarShowExpanded) !== SnackBarViewStatus.Collapsed;

  const { snackbarViewed, snackbarClicked, snackbarExpanded, snackbarCollapsed } = useAnalyticsContext();

  const impressionId = useGetImpressionToken();

  const eventData = useMemo(() => {
    if (!marketplaceSnackbar) {
      return {} as MarketplaceSnackbarEventData;
    }

    const componentData = isExpanded
      ? {
          [ANALYTICS_ATTRIBUTES.SNACKBAR_BODY]: marketplaceSnackbar.expandedBody,
          [ANALYTICS_ATTRIBUTES.SNACKBAR_BUTTON_TEXT]: marketplaceSnackbar.expandedButtonText,
        }
      : {
          [ANALYTICS_ATTRIBUTES.SNACKBAR_BODY]: marketplaceSnackbar.collapsedButtonText,
          [ANALYTICS_ATTRIBUTES.SNACKBAR_BUTTON_TEXT]: marketplaceSnackbar.collapsedButtonText,
        };

    return {
      ...componentData,
      [ANALYTICS_ATTRIBUTES.PRODUCT_ID]: marketplaceSnackbar.productReferenceId,
      [ANALYTICS_ATTRIBUTES.SNACKBAR_IMPRESSION_ID]: impressionId,
      [ANALYTICS_ATTRIBUTES.SNACKBAR_EXPANDED_STATE]: isExpanded,
      [ANALYTICS_ATTRIBUTES.SNACKBAR_DESTINATION_URL]: analyticsAttributes?.destinationUrl ?? "",
      [ANALYTICS_ATTRIBUTES.EVENT_ACTION]: EVENT_ACTIONS.EVENT,
      [ANALYTICS_ATTRIBUTES.EVENT_CATEGORY]: EVENT_CATEGORIES.EVENT,
    };
  }, [marketplaceSnackbar, isExpanded, impressionId, analyticsAttributes?.destinationUrl]);

  if (!marketplaceSnackbar) {
    return null;
  }

  const trackViewEvent = () => {
    snackbarViewed<MarketplaceSnackbarEventData>?.(eventData);

    if (recordPromotionAction) {
      recordPromotionAction({
        key: `${marketplaceSnackbar.knackId}-${
          PRODUCT_VERTICAL_ID_TO_NAMES_MAPPING[marketplaceSnackbar.vertical as PRODUCT_VERTICAL_ID]
        }`,
        type: PROMOTION_TYPE.SNACKBAR,
      });
    }
  };

  const onPress = () => {
    snackbarClicked<MarketplaceSnackbarEventData>?.(eventData);
    redirectAction();
  };

  const onAccordionToggled = (expandedState: boolean) => {
    if (expandedState) {
      snackbarExpanded<MarketplaceSnackbarEventData>?.(eventData);
      return;
    }
    snackbarCollapsed<MarketplaceSnackbarEventData>?.(eventData);
  };

  return (
    <ElementTracker shouldStartTracking={enableAnalyticsEvent} trackingHandler={trackViewEvent}>
      <PromoCardTemplate
        variant="accordion"
        icon="sparkle"
        body={marketplaceSnackbar.expandedTitle}
        accordionCtaTitleCollapsed={i18next.t("dashboard:marketplaceSnackbar:accordionCtaTitleCollapsed")}
        accordionCtaTitleExpanded={i18next.t("dashboard:marketplaceSnackbar:accordionCtaTitleExpanded")}
        onAccordionToggled={onAccordionToggled}
        accordionContent={
          <AccordionContentContainer isMobile={isMobile}>
            <ImageContainer isMobile={isMobile} isTablet={isTablet}>
              <Image
                source={{ uri: marketplaceSnackbar.expandedImage, height: 109, width: 164 }}
                resizeMode="contain"
              />
            </ImageContainer>
            <TextContainer isMobile={isMobile} isTablet={isTablet}>
              <StyledBody>{marketplaceSnackbar.expandedBody}</StyledBody>
              <ButtonContainer isMobile={isMobile}>
                <Button
                  testID={MARKETPLACE_SNACKBAR_CTA_BUTTON_TEST_ID}
                  title={marketplaceSnackbar.expandedButtonText}
                  onPress={onPress}
                  type={BUTTON_TYPE.PRIMARY}
                />
              </ButtonContainer>
            </TextContainer>
          </AccordionContentContainer>
        }
        isAccordionExpanded={isExpanded}
        testID={MARKETPLACE_SNACKBAR_TEST_ID}
      />
    </ElementTracker>
  );
};
