import {
  GEMSTONE_APP_ROUTES,
  GemstoneAppRoute,
  ImpressionedProductVerticalCreditCard,
  MARKETPLACE_CREDIT_CARD_CATEGORY_PATHNAMES,
  MarketplaceCreditCardCategoryKey,
  PRODUCT_VERTICAL,
  PRODUCT_VERTICAL_ID,
  PRODUCT_VERTICAL_ID_TO_NAMES_MAPPING,
  PRODUCT_VERTICAL_TO_ID_MAPPING,
} from "@bwll/bw-types";
import { getFilterParamFromPath } from "@bwll/bw-utils";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";
import { GEMSTONE_APP_ROUTES_TO_MARKETPLACE_ROUTES_MAPPING } from "@app/router";

export const creditBuilderUrl = `${ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL}/#${GEMSTONE_APP_ROUTES.CREDIT_BUILDER}`;

export const gemstoneNavigateToCreditCard = (item: ImpressionedProductVerticalCreditCard) => {
  if (item.isQuickApply && item.productVerticalId && item.productReferenceNumber && item.id)
    return gemstoneNavigate(
      `${ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL}/#/app/product-partnership/creditcards/${item.productReferenceNumber}/${item.id}`,
    );

  if (item.impressionedProductLink) gemstoneNavigate(item.impressionedProductLink, true);
};

export const gemstoneGeneratePathToProductPartnership = (
  vertical: PRODUCT_VERTICAL | undefined,
  productReferenceNumber: number,
  productReferenceId: string,
  queryParams?: string,
) => {
  if (!vertical) return "";

  let productPath = `${ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL}/#app/product-partnership/${
    PRODUCT_VERTICAL_ID_TO_NAMES_MAPPING[PRODUCT_VERTICAL_TO_ID_MAPPING[vertical]]
  }/${productReferenceNumber}/${productReferenceId}`;
  if (queryParams) productPath = productPath + `?${queryParams}`;
  return productPath;
};

export const gemstoneGeneratePathToProduct = (
  vertical: number,
  productReferenceId: string,
  queryParams?: string,
) => {
  let productPath = `${ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL}/#/app/product/${
    PRODUCT_VERTICAL_ID_TO_NAMES_MAPPING[vertical as PRODUCT_VERTICAL_ID]
  }/${productReferenceId}`;
  if (queryParams) productPath = productPath + `?${queryParams}`;
  return productPath;
};

export const gemstoneNavigateToCreditBuilder = () => {
  gemstoneNavigate(creditBuilderUrl);
};

export const gemstoneNavigateToProduct = (productPath: string) => {
  gemstoneNavigate(productPath);
};

export const gemstoneNavigateToVertical = (gemstoneAppPath: GemstoneAppRoute) => {
  gemstoneNavigate(`${ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL}/#${gemstoneAppPath}`);
};

/**
 * Maps a Gemstone application route to a React Marketplace URL.
 *
 * Example of matches:
 * - app/product/credit-cards?filterBy=Cash20%Back -> /marketplace/credit-cards/category/cash-back
 * - /app/product/bank-accounts -> /marketplace/bank-accounts
 *
 * @param {GemstoneAppRoute} gemstoneRoute - The Gemstone application route.
 * @returns {string | undefined} - The React Marketplace URL, or undefined if no matching route is found.
 */
export const gemstoneMapUrlToReactMarketplaceUrl = (gemstoneRoute: GemstoneAppRoute): string | undefined => {
  const matchedGemstoneRoute = findGemstoneAppRoute(gemstoneRoute);

  if (!matchedGemstoneRoute) return;

  const matchedMarketplaceRoute = GEMSTONE_APP_ROUTES_TO_MARKETPLACE_ROUTES_MAPPING[matchedGemstoneRoute];
  if (!matchedMarketplaceRoute) return;

  return updateFilter(gemstoneRoute, matchedMarketplaceRoute.toString());
};

const findGemstoneAppRoute = (route: GemstoneAppRoute): GemstoneAppRoute | undefined => {
  if (!route) return;
  const providedSanitizedRoute = sanitizeRoute(route);
  return findMatchedRoute(providedSanitizedRoute);
};

const updateFilter = (gemstoneRoute: GemstoneAppRoute, matchedMarketplaceRoute: string): string => {
  if (!hasQueryParams(gemstoneRoute)) return matchedMarketplaceRoute;

  const originalQueryParamValue = getFilterParamFromPath(gemstoneRoute);
  const marketplaceCategoryKey = originalQueryParamValue as MarketplaceCreditCardCategoryKey;
  const category = MARKETPLACE_CREDIT_CARD_CATEGORY_PATHNAMES[marketplaceCategoryKey];

  return matchedMarketplaceRoute.replace(":category", category);
};

const hasQueryParams = (route: string): boolean => route.indexOf("?") !== -1;

const sanitizeRoute = (route: string): string =>
  route.replace(/^\//, "").replace(/(\?filterBy=)(.*)/i, "?filterBy=:category");

const findMatchedRoute = (providedRoute: string): GemstoneAppRoute | undefined =>
  Object.values(GEMSTONE_APP_ROUTES).find((existingRoute) => {
    const validSanitizedRoute = existingRoute.replace(/^\//, "");
    return validSanitizedRoute.toLowerCase().startsWith(providedRoute.toLowerCase());
  });

/**
 * Navigates to a Gemstone application route.
 * @param {string} path - The Gemstone application route.
 * @param {boolean} isNewTab - Whether to open the route in a new tab.
 * @returns {void}
 * @example
 * gemstoneNavigate(GEMSTONE_APP_ROUTES.CREDIT_BUILDER);
 * gemstoneNavigate(GEMSTONE_APP_ROUTES.CREDIT_BUILDER, true);
 * The timeout is added to prevent the calls from being cancelled by the browser upon navigation.
 **/
export const gemstoneNavigate = (path: string, isNewTab = false) => {
  isNewTab
    ? window.open(path)
    : setTimeout(() => {
        window.location.assign(path);
      }, 500);
};
