import React from "react";

import { Skeleton } from "@bwll/bw-components/next";
import { useBreakpoints, usePromotions } from "@bwll/bw-hooks";
import { PROMOTION_TYPE, PromotionSnackbar } from "@bwll/bw-types";

import { MarketplaceSnackbarCard } from "../MarketplaceSnackbarCard";
import { RecommendationsSnackbarCard } from "../RecommendationsSnackbarCard";

/**
 * A wrapper around the RecommendationsSnackbarCard and MarketplaceSnackbarCard components
 * that controls which one to render based on the data returned from the usePromotions hook
 */
export const SnackbarCard = () => {
  const { isLoading, data } = usePromotions({
    promotionType: PROMOTION_TYPE.SNACKBAR,
  });
  const snackbarData = data?.data[0] as unknown as PromotionSnackbar;
  const { isMobile } = useBreakpoints();

  const isMarketplaceSnackbar = !!snackbarData;
  const CARD_HEIGHT = 56;
  const MOBILE_CARD_HEIGHT = 141;

  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height={isMobile ? MOBILE_CARD_HEIGHT : CARD_HEIGHT} />;
  }

  if (!isMarketplaceSnackbar) {
    return <RecommendationsSnackbarCard />;
  }

  return <MarketplaceSnackbarCard marketplaceSnackbarData={snackbarData} />;
};
