/** Test IDs */
export const PRODUCT_RECOMMENDATIONS_PROMO_CARD_TEST_ID = "product-recommendations-promo-card";
export const PRODUCT_RECOMMENDATIONS_PROMO_CARD_CTA_BUTTON_TEST_ID =
  "product-recommendations-promo-card-button-cta";
export const PRODUCT_RECOMMENDATIONS_PROMO_CARD_SKELETON_TEST_ID =
  "product-recommendations-promo-card-skeleton";

/** Product Recommendations PromoCard height according to Figma */
export const CARD_HEIGHT = 133;
export const MOBILE_CARD_HEIGHT = 153;
