import { isDateValid } from "./isDateValid";
import { PossiblyValidDate } from "./types";

/**
 * Returns a standard ISO-8601 date string from the given date
 *
 * @example
 * getISO('2024-01-01') // will return the ISO-8601 date string based on the "Jan 1st, 2024" date
 * getISO() // will return the ISO-8601 date string based on the current date and time
 */
export const getISO = (value?: PossiblyValidDate): string => {
  const today = new Date();

  if (value) {
    const date = new Date(value);

    if (isDateValid(date)) {
      return date.toISOString();
    }
  }

  return today.toISOString();
};
