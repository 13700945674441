import styled from "@emotion/native";

import { isWeb } from "@bwll/bw-components/next/constants";
import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { BUTTON_MAX_WIDTH } from "./AddressForm.constants";

export const Container = styled.View`
  flex: 1 1 100%;
`;

export const BottomCtaContainer = styled.View`
  width: 100%;
  max-width: ${styledValue(BUTTON_MAX_WIDTH)};
`;

const determineZIndex = (providedZIndex?: number) => {
  if (!providedZIndex) {
    return isWeb ? "unset" : 0;
  }

  return providedZIndex;
};

export const SectionContainer = styled.View<{ isRow: boolean; zIndex?: number }>`
  z-index: ${({ zIndex }) => determineZIndex(zIndex)};
  column-gap: ${({ isRow }) => styledValue(isRow ? spacing.xs : spacing.none)};
  flex-direction: ${({ isRow }) => (isRow ? "row" : "column")};
`;

export const SectionItem = styled.View<{ flex: number; zIndex?: number }>`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: ${({ flex }) => `${flex * 100}%`};
  z-index: ${({ zIndex }) => determineZIndex(zIndex)};
  margin-bottom: ${styledValue(spacing.xs)};
`;
