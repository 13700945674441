import { IconNames } from "@bwll/bw-components/next";
import { CREDIT_CARD_CATEGORY_KEYS, CreditCardCategoryKey } from "@bwll/bw-types";

export const creditCardsCategoryHeaderTestIds = {
  title: "credit-cards-header-title",
  titleIcon: "credit-cards-header-title-icon",
  description: "credit-cards-header-description",
};

/**
 * Mapping for category icons.
 */
export const CREDIT_CARD_CATEGORY_ICONS = {
  [CREDIT_CARD_CATEGORY_KEYS.TOP_PICKS]: "flag_thin",
  [CREDIT_CARD_CATEGORY_KEYS.TRAVEL]: "plane_thin_active",
  [CREDIT_CARD_CATEGORY_KEYS.CASH_BACK]: "cash_back",
  [CREDIT_CARD_CATEGORY_KEYS.REWARDS]: "rewards",
  [CREDIT_CARD_CATEGORY_KEYS.NO_FEE]: "no_fee",
  [CREDIT_CARD_CATEGORY_KEYS.PRE_QUALIFIED_OFFERS]: "checkmark_circle_thin",
  [CREDIT_CARD_CATEGORY_KEYS.BALANCE_TRANSFER]: "balance_transfer",
  [CREDIT_CARD_CATEGORY_KEYS.TRENDING]: "trending-up",
  [CREDIT_CARD_CATEGORY_KEYS.OTHER]: "card_2",
  [CREDIT_CARD_CATEGORY_KEYS.ALL]: "card_2",
} as const satisfies Record<CreditCardCategoryKey, IconNames>;
