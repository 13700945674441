import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ListRenderItem } from "react-native";

import { HorizontalCarousel } from "@bwll/bw-components/next";
import { useCreditScoreRequest } from "@bwll/bw-hooks";
import { COLORS, fontSize } from "@bwll/bw-styles";
import { LimitedTimeOffer } from "@bwll/bw-types";
import { getImpressionedUrl, uuid } from "@bwll/bw-utils";

import { LimitedTimeOfferCard } from "./LimitedTimeOfferCard/LimitedTimeOfferCard.component";
import { LIMITED_TIME_OFFER_CAROUSEL_TEST_ID } from "./LimitedTimeOfferCarousel.constants";
import { LimitedTimeOfferCarouselProps } from "./LimitedTimeOfferCarousel.types";

export const LimitedTimeOfferCarousel = ({
  dashboardLTOsPromoCard,
  primaryAction,
  secondaryAction,
}: LimitedTimeOfferCarouselProps) => {
  const { data: creditScoreRequest } = useCreditScoreRequest();
  const individualClientIdReferenceNumber = creditScoreRequest?.individualClientIdReferenceNumber ?? 0;
  const i18next = useTranslation();
  const cards = useMemo(() => {
    return (
      dashboardLTOsPromoCard?.limitedTimeOffers?.map((lto) => ({
        ...lto,
        productImpressionId: uuid(),
      })) ?? []
    );
  }, [dashboardLTOsPromoCard?.limitedTimeOffers]);
  const [activeProductId, setActiveProductId] = useState(cards[0]?.id);

  const handleChange = useCallback((item: LimitedTimeOffer) => {
    setActiveProductId(item.id);
  }, []);

  const renderItem: ListRenderItem<LimitedTimeOffer> = useCallback(
    ({ item, index }) => {
      /** @TODO Limited time offer carousel should not populate if credit score hasn't returned, as CID will be populated incorrectly */
      item.productUrl =
        getImpressionedUrl(item.productUrl, individualClientIdReferenceNumber, item.productImpressionId ?? "")
          .impressionedUrl ?? "";
      return (
        <LimitedTimeOfferCard
          activeProductId={activeProductId ?? ""}
          limitedTimeOffer={item}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
          position={index}
        />
      );
    },
    [activeProductId, individualClientIdReferenceNumber, primaryAction, secondaryAction],
  );

  if (!dashboardLTOsPromoCard || !dashboardLTOsPromoCard.limitedTimeOffers) {
    return null;
  }

  return (
    <HorizontalCarousel
      testID={LIMITED_TIME_OFFER_CAROUSEL_TEST_ID}
      onChange={handleChange}
      data={cards}
      renderItem={renderItem}
      title={dashboardLTOsPromoCard.title}
      iconName="zap-fast"
      iconSize={fontSize.xl}
      iconColor={COLORS.RED["500"]}
      renderLabelText={(params) => i18next.t("dashboard:cards:ltoCarousel:label", params)}
    />
  );
};
