import axios from "axios";
import { useQuery } from "react-query";
import { v5 as uuid } from "uuid";

import { useEnvironmentContext } from "@bwll/bw-hooks";
import { useSessionContext } from "@bwll/bw-hooks/src/contexts";
import { MemberData, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

export const fetchMember = async (accessToken: string, apiGatewayUrl: string): Promise<MemberData> => {
  const response = await axios.get<MemberData>(`${apiGatewayUrl}/transition/Member`, {
    headers: generateApiHeaders(accessToken),
  });

  return response.data;
};

export const useTransitionGetMember = () => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  // We can assert on the values below because `enabled` will not allow the query
  // to run until they are defined.
  return useQuery(
    generateQueryKey(QUERY_KEYS.MEMBER_CALL, accessToken),
    () => fetchMember(accessToken as string, envConfigs.API_GATEWAY_URL),
    {
      ...queryBehavior({
        expiresIn,
        enabled: !!accessToken,
      }),
      select: (data) => {
        const { individual, creditScoreRequest, creditScores, relativeScoreChange } = data;

        return {
          individual,
          creditScoreRequest,
          persistenceKey: uuid(creditScoreRequest.individualClientId, envConfigs.SESSION_NAMESPACE),
          creditScores,
          relativeScoreChange,
        };
      },
    },
  );
};

export type TransitionMember = ReturnType<typeof useTransitionGetMember>;
