import styled from "@emotion/styled";

import { mq, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const FullWidthContainer = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: `${styledValue(spacing.m)}`,
});

export const MainContentFullWidthContainer = styled.div(() =>
  mq({
    width: "100%",
    display: "flex",
    flexDirection: ["column", "row", "row"],
    gap: [`${styledValue(spacing.m)}`, `${styledValue(spacing.xs)}`, `${styledValue(spacing.xs)}`],
  }),
);

export const MainContentContainer = styled.div(() =>
  mq({
    width: ["100%", "65%", "65%"],
    display: "flex",
    flexDirection: "column",
    gap: `${styledValue(spacing.m)}`,
  }),
);

export const SideContentContainer = styled.div(() =>
  mq({
    width: ["100%", "35%", "35%"],
    display: "flex",
    flexDirection: "column",
    gap: `${styledValue(spacing.m)}`,
  }),
);
