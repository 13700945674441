import styled, { css } from "@emotion/native";

import { Caption } from "@bwll/bw-components/next";
import { COLORS, borderRadius, fontSize, spacing, toRGBAString } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const TooltipTriggerContainer = styled.Pressable`
  align-items: center;
  justify-content: center;
  width: ${styledValue(spacing.xs)};
  height: ${styledValue(spacing.xs)};
  border-radius: ${styledValue(spacing.xxs)};
  border: 1px solid ${COLORS.NEUTRAL.COOL["600"]};
`;

export const TooltipTriggerText = styled(Caption)`
  font-size: ${styledValue(fontSize.xxxs)};
  letter-spacing: 0px;
  line-height: ${styledValue(fontSize.xxs)};
  color: ${COLORS.NEUTRAL.COOL["600"]};
`;

export const ModalContainer = styled.View`
  position: relative;
  flex: 1;
  background-color: ${toRGBAString(COLORS.BLACK, 0.4)};
`;

export const contentContainerStyle = css`
  flex-grow: 1;
`;

const MODAL_CONTENT_WIDTH = 340;
//  top: ${styledValue(170)};

export const ModalContent = styled.View`
  position: absolute;
  padding: ${styledValue(spacing.xs)};
  top: 15%;
  left: 50%;
  border-radius: ${styledValue(borderRadius.s)};
  transform: ${`translateX(${styledValue(-Math.floor(MODAL_CONTENT_WIDTH / 2))})`};

  width: ${styledValue(MODAL_CONTENT_WIDTH)};
  border: 1px solid ${COLORS.NEUTRAL.COOL["100"]};
  background-color: white;
`;

export const ModalHeader = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${styledValue(spacing.xxs)};
`;
