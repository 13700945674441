import React from "react";
import { useTranslation } from "react-i18next";

import { SponsoredProductDetailsTemplate } from "../SponsoredProductDetailsTemplate";
import { SponsoredLoanDetailsProps } from "./SponsoredLoanDetails.types";

export const SponsoredLoanDetails = ({ loanAmount, apr, term }: SponsoredLoanDetailsProps) => {
  const i18next = useTranslation();

  return (
    <SponsoredProductDetailsTemplate
      details={[
        {
          label: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:loans:loanAmount"),
          value: loanAmount,
        },
        {
          label: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:loans:apr"),
          value: apr,
        },
        {
          label: i18next.t("dashboard:cards:sponsoredCarousel:sponsoredCards:loans:term"),
          value: term,
        },
      ]}
    />
  );
};
