import React from "react";

import { Skeleton } from "@bwll/bw-components/next";
import { useCurrentCreditScore, useGetCreditFactorsSummary } from "@bwll/bw-hooks";

import { CreditScoreGraph as CreditScoreGraphUI } from "./CreditScoreGraph.component";
import { CARD_HEIGHT, GRAPH_SCORE_SKELETON_TEST_ID } from "./CreditScoreGraph.constants";
import type { CreditScoreGraphContainerProps } from "./CreditScoreGraph.types";

/**
 * Component represents a data container for the CS Graph UI.
 * @param {CreditScoreGraphContainerProps} props - container props interface
 * @property {Function} onPress - the main CTA press handler
 * @property {Function} onTooltipLinkPress - the tooltip link press handler
 * @property {Function} [onTooltipPress] - the optional tooltip trigger press handler
 */
export const CreditScoreGraphContainer = (props: CreditScoreGraphContainerProps) => {
  const { isLoading: isCurrentGetCreditScoreLoading, isError, ...creditScoreData } = useCurrentCreditScore();
  const { data: creditFactorsSummary, isLoading: isGetCreditFactorsSummaryLoading } =
    useGetCreditFactorsSummary();

  if (isCurrentGetCreditScoreLoading || isGetCreditFactorsSummaryLoading) {
    return (
      <Skeleton testID={GRAPH_SCORE_SKELETON_TEST_ID} variant="rounded" width="100%" height={CARD_HEIGHT} />
    );
  }

  if (isError) {
    /** @TODO handle error */
    return null;
  }

  return <CreditScoreGraphUI creditFactorsSummary={creditFactorsSummary} {...creditScoreData} {...props} />;
};
