import styled from "@emotion/native";

import { Label as DefaultLabel } from "@bwll/bw-components/next";
import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Table = styled.View<{ isMobile: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${COLORS.WHITE};
`;

export const Cell = styled.View`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${styledValue(spacing.xxs)};
`;

export const Label = styled(DefaultLabel)`
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;

export const TextValue = styled.View`
  flex-direction: row;
  font-weight: 600;
`;
