import axios, { AxiosRequestConfig } from "axios";
import jwtDecode from "jwt-decode";
import { useCallback, useMemo } from "react";
import { useMutation } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks/src/contexts";
import { ANALYTICS_ATTRIBUTES, MutationOptions } from "@bwll/bw-types";
import { generateApiHeaders, getISO as getDateIsoString } from "@bwll/bw-utils";

type IEvents = {
  [ANALYTICS_ATTRIBUTES.EVENT_LABEL]?: string;
  [ANALYTICS_ATTRIBUTES.SCREEN_NAME]?: string;
  [ANALYTICS_ATTRIBUTES.SHOULD_EMIT_TO_SEGMENT]: boolean;
  [key: string]: string | boolean | number | undefined;
};

const eventAction = "event";
const eventCategory = "event";

const getCommonAttributes = (accessToken: string) => {
  const {
    individualClientId,
    bwsid: authenticatedSessionId,
    auth_time: sessionStartTime,
  } = jwtDecode(accessToken);

  return {
    [ANALYTICS_ATTRIBUTES.AUTHENTICATED_SESSION_ID]: authenticatedSessionId,
    [ANALYTICS_ATTRIBUTES.EVENT_ACTION]: eventAction,
    [ANALYTICS_ATTRIBUTES.EVENT_CATEGORY]: eventCategory,
    [ANALYTICS_ATTRIBUTES.INDIVIDUAL_CLIENT_ID]: individualClientId,
    [ANALYTICS_ATTRIBUTES.SHOULD_EMIT_TO_SEGMENT]: true,
    [ANALYTICS_ATTRIBUTES.SESSION_START_TIME]: sessionStartTime,
  };
};

const sendTrackEventAnalytics = async (
  accessToken: string,
  baseUrl: string,
  event: object & IEvents,
  isProduction: boolean,
  requestConfig?: AxiosRequestConfig,
) => {
  const response = await axios.post<object>(`${baseUrl}`, event, {
    ...requestConfig,
    headers: generateApiHeaders(accessToken),
  });

  if (!isProduction) {
    console.log(
      `[App Insight Analytics] Event "${event?.[`${ANALYTICS_ATTRIBUTES.EVENT_LABEL}`]}" on "${
        event?.[`${ANALYTICS_ATTRIBUTES.SCREEN_NAME}`] ??
        event?.[`${ANALYTICS_ATTRIBUTES.CURRENT_STEP_NAME}`] ??
        event?.[`${ANALYTICS_ATTRIBUTES.PAGE}`]
      }"`,
      event,
    );

    if (event?.[`${ANALYTICS_ATTRIBUTES.SHOULD_EMIT_TO_SEGMENT}`]) {
      console.log(
        `[Segment Analytics] Event "${event?.[`${ANALYTICS_ATTRIBUTES.EVENT_LABEL}`]}" on "${
          event?.[`${ANALYTICS_ATTRIBUTES.SCREEN_NAME}`] ??
          event?.[`${ANALYTICS_ATTRIBUTES.CURRENT_STEP_NAME}`] ??
          event?.[`${ANALYTICS_ATTRIBUTES.PAGE}`]
        }"`,
        event,
      );
    }
  }

  return response.data;
};

/**
 * Sends an event to the Analytics tracking system using `fetch`, with `keepalive` set to true.
 * @param accessToken The user's authorization access token.
 * @param url The Analytics URL to POST to.
 * @param eventPayload The event payload.
 * @param isProduction When false, logs event data to console.
 * @returns A promise that does not need to be awaited.
 */
const sendTrackEventFetch = (
  accessToken: string,
  url: string,
  eventPayload: object & IEvents,
  isProduction: boolean,
) => {
  const promise = fetch(url, {
    keepalive: true,
    method: "POST",
    headers: generateApiHeaders(accessToken),
    body: JSON.stringify(eventPayload),
  });

  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.log(
      `[Analytics] Event "${eventPayload[ANALYTICS_ATTRIBUTES.EVENT_LABEL]}" on "${
        eventPayload[ANALYTICS_ATTRIBUTES.SCREEN_NAME] ??
        eventPayload[ANALYTICS_ATTRIBUTES.CURRENT_STEP_NAME] ??
        eventPayload[ANALYTICS_ATTRIBUTES.PAGE]
      }"`,
      eventPayload,
    );
  }

  return promise;
};

/**
 * Gets a React Query mutation that sends events to the Analytics service. Call `mutate` on the returned object to post.
 *
 * The event will include some common properties.
 * - authenticatedSessionId
 * - eventAction
 * - eventCategory
 * - eventTimestamp
 * - individualClientId
 * - shouldEmitToSegment
 * @see {@link https://tanstack.com/query/v3/docs/framework/react/guides/mutations}
 * @returns A mutation object.
 */
export const useTrackEvent = ({
  onSuccess,
  onError,
  onSettled,
  onMutate,
  requestConfig,
}: MutationOptions = {}) => {
  const { userData } = useSessionContext();
  const { envConfigs, isProduction } = useEnvironmentContext();

  const { accessToken } = userData;

  const commonAttributes = useMemo(() => getCommonAttributes(accessToken), [accessToken]);

  return useMutation(
    (data: Record<string, string | boolean | number | undefined>) =>
      sendTrackEventAnalytics(
        accessToken,
        envConfigs.ANALYTICS_URL,
        {
          ...commonAttributes,
          [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]: getDateIsoString(),
          ...data,
        },
        isProduction,
        requestConfig,
      ),
    {
      onSuccess,
      onError,
      onSettled,
      onMutate,
    },
  );
};

/**
 * Gets a function that, when called, sends an event to Analytics Service.
 *
 * This version of `useTrackEvent` does not use React Query, mutations or axios.
 * @returns An asynchronous function that sends an event to Analytics Service.
 */
export const useTrackEventDirect = () => {
  const { userData } = useSessionContext();
  const { envConfigs, isProduction } = useEnvironmentContext();

  const { accessToken } = userData;

  const commonAttributes = useMemo(() => getCommonAttributes(accessToken), [accessToken]);

  return useCallback(
    (data: Record<string, string | boolean | number | string[] | undefined>) =>
      sendTrackEventFetch(
        accessToken,
        envConfigs.ANALYTICS_URL,
        { ...commonAttributes, [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]: getDateIsoString(), ...data },
        isProduction,
      ),
    [accessToken, commonAttributes, envConfigs.ANALYTICS_URL, isProduction],
  );
};
