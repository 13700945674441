import React, { useCallback } from "react";

import { useExperiments } from "@bwll/bw-hooks";
import { LimitedTimeOfferCarousel } from "@bwll/bw-modules";
import {
  DASHBOARD_ANALYTICS_ORIGINS,
  LimitedTimeOffer,
  PRODUCT_VERTICAL_GUID,
  PRODUCT_VERTICAL_ID,
  WEB_EXPERIMENT_IDS,
  WEB_TREATMENT_IDS,
} from "@bwll/bw-types";

import { useDashboardProductApplication, useInternalNavigate } from "@app/hooks";

export const LimitedTimeOfferCarouselCard = () => {
  const { navigateToProduct } = useInternalNavigate();
  const shouldShowPrimeDashboardLTOs = useExperiments({
    experimentId: WEB_EXPERIMENT_IDS.ENABLE_PRIME_DASHBOARD_LTOS,
    treatments: [WEB_TREATMENT_IDS.primeDashboardLTOs.ON],
  });
  const productApply = useDashboardProductApplication(DASHBOARD_ANALYTICS_ORIGINS.LTO_PLACEMENT);

  const applyToProduct = useCallback(
    (limitedTimeOffer: LimitedTimeOffer) => {
      productApply({
        ...limitedTimeOffer,
        productVerticalId: PRODUCT_VERTICAL_GUID.CREDIT_CARD,
        impressionedProductLink: limitedTimeOffer.productUrl,
        productReferenceNumber: limitedTimeOffer.productIdReferenceNumber,
      });
    },
    [productApply],
  );
  const goToDeatilsPage = useCallback(
    (limitedTimeOffer: LimitedTimeOffer) => {
      navigateToProduct(
        PRODUCT_VERTICAL_ID.CREDIT_CARD,
        limitedTimeOffer.id,
        `origin=${DASHBOARD_ANALYTICS_ORIGINS.LTO_PLACEMENT}`,
      );
    },
    [navigateToProduct],
  );
  if (!shouldShowPrimeDashboardLTOs) return null;
  return <LimitedTimeOfferCarousel primaryAction={applyToProduct} secondaryAction={goToDeatilsPage} />;
};
