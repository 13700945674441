import styled, { css } from "@emotion/native";

import { Heading2 } from "@bwll/bw-components/next";
import { COLORS, fontSize, fontWeights, letterSpacing, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

const rowCenter = css`
  flex-direction: row;
  align-items: center;
`;

export const ContentContainer = styled.View`
  width: 100%;
  margin-vertical: ${styledValue(spacing.xs)};
  flex-direction: column;
  gap: ${styledValue(16)};
`;

export const TitleContainer = styled.View`
  ${rowCenter};
  gap: ${styledValue(8)};
`;

export const Row = styled.View`
  ${rowCenter};
  width: 100%;
`;

export const TitleText = styled(Heading2)`
  color: ${COLORS.NEUTRAL.WARM["800"]};
  font-size: ${styledValue(fontSize.m)};
  font-weight: ${fontWeights.bold};
  line-height: ${styledValue(lineHeight.xl)};
  letter-spacing: ${styledValue(letterSpacing.xs)};
`;
