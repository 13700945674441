import { useCallback, useMemo } from "react";
import { Platform } from "react-native";

import {
  ANALYTICS_ATTRIBUTES,
  ANALYTICS_VALUES,
  HISTORICAL_REPORTING_ANALYTICS_EVENT_NAME,
} from "@bwll/bw-types";
import { getISO } from "@bwll/bw-utils";

import { TFeatureAnalyticsAttributes } from "../../analyticsContext.types";
import { AnalyticsEventProps } from "../../featuresAttributes";

export const useHistoricalReportingEvents = ({ trackEvent, commonAttributes }: AnalyticsEventProps) => {
  return useMemo(
    () => ({
      onHistoricalReportingScreenViewed: <T>(featureAttributes: TFeatureAnalyticsAttributes<T>) => {
        const customAttributes = {
          ...commonAttributes,
          ...featureAttributes,
          ...(Platform.OS === "web"
            ? { [ANALYTICS_ATTRIBUTES.APPLICATION_TYPE]: ANALYTICS_VALUES.SINGLE_SPA }
            : {
                [ANALYTICS_ATTRIBUTES.APPLICATION_TYPE]: ANALYTICS_VALUES.REACT_NATIVE,
              }),
          [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]: getISO(),
        };

        trackEvent(HISTORICAL_REPORTING_ANALYTICS_EVENT_NAME.HISTORICAL_REPORTING, customAttributes);
      },

      onHistoricalReportingButtonClicked: <T>(
        featureAttributes: TFeatureAnalyticsAttributes<T>,
        onSettled?: (arg: unknown) => void,
      ) => {
        const customAttributes = {
          ...commonAttributes,
          ...featureAttributes,
          ...(Platform.OS === "web"
            ? { [ANALYTICS_ATTRIBUTES.APPLICATION_TYPE]: ANALYTICS_VALUES.SINGLE_SPA }
            : {
                [ANALYTICS_ATTRIBUTES.APPLICATION_TYPE]: ANALYTICS_VALUES.REACT_NATIVE,
              }),
          [ANALYTICS_ATTRIBUTES.EVENT_TIMESTAMP]: getISO(),
        };

        trackEvent(
          HISTORICAL_REPORTING_ANALYTICS_EVENT_NAME.HISTORICAL_REPORTING,
          customAttributes,
          onSettled,
        );
      },
    }),
    [trackEvent, commonAttributes],
  );
};
