import React, { useCallback } from "react";

import { TopPicksPromoCardsEventData, useAnalyticsContext } from "@bwll/bw-hooks";

import { OffersPromoCard } from "../OffersPromoCard";
import { TRENDING_OFFERS_PROMO_CARD_TEST_ID } from "./TrendingOffersPromoCard.constants";
import { TrendingOffersPromoCardProps } from "./TrendingOffersPromoCard.types";
import { useTrendingOffersCommonAttributes } from "./useTrendingOffersCommonAttributes";

export const TrendingOffersPromoCard = ({ promoCard, onPress }: TrendingOffersPromoCardProps) => {
  const { promoCardViewed, promoCardClicked } = useAnalyticsContext();
  const analyticsAttributes = useTrendingOffersCommonAttributes(promoCard);

  const onTrackView = useCallback(() => {
    promoCardViewed<TopPicksPromoCardsEventData>(analyticsAttributes);
  }, [analyticsAttributes, promoCardViewed]);

  const clickAction = useCallback(() => {
    promoCardClicked<TopPicksPromoCardsEventData>(analyticsAttributes);
    onPress(promoCard.productRoute.path);
  }, [analyticsAttributes, onPress, promoCard.productRoute.path, promoCardClicked]);

  return (
    <OffersPromoCard
      testID={TRENDING_OFFERS_PROMO_CARD_TEST_ID}
      promoCard={promoCard}
      onTrackView={onTrackView}
      clickAction={clickAction}
    />
  );
};
