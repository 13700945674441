import React, { ReactNode, useCallback } from "react";
import { Text, View } from "react-native";
import { ASTNode, RenderFunction } from "react-native-markdown-display";

import { Body2, Heading1, Heading2 } from "../../..";
import {
  CustomLink2,
  SmallScript,
  SmallScriptWrapper,
  listItemBulletStyles,
  listItemWrapperStyles,
} from "../Markdown.styles";

type NodeKey = {
  key: string;
  // We use `style: any` because it must match RenderFunction type.
  attributes: Record<string, string>;
};

type LinkFunctions = {
  onLinkPress?: (url: string) => void;
  handlers?: Record<string, (url: string) => void>;
};

export const MDHeading1: RenderFunction = (node: NodeKey, children: ReactNode, _parent: ASTNode[]) => (
  <Heading1 key={node.key}>{children}</Heading1>
);

export const MDHeading2: RenderFunction = (node: NodeKey, children: ReactNode, _parent: ASTNode[]) => (
  <Heading2 key={node.key}>{children}</Heading2>
);

export const RenderLink =
  ({ onLinkPress, handlers }: LinkFunctions) =>
  // eslint-disable-next-line react/display-name
  (node: { key: string; attributes: Record<string, string> }, _children: ReactNode) => {
    return (
      <MDLink node={node} onLinkPress={onLinkPress} handlers={handlers}>
        {_children}
      </MDLink>
    );
  };

const noop = () => null;

export const MDLink = ({
  node,
  onLinkPress,
  children,
  handlers,
}: {
  node: NodeKey;
  children: ReactNode;
} & LinkFunctions) => {
  const onPress = handlers?.[node.attributes?.href] ?? onLinkPress ?? noop;

  const onDefaultLinkPress = useCallback(() => {
    onPress?.(node.attributes?.href ?? "#");
  }, [onPress, node.attributes?.href]);

  return (
    <CustomLink2 key={node.key} onPress={onDefaultLinkPress}>
      {children}
    </CustomLink2>
  );
};

export const MDBody2: RenderFunction = (node: NodeKey, children: ReactNode) => (
  <Body2 key={node.key}>{children}</Body2>
);

export const MDBoldBody2: RenderFunction = (node: NodeKey, children: ReactNode) => (
  <Body2 key={node.key} variant="bold">
    {children}
  </Body2>
);

export const MDSoftBreak: RenderFunction = () => <Text>{"\n"}</Text>;

export const MDBulletList: RenderFunction = (
  node: NodeKey,
  children: ReactNode,
  _parent: ASTNode[],
  // We use `style: any` because it must match RenderFunction type.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles: any,
) => (
  <View key={node.key} style={styles._VIEW_SAFE_bullet_list}>
    {children}
  </View>
);

export const MDOrderedList: RenderFunction = (
  node: NodeKey,
  children: ReactNode,
  _parent: ASTNode[],
  // We use `style: any` because it must match RenderFunction type.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles: any,
) => {
  return (
    <View key={node.key} style={styles._VIEW_SAFE_ordered_list}>
      {children}
    </View>
  );
};

export const MDListItem = ({
  node: { key },
  children,
  styles,
  color,
}: {
  node: { key: string; content: string };
  children: ReactNode;
  // We use `style: any` because it must match RenderFunction type.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles: any;
  color?: string;
}) => {
  return (
    <View key={key} style={{ ...styles._VIEW_SAFE_list_item, ...listItemWrapperStyles }}>
      <Body2 color={color} style={listItemBulletStyles}>
        {"\u2022"}
      </Body2>
      <Body2 color={color} style={styles._VIEW_SAFE_bullet_list_content}>
        {children}
      </Body2>
    </View>
  );
};

export const RenderListItem =
  ({ color }: { color?: string }): RenderFunction =>
  // We use `style: any` because it must match RenderFunction type.
  // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
  (node: { key: string; content: string }, children, _parent, styles: any) => {
    return (
      <MDListItem node={node} color={color} styles={styles}>
        {children}
      </MDListItem>
    );
  };

export const Superscript = ({ children }: { children: ReactNode }) => {
  return (
    <SmallScriptWrapper>
      <SmallScript>{children}</SmallScript>
    </SmallScriptWrapper>
  );
};

export const MDSuperscript: RenderFunction = (node: { content?: string; key: string }) => {
  const content = node?.content?.trim() ?? "";
  return <Superscript key={node.key}>{content}</Superscript>;
};
