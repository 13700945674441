import {
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
} from "date-fns";

import { isDateValid } from "./isDateValid";
import { DateTimeUnit, PossiblyValidDate } from "./types";

/**
 * Returns the numeric difference between 2 given dates by the time unit (default is 'milliseconds')
 *
 * @example
 * getDateTimeDiff(new Date(2024, 0, 2), new Date(2024, 0, 1), 'hour') // 24
 * getDateTimeDiff(new Date(2024, 0, 2, new Date(2024, 0, 1)) // 86400000
 */
export const getDateTimeDiff = (
  dateA: PossiblyValidDate,
  dateB: PossiblyValidDate,
  unit: DateTimeUnit = "millisecond",
): number => {
  const defaultDiff = 0;
  const convertedDateA = new Date(dateA);
  const convertedDateB = new Date(dateB);

  if (!isDateValid(convertedDateA) || !isDateValid(convertedDateB)) {
    return defaultDiff;
  }

  switch (unit) {
    case "year":
      return differenceInYears(convertedDateA, convertedDateB);
    case "month":
      return differenceInMonths(convertedDateA, convertedDateB);
    case "day":
      return differenceInDays(convertedDateA, convertedDateB);
    case "hour":
      return differenceInHours(convertedDateA, convertedDateB);
    case "minute":
      return differenceInMinutes(convertedDateA, convertedDateB);
    case "second":
      return differenceInSeconds(convertedDateA, convertedDateB);
    default:
      return differenceInMilliseconds(convertedDateA, convertedDateB);
  }
};
