import i18next from "i18next";
import { useMemo } from "react";

import { useLatestRentPeriodTransactions } from "@bwll/bw-hooks";
import { reportingStatusBadgeStyle } from "@bwll/bw-styles";
import { DATE_FORMATS, RENT_REPORTING_STATUS, ReportingStatusConfig } from "@bwll/bw-types";
import { adjustDateString, formatDate, isValidRentReportingStatus } from "@bwll/bw-utils";

export const useReportingStatusConfig = () => {
  const { data } = useLatestRentPeriodTransactions();
  const reportedToAgencyDate = data?.transactions[0].reportedToAgencyDate;
  const reportingStatus = data?.transactions[0].reportingStatus;

  const result = useMemo<ReportingStatusConfig>(() => {
    const reportedPeriod = formatDate({
      dateString: reportedToAgencyDate,
      dateFormat: DATE_FORMATS.MMMM_yyyy,
    });
    const currentPeriod = formatDate({
      dateString: data?.rentPeriod,
      dateFormat: DATE_FORMATS.MMMM_yyyy,
      ignoreOffset: true,
    });
    const estimatedPaymentVerifiedDate = formatDate({
      dateString: adjustDateString({
        dateString: reportedToAgencyDate,
        duration: { months: 1 },
      }),
      dateFormat: "MMMM dd, yyyy",
    });

    const config = {
      [RENT_REPORTING_STATUS.REPORTED_AS_PAID]: {
        ...reportingStatusBadgeStyle.reportedAsPaid,
        iconName: "checkmark",
        title: i18next.t("rentReporting:reportingStatus:reportedAsPaid:title"),
        status: i18next.t("rentReporting:reportingStatus:reportedAsPaid:status"),
        tooltip: i18next.t("rentReporting:reportingStatus:reportedAsPaid:tooltip", {
          paymentPeriod: reportedPeriod,
          date: estimatedPaymentVerifiedDate,
        }),
        description: i18next.t("rentReporting:reportingStatus:reportedAsPaid:description", {
          paymentPeriod: reportedPeriod,
          date: estimatedPaymentVerifiedDate,
        }),
        hasTransactionFooter: true,
        hasContactSupportFooter: false,
      },
      [RENT_REPORTING_STATUS.REPORTED_AS_MISSED]: {
        ...reportingStatusBadgeStyle.reportedAsMissed,
        iconName: "close",
        title: i18next.t("rentReporting:reportingStatus:reportedAsMissed:title"),
        status: i18next.t("rentReporting:reportingStatus:reportedAsMissed:status"),
        tooltip: i18next.t("rentReporting:reportingStatus:reportedAsMissed:tooltip", {
          paymentPeriod: reportedPeriod,
          date: estimatedPaymentVerifiedDate,
        }),
        description: i18next.t("rentReporting:reportingStatus:reportedAsMissed:description", {
          paymentPeriod: reportedPeriod,
          date: estimatedPaymentVerifiedDate,
        }),
        hasTransactionFooter: false,
        hasContactSupportFooter: true,
      },
      [RENT_REPORTING_STATUS.WILL_BE_REPORTED_AS_PAID]: {
        ...reportingStatusBadgeStyle.willBeReportedAsPaid,
        iconName: "checkmark",
        title: i18next.t("rentReporting:reportingStatus:willBeReportedAsPaid:title"),
        status: i18next.t("rentReporting:reportingStatus:willBeReportedAsPaid:status"),
        tooltip: i18next.t("rentReporting:reportingStatus:willBeReportedAsPaid:tooltip", {
          paymentPeriod: currentPeriod,
        }),
        description: i18next.t("rentReporting:reportingStatus:willBeReportedAsPaid:description", {
          paymentPeriod: currentPeriod,
        }),
        hasTransactionFooter: true,
        hasContactSupportFooter: false,
      },
      [RENT_REPORTING_STATUS.WILL_BE_REPORTED_AS_MISSED]: {
        ...reportingStatusBadgeStyle.willBeReportedAsMissed,
        iconName: "close",
        title: i18next.t("rentReporting:reportingStatus:willBeReportedAsMissed:title"),
        status: i18next.t("rentReporting:reportingStatus:willBeReportedAsMissed:status"),
        tooltip: i18next.t("rentReporting:reportingStatus:willBeReportedAsMissed:tooltip", {
          paymentPeriod: currentPeriod,
        }),
        description: i18next.t("rentReporting:reportingStatus:willBeReportedAsMissed:description", {
          paymentPeriod: currentPeriod,
        }),
        hasTransactionFooter: false,
        hasContactSupportFooter: true,
      },
    };

    if (reportingStatus !== undefined && isValidRentReportingStatus(reportingStatus)) {
      return config[`${reportingStatus}`];
    } else {
      return {
        ...reportingStatusBadgeStyle.empty,
        iconName: "close",
        title: "",
        status: "",
        description: "",
        tooltip: "",
        hasTransactionFooter: false,
        hasContactSupportFooter: false,
      };
    }
  }, [reportingStatus, reportedToAgencyDate, data?.rentPeriod]);

  return result;
};
