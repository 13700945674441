export enum PreapprovalStatus {
  Preapproved = 0,
  Declined = 1,
  NotStarted = 2,
}

export interface PreapprovalOffer {
  offerId: string;
  termInMonths: number;
  termDurationMonths: number;
  loanAmount: number;
  monthlyPayment: number;
  apr: number;
  fees: number;
  totalCost: number;
}

// from api {{gatewayUrl}}/api/product-partnerships/preapprovals response
export interface Preapproval {
  expiresAt: string;
  partnerId: string;
  productVerticalReferenceId: string;
  status: PreapprovalStatus;
  applicationRedirectUrl?: string;
  applicationId?: string;
  daysLeft?: number;
  daysLeftLabel?: string;
  offers?: PreapprovalOffer[];
}
