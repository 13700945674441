import styled from "@emotion/native";

import { COLORS, borderRadius, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.View`
  flex: 1 0 auto;
`;

export const Row = styled.View`
  flex-direction: row;
  align-items: flex-start;
`;

export const ApprovalChanceContainer = styled.View`
  flex-direction: row;
  margin-bottom: ${styledValue(spacing.xxs)};
  align-items: center;
`;

export const ImageContainer = styled.View<{ width: number; height: number }>`
  width: ${({ width }) => styledValue(width)};
  height: ${({ height }) => styledValue(height)};
  margin-right: ${styledValue(spacing.xs)};
  align-items: flex-start;
`;

export const ProductImage = styled.Image`
  width: 100%;
  height: 100%;
`;

export const DataColumn = styled.View<{ minHeight: number }>`
  flex: 1;
  height: auto;
  min-height: ${({ minHeight }) => styledValue(minHeight)};
`;

export const SponsoredLabelContainer = styled.View`
  max-width: 100px;
  flex-direction: row;
  align-items: center;
`;

export const TooltipIconContainer = styled.View`
  margin-top: 2px;
`;

export const TitleContainer = styled.View`
  margin-vertical: ${styledValue(spacing.xxs)};
`;

export const CtaContainer = styled.View`
  margin-top: auto;
`;

export const ButtonContainer = styled.View`
  max-width: ${styledValue(261)};
`;

export const DescriptionContainer = styled.View`
  border-radius: ${styledValue(borderRadius.s)};
  background-color: ${COLORS.NEUTRAL.COOL["050"]};
  padding: ${styledValue(spacing.xs)};
  margin-top: ${styledValue(spacing.m)};
`;

export const DetailsContainer = styled.View<{ isMobile: boolean }>`
  margin-top: ${({ isMobile }) => styledValue(isMobile ? spacing.xs : spacing.none)};
`;

export const Banner = styled.View`
  position: absolute;
  top: ${styledValue(-spacing.xs - 1)};
  left: ${styledValue(-spacing.xs - 1)};
  right: ${styledValue(-spacing.xs - 1)};
  height: ${styledValue(spacing.m)};
  border-top-left-radius: ${styledValue(borderRadius.m)};
  border-top-right-radius: ${styledValue(borderRadius.m)};
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.TURQUOISE["900"]};
  text-align: center;
`;

export const Whitespace = styled.View`
  flex: 1;
  margin-top: auto;
`;
