import { useMemo } from "react";

import { AnalyticsEventProps, ON_LINK_CLICK_EVENT_NAMES, TFeatureAnalyticsAttributes } from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES } from "@bwll/bw-types";

const CARD_NAME = "credit coach updates";

export const useCreditCoachUpdatesCardAnalytics = ({ trackEvent, commonAttributes }: AnalyticsEventProps) => {
  return useMemo(
    () => ({
      buttonClicked: <T>(eventAttributes: TFeatureAnalyticsAttributes<T>) => {
        const customAttributes = {
          ...commonAttributes,
          ...eventAttributes,
          [ANALYTICS_ATTRIBUTES.INFO_CARD_NAME]: CARD_NAME,
        };

        trackEvent(ON_LINK_CLICK_EVENT_NAMES.INFO_CARD_CLICKED, customAttributes);
      },
    }),
    [trackEvent, commonAttributes],
  );
};
