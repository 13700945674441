const rawPhoneNumberMaxLength = 11;

/**
 * Formats a phone number to the format XXX-XXX-XXXX. Works with 10 or 11 digit phone numbers, with or without hyphens.
 * @param rawPhoneNumber The raw phone number to format.
 * @returns The formatted phone number.
 */
export const formatPhoneNumber = (rawPhoneNumber?: string) => {
  if (!rawPhoneNumber) return rawPhoneNumber;

  const phoneNumber =
    rawPhoneNumber.length === rawPhoneNumberMaxLength - 1 ? `1${rawPhoneNumber}` : rawPhoneNumber;

  if (
    phoneNumber[0] === "1" &&
    phoneNumber.length === rawPhoneNumberMaxLength &&
    phoneNumber.indexOf("-") === -1
  ) {
    const parts = phoneNumber
      .slice(1)
      .replace(/\D/g, "")
      .match(/(\d{3})(\d{3})(\d{4})/);
    const formattedPhoneNumber = `${parts?.[1]}-${parts?.[2]}-${parts?.[3]}`;

    return formattedPhoneNumber;
  }

  return rawPhoneNumber;
};
