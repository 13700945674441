import { PrimeCardType } from "@bwll/bw-types";

/** Test IDs */
export const TOP_PICKS_PROMO_CARDS_V2_TEST_ID = "top-picks-promo-cards-v2";
export const TOP_PICKS_PROMO_CARDS_TITLE_TEST_ID = "top-picks-promo-cards-title";
export const TOP_PICKS_CREDIT_CARDS_PROMO_CARD_V2_SKELETON_TEST_ID =
  "top-picks-credit-cards-promo-card-skeleton";
export const TOP_PICKS_CREDIT_CARDS_BANK_ACCOUNTS_V2_SKELETON_TEST_ID =
  "top-picks-bank-accounts-promo-card-skeleton";
export const TOP_PICKS_CREDIT_CARDS_PROMO_CARD_V2_TEST_ID = "top-picks-credit-cards-promo-card";
export const TOP_PICKS_CREDIT_CARDS_BANK_ACCOUNTS_V2_TEST_ID = "top-picks-bank-accounts-promo-card";
export const TOP_PICKS_CLU_PROMO_CARD_V2_TEST_ID = "top-picks-clu-promo-card";

export const TOP_PICKS_CLU_SKELETON_TEST_ID = "top-picks-clu-promo-card-skeleton";

/** TopPicks PromoCards height according to Figma */
export const TOP_PICKS_CARD_HEIGHT = 133;
export const TOP_PICKS_MOBILE_CARD_HEIGHT = 153;

/** TopPicks PromoCards Analytics Values */
export const PRIME_PRODUCT_PICKS_PROMO_CARD_NAME = "prime_product_picks";
export const COMPARE_BANK_ACCOUNTS_PROMO_CARD_NAME = "prime_compare_bank_accounts";
export const CLU_PROMO_CARD_NAME = "prime_credit_limit_utilization";
export const TOP_PICKS_PROMO_CARD_CATEGORY = "product_catalog";

/** CLU constants */
export const TOP_PICKS_TARGET_CREDIT_UTILIZATION = 20;

export const TOP_PICKS_TEST_ID_MAPPING = {
  [PrimeCardType.CreditCards]: TOP_PICKS_CREDIT_CARDS_PROMO_CARD_V2_TEST_ID,
  [PrimeCardType.BankAccounts]: TOP_PICKS_CREDIT_CARDS_BANK_ACCOUNTS_V2_TEST_ID,
  [PrimeCardType.CreditLimitUtilization]: TOP_PICKS_CLU_PROMO_CARD_V2_TEST_ID,
};
