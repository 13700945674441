import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const PageHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const PageTitleContainer = styled.View`
  flex: 1;
  margin-right: ${styledValue(spacing.xs)};
`;

export const PageHeaderButtonContainer = styled.View`
  margin-top: ${styledValue(spacing.xxxs)};
`;
