import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import type { Path } from "react-router-dom";
import { navigateToUrl } from "single-spa";

import { PRODUCT_APPLICATION_DESTINATION_TYPES, useProductApplication } from "@bwll/bw-hooks";
import { ImpressionedApplyableProduct, ImpressionedQuickApplyableProduct } from "@bwll/bw-types";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";
import { gemstoneNavigate } from "@app/helpers";

const marketplaceNavigate = (returnUrl: string, to: Partial<Path>) => {
  const searchParams = new URLSearchParams(to.search);
  searchParams.set("returnUrl", returnUrl);
  navigateToUrl(`${to.pathname}?${searchParams.toString()}`);
};

/**
 * Returns a function which, when called, will apply for the product on behalf of the user.
 *
 * - If product is not Quick Applyable, a window will be opened to the partner application page.
 * - If the product is Quick Applyable and the user is in the `sspaMarketplaceQuickApply` treatment, they will be navigated to the Quick Apply screen.
 * - If not in the `sspaMarketplaceQuickApply` treatment, they will be navigated to Gemstone Web's Quick Apply.
 */
export const useDashboardProductApplication = (
  origin?: string,
): ((product: ImpressionedApplyableProduct | ImpressionedQuickApplyableProduct) => void) => {
  const location = useLocation();
  const searchParams = {
    returnUrl: `/dashboard${location.pathname}${location.search}`,
    ...(origin ? { origin } : {}),
  };
  const getApplyDestination = useProductApplication(ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL, {
    searchParams,
  });

  return useCallback(
    (product) => {
      const destination = getApplyDestination(product);

      switch (destination.type) {
        case PRODUCT_APPLICATION_DESTINATION_TYPES.MARKETPLACE_QUICK_APPLY:
          navigateToUrl(destination.sspaUrl);
          break;
        case PRODUCT_APPLICATION_DESTINATION_TYPES.GEMSTONE_WEB_QUICK_APPLY:
          gemstoneNavigate(destination.url);
          break;
        case PRODUCT_APPLICATION_DESTINATION_TYPES.EXTERNAL_WEBSITE:
        default:
          if (destination.url) {
            window.open(destination.url);
          }
      }
    },
    [getApplyDestination],
  );
};
