import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator } from "react-native";

import { BUTTON_TYPE, Button, Display2, Dropdown, Heading2, TextInput } from "@bwll/bw-components/next";
import { useBreakpoints, useGetMember } from "@bwll/bw-hooks";
import { COLORS } from "@bwll/bw-styles";
import { DATE_FORMATS, REGIONS_ORDERED } from "@bwll/bw-types";
import { formatDate, formatPhoneNumber } from "@bwll/bw-utils";

import { PROFILE_EDIT_TEST_IDS as TEST_IDS } from "./ProfileEditForm.constants";
import * as Styled from "./ProfileEditForm.styles";
import { ProfileEditFormProps } from "./ProfileEditForm.types";

export const ProfileEditForm = ({ onSubmit, onNavigateBack }: ProfileEditFormProps) => {
  const i18next = useTranslation();
  const { isMobile } = useBreakpoints();

  const { isLoading, data: member } = useGetMember({
    transform: (response) => {
      const { legalName, birthDate, primaryPhoneNumber, email } = response.individual;

      const { streetAddress, streetAddress2, city, regionName, regionId, postalCode } =
        response.individual.primaryAddress;

      return {
        legalName,
        dateOfBirth: formatDate({
          dateString: birthDate,
          dateFormat: DATE_FORMATS.MMMM_dd_yyyy,
          ignoreOffset: true,
        }),
        phoneNumber: formatPhoneNumber(primaryPhoneNumber) ?? primaryPhoneNumber,
        email,
        streetAddress,
        streetAddress2,
        city,
        regionName,
        regionId,
        postalCode,
      };
    },
  });

  const [formData, setFormData] = useState(member);

  const editHeading = i18next.t("profile:edit:headings:edit");
  const identityHeading = i18next.t("profile:edit:headings:identity");
  const contactHeading = i18next.t("profile:edit:headings:contact");
  const addressHeading = i18next.t("profile:edit:headings:address");

  const nameLabel = i18next.t("profile:edit:form:fullNameLabel");
  const birthDateLabel = i18next.t("profile:edit:form:dateOfBirthLabel");
  const phoneLabel = i18next.t("profile:edit:form:phoneNumberLabel");
  const emailLabel = i18next.t("profile:edit:form:emailAddressLabel");
  const address1Label = i18next.t("profile:edit:form:streetAddress1Label");
  const address2Label = i18next.t("profile:edit:form:streetAddress2Label");
  const cityLabel = i18next.t("profile:edit:form:cityLabel");
  const regionLabel = i18next.t("profile:edit:form:regionLabel");
  const postalCodeLabel = i18next.t("profile:edit:form:postalCodeLabel");

  const updateButtonText = i18next.t("profile:edit:buttons:updateProfile");
  const discardButtonText = i18next.t("profile:edit:buttons:discardChanges");

  return isLoading || !formData ? (
    <ActivityIndicator size="small" color={COLORS.NEUTRAL.COOL["600"]} />
  ) : (
    <Styled.Container>
      <Display2>{editHeading}</Display2>

      <Styled.SectionContainer>
        <Heading2>{identityHeading}</Heading2>
        <Styled.InputSection isMobile={isMobile}>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput label={nameLabel} value={formData.legalName} currentState={{ disabled: true }} />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={birthDateLabel}
              value={formData.dateOfBirth}
              currentState={{ disabled: true }}
            />
          </Styled.InputSectionItem>
        </Styled.InputSection>
      </Styled.SectionContainer>

      <Styled.SectionContainer>
        <Heading2>{contactHeading}</Heading2>
        <Styled.InputSection isMobile={isMobile}>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={phoneLabel}
              value={formData.phoneNumber}
              onChangeText={(value) => setFormData({ ...formData, phoneNumber: value })}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={emailLabel}
              value={formData.email}
              onChangeText={(value) => setFormData({ ...formData, email: value })}
            />
          </Styled.InputSectionItem>
        </Styled.InputSection>
      </Styled.SectionContainer>

      <Styled.SectionContainer>
        <Heading2>{addressHeading}</Heading2>
        <Styled.InputSection isMobile={isMobile}>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={address1Label}
              value={formData.streetAddress}
              onChangeText={(value) => setFormData({ ...formData, streetAddress: value })}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={address2Label}
              value={formData.streetAddress2}
              onChangeText={(value) => setFormData({ ...formData, streetAddress2: value })}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={cityLabel}
              value={formData.city}
              onChangeText={(value) => setFormData({ ...formData, city: value })}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <Dropdown
              label={regionLabel}
              options={REGIONS_ORDERED.map((region) => ({ label: region.name, value: region.id }))}
              value={formData.regionId}
              onValueChange={(value) => setFormData({ ...formData, regionId: value })}
            />
          </Styled.InputSectionItem>
          <Styled.InputSectionItem isMobile={isMobile}>
            <TextInput
              label={postalCodeLabel}
              value={formData.postalCode}
              onChangeText={(value) => setFormData({ ...formData, postalCode: value })}
            />
          </Styled.InputSectionItem>
        </Styled.InputSection>
      </Styled.SectionContainer>

      <Styled.SectionContainer>
        <Button
          type={BUTTON_TYPE.PRIMARY}
          onPress={() => onSubmit(formData)}
          title={updateButtonText}
          testID={TEST_IDS.BUTTON_UPDATE_PROFILE}
        />
        <Button
          type={BUTTON_TYPE.TERTIARY}
          onPress={onNavigateBack}
          title={discardButtonText}
          testID={TEST_IDS.BUTTON_DISCARD_CHANGES}
        />
      </Styled.SectionContainer>
    </Styled.Container>
  );
};
