import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  Chip,
  ElementTracker,
  PromoCardTemplate,
  PromoCardTemplateCtaContainer,
  PromoCardTemplateCtaText,
} from "@bwll/bw-components/next";
import {
  RecommendationSnackbarEventData,
  useAnalyticsContext,
  useBreakpoints,
  usePlatformContext,
} from "@bwll/bw-hooks";
import { COLORS } from "@bwll/bw-styles";
import { ANALYTICS_ATTRIBUTES, CREDIT_SCORE_REQUEST_STATUS_IDS } from "@bwll/bw-types";
import { capitalize, uuid } from "@bwll/bw-utils";

import {
  RECOMMENDATIONS_SNACKBAR_CTA_TEST_ID,
  RECOMMENDATIONS_SNACKBAR_TEST_ID,
} from "./RecommendationsSnackbar.constants";
import { RecommendationsSnackbarProps } from "./RecommendationsSnackbar.types";

export const RecommendationsSnackbar = ({
  creditScoreRequest,
  creditScores,
  enableAnalyticsEvent = true,
  shouldShowBadge = true,
  analyticsAttributes,
  redirectAction,
}: RecommendationsSnackbarProps) => {
  const { platform } = usePlatformContext();
  const { isMobile, isDesktop } = useBreakpoints();
  const i18next = useTranslation();

  const { snackbarClicked, snackbarViewed } = useAnalyticsContext();

  const hasBadCreditScore = () => {
    if (!creditScoreRequest) return true;

    const isInvalidData =
      creditScoreRequest?.realStatusId === CREDIT_SCORE_REQUEST_STATUS_IDS.REQUEST_INVALID_DATA;

    const isNoHit =
      creditScoreRequest?.statusId === CREDIT_SCORE_REQUEST_STATUS_IDS.REQUEST_NO_HIT ||
      creditScoreRequest?.statusId === CREDIT_SCORE_REQUEST_STATUS_IDS.REQUEST_FAILED;
    const hasNoScore = !creditScores || creditScores.length === 0;

    const isZeroScore =
      hasNoScore ||
      !creditScoreRequest?.mostRecentCreditScore?.score ||
      creditScoreRequest?.mostRecentCreditScore?.score === 0;

    return isNoHit || hasNoScore || isZeroScore || isInvalidData;
  };

  const body = hasBadCreditScore()
    ? i18next.t("dashboard:recommendationsSnackbar.bodyBadCredit")
    : i18next.t("dashboard:recommendationsSnackbar.body");

  const buttonText = i18next.t("dashboard:recommendationsSnackbar.buttonText");

  const destinationUrl = analyticsAttributes?.destinationUrl ?? "";

  const eventData = useMemo(() => {
    return {
      [ANALYTICS_ATTRIBUTES.SNACKBAR_TEXT]: body,
      [ANALYTICS_ATTRIBUTES.BUTTON_TEXT]: capitalize(buttonText),
      [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: destinationUrl,
      [ANALYTICS_ATTRIBUTES.SNACKBAR_TYPE]: "pc_vertical_linking",
      [ANALYTICS_ATTRIBUTES.SNACKBAR_IMPRESSION_ID]: uuid(),
    };
  }, [body, buttonText, destinationUrl]);

  const onPress = () => {
    snackbarClicked<RecommendationSnackbarEventData>?.(eventData);
    redirectAction();
  };

  return (
    <ElementTracker
      shouldStartTracking={enableAnalyticsEvent}
      trackingHandler={() => snackbarViewed<RecommendationSnackbarEventData>?.(eventData)}
    >
      <PromoCardTemplate
        variant={isMobile ? "card" : "snackbar"}
        icon={"sparkle"}
        body={body}
        cta={
          <PromoCardTemplateCtaContainer
            testID={RECOMMENDATIONS_SNACKBAR_CTA_TEST_ID}
            onPress={onPress}
            isSnackbar={isDesktop}
          >
            <PromoCardTemplateCtaText platform={platform}>{buttonText}</PromoCardTemplateCtaText>
            {shouldShowBadge && (
              <Chip text={"6"} backgroundColor={COLORS.RED[500]} textColor={COLORS.WHITE} />
            )}
          </PromoCardTemplateCtaContainer>
        }
        testID={RECOMMENDATIONS_SNACKBAR_TEST_ID}
      />
    </ElementTracker>
  );
};
