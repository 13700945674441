import { SvgImageKey } from "@bwll/bw-components/next";

export const HERO_BACKGROUND_COLOR = "#F9F8F4";
export const HERO_HEADING_COLOR = "#474D73";

export const IMAGE_KEYS: Record<string, SvgImageKey> = {
  CREDIT_SCORE_GROWTH: "ra-credit-score-growth",
  SIGN_UP: "ra-sign-up",
  CONFIRM_YOUR_RENT: "ra-confirm-your-rent",
  BUILD_CREDIT_HISTORY: "ra-build-credit-history",
  WATCH_CREDIT_GROW: "ra-watch-credit-grow",
  BETTER_INTEREST_RATES: "ra-better-interest-rates",
  QUALIFY_MORE_PRODUCTS: "ra-qualify-more-products",
} as const;
