import styled from "@emotion/native";

import { Body1, Button } from "@bwll/bw-components/next";
import {
  COLORS,
  borderWidth,
  fontSize,
  fontWeights,
  letterSpacing,
  lineHeight,
  spacing,
} from "@bwll/bw-styles";
import { styledValue as sv } from "@bwll/bw-utils";

const disabledBackgroundColour = COLORS.NEUTRAL.COOL["200"];
const disabledTextColour = COLORS.NEUTRAL.COOL["400"];

export const CounterButton = styled(Button)<{ disabled: boolean }>`
  border-color: ${COLORS.BRIGHT_GREEK["400"]};
  border-width: ${sv(borderWidth.small)};
  padding: ${sv(12)} ${sv(18)};

  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${disabledBackgroundColour};
    border-color: ${disabledTextColour};
  `}
`;

export const CounterTextContainer = styled.View`
  flex-direction: row;
  align-items: center;
  gap: ${sv(spacing.xxs)};
`;

export const CounterButtonText = styled(Body1)<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? disabledTextColour : COLORS.BRIGHT_GREEK["400"])};
  font-size: ${sv(fontSize.xs)};
  font-weight: ${fontWeights.bold};
  letter-spacing: ${sv(letterSpacing.xxl)};
  line-height: ${sv(lineHeight.xs)};
`;

export const CounterCircleText = styled.View<{ disabled: boolean }>`
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: ${({ disabled }) => (disabled ? disabledTextColour : COLORS.PRIMARY["600"])};
  color: ${COLORS.WHITE};
  font-size: ${sv(fontSize.s)};
  width: ${sv(spacing.m)};
  height: ${sv(spacing.m)};
`;
