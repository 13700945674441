import { useMemo } from "react";

import {
  DashboardComponentAnalyticsAttributes,
  HierarchyPromoCardCommonEventData,
  HierarchyPromoCardEventData,
  TFeatureAnalyticsAttributes,
  useEnvironmentContext,
} from "@bwll/bw-hooks";
import {
  ANALYTICS_ATTRIBUTES,
  ANALYTICS_VALUES,
  CardHierarchyResponse,
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  IEndpoint,
  PvPreSelectedProduct,
} from "@bwll/bw-types";

import { HierarchyPromoCardData, UseHierarchyPromoCardEventData } from "./HierarchyPromoCard.types";

const noEventData = {
  clickedEventData: {},
  viewedEventData: {},
} as HierarchyPromoCardEventData;

const getPreSelectionPlatformSpecificAttributes = (
  isMobile: boolean,
  preSelectedProduct: PvPreSelectedProduct,
  impressionId: string,
) => {
  const commonPreSelectionEventProperties = {
    [ANALYTICS_ATTRIBUTES.CARD_PROMO_CATEGORY]: ANALYTICS_VALUES.PRE_SELECTION,
    [ANALYTICS_ATTRIBUTES.CARD_LOCATION]: ANALYTICS_VALUES.DASHBOARD_LOCATION,
  };

  if (isMobile) {
    const mobileImpressionIdMapping: Record<string, string> = {
      Fairstone: "dashboard-fs-loan-preselection-card",
      "ConsumerCapital Canada": "dashboard-ccc-preselection-card",
      MBNA: "dashboard-mbna-preselection-card",
      "American Express": "dashboard-amex-preselection-card",
      BMO: "dashboard-bmo-preselection-card",
      "SkyCap Financial": "dashboard-skycap-preselection-card",
    };

    const mobileImpressionId = mobileImpressionIdMapping[preSelectedProduct.partner];

    return {
      ...commonPreSelectionEventProperties,
      [ANALYTICS_ATTRIBUTES.POSITION]: ANALYTICS_VALUES.DASHBOARD_LOCATION,
      [ANALYTICS_ATTRIBUTES.HIERARCHY_IMPRESSION_ID]: mobileImpressionId,
      [ANALYTICS_ATTRIBUTES.CARD_POSITION]: ANALYTICS_VALUES.FEED_POSITION,
    };
  }

  return {
    ...commonPreSelectionEventProperties,
    [ANALYTICS_ATTRIBUTES.EVENT_ACTION]: EVENT_ACTIONS.EVENT,
    [ANALYTICS_ATTRIBUTES.EVENT_CATEGORY]: EVENT_CATEGORIES.EVENT,
    [ANALYTICS_ATTRIBUTES.CARD_POSITION]: ANALYTICS_VALUES.DASHBOARD_SIDEBAR_LOCATION,
    [ANALYTICS_ATTRIBUTES.POSITION]: ANALYTICS_VALUES.DASHBOARD_SIDEBAR_LOCATION,
    [ANALYTICS_ATTRIBUTES.HIERARCHY_PROMO_CARD_IMPRESSION_ID]: impressionId,
  };
};

const getPreSelectionEventData = (
  isMobile: boolean,
  impressionId: string,
  preSelectedProduct?: PvPreSelectedProduct,
  hierarchyPromoCardData?: HierarchyPromoCardData,
  analyticsAttributes?: TFeatureAnalyticsAttributes<DashboardComponentAnalyticsAttributes>,
) => {
  if (!preSelectedProduct || !hierarchyPromoCardData) {
    return noEventData;
  }

  //No difference between Viewed and Clicked events.
  const eventData = {
    [ANALYTICS_ATTRIBUTES.AMOUNT]: preSelectedProduct.amount,
    [ANALYTICS_ATTRIBUTES.PARTNER]: preSelectedProduct.partner,
    //Only Multi product promo card has this value as 2_promo_card, all others are 1_promo_card
    [ANALYTICS_ATTRIBUTES.RANKING]: ANALYTICS_VALUES.HIERARCHY_PROMO_CARD_RANKING,
    [ANALYTICS_ATTRIBUTES.VERTICAL]: preSelectedProduct.vertical,
    [ANALYTICS_ATTRIBUTES.CARD_PROMO_TEXT_HEADER]: hierarchyPromoCardData.cardTitle,
    [ANALYTICS_ATTRIBUTES.CARD_PROMO_TEXT_BODY]: hierarchyPromoCardData.cardBody,
    [ANALYTICS_ATTRIBUTES.CALL_TO_ACTION]: hierarchyPromoCardData.cardButtonText,
    [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: analyticsAttributes?.destinationUrl ?? "",
    ...getPreSelectionPlatformSpecificAttributes(isMobile, preSelectedProduct, impressionId),
  } as unknown as HierarchyPromoCardCommonEventData;

  return {
    clickedEventData: eventData,
    viewedEventData: eventData,
  };
};

const getPlatformSpecificCbEventData = (isMobile: boolean, impressionId: string) => {
  if (isMobile) {
    return {
      [ANALYTICS_ATTRIBUTES.CARD_POSITION]: ANALYTICS_VALUES.FEED_POSITION,
      [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: "app/cbl/root",
      [ANALYTICS_ATTRIBUTES.PROMO_CATEGORY]: ANALYTICS_VALUES.CATEGORY_PRODUCT_CATALOG_MOBILE,
      [ANALYTICS_ATTRIBUTES.PROMO_CARD_NAME]: ANALYTICS_VALUES.IMPRESSION_DASHBOARD_CBL_PROMO_CARD_V2,
    };
  }

  return {
    [ANALYTICS_ATTRIBUTES.CARD_POSITION]: ANALYTICS_VALUES.DASHBOARD_SIDEBAR_LOCATION,
    [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: "/app/cb",
    [ANALYTICS_ATTRIBUTES.PROMO_CATEGORY]: ANALYTICS_VALUES.CATEGORY_PRODUCT_CATALOG,
    [ANALYTICS_ATTRIBUTES.EVENT_ACTION]: EVENT_ACTIONS.EVENT,
    [ANALYTICS_ATTRIBUTES.EVENT_CATEGORY]: EVENT_CATEGORIES.EVENT,
    [ANALYTICS_ATTRIBUTES.HIERARCHY_PROMO_CARD_IMPRESSION_ID]: impressionId,
    //Only Multi product promo card has this value as 2_promo_card, all others are 1_promo_card
    [ANALYTICS_ATTRIBUTES.RANKING]: ANALYTICS_VALUES.HIERARCHY_PROMO_CARD_RANKING,
  };
};

const getActionSpecificCbEventData = (isMobile: boolean, isClickAction: boolean) => {
  if (!isMobile) {
    return {};
  }

  return isClickAction
    ? {
        [ANALYTICS_ATTRIBUTES.CLICK_ID]: ANALYTICS_VALUES.IMPRESSION_DASHBOARD_CBL_PROMO_CARD_V2,
      }
    : {
        [ANALYTICS_ATTRIBUTES.IMPRESSION_ID]: ANALYTICS_VALUES.IMPRESSION_DASHBOARD_CBL_PROMO_CARD_V2,
      };
};

const getCbEventData = (
  isMobile: boolean,
  impressionId: string,
  hierarchyPromoCardData?: HierarchyPromoCardData,
): HierarchyPromoCardEventData => {
  if (!hierarchyPromoCardData) {
    return noEventData;
  }

  const imageName = hierarchyPromoCardData?.imageName ?? ANALYTICS_VALUES.CBL2_DEFAULT_IMAGE_NAME;

  const commonEventData = {
    [ANALYTICS_ATTRIBUTES.BUTTON_TEXT]: hierarchyPromoCardData.cardButtonText,
    [ANALYTICS_ATTRIBUTES.CARD_LOCATION]: ANALYTICS_VALUES.DASHBOARD_LOCATION,
    [ANALYTICS_ATTRIBUTES.PROMO_TEXT_BODY]: hierarchyPromoCardData.cardBody,
    [ANALYTICS_ATTRIBUTES.PROMO_TEXT_HEADER]: hierarchyPromoCardData.cardTitle,
    [ANALYTICS_ATTRIBUTES.IMAGE]: imageName,
    ...getPlatformSpecificCbEventData(isMobile, impressionId),
  };

  return {
    clickedEventData: {
      ...commonEventData,
      ...getActionSpecificCbEventData(isMobile, false),
    },
    viewedEventData: {
      ...commonEventData,
      ...getActionSpecificCbEventData(isMobile, true),
    },
  };
};

const getPlatformSpecificRaEventData = (
  isMobile: boolean,
  impressionId: string,
  hierarchyPromoCardData?: HierarchyPromoCardData,
) => {
  if (isMobile) {
    return {
      [ANALYTICS_ATTRIBUTES.CARD_POSITION]: ANALYTICS_VALUES.FEED_POSITION,
      [ANALYTICS_ATTRIBUTES.PROMO_CATEGORY]: ANALYTICS_VALUES.PROMO_CATEGORY_RENT_ADVANTAGE_MOBILE,
    };
  }

  return {
    [ANALYTICS_ATTRIBUTES.CARD_POSITION]: ANALYTICS_VALUES.DASHBOARD_SIDEBAR_LOCATION,
    [ANALYTICS_ATTRIBUTES.PROMO_CATEGORY]: ANALYTICS_VALUES.PROMO_CATEGORY_RENT_ADVANTAGE_WEB,
    [ANALYTICS_ATTRIBUTES.EVENT_ACTION]: EVENT_ACTIONS.EVENT,
    [ANALYTICS_ATTRIBUTES.EVENT_CATEGORY]: EVENT_CATEGORIES.EVENT,
    [ANALYTICS_ATTRIBUTES.HIERARCHY_PROMO_CARD_IMPRESSION_ID]: impressionId,
    [ANALYTICS_ATTRIBUTES.PROMO_IMAGE]: hierarchyPromoCardData?.imageName,
  };
};

const getActionSpecificRaEventData = (isMobile: boolean, isClickAction: boolean, envConfigs: IEndpoint) => {
  if (!isMobile) {
    return {};
  }

  return isClickAction
    ? {
        [ANALYTICS_ATTRIBUTES.CARD_RANK]: ANALYTICS_VALUES.HIERARCHY_PROMO_CARD_RANKING,
      }
    : {
        [ANALYTICS_ATTRIBUTES.PRODUCT_ID]: envConfigs.RENT_REPORTING_PRODUCT_ID,
        [ANALYTICS_ATTRIBUTES.TRACKING_TAG]: ANALYTICS_VALUES.RENT_ADVANTAGE_TRACKING_TAG,
      };
};

const getRaEventData = (
  isMobile: boolean,
  impressionId: string,
  envConfigs: IEndpoint,
  analyticsAttributes?: TFeatureAnalyticsAttributes<DashboardComponentAnalyticsAttributes>,
  hierarchyPromoCardData?: HierarchyPromoCardData,
): HierarchyPromoCardEventData => {
  if (!hierarchyPromoCardData) {
    return noEventData;
  }

  const imageName = hierarchyPromoCardData?.imageName ?? ANALYTICS_VALUES.RENT_ADVANTAGE_DEFAULT_IMAGE_NAME;

  const commonEventData = {
    [ANALYTICS_ATTRIBUTES.BUTTON_TEXT]: hierarchyPromoCardData.cardButtonText,
    [ANALYTICS_ATTRIBUTES.CARD_LOCATION]: ANALYTICS_VALUES.DASHBOARD_LOCATION,
    [ANALYTICS_ATTRIBUTES.PROMO_TEXT_BODY]: hierarchyPromoCardData.cardBody,
    [ANALYTICS_ATTRIBUTES.PROMO_TEXT_HEADER]: hierarchyPromoCardData.cardTitle,
    [ANALYTICS_ATTRIBUTES.IMAGE]: imageName,
    [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: analyticsAttributes?.destinationUrl ?? "",
    ...getPlatformSpecificRaEventData(isMobile, impressionId),
  };

  return {
    clickedEventData: {
      ...commonEventData,
      ...getActionSpecificRaEventData(isMobile, false, envConfigs),
    },
    viewedEventData: {
      ...commonEventData,
      ...getActionSpecificRaEventData(isMobile, true, envConfigs),
    },
  };
};

export const useHierarchyPromoCardEventData = ({
  isMobile,
  impressionId,
  preSelectedProduct,
  hierarchyPromoCardData,
  analyticsAttributes,
  promoCardType,
}: UseHierarchyPromoCardEventData): HierarchyPromoCardEventData => {
  const { envConfigs } = useEnvironmentContext();
  return useMemo(() => {
    switch (promoCardType) {
      case CardHierarchyResponse.PRESELECTION:
        return getPreSelectionEventData(
          isMobile,
          impressionId,
          preSelectedProduct,
          hierarchyPromoCardData,
          analyticsAttributes,
        );

      case CardHierarchyResponse.CBL2:
        return getCbEventData(isMobile, impressionId, hierarchyPromoCardData);

      case CardHierarchyResponse.RENT_REPORTING:
        return getRaEventData(
          isMobile,
          impressionId,
          envConfigs,
          analyticsAttributes,
          hierarchyPromoCardData,
        );

      default:
        return noEventData;
    }
  }, [
    isMobile,
    impressionId,
    preSelectedProduct,
    hierarchyPromoCardData,
    analyticsAttributes,
    promoCardType,
    envConfigs,
  ]);
};
