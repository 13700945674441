export const errors = {
  "default": {
    "title": "Oops! Our marketplace needs a moment",
    "description1": "We apologize, but we're facing difficulties loading this page.",
    "description2": "Our team is actively working on it, but in the meantime, you may find it helpful to try logging out and then logging back in again."
  },
  "emptyCreditCards": {
    "title": "No results... yet",
    "description1": "You may not qualify for any of our offers right now",
    "description2": "Our team is looking for new products to bring you every day! Improving your credit score can yield more results. Check out Credit Builder to build your credit history!"
  },
  "emptyBankAccounts": {
    "title": "No offers... yet",
    "description1": "You may not qualify for any of our offers right now",
    "description2": "Our team is looking for new products to bring you every day! Improving your credit score can yield more results. Check out Credit Builder to build your credit history!"
  }
}
