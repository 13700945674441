import { useCallback } from "react";

import {
  IMPRESSION_EVENT_NAMES,
  ON_LINK_CLICK_EVENT_NAMES,
  TButtonAnalyticsAttributes,
  TFeatureAnalyticsAttributes,
  TLinkAnalyticsAttributes,
  TSharedAnalyticsAttributes,
} from "./analyticsContext.types";
import { TImpressionAnalyticsAttributes } from "./featuresAttributes";
import { useHistoricalReportingEvents, useRentAdvantageEvents } from "./featuresEvents";
import { useMarketplaceEvents } from "./featuresEvents/marketplace/useMarketplaceEvents";
import { useCreditCoachUpdatesCardAnalytics } from "./useCreditCoachUpdatesCardAnalytics";
import { useCreditFactoUpdateTrackEvents } from "./useCreditFactorUpdateTrackEvents";
import { usePopupTrackEvents } from "./usePopupTrackEvents";
import { useProductTrackEvents } from "./useProductTrackEvents";
import { usePromoCardTrackEvents } from "./usePromoCardTrackEvents";
import { useRentAdvantageWidgetTrackEvents } from "./useRentAdvantageWidgetTrackEvents";
import { useSnackbarTrackEvents } from "./useSnackbarTrackEvents";
import { useSponsoredCarouselTrackEvents } from "./useSponsoredCarouselTrackEvents";

/**
 *
 * All shared Analytics/Segment events should be placed on this hook.
 * @returns
 */

/**
 * List of available events. This should only contain core event names, not feature related.
 */
const enum ANALYTICS_EVENT_NAMES {
  BUTTON_CLICK = "Button Click",
  // COMING SOON: View, Navigate, On Error | Future Events
}

export const useSharedAnalytics = ({ commonAttributes = {}, trackEvent }: TSharedAnalyticsAttributes) => {
  // Feature Events
  const snackbarEvents = useSnackbarTrackEvents({ trackEvent, commonAttributes });
  const creditFactorEvents = useCreditFactoUpdateTrackEvents({ trackEvent, commonAttributes });
  const promoCardEvents = usePromoCardTrackEvents({ trackEvent, commonAttributes });
  const rentAdvantageEvents = useRentAdvantageEvents({ trackEvent, commonAttributes });
  const marketplaceEvents = useMarketplaceEvents({ trackEvent, commonAttributes });

  const popupAnalytics = usePopupTrackEvents({ trackEvent, commonAttributes });
  const sponsoredCarouselAnalytics = useSponsoredCarouselTrackEvents({ trackEvent, commonAttributes });
  const productAnalytics = useProductTrackEvents({ trackEvent, commonAttributes });
  const creditCoachUpdatesAnalytics = useCreditCoachUpdatesCardAnalytics({ trackEvent, commonAttributes });
  const rentAdvantageWidgetAnalytics = useRentAdvantageWidgetTrackEvents({ trackEvent, commonAttributes });

  const historicalReportingEvents = useHistoricalReportingEvents({ trackEvent, commonAttributes });

  // Common Events
  const onButtonClick = useCallback(
    (eventAttributes: TButtonAnalyticsAttributes & TFeatureAnalyticsAttributes) =>
      trackEvent(
        `${ANALYTICS_EVENT_NAMES.BUTTON_CLICK}${
          eventAttributes?.flowName ? `: ${eventAttributes.flowName}` : ""
        }`,
        {
          ...commonAttributes,
          ...eventAttributes,
        },
      ),
    [commonAttributes, trackEvent],
  );

  const impressionView = useCallback(
    <TCustomComponentAttributes = TImpressionAnalyticsAttributes>({
      eventName = IMPRESSION_EVENT_NAMES.IMPRESSION_VIEW,
      eventAttributes,
    }: {
      eventName?: IMPRESSION_EVENT_NAMES;
      eventAttributes: TFeatureAnalyticsAttributes<TCustomComponentAttributes>;
    }) =>
      trackEvent(`${eventName}${eventAttributes?.flowName ? `: ${eventAttributes.flowName}` : ""}`, {
        ...commonAttributes,
        ...eventAttributes,
      }),
    [commonAttributes, trackEvent],
  );

  const onLinkClick = useCallback(
    <TCustomComponentAttributes = TLinkAnalyticsAttributes>({
      eventName = ON_LINK_CLICK_EVENT_NAMES.LINK_CLICK,
      eventAttributes,
    }: {
      eventName?: ON_LINK_CLICK_EVENT_NAMES;
      eventAttributes: TCustomComponentAttributes & TFeatureAnalyticsAttributes;
    }) =>
      trackEvent(`${eventName}${eventAttributes?.flowName ? `: ${eventAttributes.flowName}` : ""}`, {
        ...commonAttributes,
        ...eventAttributes,
      }),
    [commonAttributes, trackEvent],
  );

  return {
    onButtonClick,
    impressionView,
    onLinkClick,
    sponsoredCarouselAnalytics,
    productAnalytics,
    creditCoachUpdatesAnalytics,
    popupAnalytics,
    ...snackbarEvents,
    ...promoCardEvents,
    ...creditFactorEvents,
    ...rentAdvantageEvents,
    ...marketplaceEvents,
    historicalReportingEvents,
    rentAdvantageWidgetAnalytics,
  };
};
