import { BasePrimePromoCard, GemstoneAppRoute, PrimeCardType } from "@bwll/bw-types";

import {
  CLU_PROMO_CARD_NAME,
  COMPARE_BANK_ACCOUNTS_PROMO_CARD_NAME,
  PRIME_PRODUCT_PICKS_PROMO_CARD_NAME,
} from "./TopPicksPromoCardsV2.constants";

export interface TopPicksPromoCardV2Type extends BasePrimePromoCard {
  onPress: () => void;
}

export type TopPicksPromoCardsV2Props = {
  promoCards: TopPicksPromoCardV2Type[];
  testID?: string;
};

export const PRIME_PROMO_CARD_IMAGE_MAPPING: Record<PrimeCardType, string> = {
  [PrimeCardType.CreditCards]: "$web/assets/dashboard/top-picks-credit-cards.png",
  [PrimeCardType.BankAccounts]: "$web/assets/dashboard/top-picks-bank-accounts.png",
  [PrimeCardType.CreditLimitUtilization]: "$web/assets/dashboard/top-picks-clu.png",
};

export const PRIME_PROMO_CARD_NAME_MAPPING: Record<PrimeCardType, string> = {
  [PrimeCardType.CreditCards]: PRIME_PRODUCT_PICKS_PROMO_CARD_NAME,
  [PrimeCardType.BankAccounts]: COMPARE_BANK_ACCOUNTS_PROMO_CARD_NAME,
  [PrimeCardType.CreditLimitUtilization]: CLU_PROMO_CARD_NAME,
};

export type TopPicksPromoCardsV2ContainerProps = {
  mobileNavigationFn?: (primeCardType: PrimeCardType, productRoute: string) => void;
  webNavigationFn?: (gemstoneAppRoute: GemstoneAppRoute) => void;
  shouldShowPhase2Features?: boolean;
  enabled?: boolean;
};
